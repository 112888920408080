import { keccak256 } from "./crypto/sha3";
import { CurrencyType, OptsType } from "./types";

const verifyChecksum = (address: string) => {
  // Check each case
  address = address.replace('0x','');

  const addressHash = keccak256(address.toLowerCase());

  for (let i = 0; i < 40; i++ ) {
    // The nth letter should be uppercase if the nth digit of casemap is 1
    if ((parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) ||
      (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])) {
      return false;
    }
  }

  return true;
};

const isValidAddress = (address: string, currency?: CurrencyType, opts?: OptsType) => {
    if (!/^0x[0-9a-fA-F]{40}$/.test(address)) {
        return false;
    }

    if (/^0x[0-9a-f]{40}$/.test(address) || /^0x?[0-9A-F]{40}$/.test(address)) {
        return true;
    }

    return verifyChecksum(address);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isValidAddress,
};
