import { Switch } from '@mui/material';
import styled from 'styled-components';

export const SwitchStyles: any = styled(Switch)`
  align-items: center;
  padding: 2px!important;
  margin-right: 9px;
  width: 39px!important;
  height: 20px!important;
  border-radius: 12px;
  overflow: hidden;
  font-family: var(--inter);

  &.MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(17px);
  }

  .MuiSwitch-switchBase {
    padding: 0;
    margin: 2px;
  }

  .MuiSwitch-thumb {
    width: 16px;
    height: 16px;
    background-color: var(--white);
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05);
  }

  .MuiSwitch-track {
    width: 39px;
    height: 20px;
    background-color: #F2F4F7;
    border-radius: 12px;
    opacity: 1;
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: var(--purple)!important;
    opacity: 1!important;
  }

  .Mui-checked {
    color: var(--black);
  }
  
  .MuiTypography-root {
    font-family: var(--inter);
  }
`;
