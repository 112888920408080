export const config = {
  REACT_APP_API_URL: 'https://api.cryptoprocessing.io/api/lk/v1',
  REACT_APP_API_TESTNET_URL: 'https://testnet.cryptoprocessing.io/api/lk/v1',
  REACT_APP_PAY_URL: 'https://pay.cryptoprocessing.io',
  REACT_APP_PAY_TESTNET_URL: 'https://pay.testnet.cryptoprocessing.io',
  REACT_APP_APPLICATION_UID: '1c6ef1a127d490a646c7f5362954308e5a99ee4215c61a003b1f2db08739717f',
  REACT_APP_GOOGLE_OAUTH_CLIENT_ID: '28469131122-pmca7ghfsuk86saj6glrgektvftnpdnb.apps.googleusercontent.com',
  REACT_APP_GITHUB_OAUTH_CLIENT_ID: 'd1e40d6244360f481f54',
  REACT_APP_OAUTH_REDIRECT_URI: 'https://app.cryptoprocessing.io',
}
