import types from '../actionTypes';
import {
  CreateWithdrawalParams,
  GetWithdrawalsParams,
  DeclineWithdrawalParams
} from "../../api";

const createWithdrawal = (payload: CreateWithdrawalParams) => ({
  type: types.CREATE_WITHDRAWAL_REQUEST,
  payload,
});

const getWithdrawals = (payload: GetWithdrawalsParams) => ({
  type: types.GET_WITHDRAWALS_REQUEST,
  payload,
});

const declineWithdrawal = (payload: DeclineWithdrawalParams) => ({
  type: types.DECLINE_WITHDRAWAL_REQUEST,
  payload,
});

const setWithdrawalsLoading = (payload: GetWithdrawalsParams) => ({
  type: types.SET_WITHDRAWALS_LOADING,
  payload,
});

export {
  createWithdrawal,
  getWithdrawals,
  declineWithdrawal,
  setWithdrawalsLoading,
};
