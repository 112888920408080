import configObj from "../config";

const projectConfig: string = process.env.REACT_APP_PROJECT_CONFIG || 'staging';
const config = configObj[projectConfig];

export const API_URL: string = config.REACT_APP_API_URL;
export const API_TESTNET_URL: string = config.REACT_APP_API_TESTNET_URL;
export const PAY_URL: string = config.REACT_APP_PAY_URL;
export const PAY_TESTNET_URL: string = config.REACT_APP_PAY_TESTNET_URL;
export const APPLICATION_UID: string = config.REACT_APP_APPLICATION_UID;
export const IS_TESTNET: boolean = window.location.href.includes('testnet');
export const GITHUB_OAUTH_CLIENT_ID: string = config.REACT_APP_GITHUB_OAUTH_CLIENT_ID;
export const GOOGLE_OAUTH_CLIENT_ID: string = config.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
export const OAUTH_REDIRECT_URI: string = config.REACT_APP_OAUTH_REDIRECT_URI;
