import {
  ActivateTFAParams, ConfirmEmailCodeParams, ConfirmUpdateEmailCodeParams, EmailData,
  LogInParams, OAuthlogInParams,
  RegistrationParams, ResendConfirmationCodeParams, UpdatePasswordParams, UserData
} from '../../api';

const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';
const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
const UPDATE_FORGOTTEN_PASSWORD_REQUEST = 'UPDATE_FORGOTTEN_PASSWORD_REQUEST';
const UPDATE_FORGOTTEN_PASSWORD_SUCCESS = 'UPDATE_FORGOTTEN_PASSWORD_SUCCESS';
const UPDATE_FORGOTTEN_PASSWORD_FAILURE = 'UPDATE_FORGOTTEN_PASSWORD_FAILURE';
const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST';
const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE';
const RESEND_CONFIRMATION_REQUEST = 'RESEND_CONFIRMATION_REQUEST';
const RESEND_CONFIRMATION_SUCCESS = 'RESEND_CONFIRMATION_SUCCESS';
const RESEND_CONFIRMATION_FAILURE = 'RESEND_CONFIRMATION_FAILURE';
const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';
const GET_TFA_REQUEST = 'GET_TFA_REQUEST';
const GET_TFA_SUCCESS = 'GET_TFA_SUCCESS';
const GET_TFA_FAILURE = 'GET_TFA_FAILURE';
const ACTIVATE_TFA_REQUEST = 'ACTIVATE_TFA_REQUEST';
const ACTIVATE_TFA_SUCCESS = 'ACTIVATE_TFA_SUCCESS';
const ACTIVATE_TFA_FAILURE = 'ACTIVATE_TFA_FAILURE';
const TURN_OFF_TFA_REQUEST = 'TURN_OFF_TFA_REQUEST';
const TURN_OFF_TFA_SUCCESS = 'TURN_OFF_TFA_SUCCESS';
const TURN_OFF_TFA_FAILURE = 'TURN_OFF_TFA_FAILURE';
const TURN_ON_TFA_REQUEST = 'TURN_ON_TFA_REQUEST';
const TURN_ON_TFA_SUCCESS = 'TURN_ON_TFA_SUCCESS';
const TURN_ON_TFA_FAILURE = 'TURN_ON_TFA_FAILURE';
const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';
const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
const UPDATE_EMAIL_REQUEST = 'UPDATE_EMAIL_REQUEST';
const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
const UPDATE_EMAIL_FAILURE = 'UPDATE_EMAIL_FAILURE';
const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
const CLEAR = 'CLEAR';
const CHANGE_USING_TESTNET = 'CHANGE_USING_TESTNET';
const CHANGE_USING_TESTNET_SUCCESS = 'CHANGE_USING_TESTNET_SUCCESS';
const CHANGE_USING_TESTNET_FAILURE = 'CHANGE_USING_TESTNET_FAILURE';
const CONFIRM_CHANGE_EMAIL_REQUEST = 'CONFIRM_CHANGE_EMAIL_REQUEST';
const CONFIRM_CHANGE_EMAIL_SUCCESS = 'CONFIRM_CHANGE_EMAIL_SUCCESS';
const CONFIRM_CHANGE_EMAIL_FAILURE = 'CONFIRM_CHANGE_EMAIL_FAILURE';
const OAUTH_LOGIN_REQUEST = 'OAUTH_LOGIN_REQUEST';
const OAUTH_LOGIN_SUCCESS = 'OAUTH_LOGIN_SUCCESS';
const OAUTH_LOGIN_FAILURE = 'OAUTH_LOGIN_FAILURE';

type LoginActionType = {
  type: typeof LOGIN_SUCCESS | typeof LOGIN_FAILURE;
  payload?: any;
};

export type LoginRequest = {
  type: typeof LOGIN_REQUEST;
  payload?: LogInParams;
};

type RegistrationActionType = {
  type: typeof REGISTRATION_SUCCESS | typeof REGISTRATION_FAILURE;
  payload?: any;
};

export type RegistrationRequest = {
  type: typeof REGISTRATION_REQUEST;
  payload?: RegistrationParams;
};

type ForgotPasswordActionType = {
  type: typeof FORGOT_PASSWORD_SUCCESS | typeof FORGOT_PASSWORD_FAILURE;
  payload?: any;
};

export type ForgotPasswordRequest = {
  type: typeof FORGOT_PASSWORD_REQUEST;
  payload?: any;
};

type UpdateForgottenPasswordActionType = {
  type: typeof UPDATE_FORGOTTEN_PASSWORD_SUCCESS | typeof UPDATE_FORGOTTEN_PASSWORD_FAILURE;
  payload?: any;
};

export type UpdateForgottenPasswordRequest = {
  type: typeof UPDATE_FORGOTTEN_PASSWORD_REQUEST;
  payload?: any;
};

type ConfirmEmailActionType = {
  type: typeof CONFIRM_EMAIL_SUCCESS | typeof CONFIRM_EMAIL_FAILURE;
  payload?: any;
};

export type ConfirmEmailRequest = {
  type: typeof CONFIRM_EMAIL_REQUEST;
  payload: ConfirmEmailCodeParams;
};

type ResendConfirmationActionType = {
  type: typeof RESEND_CONFIRMATION_SUCCESS | typeof RESEND_CONFIRMATION_FAILURE;
  payload?: any;
};

export type ResendConfirmationRequest = {
  type: typeof RESEND_CONFIRMATION_REQUEST;
  payload: ResendConfirmationCodeParams;
};

type UpdatePasswordActionType = {
  type: typeof UPDATE_PASSWORD_SUCCESS | typeof UPDATE_PASSWORD_FAILURE;
  payload?: any;
};

export type UpdatePasswordRequest = {
  type: typeof UPDATE_PASSWORD_REQUEST;
  payload: UpdatePasswordParams;
};

type GetTFAActionType = {
  type: typeof GET_TFA_SUCCESS | typeof GET_TFA_FAILURE;
  payload?: any;
};

export type GetTFARequest = {
  type: typeof GET_TFA_REQUEST;
};

type ActivateTFAActionType = {
  type: typeof ACTIVATE_TFA_SUCCESS | typeof ACTIVATE_TFA_FAILURE;
  payload?: any;
};

export type ActivateTFARequest = {
  type: typeof ACTIVATE_TFA_REQUEST;
  payload: ActivateTFAParams;
};

type TurnOffTFAActionType = {
  type: typeof TURN_OFF_TFA_SUCCESS | typeof TURN_OFF_TFA_FAILURE;
  payload?: any;
};

export type TurnOffTFARequest = {
  type: typeof TURN_OFF_TFA_REQUEST;
  payload: ActivateTFAParams;
};

type TurnOnTFAActionType = {
  type: typeof TURN_ON_TFA_SUCCESS | typeof TURN_ON_TFA_FAILURE;
  payload?: any;
};

export type TurnOnTFARequest = {
  type: typeof TURN_ON_TFA_REQUEST;
  payload: ActivateTFAParams;
};

type GetProfileActionType = {
  type: typeof GET_PROFILE_SUCCESS | typeof GET_PROFILE_FAILURE;
  payload?: any;
};

export type GetProfileRequest = {
  type: typeof GET_PROFILE_REQUEST;
};

type UpdateProfileActionType = {
  type: typeof UPDATE_PROFILE_SUCCESS | typeof UPDATE_PROFILE_FAILURE;
  payload?: any;
};

export type UpdateProfileRequest = {
  type: typeof UPDATE_PROFILE_REQUEST;
  payload: UserData;
};

type UpdateEmailActionType = {
  type: typeof UPDATE_EMAIL_SUCCESS | typeof UPDATE_EMAIL_FAILURE;
  payload?: any;
};

export type UpdateEmailRequest = {
  type: typeof UPDATE_EMAIL_REQUEST;
  payload: EmailData;
};

type LogoutActionType = {
  type: typeof LOGOUT_SUCCESS | typeof LOGOUT_FAILURE;
};

export type LogoutRequest = {
  type: typeof LOGOUT_REQUEST;
};

export type ClearRequest = {
  type: typeof CLEAR;
};

export type ChangeUsingTestnet = {
  type: typeof CHANGE_USING_TESTNET;
  payload?: any;
};

export type ChangeUsingTestnetActionType = {
  type: typeof CHANGE_USING_TESTNET_SUCCESS | typeof CHANGE_USING_TESTNET_FAILURE;
  payload?: any;
};

type ConfirmChangeEmailActionType = {
  type: typeof CONFIRM_CHANGE_EMAIL_SUCCESS | typeof CONFIRM_CHANGE_EMAIL_FAILURE;
  payload?: any;
};

export type ConfirmChangeEmailRequest = {
  type: typeof CONFIRM_CHANGE_EMAIL_REQUEST;
  payload: ConfirmUpdateEmailCodeParams;
};

type OAuthLoginActionType = {
  type: typeof OAUTH_LOGIN_SUCCESS | typeof OAUTH_LOGIN_FAILURE;
  payload?: any;
};

export type OAuthLoginRequest = {
  type: typeof OAUTH_LOGIN_REQUEST;
  payload?: OAuthlogInParams;
};


// refactored for shorter version
export type LoginActionTypes = LoginActionType | LoginRequest;
export type RegistrationActionTypes = RegistrationActionType | RegistrationRequest;
export type ForgotPasswordActionTypes = ForgotPasswordActionType | ForgotPasswordRequest;
export type UpdateForgottenPasswordActionTypes = UpdateForgottenPasswordActionType | UpdateForgottenPasswordRequest;
export type ConfirmEmailActionTypes = ConfirmEmailActionType | ConfirmEmailRequest;
export type ResendConfirmationActionTypes = ResendConfirmationActionType | ResendConfirmationRequest;
export type UpdatePasswordActionTypes = UpdatePasswordActionType | UpdatePasswordRequest;
export type GetTFAActionTypes = GetTFAActionType | GetTFARequest;
export type ActivateTFAActionTypes = ActivateTFAActionType | ActivateTFARequest;
export type TurnOffTFAActionTypes = TurnOffTFAActionType | TurnOffTFARequest;
export type TurnOnTFAActionTypes = TurnOnTFAActionType | TurnOnTFARequest;
export type GetProfileActionTypes = GetProfileActionType | GetProfileRequest;
export type UpdateProfileActionTypes = UpdateProfileActionType | UpdateProfileRequest;
export type UpdateEmailActionTypes = UpdateEmailActionType | UpdateEmailRequest;
export type LogoutActionTypes = LogoutActionType | LogoutRequest;
export type ChangeUsingTestnetTypes = ChangeUsingTestnetActionType | ChangeUsingTestnet;
export type ConfirmChangeEmailActionTypes = ConfirmChangeEmailActionType | ConfirmChangeEmailRequest;
export type OAuthLoginActionTypes = OAuthLoginActionType | OAuthLoginRequest;

export type UserActionTypes =
  | LoginActionTypes
  | RegistrationActionTypes
  | ForgotPasswordActionTypes
  | UpdateForgottenPasswordActionTypes
  | ConfirmEmailActionTypes
  | ResendConfirmationActionTypes
  | UpdatePasswordActionTypes
  | GetTFAActionTypes
  | ActivateTFAActionTypes
  | TurnOffTFAActionTypes
  | TurnOnTFAActionTypes
  | GetProfileActionTypes
  | UpdateProfileActionTypes
  | UpdateEmailActionTypes
  | LogoutActionTypes
  | ClearRequest
  | ChangeUsingTestnetTypes
  | ConfirmChangeEmailActionTypes
  | OAuthLoginActionTypes;

export default {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  UPDATE_FORGOTTEN_PASSWORD_REQUEST,
  UPDATE_FORGOTTEN_PASSWORD_SUCCESS,
  UPDATE_FORGOTTEN_PASSWORD_FAILURE,
  CONFIRM_EMAIL_REQUEST,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE,
  RESEND_CONFIRMATION_REQUEST,
  RESEND_CONFIRMATION_SUCCESS,
  RESEND_CONFIRMATION_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  GET_TFA_REQUEST,
  GET_TFA_SUCCESS,
  GET_TFA_FAILURE,
  ACTIVATE_TFA_REQUEST,
  ACTIVATE_TFA_SUCCESS,
  ACTIVATE_TFA_FAILURE,
  TURN_OFF_TFA_REQUEST,
  TURN_OFF_TFA_SUCCESS,
  TURN_OFF_TFA_FAILURE,
  TURN_ON_TFA_REQUEST,
  TURN_ON_TFA_SUCCESS,
  TURN_ON_TFA_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  CLEAR,
  CHANGE_USING_TESTNET,
  CHANGE_USING_TESTNET_SUCCESS,
  CHANGE_USING_TESTNET_FAILURE,
  CONFIRM_CHANGE_EMAIL_REQUEST,
  CONFIRM_CHANGE_EMAIL_SUCCESS,
  CONFIRM_CHANGE_EMAIL_FAILURE,
  OAUTH_LOGIN_REQUEST,
  OAUTH_LOGIN_SUCCESS,
  OAUTH_LOGIN_FAILURE,
} as const;
