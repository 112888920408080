import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  // useMemo
} from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import {
  Button,
  Container, Input, Loader,
  MainContent,
  Tabs, TextArea, PhoneInput, Icon,
  // Skeleton,
  SelectAutocomplete,
  // Switch,
  Select,
  // Icon
} from '../../elements';
import {
  // FileLoader,
  SettingsContainer, SettingsSlider,
  // SettingsSlider
} from "./StoreSettings.Styles";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
// import {FormControlLabel} from "@mui/material";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import {TabsContentItem} from "../../elements/Tabs/Tabs.Styles";
import {
  CreateStoreParams,
  // APIKey,
  CreateWithdrawalRequisiteParams, DeleteWithdrawalRequisiteParams,
  Store,
  UpdateStoreInfoParams, WithdrawalRequisite,
} from "../../api";
import {validate} from "../../common/utils/address_validator";
import {AppStateType} from "../../store";
import {selectLoadingByKey} from "../../store/loadingsErrors/selectors";
import types from "../../store/actionTypes";
import {COUNTRIES, CountryWithLangs} from "../../const/countries.constants";
import {clear} from "../../store/user/actions";
import useToast from "../../hooks/useToast";
import {LOADING_TYPES} from "../../const/app.constants";
import find from "lodash/find";
import {StoresReducerState} from "../../store/stores/reducers";
import {
  getWithdrawalRequisites,
  createWithdrawalRequisite,
  deleteWithdrawalRequisite,
  getStoreInfo,
  updateStoreInfo, updateStore
} from "../../store/stores/actions";
import {validateEmail} from "../../common/utils/validators";
// import dayjs from "dayjs";
import {closeModal, openModal} from "../../store/app/actions";
// import CreateWithdrawalRequisite from "../../components/Modals/CreateWithdrawalRequisite";
import {PATHS} from "../../const/paths.constants";
import {formatNumber} from "../../common/utils/formatters";

export const SETTINGS_TABS_LIST = ['PAYMENTS', 'STORE_DETAILS', 'ADVANCED_SETTINGS'];
export enum SETTINGS_TABS {
  PAYMENTS = 'PAYMENTS',
  STORE_DETAILS = 'STORE_DETAILS',
  ADVANCED_SETTINGS = 'ADVANCED_SETTINGS'
}
export enum STORE_DETAILS_SECTIONS {
  BUSINESS = 'BUSINESS',
  BENEFICIAL = 'BENEFICIAL',
}

// const SORT_FIELD = {
//   name: 'name',
//   currency: 'currency',
//   created_at: 'created_at',
// };

const currenciesList = [
  {
    value: "USDTTRX",
    text: "USDT"
  }
]

export type StoreSettingsParams = {
  tab?: string;
  percentage: string;
  currency: string;
  address: string;
  auth: string;
};

export interface StoreSettingsProps {
  stores: StoresReducerState;
  updateStoreInfo: (payload: UpdateStoreInfoParams) => void;
  getStoreInfo: (payload: { storeId: string }) => void;
  loading: boolean;
  loadingUpdate: boolean;
  loadingWithdrawals: boolean;
  loadingCreateWithdrawal: boolean;
  loadingDeleteWithdrawal: boolean;
  openModal: (payload: any) => void;
  closeModal: () => void;
  createWithdrawalRequisite: (payload: CreateWithdrawalRequisiteParams) => void;
  getWithdrawalRequisites: (payload: { storeId: string }) => void;
  deleteWithdrawalRequisite: (payload: DeleteWithdrawalRequisiteParams) => void;
  clear: () => void;
  updateStore: (payload: {storeId: string; params: CreateStoreParams}) => void;
  loadingUpdateStore: boolean;
}

const StoreSettings: React.FC<StoreSettingsProps> = (props: StoreSettingsProps) => {
  const {
    stores,
    clear,
    loading,
    loadingUpdate,
    loadingWithdrawals,
    loadingCreateWithdrawal,
    loadingDeleteWithdrawal,
    updateStoreInfo,
    getStoreInfo,
    createWithdrawalRequisite,
    getWithdrawalRequisites,
    deleteWithdrawalRequisite,
    updateStore,
    loadingUpdateStore,
    // openModal,
    // closeModal,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toastError, toastSuccess } = useToast();
  let [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const countriesList = COUNTRIES.map((country: CountryWithLangs) => {
    return {
      value: country.code.toLowerCase(),
      text: country.en
    }
  })

  const [values, setValues] = useState<{ [key: string]: string }>({
    tab: tab || SETTINGS_TABS.PAYMENTS,
    storeDetailsSection: '',
    percentage: '30',
    currency: 'USDT',
    address: '',
    auth: '',
    companyName: '',
    country: '',
    companyAddress: '',
    companyRegNumber: '',
    website: '',
    shopName: '',
    firstName: '',
    secondName: '',
    personalPhone: '',
    email: '',
    position: '',
    name: '',
    min_btc_confirmations: '',
    min_eth_confirmations: '',
    allowed_amount_diff_percent: '',
    min_btc_tx_amount: '',
    min_usdteth_tx_amount: '',
    min_usdceth_tx_amount: '',
    min_daieth_tx_amount: '',
    min_usdttrx_tx_amount: '',
  });
  // const [withdrawals, setWithdrawals] = useState<WithdrawalRequisite[]>([]);
  const [withdrawal, setWithdrawal] = useState<WithdrawalRequisite>({
    address: '',
    currency: 'USDTTRX',
    id: '',
    name: '1',
    created_at: '',
    auth: ''
  });
  // const [conversions, setConversions] = useState<{ [key: string]: boolean }>({
  //   btc: true,
  //   eth: true,
  //   usdt: true
  // });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [withdrawalErrors, setWithdrawalErrors] = useState<{ [key: string]: string }>({});
  // const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  // const [files, setFiles] = useState<any[]>([]);
  const [activeShop, setActiveShop] = useState<string | undefined>(undefined);
  const [shopData, setShopData] = useState<Store | undefined>(undefined);
  // const [sortField, setSortField] = useState(SORT_FIELD.created_at);
  // const [sortDirection, setSortDirection] = useState<boolean>(true);
  const [hasStoreInfo, setHasStoreInfo] = useState<boolean>(false);
  const [hasStoreUpdate, setHasStoreUpdate] = useState<boolean>(false);
  const [loadingStoreDetailsSection, setLoadingStoreDetailsSection] = useState<STORE_DETAILS_SECTIONS | undefined>(undefined);
  const AuthWithdrawalInputRef = useRef<AuthCodeRef>(null);

  // const modalAddWithdrawal = () => (
  //   <div className="modal-content">
  //     <div className="modal-addWithdrawal">
  //       <CreateWithdrawalRequisite closeModal={handleCloseModal} applyModal={handleApplyModal} />
  //     </div>
  //   </div>
  // );

  useEffect(() => {
    if (values.tab === SETTINGS_TABS.PAYMENTS && stores.stores.loadedShop === LOADING_TYPES.LOADED) {
      if (stores.activeStore) {
        const shop: Store | undefined = find(stores.stores.list, { id: stores.activeStore })
        if (shop?.loadedWithdrawals === LOADING_TYPES.NOT_LOADED) {
          getWithdrawalRequisites({storeId: stores.activeStore});
        }

        if (shop?.loadedWithdrawals === LOADING_TYPES.LOADED) {
          if (stores.activeStore !== activeShop) {
            setShopData(shop);

            if (shop.withdrawals?.length && !withdrawal.id) {
              setWithdrawal(shop.withdrawals[0]);
            }
          }
        }
      }
    }

    if (values.tab === SETTINGS_TABS.STORE_DETAILS && stores.stores.loadedShop === LOADING_TYPES.LOADED) {
      if (stores.activeStore) {
        const shop: Store | undefined = find(stores.stores.list, { id: stores.activeStore })
        if (shop?.loadedInfo === LOADING_TYPES.NOT_LOADED) {
          getStoreInfo({storeId: stores.activeStore});
        }
        if (shop?.loadedInfo === LOADING_TYPES.LOADED) {
          if (stores.activeStore !== activeShop || (!values.companyName && !!shop.info?.company_legal_name)) {
            setValues(prev => ({
              ...prev,
              companyName: shop.info?.company_legal_name || '',
              country: shop.info?.company_country || '',
              companyAddress: shop.info?.company_address || '',
              companyRegNumber: shop.info?.company_reg_number || '',
              website: shop.info?.company_website || '',
              shopName: shop.info?.shop_name || '',
              firstName: shop.info?.beneficiary_first_name || '',
              secondName: shop.info?.beneficiary_last_name || '',
              personalPhone: shop.info?.beneficiary_phone || '',
              email: shop.info?.beneficiary_email || '',
              position: shop.info?.beneficiary_position || '',
            }));
            setActiveShop(stores.activeStore);
            setHasStoreInfo(shop.has_store_info || false);
          }
        }
      }
    }

    if (values.tab === SETTINGS_TABS.ADVANCED_SETTINGS && stores.stores.loadedShop === LOADING_TYPES.LOADED) {
      if (stores.activeStore) {
        const shop: Store | undefined = find(stores.stores.list, { id: stores.activeStore })
        if (shop) {
          if (stores.activeStore !== activeShop || !values.name) {
            setValues(prev => ({
              ...prev,
              name: shop.name || '',
              min_btc_confirmations: shop?.min_btc_confirmations?.toString() || '',
              min_eth_confirmations: shop?.min_eth_confirmations?.toString() || '',
              allowed_amount_diff_percent: shop.allowed_amount_diff_percent || '',
              min_btc_tx_amount: shop.min_btc_tx_amount || '',
              min_usdteth_tx_amount: shop.min_usdteth_tx_amount || '',
              min_usdceth_tx_amount: shop.min_usdceth_tx_amount || '',
              min_daieth_tx_amount: shop.min_daieth_tx_amount || '',
              min_usdttrx_tx_amount: shop.min_usdttrx_tx_amount || '',
            }));
            setActiveShop(stores.activeStore);
          }
        }
      }
    }

    if (!values.name && stores.stores.loadedShop === LOADING_TYPES.LOADED) {

    }

    if (stores.answer?.success) {
      toastSuccess(stores.answer?.success);
      const successShop: any = find(stores.stores.list, { id: stores.activeStore });
      if (!hasStoreInfo && successShop?.has_store_info && values.tab === SETTINGS_TABS.STORE_DETAILS) {
        navigate(PATHS.DASHBOARD.replace(':store', `${stores.activeStore}`));
      }
      if (!successShop.withdrawals?.length && withdrawal.id) {
        setWithdrawal({
          address: '',
          currency: 'USDTTRX',
          id: '',
          name: '1',
          created_at: '',
          auth: ''
        });
      }
      clear();
    }

    if (stores.answer?.error) {
      if (stores.answer?.error?.error) {
        toastError(stores.answer.error.error);
      } else {
        toastError(stores.answer.error);
      }
      clear();
    }
  }, [values.tab, stores, clear, toastSuccess, toastError, setValues, getStoreInfo, activeShop, setActiveShop, getWithdrawalRequisites, setShopData, hasStoreInfo, navigate]);

  // const sortedWithdrawals = useMemo(() => {
  //   return withdrawals.length
  //     ? withdrawals
  //       .slice()
  //       .sort((a, b) => {
  //         if (a && b) {
  //           return a[sortField as keyof APIKey] > b[sortField as keyof APIKey]
  //             ? (sortDirection ? -1 : 1) * 1
  //             : (sortDirection ? -1 : 1) * -1
  //         }
  //         return -1
  //       })
  //     : []
  // }, [sortField, sortDirection, withdrawals]);

  // const handleSort = useCallback(
  //   (newField: string) => {
  //     setSortField(newField)
  //     setSortDirection(sortField !== newField ? true : !sortDirection)
  //   },
  //   [sortDirection, sortField],
  // )
  //
  // const arrow = useCallback(
  //   (field: string) => {
  //     const directionArrow = !sortDirection ? '↑' : '↓'
  //     return sortField === field ? directionArrow : ''
  //   },
  //   [sortDirection, sortField],
  // )
  //
  // const handleOpenModal = (payload: any) => {
  //   if (!openModal) return
  //   openModal(payload)
  // };

  // const handleCloseModal = () => {
  //   if (!closeModal) return
  //   closeModal()
  // };
  //
  // const handleApplyModal = (payload: any) => {
  //   if (!closeModal) return
  //   closeModal();
  //   const newWithdrawal = cloneDeep(payload)
  //   const createData: CreateWithdrawalRequisiteParams = {
  //     data: {
  //       tfa_code: newWithdrawal.tfa_code,
  //       withdrawal_requisite: {
  //         name: newWithdrawal.name,
  //         currency: newWithdrawal.currency,
  //         address: newWithdrawal.address
  //       },
  //       required: newWithdrawal.required
  //     },
  //     storeId: stores.activeStore || ''
  //   };
  //
  //   createWithdrawalRequisite(createData);
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFormErrors = (data: any) => {
    const {
      storeDetailsSection, tab, percentage, currency, address, auth, companyName, country, companyAddress, companyRegNumber, website, shopName, firstName, secondName, personalPhone, email, position,
      name, min_btc_confirmations, min_eth_confirmations
    } = data;
    const newErrors: any = {
      percentage: '',
      currency: '',
      address: '',
      auth: '',
      companyName: '',
      country: '',
      companyAddress: '',
      companyRegNumber: '',
      website: '',
      shopName: '',
      firstName: '',
      secondName: '',
      personalPhone: '',
      email: '',
      position: '',
      name: '',
      min_btc_confirmations: '',
      min_eth_confirmations: '',
    };

    if (tab === SETTINGS_TABS.PAYMENTS) {
      if (!Number(percentage)) newErrors.percentage = 'Select percentage';
      if (!currency) newErrors.currency = 'Select currency';
      if (!address) newErrors.address = 'Enter address';
      if (!validate(address, currency)) newErrors.address = 'Invalid address';
      if (!auth) newErrors.auth = 'Enter two-factor authentification';
    }

    if (tab === SETTINGS_TABS.STORE_DETAILS) {
      if (storeDetailsSection === STORE_DETAILS_SECTIONS.BUSINESS) {
        if (!companyName) newErrors.companyName = 'Enter company name';
        if (!country) newErrors.country = 'Select country';
        if (!companyAddress) newErrors.companyAddress = 'Enter company address';
        if (!companyRegNumber) newErrors.companyRegNumber = 'Enter company registration number';
        if (!website) newErrors.website = 'Enter company website';
        if (!shopName) newErrors.shopName = 'Enter company shop name';
      }

      if (storeDetailsSection === STORE_DETAILS_SECTIONS.BENEFICIAL) {
        if (!firstName) newErrors.firstName = 'Enter your first name';
        if (!secondName) newErrors.secondName = 'Enter your second name';
        if (!personalPhone) newErrors.personalPhone = 'Enter your personal phone';
        if (!email) newErrors.email = 'Enter email';
        if (email && !validateEmail(email)) newErrors.email = 'Please enter a valid email';
        if (!position) newErrors.position = 'Enter your company position';
      }
    }

    if (tab === SETTINGS_TABS.ADVANCED_SETTINGS) {
      if (!name) newErrors.name = 'Enter name';
      if (!Number(min_btc_confirmations)) newErrors.min_btc_confirmations = 'Must be more then 0';
      if (!Number(min_eth_confirmations)) newErrors.min_eth_confirmations = 'Must be more then 0';
    }

    return newErrors;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: StoreSettingsParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }

    if (field === 'tab') {
      setSearchParams({ tab: value });
      setErrors({});
    }

    if (values.tab === SETTINGS_TABS.ADVANCED_SETTINGS ) {
      if (findStoreUpdate(field, value)) {
        setHasStoreUpdate(true);
      } else {
        setHasStoreUpdate(false);
      }
    }
  };

  const findStoreUpdate = (field: string, value: string) => {
    // @ts-ignore
    const shop: Store | undefined = find(stores.stores.list, { id: stores.activeStore })
    const shopActualData = {
      name: shop?.name,
      min_btc_confirmations: Number(shop?.min_btc_confirmations).toString(),
      min_eth_confirmations: Number(shop?.min_eth_confirmations).toString(),
      allowed_amount_diff_percent: Number(shop?.allowed_amount_diff_percent).toString(),
      min_btc_tx_amount: Number(shop?.min_btc_tx_amount).toString(),
      min_usdteth_tx_amount: Number(shop?.min_usdteth_tx_amount).toString(),
      min_usdceth_tx_amount: Number(shop?.min_usdceth_tx_amount).toString(),
      min_daieth_tx_amount: Number(shop?.min_daieth_tx_amount).toString(),
      min_usdttrx_tx_amount: Number(shop?.min_usdttrx_tx_amount).toString(),
    };

    const shopTestData = {
      name: values.name,
      min_btc_confirmations: Number(values.min_btc_confirmations).toString(),
      min_eth_confirmations: Number(values.min_eth_confirmations).toString(),
      allowed_amount_diff_percent: Number(values.allowed_amount_diff_percent).toString(),
      min_btc_tx_amount: Number(values.min_btc_tx_amount).toString(),
      min_usdteth_tx_amount: Number(values.min_usdteth_tx_amount).toString(),
      min_usdceth_tx_amount: Number(values.min_usdceth_tx_amount).toString(),
      min_daieth_tx_amount: Number(values.min_daieth_tx_amount).toString(),
      min_usdttrx_tx_amount: Number(values.min_usdttrx_tx_amount).toString(),
    };

    shopTestData[field] = value;
    let testDataHasChanges = false;

    Object.keys(shopActualData).forEach((key: string) => {
      if (shopActualData[key] !== shopTestData[key]) {
        testDataHasChanges = true
      }
    });

    return testDataHasChanges
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: any = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  // const onChangeConversions = (field: string, value: boolean) => {
  //   if (field === 'all') {
  //     setConversions({
  //       btc: value,
  //       eth: value,
  //       usdt: value
  //     });
  //   } else {
  //     setConversions(prev => ({
  //       ...prev,
  //       [field]: value,
  //     }));
  //   }
  // };

  const onChangePhone = (field: string, value: string, isValid: boolean) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!isValid && value) {
      setErrors({
        ...errors,
        [field]: 'Phone not valid',
      });
    }

    if (!value) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (isValid && value) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }
  };

  // const handleUploadFile = (event: React.ChangeEvent<any>) => {
  //   const fileUploaded = event.target.files[0];
  //   const formData = [
  //     {
  //       name: 'photo',
  //       value: fileUploaded,
  //     },
  //   ];
  //
  //   const updateData = {
  //     data: formData,
  //     type: 'form',
  //   };
  //
  //   console.log(updateData);
  //   const newFiles = cloneDeep(files);
  //   newFiles.push({
  //     file: fileUploaded
  //   });
  //   console.log(newFiles);
  //
  //   setFiles(newFiles);
  // };

  // const onFileRemove = (event: React.ChangeEvent<any>, index: number) => {
  //   console.log('event', event);
  //   console.log('index', index);
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFormWithdrawalErrors = (data: any) => {
    const { address } = data;
    const newErrors: any = {
      address: ''
    };

    if (!address) newErrors.address = 'Enter address';
    if (!validate(address, 'usdt', { networkType: 'prod', chainType: 'trc20' })) newErrors.address = 'Invalid address';

    return newErrors;
  };

  const onWithdrawalChange = (field: string, value: string) => {
    setWithdrawal(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!withdrawalErrors[field]) {
      setWithdrawalErrors({
        ...withdrawalErrors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(withdrawalErrors, field)) {
      const newWithdrawal = cloneDeep(withdrawal);
      newWithdrawal[field] = value;
      const newWithdrawalErrors: WithdrawalRequisite = getFormWithdrawalErrors(newWithdrawal);

      setWithdrawalErrors({
        ...withdrawalErrors,
        [field]: newWithdrawalErrors[field],
      });
    }
  };

  const onWithdrawalBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(withdrawalErrors, field)) {
      const newWithdrawal = cloneDeep(withdrawal);
      const newWithdrawalErrors: any = getFormWithdrawalErrors(newWithdrawal);

      setWithdrawalErrors({
        ...withdrawalErrors,
        [field]: newWithdrawalErrors[field],
      });
    }
  };

  const handleWithdrawalAuthCode = (res: string) => {
    onWithdrawalChange('auth', res);
  };

  const handleDeleteWithdrawal = useCallback(
    (e: React.ChangeEvent<any>, withdrawalId: string) => {
      e.preventDefault();
      e.stopPropagation();

      const deleteData: DeleteWithdrawalRequisiteParams = {
        storeId: stores.activeStore || '',
        withdrawalId
      };

      deleteWithdrawalRequisite(deleteData);
      AuthWithdrawalInputRef.current?.clear();
    },
    [deleteWithdrawalRequisite, stores.activeStore]
  );

  const onWithdrawalSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: any = getFormWithdrawalErrors(withdrawal);
      setWithdrawalErrors(newErrors);

      const createData: CreateWithdrawalRequisiteParams = {
        data: {
          tfa_code: withdrawal.auth || '',
          withdrawal_requisite: {
            name: '1',
            currency: withdrawal.currency || 'USDTRX',
            address: withdrawal.address || ''
          },
          required: true
        },
        storeId: stores.activeStore || ''
      };

      if (!checkErrors(newErrors)) {
        createWithdrawalRequisite(createData);
        AuthWithdrawalInputRef.current?.clear();
      }
    },
    [withdrawal, stores.activeStore, createWithdrawalRequisite]
  );

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const storeDetailsSection: STORE_DETAILS_SECTIONS = e.target.title;
      const newErrors: any = getFormErrors({...values, storeDetailsSection});
      setErrors(newErrors);
      setLoadingStoreDetailsSection(storeDetailsSection);

      const data: StoreSettingsParams = {
        percentage: values.percentage,
        currency: values.currency,
        address: values.address,
        auth: values.auth,
      };

      const dataStoreDetails: UpdateStoreInfoParams = {
        storeId: stores.activeStore || '',
      };

      if (storeDetailsSection === STORE_DETAILS_SECTIONS.BUSINESS) {
        dataStoreDetails.data = {
          company_address: values.companyAddress,
          company_country: values.country,
          company_legal_name: values.companyName,
          company_reg_number: values.companyRegNumber,
          company_website: values.website,
          shop_name: values.shopName,
        }
      }

      if (storeDetailsSection === STORE_DETAILS_SECTIONS.BENEFICIAL) {
        dataStoreDetails.data = {
          beneficiary_email: values.email,
          beneficiary_first_name: values.firstName,
          beneficiary_last_name: values.secondName,
          beneficiary_phone: values.personalPhone,
          beneficiary_position: values.position,
        }
      }

      if (!checkErrors(newErrors)) {
        console.log(data);
        if (values.tab === SETTINGS_TABS.STORE_DETAILS) {
          updateStoreInfo(dataStoreDetails);
        }
      }
    },
    [values, getFormErrors, stores, updateStoreInfo]
  );

  const onUpdateAdvancedSettings = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();

      const newErrors: any = getFormErrors(values);
      setErrors(newErrors);

      const data: {storeId: string; params: CreateStoreParams} = {
        storeId: stores.activeStore || '',
        params: {
          name: values.name,
          kind: 'convertible',
          min_btc_confirmations: values.min_btc_confirmations,
          min_eth_confirmations: values.min_eth_confirmations,
          allowed_amount_diff_percent: values.allowed_amount_diff_percent,
          min_btc_tx_amount: values.min_btc_tx_amount,
          min_usdteth_tx_amount: values.min_usdteth_tx_amount,
          min_usdceth_tx_amount: values.min_usdceth_tx_amount,
          min_daieth_tx_amount: values.min_daieth_tx_amount,
          min_usdttrx_tx_amount: values.min_usdttrx_tx_amount,
        }
      };

      if (!checkErrors(newErrors)) {
        updateStore(data);
      }
    },
    [values, getFormErrors, stores, updateStore]
  );

  return (
    <MainContent className="content-main">
      <Container>
        <SettingsContainer className="settings">
          <div className="settings-head">
            <span className="settings-head__title">Store settings</span>
          </div>
          <div className="settings-tabs__wrap">
            <Tabs
              className="settings-tabs"
              tabHeaderClassName="settings-tabs__tab"
              tabsList={SETTINGS_TABS_LIST.map((item: string) => {
                return {
                  key: item,
                  name: t(`settings.tabs.${item}`),
                };
              })}
              field="tab"
              activeTab={values.tab}
              onChange={onChange}
            >
              <TabsContentItem className={`setting-tab ${values.tab === SETTINGS_TABS.PAYMENTS ? 'active' : ''}`}>
                {/*<div className="conversion">*/}
                {/*  <div className="conversion-title__wrap">*/}
                {/*    <span className="conversion-title">Auto conversion</span>*/}
                {/*  </div>*/}
                {/*  <div className="grid-x conversion-wrap">*/}
                {/*    <div className="cell small-4 xlarge-3 conversion-row">*/}
                {/*      <p className="conversion-item">Choose currencies you would like to accept:</p>*/}
                {/*    </div>*/}
                {/*    <div className="cell small-8 xlarge-9 conversion-row">*/}
                {/*      <div className="conversion-switch__wrap">*/}
                {/*        <FormControlLabel*/}
                {/*          className="switch"*/}
                {/*          control={*/}
                {/*            <Switch*/}
                {/*              className="conversion-switch"*/}
                {/*              checked={conversions.btc && conversions.eth && conversions.usdt}*/}
                {/*              onChange={() => onChangeConversions('all', !conversions.btc && !conversions.eth && !conversions.usdt)}*/}
                {/*              name="allConversion"*/}
                {/*              color="primary"*/}
                {/*            />*/}
                {/*          }*/}
                {/*          label="Accept all"*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*      <div className="conversion-switch__wrap">*/}
                {/*        <FormControlLabel*/}
                {/*          className="switch"*/}
                {/*          control={*/}
                {/*            <Switch*/}
                {/*              className="conversion-switch"*/}
                {/*              checked={conversions.btc}*/}
                {/*              onChange={() => onChangeConversions('btc', !conversions.btc)}*/}
                {/*              name="btcConversion"*/}
                {/*              color="primary"*/}
                {/*            />*/}
                {/*          }*/}
                {/*          label="BTC"*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*      <div className="conversion-switch__wrap">*/}
                {/*        <FormControlLabel*/}
                {/*          className="switch"*/}
                {/*          control={*/}
                {/*            <Switch*/}
                {/*              className="conversion-switch"*/}
                {/*              checked={conversions.eth}*/}
                {/*              onChange={() => onChangeConversions('eth', !conversions.eth)}*/}
                {/*              name="ethConversion"*/}
                {/*              color="primary"*/}
                {/*            />*/}
                {/*          }*/}
                {/*          label="ETH"*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*      <div className="conversion-switch__wrap">*/}
                {/*        <FormControlLabel*/}
                {/*          className="switch"*/}
                {/*          control={*/}
                {/*            <Switch*/}
                {/*              className="conversion-switch"*/}
                {/*              checked={conversions.usdt}*/}
                {/*              onChange={() => onChangeConversions('usdt', !conversions.usdt)}*/}
                {/*              name="usdtConversion"*/}
                {/*              color="primary"*/}
                {/*            />*/}
                {/*          }*/}
                {/*          label="USDT"*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className="withdrawal">
                  {/*<div className="withdrawal-title__wrap">*/}
                  {/*  <span className="withdrawal-title">Withdrawal requisites</span>*/}
                  {/*</div>*/}
                  {/*<div className="withdrawal-wrap">*/}
                  {/*  <div className="withdrawal__table">*/}
                  {/*    <div className="withdrawal__table_header">*/}
                  {/*      <span className="withdrawal__table_header_title">Withdrawal requisites</span>*/}
                  {/*      <div className="withdrawal__table_header_btns">*/}
                  {/*        /!*<Button*!/*/}
                  {/*        /!*  className="developers-api__table_header_btn -white -generate"*!/*/}
                  {/*        /!*  type="button"*!/*/}
                  {/*        /!*>*!/*/}
                  {/*        /!*  Generate E-commerce key*!/*/}
                  {/*        /!*</Button>*!/*/}
                  {/*        <Button*/}
                  {/*          className="withdrawal__table_header_btn -white -add"*/}
                  {/*          type="button"*/}
                  {/*          disabled={loadingWithdrawals || loadingCreateWithdrawal}*/}
                  {/*          onClick={*/}
                  {/*            () => handleOpenModal({*/}
                  {/*              closeModal: closeModal,*/}
                  {/*              className: "modal modalWithdrawal",*/}
                  {/*              content: modalAddWithdrawal*/}
                  {/*            })*/}
                  {/*          }*/}
                  {/*        >*/}
                  {/*          Add Withdrawal requisite*/}
                  {/*          {loadingCreateWithdrawal ? <Loader /> : null}*/}
                  {/*        </Button>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*    <div className="withdrawal__table_head">*/}
                  {/*      <span*/}
                  {/*        className="withdrawal__table_head_item"*/}
                  {/*        onClick={() => handleSort(SORT_FIELD.name)}*/}
                  {/*      >*/}
                  {/*        Label <span className="withdrawal__table_head__arrow">{arrow(SORT_FIELD.name)}</span>*/}
                  {/*      </span>*/}
                  {/*      <span className="withdrawal__table_head_item">Address</span>*/}
                  {/*      <span*/}
                  {/*        className="withdrawal__table_head_item"*/}
                  {/*        onClick={() => handleSort(SORT_FIELD.currency)}*/}
                  {/*      >*/}
                  {/*        Currency <span className="withdrawal__table_head__arrow">{arrow(SORT_FIELD.currency)}</span>*/}
                  {/*      </span>*/}
                  {/*      <span*/}
                  {/*        className="withdrawal__table_head_item"*/}
                  {/*        onClick={() => handleSort(SORT_FIELD.created_at)}*/}
                  {/*      >*/}
                  {/*        Date Created <span className="withdrawal__table_head__arrow">{arrow(SORT_FIELD.created_at)}</span>*/}
                  {/*      </span>*/}
                  {/*      <span className="withdrawal__table_head_item" />*/}
                  {/*    </div>*/}
                  {/*    <div className="withdrawal__table_body">*/}
                  {/*      {sortedWithdrawals.map((withdrawal, index) => {*/}
                  {/*        if (withdrawal) {*/}
                  {/*          return (*/}
                  {/*            <DataRow*/}
                  {/*              key={`withdrawal-${index}`}*/}
                  {/*              withdrawal={withdrawal}*/}
                  {/*              loading={loadingDeleteWithdrawal}*/}
                  {/*              loadingDeleteWithdrawal={loadingDeleteWithdrawal}*/}
                  {/*              onDelete={handleDeleteWithdrawal}*/}
                  {/*            />*/}
                  {/*          )*/}
                  {/*        }*/}
                  {/*        return null*/}
                  {/*      })}*/}

                  {/*      {sortedWithdrawals.length === 0 ? (*/}
                  {/*        <span className="withdrawal__table_zero" >No Withdrawal requisites</span>*/}
                  {/*      ) : null}*/}

                  {/*      {*/}
                  {/*        loadingWithdrawals ? <DataRowLoader /> : null*/}
                  {/*      }*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="grid-x withdrawal-wrap">
                    <div className="cell small-4 xlarge-3 withdrawal-row">
                      <span className="withdrawal-item">Scheduled transfer</span>
                      {/*<span className="withdrawal-subitem">Sent every Monday (02:30 PM)</span>*/}
                      <span className="withdrawal-subitem">Withdrawals are processed automatically based on agreed terms with your manager</span>
                    </div>
                    <div className="cell small-8 xlarge-9 withdrawal-row">
                      <form className="withdrawal-form" onSubmit={onSubmit}>
                        <div className="withdrawal-form__head">
                          <div className="withdrawal-token">
                            <img className="withdrawal-token__img" src="/img/tokens/usdx.svg" alt="Tier" />
                            <div className="withdrawal-token__amounts">
                              <span className="withdrawal-token__amount">{formatNumber(shopData?.balance || '0')} USDX</span>
                              <span className="withdrawal-token__amount -usd">{formatNumber(shopData?.balance || '0')} USD</span>
                            </div>
                          </div>
                          {/*<div className="withdrawal-switch__wrap">*/}
                          {/*  <FormControlLabel*/}
                          {/*    className="switch"*/}
                          {/*    control={*/}
                          {/*      <Switch*/}
                          {/*        className="withdrawal-switch"*/}
                          {/*        checked={withdrawal}*/}
                          {/*        onChange={() => setWithdrawal(!withdrawal)}*/}
                          {/*        name="withdrawal"*/}
                          {/*        color="primary"*/}
                          {/*      />*/}
                          {/*    }*/}
                          {/*    label=""*/}
                          {/*  />*/}
                          {/*</div>*/}
                        </div>
                        <div className="withdrawal-form__body">
                          <div className="withdrawal-form__percentage">
                            {/*<div className="withdrawal-form__percentage_head">*/}
                            {/*  <div className="withdrawal-form__percentage_title_wrap">*/}
                            {/*    <span className="withdrawal-form__percentage_title">Choose percentage transferred</span>*/}
                            {/*  </div>*/}
                            {/*  <div className="withdrawal-form__percentage_count_wrap">*/}
                            {/*    <span className="withdrawal-form__percentage_count">{values.percentage}%</span>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                            {/*<div className="withdrawal-form__percentage_slider">*/}
                            {/*  <SettingsSlider*/}
                            {/*    value={Number(values.percentage)}*/}
                            {/*    onChange={(event: Event, value: number) => onChange('percentage', String(value))}*/}
                            {/*  />*/}
                            {/*</div>*/}
                            <div className="withdrawal-form__address">
                              <div className="withdrawal-form__address_container">
                                <div className="grid-x grid-margin-x">
                                  <div className="cell small-4 xlarge-4">
                                    <Select
                                      className="withdrawal-form__select"
                                      name="currency"
                                      value={withdrawal?.currency}
                                      placeholder="Settlement currency"
                                      error={withdrawalErrors.currency}
                                      list={currenciesList}
                                      image={`/img/tokens/${withdrawal?.currency.toLowerCase()}.svg`}
                                      // image={`/img/tokens/usdttrx.svg`}
                                      label="Settlement currency"
                                      fullWidth
                                      onChange={onWithdrawalChange}
                                    />
                                  </div>
                                  <div className="cell small-8 xlarge-8">
                                    <Input
                                      className='withdrawal-form__input'
                                      type="text"
                                      name="address"
                                      value={withdrawal?.address}
                                      placeholder="Your TRX address"
                                      label="Your TRX address"
                                      error={withdrawalErrors.address}
                                      onChange={onWithdrawalChange}
                                      onBlur={onWithdrawalBlur}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="withdrawal-auth">
                            <span className="withdrawal-auth__title">Two-factor authentification</span>
                            <div className="withdrawal-code__wrap">
                              <AuthCode
                                ref={AuthWithdrawalInputRef}
                                containerClassName="withdrawal-code__container"
                                inputClassName="withdrawal-code"
                                allowedCharacters="numeric"
                                length={6}
                                placeholder="0"
                                autoFocus={false}
                                onChange={handleWithdrawalAuthCode}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="withdrawal-form__footer">
                          {
                            !withdrawal.id && !loadingWithdrawals ? (
                              <Button
                                className="withdrawal-form__footer_btn loading-btn"
                                type="submit"
                                disabled={loadingCreateWithdrawal || !withdrawal?.address || !withdrawal.auth}
                                onClick={(e) => onWithdrawalSubmit(e)}
                              >
                                Add Withdrawal requisite
                                {loadingCreateWithdrawal ? <Loader /> : null}
                              </Button>
                            ) : null
                          }

                          {
                            withdrawal.id ? (
                              <Button
                                className="withdrawal-form__footer_btn -remove"
                                disabled={loadingDeleteWithdrawal || !withdrawal.id || !withdrawal.auth}
                                type="submit"
                                onClick={
                                  (e) => handleDeleteWithdrawal(e,withdrawal.id)
                                }
                              >
                                {
                                  loadingDeleteWithdrawal ? <Loader /> : (
                                    <>
                                      <Icon className="withdrawal-form__footer_btn_icon" name="trash" size="20" />
                                      <span className="withdrawal-form__footer_btn_item">Remove</span>
                                    </>
                                  )
                                }
                              </Button>
                            ) : null
                          }
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </TabsContentItem>
              <TabsContentItem className={`setting-tab ${values.tab === SETTINGS_TABS.STORE_DETAILS ? 'active' : ''}`}>
                <div className="business">
                  <div className="business-title__wrap">
                    <span className="business-title">Business</span>
                    <span className="business-desc">Update your company details here.</span>
                  </div>
                  <form
                    onSubmit={onSubmit}
                    title={STORE_DETAILS_SECTIONS.BUSINESS}
                  >
                    <div className="business-container">
                      <div className="business-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="business-name">Company legal name</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='business-input'
                              type="text"
                              name="companyName"
                              value={values.companyName}
                              placeholder="Company legal name"
                              error={errors.companyName}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="business-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="business-name">Country</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <SelectAutocomplete
                              className="business-input"
                              name="country"
                              selectedValue={values.country}
                              placeholder="Country"
                              error={errors.country}
                              list={countriesList}
                              withFlag
                              fullWidth
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="business-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="business-name">Company address</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <TextArea
                              className='business-textarea'
                              type="text"
                              name="companyAddress"
                              value={values.companyAddress}
                              placeholder="Company address"
                              error={errors.companyAddress}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="business-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="business-name">Company Reg number</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='business-input'
                              type="text"
                              name="companyRegNumber"
                              value={values.companyRegNumber}
                              placeholder="Company Reg number"
                              error={errors.companyRegNumber}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="business-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="business-name">Website</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='business-input'
                              type="url"
                              name="website"
                              value={values.website}
                              placeholder="Website"
                              error={errors.website}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="business-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="business-name">Shop name</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='business-input'
                              type="text"
                              name="shopName"
                              value={values.shopName}
                              placeholder="Shop name"
                              error={errors.shopName}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      {/*<div className="business-row">*/}
                      {/*  <div className="grid-x grid-margin-x">*/}
                      {/*    <div className="cell small-3 xlarge-35">*/}
                      {/*      <span className="business-name">Uploaded documents</span>*/}
                      {/*    </div>*/}
                      {/*    <div className="cell small-6 xlarge-6">*/}
                      {/*      <div className="business-upload">*/}
                      {/*        <div className="business-upload__area">*/}
                      {/*          <img className="business-upload__image" src={`/img/upload.svg`} alt="cp" />*/}
                      {/*          <span className="business-upload__title"><span className="-blue">Click to upload</span> or drag and drop</span>*/}
                      {/*          <span className="business-upload__text">DOC, PDF, PNG or JPG</span>*/}
                      {/*        </div>*/}
                      {/*        <input*/}
                      {/*          type="file"*/}
                      {/*          accept="image/png, image/gif, image/jpeg, application/msword, application/pdf"*/}
                      {/*          ref={(instance: HTMLInputElement) => (hiddenFileInput.current = instance)}*/}
                      {/*          onChange={handleUploadFile}*/}
                      {/*          className="business-upload__input"*/}
                      {/*        />*/}
                      {/*      </div>*/}
                      {/*      <div className="business-upload__files">*/}
                      {/*        <div className="business-upload__file -success">*/}
                      {/*          <img className="business-upload__file_img" src={`/img/file.svg`} alt="cp" />*/}
                      {/*          <div className="business-upload__file_texts">*/}
                      {/*            <span className="business-upload__file_title">Requirements.pdf</span>*/}
                      {/*            <span className="business-upload__file_size">200 KB – 100% uploaded</span>*/}
                      {/*          </div>*/}
                      {/*          <div className="business-upload__file_icon">*/}
                      {/*            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                      {/*              <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#444CE7"/>*/}
                      {/*              <path d="M11.3337 5.5L6.75033 10.0833L4.66699 8" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>*/}
                      {/*              <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#444CE7"/>*/}
                      {/*            </svg>*/}
                      {/*          </div>*/}
                      {/*        </div>*/}
                      {/*        <div className="business-upload__file -error">*/}
                      {/*          <img className="business-upload__file_img" src={`/img/file_error.svg`} alt="cp" />*/}
                      {/*          <div className="business-upload__file_texts">*/}
                      {/*            <span className="business-upload__file_title">Passport.pdf</span>*/}
                      {/*            <span className="business-upload__file_size">1.6 MB – 70% uploaded</span>*/}
                      {/*            <button*/}
                      {/*              className="business-upload__file_again"*/}
                      {/*              onClick={(e) => onFileRemove(e, 0)}*/}
                      {/*              type="button"*/}
                      {/*            >*/}
                      {/*              Try again*/}
                      {/*            </button>*/}
                      {/*          </div>*/}
                      {/*          <div className="business-upload__file_icon">*/}
                      {/*            <button*/}
                      {/*              className="business-upload__file_remove"*/}
                      {/*              onClick={(e) => onFileRemove(e, 0)}*/}
                      {/*              type="button"*/}
                      {/*            >*/}
                      {/*              <Icon name="trash" size="20" />*/}
                      {/*            </button>*/}
                      {/*          </div>*/}
                      {/*        </div>*/}
                      {/*        <div className="business-upload__file -progress">*/}
                      {/*          <img className="business-upload__file_img" src={`/img/file.svg`} alt="cp" />*/}
                      {/*          <div className="business-upload__file_texts">*/}
                      {/*            <span className="business-upload__file_title">License.jpg</span>*/}
                      {/*            <span className="business-upload__file_size">2.3 MB – 70% uploaded</span>*/}
                      {/*          </div>*/}
                      {/*          <div className="business-upload__file_icon">*/}
                      {/*            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                      {/*              <path d="M30 16C30 17.8385 29.6379 19.659 28.9343 21.3576C28.2308 23.0561 27.1995 24.5995 25.8995 25.8995C24.5995 27.1995 23.0561 28.2307 21.3576 28.9343C19.659 29.6379 17.8385 30 16 30C14.1615 30 12.341 29.6379 10.6424 28.9343C8.94387 28.2307 7.40052 27.1995 6.1005 25.8995C4.80048 24.5995 3.76925 23.0561 3.06569 21.3576C2.36212 19.659 2 17.8385 2 16C2 14.1615 2.36212 12.341 3.06569 10.6424C3.76926 8.94387 4.80049 7.40052 6.10051 6.1005C7.40053 4.80048 8.94388 3.76925 10.6424 3.06568C12.341 2.36212 14.1615 2 16 2C17.8385 2 19.659 2.36212 21.3576 3.06569C23.0561 3.76926 24.5995 4.80049 25.8995 6.10051C27.1995 7.40053 28.2308 8.94388 28.9343 10.6424C29.6379 12.341 30 14.1615 30 16L30 16Z" stroke="#EEF4FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>*/}
                      {/*              <path d="M16 2C17.8385 2 19.659 2.36212 21.3576 3.06569C23.0561 3.76925 24.5995 4.80049 25.8995 6.10051C27.1995 7.40053 28.2308 8.94388 28.9343 10.6424C29.6379 12.341 30 14.1615 30 16" stroke="#444CE7" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>*/}
                      {/*            </svg>*/}
                      {/*          </div>*/}
                      {/*          <FileLoader progress={70} />*/}
                      {/*        </div>*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      <div className="business-btns">
                        {/*<Button*/}
                        {/*  className="business-btn -white"*/}
                        {/*  type="button"*/}
                        {/*>*/}
                        {/*  Cancel*/}
                        {/*</Button>*/}
                        <Button
                          className="business-btn loading-btn"
                          type="submit"
                          disabled={loadingUpdate || loading}
                        >
                          Save
                          {loadingUpdate && loadingStoreDetailsSection === STORE_DETAILS_SECTIONS.BUSINESS ? <Loader /> : null}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="beneficial">
                  <div className="beneficial-title__wrap">
                    <span className="beneficial-title">Beneficial</span>
                    <span className="beneficial-desc">Update owner details here.</span>
                  </div>
                  <form
                    onSubmit={onSubmit}
                    title={STORE_DETAILS_SECTIONS.BENEFICIAL}
                  >
                    <div className="beneficial-container">
                      <div className="beneficial-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="beneficial-name">Full name</span>
                          </div>
                          <div className="cell small-3 xlarge-3">
                            <Input
                              className='beneficial-input'
                              type="text"
                              name="firstName"
                              value={values.firstName}
                              placeholder="First name"
                              error={errors.firstName}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                          <div className="cell small-3 xlarge-3">
                            <Input
                              className='beneficial-input'
                              type="text"
                              name="secondName"
                              value={values.secondName}
                              placeholder="Second name"
                              error={errors.secondName}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="beneficial-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="beneficial-name">Phone number</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <PhoneInput
                              className='beneficial-input'
                              placeholder="Phone number"
                              value={values.personalPhone}
                              name="personalPhone"
                              country={values.country}
                              withCountries
                              error={errors.personalPhone}
                              onChange={(value: string, isValid: boolean) =>
                                onChangePhone('personalPhone', value, isValid)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="beneficial-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="beneficial-name">Email address</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='beneficial-input'
                              type="email"
                              name="email"
                              value={values.email}
                              placeholder="Email address"
                              error={errors.email}
                              image={`/img/email.svg`}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="beneficial-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="beneficial-name">Position</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='beneficial-input'
                              type="text"
                              name="position"
                              value={values.position}
                              placeholder="Position"
                              error={errors.position}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="beneficial-btns">
                        {/*<Button*/}
                        {/*  className="beneficial-btn -white"*/}
                        {/*  type="button"*/}
                        {/*>*/}
                        {/*  Cancel*/}
                        {/*</Button>*/}
                        <Button
                          className="beneficial-btn loading-btn"
                          type="submit"
                          disabled={loadingUpdate || loading}
                        >
                          Save
                          {loadingUpdate && loadingStoreDetailsSection === STORE_DETAILS_SECTIONS.BENEFICIAL ? <Loader /> : null}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </TabsContentItem>
              <TabsContentItem className={`setting-tab ${values.tab === SETTINGS_TABS.ADVANCED_SETTINGS ? 'active' : ''}`}>
                <div className="advanced">
                  <div className="advanced-title__wrap">
                    <span className="advanced-title">Advanced settings</span>
                    <span className="advanced-desc">Update your advanced settings here.</span>
                  </div>
                  <form
                    onSubmit={onUpdateAdvancedSettings}
                  >
                    <div className="advanced-container">
                      <div className="advanced-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="advanced-name">Shop name</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='advanced-input'
                              type="text"
                              name="name"
                              value={values.name}
                              placeholder="Shop name"
                              error={errors.name}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="advanced-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell">
                            <span className="advanced-name">Allowed amount diff percent: {values.allowed_amount_diff_percent}%</span>
                          </div>
                          <SettingsSlider
                            value={Number(values.allowed_amount_diff_percent)}
                            onChange={(event: Event, value: number) => onChange('allowed_amount_diff_percent', String(value))}
                            disabled={loadingUpdateStore}
                          />
                        </div>
                      </div>
                      <div className="advanced-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="advanced-name">Minimum confirmations for BTC</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <SelectAutocomplete
                              className="advanced-input"
                              name="min_btc_confirmations"
                              selectedValue={values.min_btc_confirmations}
                              placeholder="Minimum confirmations for BTC"
                              error={errors.min_btc_confirmations}
                              list={Array.from(Array(6)).map((num, index) => {
                                return {
                                  value: `${index + 1}`,
                                  text: `${index + 1}`,
                                }
                              })}
                              fullWidth
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="advanced-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="advanced-name">Minimum confirmations for ETH</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <SelectAutocomplete
                              className="advanced-input"
                              name="min_eth_confirmations"
                              selectedValue={values.min_eth_confirmations}
                              placeholder="Minimum confirmations for ETH"
                              error={errors.min_eth_confirmations}
                              list={Array.from(Array(60)).map((num, index) => {
                                return {
                                  value: `${index + 1}`,
                                  text: `${index + 1}`,
                                }
                              })}
                              fullWidth
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="advanced-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="advanced-name">Minimum transaction amount for BTC</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='advanced-input'
                              type="number"
                              numberType="number"
                              name="min_btc_tx_amount"
                              value={formatNumber(values.min_btc_tx_amount, 0, 0)}
                              placeholder="Minimum transaction amount for BTC"
                              error={errors.min_btc_tx_amount}
                              maxLength={20}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="advanced-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="advanced-name">Minimum transaction amount for USDT</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='advanced-input'
                              type="number"
                              numberType="number"
                              name="min_usdteth_tx_amount"
                              value={formatNumber(values.min_usdteth_tx_amount, 0, 0)}
                              placeholder="Minimum transaction amount for USDT"
                              error={errors.min_usdteth_tx_amount}
                              maxLength={20}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="advanced-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="advanced-name">Minimum transaction amount for USDT in TRX</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='advanced-input'
                              type="number"
                              numberType="number"
                              name="min_usdttrx_tx_amount"
                              value={formatNumber(values.min_usdttrx_tx_amount, 0, 0)}
                              placeholder="Minimum transaction amount for USDT in TRX"
                              error={errors.min_usdttrx_tx_amount}
                              maxLength={20}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="advanced-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="advanced-name">Minimum transaction amount for USDC</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='advanced-input'
                              type="number"
                              numberType="number"
                              name="min_usdceth_tx_amount"
                              value={formatNumber(values.min_usdceth_tx_amount, 0, 0)}
                              placeholder="Minimum transaction amount for USDC"
                              error={errors.min_usdceth_tx_amount}
                              maxLength={20}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="advanced-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="advanced-name">Minimum transaction amount for DAI</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='advanced-input'
                              type="number"
                              numberType="number"
                              name="min_daieth_tx_amount"
                              value={formatNumber(values.min_daieth_tx_amount, 0, 0)}
                              placeholder="Minimum transaction amount for DAI"
                              error={errors.min_daieth_tx_amount}
                              maxLength={20}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="advanced-btns">
                        <Button
                          className="advanced-btn loading-btn"
                          type="submit"
                          disabled={loadingUpdateStore || loading || !hasStoreUpdate}
                        >
                          Save
                          {loadingUpdateStore ? <Loader /> : null}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </TabsContentItem>
            </Tabs>
          </div>
        </SettingsContainer>
      </Container>
    </MainContent>
  );
};

// const DataRow: React.FC<{ withdrawal: WithdrawalRequisite, loadingDeleteWithdrawal: boolean, loading: boolean, onDelete: (e: any, withdrawalId: string) => void }> = ({ withdrawal, loadingDeleteWithdrawal, loading, onDelete }) => {
//
//   return (
//     <div className={`withdrawal__table_body_row ${loading ? '-disabled' : ''}`}>
//       <span className="withdrawal__table_body_item -name">{withdrawal.name}</span>
//       <span className="withdrawal__table_body_item">{withdrawal.address}</span>
//       <span className="withdrawal__table_body_item">{withdrawal.currency}</span>
//       <span className="withdrawal__table_body_item">{dayjs(withdrawal.created_at).format('MMM D YYYY, h:mm A')}</span>
//       <button
//         className="withdrawal__table_body_btn "
//         disabled={loading}
//         type="button"
//         onClick={
//           (e) => onDelete(e,withdrawal.id)
//         }
//       >
//         {
//           loadingDeleteWithdrawal ? <Loader /> : (
//             <>
//               <Icon className="withdrawal__table_body_btn_icon" name="trash" size="20" />
//               <span className="withdrawal__table_body_btn_item">Remove</span>
//             </>
//           )
//         }
//
//       </button>
//     </div>
//   )
// }

// const DataRowLoader: React.FC = () => {
//   const loadingRow = (
//     <div className="withdrawal__loading">
//       <Skeleton />
//       <Skeleton />
//       <Skeleton />
//       <Skeleton />
//     </div>
//   )
//   return (
//     <>
//       {loadingRow}
//       {loadingRow}
//       {loadingRow}
//       {loadingRow}
//     </>
//   )
// }

const mapStateToProps = (state: AppStateType) => {
  const { stores } = state;

  return {
    stores,
    loading: selectLoadingByKey(state, types.GET_STORE_INFO_REQUEST),
    loadingUpdate: selectLoadingByKey(state, types.UPDATE_STORE_INFO_REQUEST),
    loadingWithdrawals: selectLoadingByKey(state, types.GET_WITHDRAWALS_REQUISITES_REQUEST),
    loadingCreateWithdrawal: selectLoadingByKey(state, types.CREATE_WITHDRAWAL_REQUISITES_REQUEST),
    loadingDeleteWithdrawal: selectLoadingByKey(state, types.DELETE_WITHDRAWAL_REQUISITES_REQUEST),
    loadingUpdateStore: selectLoadingByKey(state, types.UPDATE_STORE_REQUEST),
  };
};

export default connect(mapStateToProps, {
  getStoreInfo,
  updateStoreInfo,
  openModal,
  closeModal,
  createWithdrawalRequisite,
  getWithdrawalRequisites,
  deleteWithdrawalRequisite,
  clear,
  updateStore
})(StoreSettings);
