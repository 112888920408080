import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from '../components/Layout';
import {AppStateType} from "../store";
import {connect} from "react-redux";
import {Navigate} from "react-router-dom";
import {PATHS} from "../const/paths.constants";

interface GuestRoutesProps {
  children?: React.ReactElement;
  title?: string;
  loggedIn: boolean;
}

const GuestRoutes: React.FC<GuestRoutesProps> = ({
  children,
  title,
  loggedIn,
}) => {
  const { t } = useTranslation();
  document.title = `${t(title || '')}`;

  return !loggedIn ? (
    <AuthLayout>
      { children }
    </AuthLayout>
  ) : (
    <Navigate replace to={PATHS.NEW_DASHBOARD} />
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { user } = state;
  return {
    loggedIn: user.loggedIn,
  };
};

export default connect(mapStateToProps)(GuestRoutes);
