import cloneDeep from 'lodash/cloneDeep';
import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {validateEmail} from '../../../common/utils/validators';
import {Button, Input, Loader, Logo, Title,} from '../../../elements';
import {LoginStyles} from './Login.Styles';
import {LogInParams, OAuthlogInParams} from "../../../api";
import {Link, useNavigate} from "react-router-dom";
import {PATHS} from "../../../const/paths.constants";
import {AppStateType} from '../../../store';
import types from '../../../store/actionTypes';
import {selectLoadingByKey} from '../../../store/loadingsErrors/selectors';
import {clear, login, OAuthLogin} from '../../../store/user/actions';
import {UserReducerState} from "../../../store/user/reducers";
import useToast from "../../../hooks/useToast";
import {LOADING_TYPES} from "../../../const/app.constants";
import {getGoogleUrl} from "../../../common/utils/getGoogleUrl";
import {getGitHubUrl} from "../../../common/utils/getGithubUrl";

export interface LoginProps {
  user: UserReducerState;
  login: (payload: LogInParams | any) => void;
  OAuthLogin: (payload: OAuthlogInParams) => void;
  clear: () => void;
  loading: boolean;
}

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const { user, login, clear, loading, OAuthLogin } = props;
  const { toastError } = useToast();
  const navigate = useNavigate();
  const urlHref = window.location.href;
  const urlSearch = window.location.search;
  const urlParams = new URLSearchParams(urlSearch);
  const oauthCode = urlParams.get('code');
  const oauthState = urlParams.get('state');
  const [values, setValues] = useState<{ [key: string]: string }>({
    email: '',
    password: ''
  });
  const [showInput, setShowInput] = useState<{ [key: string]: boolean }>({
    password: false,
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onOAuth = () => {
    const data: OAuthlogInParams = {
      token: oauthCode || '',
      oauth_provider: oauthState || '',
    };
    OAuthLogin(data);
  };

  if (!!oauthCode && user.oAuthProgress === LOADING_TYPES.NOT_LOADED) {
    window.history.pushState({}, '', urlHref.replace(urlSearch, ''));
    onOAuth();
  }

  useEffect(() => {

    if (user.answer?.error?.error) {
      toastError(user.answer?.error?.error);
      if (user.answer?.error?.error === "email isn't confirmed") {
        navigate(`${PATHS.CONFIRMATION}?confirmation_email=${values.email}`);
      }

      clear();
    }
  }, [user.answer, toastError, clear, navigate, values]);

  let getFormErrors: (data: { [p: string]: string }) => LogInParams;
  getFormErrors = (data: { [key: string]: string }) => {
    const {email, password} = data;
    const newErrors: LogInParams = {
      email: '',
      password: ''
    };

    if (!email) newErrors.email = 'Enter email';
    if (email && !validateEmail(email)) newErrors.email = 'Please enter a valid email';
    if (!password) newErrors.password = 'Enter password';

    return newErrors;
  };

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: LogInParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: LogInParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onToggleShow = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(showInput, field)) {
      setShowInput({
        ...showInput,
        [field]: !showInput[field],
      });
    }
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: LogInParams = getFormErrors(values);
      setErrors(newErrors);

      const data: LogInParams = {
        email: values.email.toLowerCase(),
        password: values.password,
      };
      if (!checkErrors(newErrors)) {
        login(data);
      }
    },
    [values, getFormErrors, login]
  );

  return (
    <LoginStyles className='login'>
      <div className="login-container">
        <div className="grid-container login-grid">
          <div className="login-top">
            <Logo className="login-logo" to={PATHS.NEW_DASHBOARD}>
              <img src="/img/main/logo.svg" alt="cp" />
            </Logo>
          </div>
          <div className="login-center">
            <div className="login-box">

              <Title className='login-title'>
                Sign in
              </Title>
              <form onSubmit={onSubmit}>
                <Input
                  className='login-input'
                  type="email"
                  name="email"
                  value={values.email}
                  placeholder="Enter your email"
                  label="Email"
                  error={errors.email}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                <Input
                  className='login-input'
                  type="password"
                  name="password"
                  value={values.password}
                  placeholder="Password"
                  label="Password"
                  error={errors.password}
                  onChange={onChange}
                  onBlur={onBlur}
                  show={showInput.password}
                  onShow={onToggleShow}
                />
                <Button
                  className="login-btn"
                  type="submit"
                  disabled={loading}
                >
                  Login
                  {loading ? <Loader /> : null}
                </Button>
              </form>

              <div className="login-or">
                <span>OR</span>
              </div>

              <div className="login-oauth">
                <Button
                  className="login-btn -google"
                  as={Link}
                  to={getGoogleUrl()}
                  disabled={loading}
                >
                  Continue with Google
                </Button>

                <Button
                  className="login-btn -github"
                  as={Link}
                  to={getGitHubUrl()}
                  disabled={loading}
                >
                  Continue with GitHub
                </Button>
              </div>

              <div className="login-entrance">
                <p>Not registered yet? </p>
                <Link to={PATHS.REGISTRATION}>Register</Link>
              </div>
              <div className="login-entrance">
                <Link to={PATHS.FORGOT_PASSWORD}>Forgot Password?</Link>
              </div>
            </div>
          </div>
          <div className="login-bottom">
            <div className="login-bottom__copyright_wrap">
              <span className="login-bottom__copyright">© Cryptoprocessing</span>
            </div>
            <div className="login-bottom__link_wrap">
              <Link tabIndex={-1} className="login-bottom__link" to={PATHS.PRIVACY_POLICE} target="_blank">Privacy</Link>
              <span className="login-bottom__link"> & </span>
              <Link tabIndex={-1} className="login-bottom__link" to={PATHS.TERMS_OF_USE} target="_blank">terms</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="login-image">
        <div className="login-image__container">
          <div className="login-image__box">
            <div className="login-image__star">
              <img src="/img/auth/star.svg" alt="Tier" />
            </div>
            <div className="login-image__title">Start accepting crypto payments easily and securely.</div>
            <div className="login-image__text">Create a free account and get full access to all features for 30-days. No credit card needed. Get started in 2 minutes.</div>
            <div className="login-image__avatars">
              <div className="login-image__avatar">
                <img
                  className="login-image__avatar_img"
                  srcSet="/img/auth/ava1.png, /img/auth/ava1@2x.png 2x"
                  src="/img/auth/ava1.png"
                  alt="Tier"
                />
                <img
                  className="login-image__avatar_img"
                  srcSet="/img/auth/ava2.png, /img/auth/ava2@2x.png 2x"
                  src="/img/auth/ava2.png"
                  alt="Tier"
                />
                <img
                  className="login-image__avatar_img"
                  srcSet="/img/auth/ava3.png, /img/auth/ava3@2x.png 2x"
                  src="/img/auth/ava3.png"
                  alt="Tier"
                />
                <img
                  className="login-image__avatar_img"
                  srcSet="/img/auth/ava4.png, /img/auth/ava4@2x.png 2x"
                  src="/img/auth/ava4.png"
                  alt="Tier"
                />
                <img
                  className="login-image__avatar_img"
                  srcSet="/img/auth/ava5.png, /img/auth/ava5@2x.png 2x"
                  src="/img/auth/ava5.png"
                  alt="Tier"
                />
              </div>
              <p>Join 40,000+ users</p>
              <div className="login-image__arrow">
                <img src="/img/auth/arrow.svg" alt="Tier" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginStyles>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
    loading: selectLoadingByKey(state, types.LOGIN_REQUEST),
  };
};

export default connect(mapState, { login, clear, OAuthLogin })(Login);
