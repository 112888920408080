import {BASE_SCAN_ADDR_URLS, BASE_SCAN_URLS} from "../../const/app.constants";
import {IS_TESTNET} from "../../const/general.constants";

const getScanLink = (
  type: string = 'transaction',
  currency: string = 'ETH',
  address: string,
  network: string = IS_TESTNET ? 'TESTNET' : 'MAINNET',
): string => {
  switch (type) {
    case 'transaction': {
      return `${BASE_SCAN_URLS[currency][network]}${address}`
    }
    case 'address': {
      return `${BASE_SCAN_ADDR_URLS[currency][network]}${address}`
    }
    default: {
      return '#'
    }
  }
}

export default getScanLink
