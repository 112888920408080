export enum PATHS {
  REGISTRATION = '/registration',
  CONFIRM_EMAIL = '/confirm_email',
  CONFIRMATION = '/confirmation',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot_password',
  CONFIRMATION_FORGOT_PASSWORD = '/confirmation_forgot_password',
  CHANGE_PASSWORD = '/change_password',
  DASHBOARD = '/stores/:store/dashboard',
  NEW_DASHBOARD = '/dashboard',
  PAYMENTS = '/stores/:store/payments',
  INVOICES = '/stores/:store/invoices',
  CREATE_INVOICE = '/stores/:store/invoices/create',
  INVOICE_DETAILS = '/stores/:store/invoices/:id/details',
  DEVELOPERS = '/stores/:store/developers',
  STORE_SETTINGS = '/stores/:store/store_settings',
  SETTINGS = '/settings',
  WITHDRAW = '/stores/:store/dashboard/withdraw',
  TERMS_OF_USE = '/terms-of-use',
  PRIVACY_POLICE = '/privacy-policy',
  OAUTH_PASSWORD = '/oauth_password',
}
