import React, {useCallback, useState} from "react";
import {validate} from "../../../common/utils/address_validator";
import cloneDeep from "lodash/cloneDeep";
import {AddModalWithdrawal} from "./CreateWithdrawalRequisite.styles";
import {FormControlLabel} from "@mui/material";
import {Button, Input, Select, Switch} from "../../../elements";
import AuthCode from "react-auth-code-input";
import {AppStateType} from "../../../store";
import {connect} from "react-redux";
import {UserReducerState} from "../../../store/user/reducers";

const currenciesList = [
  // {
  //   value: "BTC",
  //   text: "BTC"
  // },
  // {
  //   value: "USDC",
  //   text: "USDC"
  // },
  {
    value: "USDT",
    text: "USDT"
  }
  // {
  //   value: "DAI",
  //   text: "DAI"
  // }
]

interface ModalWithdrawalProps {
  user: UserReducerState;
  closeModal: () => void;
  applyModal: (payload: any) => void;
}

const CreateWithdrawalRequisite: React.FC<ModalWithdrawalProps> = (props: ModalWithdrawalProps) => {
  const { user, closeModal, applyModal } = props;

  const [values, setValues] = useState<{ [key: string]: string }>({
    name: '',
    currency: 'USDT',
    address: '',
    tfa_code: ''
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [required, setRequired] = useState<boolean>(true);

  const getFormErrors = (data: any) => {
    const { name, address, currency } = data;
    const newErrors: any = {
      name: '',
      address: ''
    };

    if (!name) newErrors.name = 'Enter name';
    if (!address) newErrors.address = 'Enter address';
    if (!validate(address, currency.toLowerCase(), { networkType: 'prod', chainType: 'trc20' })) newErrors.address = 'Invalid address';

    return newErrors;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: any = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: any = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const handleAuthCode = (res: string) => {
    onChange('tfa_code', res);
  };

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: any = getFormErrors(values);
      setErrors(newErrors);

      const data: any = {
        name: values.name,
        currency: 'USDTTRX',
        address: values.address,
        tfa_code: values.tfa_code,
        required,
      };

      if (!checkErrors(newErrors)) {
        applyModal(data);
      }
    },
    [values, applyModal, required]
  );

  return (
    <AddModalWithdrawal>
      <form className="withdrawal-form" onSubmit={onSubmit}>
        <div className="withdrawal-form__head">
          <div className="withdrawal-token">
            <img className="withdrawal-token__img" src="/img/tokens/usdx.svg" alt="Tier" />
          </div>
          <div className="withdrawal-switch__wrap">
            <FormControlLabel
              className="switch"
              control={
                <Switch
                  className="withdrawal-switch"
                  checked={required}
                  onChange={() => setRequired(!required)}
                  name="withdrawal"
                  color="primary"
                />
              }
              label=""
            />
          </div>
        </div>
        <div className="withdrawal-form__body">

          <div className="withdrawal-form__row">
            <Input
              className='withdrawal-form__input'
              type="text"
              name="name"
              value={values.name}
              placeholder="Withdrawal requisite name"
              label="Withdrawal requisite name"
              error={errors.name}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>

          <div className="withdrawal-form__row">
            <Select
              className="withdrawal-form__select"
              name="currency"
              value={values.currency}
              placeholder="Settlement currency"
              error={errors.currency}
              list={currenciesList}
              image={`/img/tokens/${values.currency.toLowerCase()}.svg`}
              label="Settlement currency"
              fullWidth
              onChange={onChange}
            />
          </div>
          <div className="withdrawal-form__row">
            <Input
              className='withdrawal-form__input'
              type="text"
              name="address"
              value={values.address}
              placeholder="Your TRX address"
              label="Your TRX address"
              error={errors.address}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>

          <div className="withdrawal-auth">
            <span className="withdrawal-auth__title">Two-factor authentification</span>
            <div className="withdrawal-code__wrap">
              <AuthCode
                containerClassName="withdrawal-code__container"
                inputClassName="withdrawal-code"
                allowedCharacters="numeric"
                length={6}
                placeholder="0"
                autoFocus={false}
                onChange={handleAuthCode}
              />
            </div>
          </div>
        </div>
        <div className="withdrawal-form__footer">
          <Button
            className="withdrawal-form__btn -white"
            type="button"
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button
            className="withdrawal-form__footer_btn"
            type="submit"
            disabled={values.tfa_code.length !== 6}
          >
            Create
          </Button>
        </div>
      </form>
    </AddModalWithdrawal>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
  };
};

export default connect(mapStateToProps, {})(CreateWithdrawalRequisite);
