import React, {useCallback, useEffect} from "react";
import {Button, Icon, Loader} from "../../../elements";
import {DeleteWebhookParams, Webhook} from "../../../api";
import {DeleteApiKey} from "./DeleteEndpoint.styles"
import {connect} from "react-redux";
import {AppStateType} from "../../../store";
import {selectLoadingByKey} from "../../../store/loadingsErrors/selectors";
import types from "../../../store/actionTypes";
import {deleteWebhook} from "../../../store/stores/actions";
import {StoresReducerState} from "../../../store/stores/reducers";

interface ModalKeyProps {
  stores: StoresReducerState;
  closeModal: () => void;
  webhook: Webhook;
  title: string;
  deleteWebhook: (payload: DeleteWebhookParams) => void;
  loadingDeleteWebhook: boolean;
  storeId: string;
}

const DeleteEndpoint: React.FC<ModalKeyProps> = (props: ModalKeyProps) => {
  const { stores, closeModal, deleteWebhook, webhook, title, loadingDeleteWebhook, storeId } = props;

  useEffect(() => {
    if (stores.answer?.success) {
      closeModal();
    }

    if (stores.answer?.error?.error) {
      closeModal();
    }
  }, [closeModal, stores]);

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();

      const deleteData: DeleteWebhookParams = {
        data: {
          webhook_url: webhook
        },
        storeId
      };

      deleteWebhook(deleteData);
    },
    [storeId, webhook, deleteWebhook]
  );

  return (
    <DeleteApiKey>
      <div className="addKey-icon">
        <Icon name="remove_key" size="56" />
      </div>
      <div className="addKey-texts">
        <span className="addKey-texts__title">{title}</span>
        <span className="addKey-texts__description">{webhook}</span>
      </div>
      <div>
        <form className="addKey-form" onSubmit={onSubmit}>
          <div className="addKey-form__actions">
            <div className="addKey-form__btns">
              <Button
                className="addKey-form__btn -white"
                type="button"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              <Button
                className="addKey-form__btn loading-btn"
                type="submit"
                disabled={loadingDeleteWebhook}
              >
                Continue
                {loadingDeleteWebhook ? <Loader /> : null}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </DeleteApiKey>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { stores } = state;
  return {
    stores,
    loadingDeleteWebhook: selectLoadingByKey(state, types.DELETE_WEBHOOK_REQUEST),
  };
};

export default connect(mapStateToProps, {deleteWebhook})(DeleteEndpoint);
