import styled from 'styled-components';

export const PageSwitcherBlock: any = styled.div`
  display: flex;
  align-items: center;
  
  .pagination {
    &-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      cursor: pointer;
      
      &.-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    
    &-icon {
      
    }
    
    &-number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
      background-color: transparent;
      border-radius: var(--border-radius);
      margin: 0 1px;
      cursor: pointer;
      
      &.-active {
        color: #444CE7;
        background-color: #EEF4FF;
      }
    }
    
    &-dots {
      
    }
  }
`;
