import {makeHandler} from "./makeHandler";
import {InvoiceMeta} from "./codecs.invoices";

export const getPayments = makeHandler(
  'getPayments',
  (data: GetPaymentsParams) => ({
    method: 'GET',
    path: `/stores/${data.storeId}/payments?status=${data.status}&date_from=${data.from_day}&date_to=${data.to_day}&limit=${data.limit}&page=${data.page}&sort_by=${data.sort_by}&sort_order=${data.direction}`,
  }),
  (res: GetPaymentsResponse) => res
);

export const exportPayments = makeHandler(
  'exportPayments',
  (data: ExportPaymentsParams) => ({
    method: 'GET',
    path: `/stores/${data.storeId}/payments/csv?date_from=${data.dateFrom}&date_to=${data.dateTo}`,
  }),
  (res: Blob) => res
);


export type GetPaymentsParams = {
  storeId?: string;
  status: string;
  from_day: string;
  to_day: string;
  limit: string | number;
  page: string | number;
  sort_by: string;
  direction: string;
};

export type GetPaymentsResponse = {
  data: Payment[],
  meta: InvoiceMeta
};

export type Payment = {
  id: string;
  amount_usd: string;
  tx_hash: string;
  flat_fee: number;
  fix_usdx_fee: string;
  amount_btc: string;
  address: string;
  amount_paid: string;
  payment_currency: string;
  created_at: string;
  invoice_id: string
};

export type ExportPaymentsParams = {
  storeId: string;
  dateFrom: string;
  dateTo: string;
};

export type ExportPaymentsResponse = {
  data: {},
  meta: InvoiceMeta
};
