import React, {useEffect, useState} from 'react';

import {
  Container,
  MainContent,
  BreadCrumb,
  Button, Loader,
  // Icon
} from '../../../elements';
import {DetailsContainer} from "../Invoices.Styles";
import dayjs from "dayjs";
import {AppStateType} from "../../../store";
import {selectLoadingByKey} from "../../../store/loadingsErrors/selectors";
import types from "../../../store/actionTypes";
import {connect} from "react-redux";
import {deactivateInvoice, getInvoice, makePaidInvoice} from "../../../store/invoices/actions";
import {clear} from "../../../store/user/actions";
import {StoresReducerState} from "../../../store/stores/reducers";
import {InvoicesReducerState} from "../../../store/invoices/reducers";
import {DeactivateInvoiceParams, GetInvoiceParams, Invoice, MakePaidInvoiceParams} from "../../../api";
import useToast from "../../../hooks/useToast";
import {LOADING_TYPES} from "../../../const/app.constants";
import {Link, useNavigate, useParams} from "react-router-dom";
import {PAY_TESTNET_URL, PAY_URL} from "../../../const/general.constants";
import {UserReducerState} from "../../../store/user/reducers";
import {formatNumber} from "../../../common/utils/formatters";
import {PATHS} from "../../../const/paths.constants";
import {GetDocumentParams} from "../../../api";
import API from "../../../api/executor";
import {ApiHandlerResponse} from "../../../api/calls";
import {InvoiceStatus} from "../../../api/codecs.invoices";

export interface Props {
  user: UserReducerState;
  stores: StoresReducerState;
  invoices: InvoicesReducerState;
  getInvoice: (payload: GetInvoiceParams) => void;
  makePaidInvoice: (payload: MakePaidInvoiceParams) => void;
  deactivateInvoice: (payload: DeactivateInvoiceParams) => void;
  loading: boolean;
  clear: () => void;
}

const InvoiceDetails: React.FC<Props> = (props: Props) => {
  const {user, stores, invoices, loading, getInvoice, makePaidInvoice, deactivateInvoice, clear} = props;
  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();
  const { id } = useParams();

  const [activeStore, setActiveStore] = useState<string | null>(null);
  const [invoice, setInvoice] = useState<Invoice | undefined>(undefined);
  const [pdfViewLoading, setPdfViewLoading] = useState<boolean>(false);
  const [pdfDownloadLoading, setPdfDownloadLoading] = useState<boolean>(false);

  const getDate = (date: string | undefined): number => {
    const newDate: Date = date ? new Date(date) : new Date()
    return Math.floor(newDate.getTime() / 1000);
  };

  const getSubtotal = (items: any): number => {
    let subtotal = 0;
    if (items?.length) {
      items.forEach((item: any) => {
        subtotal += Number(item.price) * Number(item.qty)
      });
    }

    return subtotal;
  };

  useEffect(() => {
    if (stores.activeStore) {
      if (!invoices.invoice.item?.id && invoices.invoice.loaded === LOADING_TYPES.NOT_LOADED) {
        setActiveStore(stores.activeStore);
        getInvoice({
          storeId: stores.activeStore,
          invoiceId: id || '',
        })
      }

      if (invoices.invoice.item?.id !== id && invoices.invoice.loaded === LOADING_TYPES.LOADED) {
        setActiveStore(stores.activeStore);
        getInvoice({
          storeId: stores.activeStore,
          invoiceId: id || '',
        })
      }


      if ((invoices.invoice.item?.id === id) && invoices.invoice.loaded === LOADING_TYPES.LOADED) {
        setInvoice(invoices.invoice.item || undefined);
      }
    }

    if (invoices.answer?.success) {
      toastSuccess(invoices.answer?.success);
      if (invoices.invoice.item?.status === InvoiceStatus.CANCELLED) {
        navigate(PATHS.INVOICES.replace(':store', `${stores.activeStore}`));
      }
      clear();
    }

    if (invoices.answer?.error?.error) {
      toastError(stores.answer?.error?.error);
      clear();
    }
  }, [stores, invoices, toastError, toastSuccess, clear, activeStore, invoice, id, getInvoice, navigate]);

  const handleMakePaid = () => {
    const make: MakePaidInvoiceParams = {
      storeId: stores.activeStore || '',
      invoiceId: id || '',
    };
    makePaidInvoice(make);
  };

  const handleDeactivate = () => {
    const deactivate: DeactivateInvoiceParams = {
      storeId: stores.activeStore || '',
      invoiceId: id || '',
    };
    deactivateInvoice(deactivate);
  };

  const handleGetPdf = async (type?: string) => {
    const documentProps: GetDocumentParams = {
      storeId: stores.activeStore || '',
      type: 'invoices',
      id: id || '',
    };
    const getDocumentCall = (payload: GetDocumentParams) => API.call('getDocument', payload);

    if (type === 'download') {
      setPdfDownloadLoading(true);
    } else {
      setPdfViewLoading(true);
    }
    await getDocumentCall(documentProps).then((documentBlob: ApiHandlerResponse<'getDocument'>) => {
      const blobUrl = URL.createObjectURL(documentBlob);
      const link = document.createElement('a');
      link.href = blobUrl;

      if (type === 'download') {
        link.download = `invoice_id_${id}.pdf`;
        setPdfDownloadLoading(false);
      } else {
        link.target = '_blank';
        setPdfViewLoading(false);
      }
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
      document.body.removeChild(link);
    });
  };

  return (
    <MainContent className="content-main">
      <Container>
        <BreadCrumb />
        <DetailsContainer className="details">
          <div className="details-head">
            <div className="details-head__row">
              <div className="details-head__left">
                <div className="details-title__wrap">
                  <span className="details-title">{invoice?.number}</span>
                  <span className="details-status__item">
                    {
                      invoice?.status ? (
                        <span className={`details-status -${invoice?.status?.toLowerCase()}`}>{invoice?.status}</span>
                      ) : null
                    }
                  </span>
                </div>
              </div>
              <div className="details-head__right">
                <div className="details-head__btns">
                  {
                    invoice?.status?.toLowerCase() === 'new' ? (
                      <Button
                        className="details-head__btn -send"
                        as={Link}
                        target="_blank"
                        to={`${user.useTestnet ? PAY_TESTNET_URL : PAY_URL}/${id}`}
                        type="button"
                      >
                        Send invoice
                      </Button>
                    ) : null
                  }

                  {
                    invoice?.status?.toLowerCase() === 'paid' ? (
                      <>
                        <Button
                          className="details-head__btn -white -download loading-btn"
                          onClick={() => handleGetPdf('download')}
                          type="button"
                          disabled={pdfDownloadLoading || pdfViewLoading}
                        >
                          Download PDF
                          {pdfDownloadLoading ? <Loader /> : null}
                        </Button>
                        <Button
                          className="details-head__btn -white -view"
                          as={Link}
                          target="_blank"
                          to={`${user.useTestnet ? PAY_TESTNET_URL : PAY_URL}/${id}`}
                          type="button"
                        >
                          View invoice
                        </Button>
                      </>
                    ) : null
                  }
                </div>
              </div>
            </div>
            <div className="details-head__row">
              <div className="details-date__wrap">
                <span className="details-date">Due on {invoice?.data?.due_date ? Number(invoice?.data?.due_date) ? dayjs.unix(Number(invoice?.data?.due_date)).format('MMM D, YYYY') : dayjs(invoice?.data?.due_date).format('MMM D, YYYY') : dayjs(new Date()).format('MMM D, YYYY')}</span>
              </div>
            </div>
          </div>

          <div className="details-summary">
            <div className="details-summary__title_wrap">
              <span className="details-summary__title">Summary</span>
            </div>
            <div className="details-summary__info">
              <div className="details-summary__info_row">
                <span className="details-summary__info_item -name">From</span>
                <span className="details-summary__info_item -empty">{invoice?.data?.address ? invoice?.data?.address : 'No address'}</span>
                <span className="details-summary__info_item -name">Issued</span>
                <span className="details-summary__info_item">{dayjs.unix(getDate(invoice?.created_at)).format('MMM D, YYYY h:mm A')}</span>
              </div>
              <div className="details-summary__info_row">
                <span className="details-summary__info_item -name">Billed to</span>
                <span className="details-summary__info_item">{invoice?.customer_email}</span>
                <span className="details-summary__info_item -name">Due</span>
                <span className="details-summary__info_item">{invoice?.data?.due_date ? Number(invoice?.data?.due_date) ? dayjs.unix(Number(invoice?.data?.due_date)).format('MMM D, YYYY') : dayjs(invoice?.data?.due_date).format('MMM D, YYYY') : dayjs(new Date()).format('MMM D, YYYY')}</span>
              </div>
              <div className="details-summary__info_row">
                <span className="details-summary__info_item -name">Name</span>
                <span className="details-summary__info_item">{invoice?.data?.customer_name ? invoice?.data?.customer_name : 'No name'}</span>
                <span className="details-summary__info_item -name">Currency</span>
                <span className="details-summary__info_item">{invoice?.currency}</span>
              </div>
              <div className="details-summary__info_row">
                <span className="details-summary__info_item -name">Address</span>
                <span className="details-summary__info_item -empty">{invoice?.data?.customer_address ? invoice?.data?.customer_address : 'No address'}</span>
                <span className="details-summary__info_item -name">Notes</span>
                <span className="details-summary__info_item -empty">{invoice?.data?.notes ? invoice?.data?.notes : 'No notes'}</span>
              </div>
            </div>
          </div>

          <div className="details-table">
            <div className="details-table__head">
              <span
                className="details-table__head_item -name"
              >
                Item
              </span>
              <span
                className="details-table__head_item"
              >
                Quanity
              </span>
              <span
                className="details-table__head_item"
              >
                Price
              </span>
              <span
                className="details-table__head_item"
              >
                Total
              </span>
              {/*<span className="details-table__head_item" />*/}
            </div>
            <div className="details-table__body">
              {
                invoice?.data?.items.length ? invoice?.data?.items.map((item: any, index: number) => (
                  <div key={`details-table__body_row-${index + 1}`} className="details-table__body_row">
                    <span className="details-table__body_item -name">{item?.item || ''}</span>
                    <span className="details-table__body_item">{item?.qty || 1}</span>
                    <span className="details-table__body_item">{formatNumber(item.price || 0)} {invoice?.currency}</span>
                    <span className="details-table__body_item">{formatNumber(Number(item.price) * Number(item?.qty))} {invoice?.currency}</span>
                  </div>
                )) : null
              }
              {/*<div className="details-table__body_row">*/}
              {/*  <span className="details-table__body_item -name">Glass</span>*/}
              {/*  <span className="details-table__body_item">1</span>*/}
              {/*  <span className="details-table__body_item">3.00 USDX</span>*/}
              {/*  <span className="details-table__body_item">3.00 USDX</span>*/}
              {/*  <button className="details-table__body_btn">*/}
              {/*    <Icon className="details-table__body_icon" name="dots" size="16" />*/}
              {/*  </button>*/}
              {/*</div>*/}
            </div>
            <div className="details-table__footer">
              <div className="details-table__footer_row">
                <span className="details-table__footer_item" />
                <span className="details-table__footer_item" />
                <span className="details-table__footer_item">Subtotal</span>
                <span className="details-table__footer_item">{formatNumber(getSubtotal(invoice?.data?.items || []))} {invoice?.currency}</span>
                {/*<span className="details-table__footer_item" />*/}
              </div>
              <div className="details-table__footer_row">
                <span className="details-table__footer_item" />
                <span className="details-table__footer_item" />
                <span className="details-table__footer_item">Discount ({invoice?.data?.discount || 0}%)</span>
                <span className="details-table__footer_item">-{formatNumber(getSubtotal(invoice?.data?.items || []) * (Number(Number(invoice?.data?.discount) / 100) || 0) )} {invoice?.currency}</span>
                {/*<span className="details-table__footer_item" />*/}
              </div>
              {/*<div className="details-table__footer_row">*/}
              {/*  <span className="details-table__footer_item" />*/}
              {/*  <span className="details-table__footer_item" />*/}
              {/*  <span className="details-table__footer_item">Processing fee</span>*/}
              {/*  <span className="details-table__footer_item">0 {invoice?.currency}</span>*/}
              {/*  <span className="details-table__footer_item" />*/}
              {/*</div>*/}
              <div className="details-table__footer_row">
                <span className="details-table__footer_item" />
                <span className="details-table__footer_item" />
                <span className="details-table__footer_item -name">Amount due</span>
                <span className="details-table__footer_item -name">{formatNumber(invoice?.amount || 0)} {invoice?.currency}</span>
                {/*<span className="details-table__footer_item" />*/}
              </div>
            </div>
          </div>

          <div className="details-footer__btns">
            {
              invoice?.status?.toLowerCase() === 'new' ? (
                <Button
                  className="details-footer__btn -pink"
                  type="button"
                  disabled={loading}
                  onClick={() => handleDeactivate()}
                >
                  Void invoice
                </Button>
              ) : null
            }
            {
              stores.useTestnet && (invoice?.status === InvoiceStatus.NEW) ? (
                <Button
                  className="details-footer__btn"
                  type="button"
                  disabled={loading}
                  onClick={() => handleMakePaid()}
                >
                  Make paid
                </Button>
              ) : null
            }
          </div>
        </DetailsContainer>
      </Container>
    </MainContent>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { user, stores, invoices } = state;
  return {
    user,
    stores,
    invoices,
    loading: selectLoadingByKey(state, types.MAKE_PAID_INVOICE_REQUEST),
  };
};

export default connect(mapStateToProps, {getInvoice, makePaidInvoice, deactivateInvoice, clear })(InvoiceDetails);
