export enum LOADING_TYPES {
  NOT_LOADED = 'NOT_LOADED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

export enum ALERT_TYPES {
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
}

export enum ALERT_CLASSES {
  DANGER = 'alert-danger',
  SUCCESS = 'alert-success',
}

export enum SOCIAL_NETWORKS {
  WHATSAPP = 'WHATSAPP',
  TWITTER = 'TWITTER',
  VK = 'VK',
  TELEGRAM = 'TELEGRAM',
  FACEBOOK = 'FACEBOOK',
}

export const SOCIAL_NETWORKS_LANGUAGE = {
  en: {
    list: [
      SOCIAL_NETWORKS.WHATSAPP,
      SOCIAL_NETWORKS.TWITTER,
      SOCIAL_NETWORKS.VK,
      SOCIAL_NETWORKS.TELEGRAM,
      SOCIAL_NETWORKS.FACEBOOK,
    ],
    links: {
      whatsapp: '#',
      twitter: '#',
      vk: '#',
      telegram: '#',
      facebook: '#',
    },
  },
  ru: {
    list: [
      SOCIAL_NETWORKS.WHATSAPP,
      SOCIAL_NETWORKS.TWITTER,
      SOCIAL_NETWORKS.VK,
      SOCIAL_NETWORKS.TELEGRAM,
      SOCIAL_NETWORKS.FACEBOOK,
    ],
    links: {
      whatsapp: '#',
      twitter: '#',
      vk: '#',
      telegram: '#',
      facebook: '#',
    },
  },
};

export const DEFAULT_PAGE_LIMIT = 10

export const BASE_SCAN_URLS = {
  ETH: {
    TESTNET: 'https://goerli.etherscan.io/tx/',
    MAINNET: 'https://etherscan.io/tx/'
  },
  USDTETH: {
    TESTNET: 'https://goerli.etherscan.io/tx/',
    MAINNET: 'https://etherscan.io/tx/'
  },
  USDTTRX: {
    TESTNET: 'https://nile.tronscan.org/#/transaction/',
    MAINNET: 'https://tronscan.org/#/transaction/'
  },
  BTC: {
    TESTNET: 'https://live.blockcypher.com/btc-testnet/tx/',
    MAINNET: 'https://live.blockcypher.com/btc/tx/'
  }
};

export const BASE_SCAN_ADDR_URLS = {
  USDT: {
    TESTNET: 'https://nile.tronscan.org/#/address/',
    MAINNET: 'https://tronscan.org/#/address/'
  }
};
