import React, {useCallback, useEffect, useRef, useState} from "react";
import cloneDeep from "lodash/cloneDeep";
import {Button, Icon, Input, Loader} from "../../../elements";
import {IPWL, UpdateIPWLParams} from "../../../api";
import {AddEndpointStyle} from "./AddIPWL.styles"
import {InputType} from "../../../elements/Input/Input";
import {connect} from "react-redux";
import {AppStateType} from "../../../store";
import {selectLoadingByKey} from "../../../store/loadingsErrors/selectors";
import types from "../../../store/actionTypes";
import {updateIPWL} from "../../../store/stores/actions";
import {StoresReducerState} from "../../../store/stores/reducers";
import AuthCode, {AuthCodeRef} from "react-auth-code-input";

interface ModalKeyProps {
  stores: StoresReducerState;
  closeModal: () => void;
  title: string;
  ipwls: IPWL[];
  description: string;
  placeholder: string;
  inputType?: InputType;
  updateIPWL: (payload: UpdateIPWLParams) => void;
  loadingCreateIPWL: boolean;
  storeId: string;
}

const AddIPWL: React.FC<ModalKeyProps> = (props: ModalKeyProps) => {
  const { stores, closeModal, updateIPWL, storeId, loadingCreateIPWL, title, ipwls, description, placeholder, inputType } = props;

  const [values, setValues] = useState<{ [key: string]: string }>({
    name: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [tfaCode, setTfaCode] = useState<string>('');
  const tfaInputRef = useRef<AuthCodeRef>(null);

  useEffect(() => {
    if (stores.answer?.success) {
      if (stores.answer?.success === 'New IP address was successfully added') {
        closeModal();
      }
    }

    if (stores.answer?.error?.error) {
      if (stores.answer?.error?.error === '2fa code is invalid') {
        tfaInputRef.current?.clear();
        tfaInputRef.current?.focus();
      }

      if (stores.answer?.error?.error === 'Max number of failed attempts reached. All action with 2FA blocked. Please contact with the administration') {
        tfaInputRef.current?.clear();
        tfaInputRef.current?.focus();
      }
    }
  }, [closeModal, stores]);

  const getFormErrors = (data: any) => {
    const { name } = data;
    const newErrors: any = {
      name: '',
    };

    if (!name) newErrors.name = 'Enter name';

    return newErrors;
  };

  const handleAuthCode = (res: string) => {
    setTfaCode(res);
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: any = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: any = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: any = getFormErrors(values);
      setErrors(newErrors);

      const createData: UpdateIPWLParams = {
        data: {
          address_whitelist: [...ipwls, values.name].join(','),
          tfa_code: tfaCode,
        },
        storeId
      };

      if (!checkErrors(newErrors)) {
        updateIPWL(createData);
      }
    },
    [values, ipwls, tfaCode, storeId, updateIPWL]
  );

  return (
    <AddEndpointStyle>
      <div className="addKey-icon">
        <Icon name="add_key" size="56" />
      </div>
      <div className="addKey-texts">
        <span className="addKey-texts__title">{title}</span>
        <span className="addKey-texts__description">{description}</span>
      </div>
      <div>
        <form className="addKey-form" onSubmit={onSubmit}>
          <div className='addKey-input__wrap'>
            <Input
              className='addKey-input'
              type={inputType ? inputType : "text"}
              name="name"
              value={values.name}
              placeholder={placeholder}
              error={errors.name}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
          <div className="addKey-code__wrap">
            <AuthCode
              ref={tfaInputRef}
              containerClassName="addKey-code__container"
              inputClassName="addKey-code"
              allowedCharacters="numeric"
              length={6}
              placeholder="0"
              autoFocus={false}
              onChange={handleAuthCode}
            />
          </div>
          <div className="addKey-form__actions">
            <div className="addKey-form__btns">
              <Button
                className="addKey-form__btn -white"
                type="button"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              <Button
                className="addKey-form__btn"
                type="submit"
                disabled={loadingCreateIPWL}
              >
                Continue
                {loadingCreateIPWL ? <Loader/> : null}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </AddEndpointStyle>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const {stores} = state;
  return {
    stores,
    loadingCreateIPWL: selectLoadingByKey(state, types.UPDATE_IPWL_REQUEST),
  };
};

export default connect(mapStateToProps, {updateIPWL})(AddIPWL);
