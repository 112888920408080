import {
  CreateWithdrawalParams,
  GetWithdrawalsParams,
  DeclineWithdrawalParams,
} from '../../api';

const CREATE_WITHDRAWAL_REQUEST = 'CREATE_WITHDRAWAL_REQUEST';
const CREATE_WITHDRAWAL_SUCCESS = 'CREATE_WITHDRAWAL_SUCCESS';
const CREATE_WITHDRAWAL_FAILURE = 'CREATE_WITHDRAWAL_FAILURE';
const GET_WITHDRAWALS_REQUEST = 'GET_WITHDRAWALS_REQUEST';
const GET_WITHDRAWALS_SUCCESS = 'GET_WITHDRAWALS_SUCCESS';
const GET_WITHDRAWALS_FAILURE = 'GET_WITHDRAWALS_FAILURE';
const DECLINE_WITHDRAWAL_REQUEST = 'DECLINE_WITHDRAWAL_REQUEST';
const DECLINE_WITHDRAWAL_SUCCESS = 'DECLINE_WITHDRAWAL_SUCCESS';
const DECLINE_WITHDRAWAL_FAILURE = 'DECLINE_WITHDRAWAL_FAILURE';
const SET_WITHDRAWALS_LOADING = 'SET_WITHDRAWALS_LOADING';

type CreateWithdrawalActionType = {
  type: typeof CREATE_WITHDRAWAL_SUCCESS | typeof CREATE_WITHDRAWAL_FAILURE;
  payload?: any;
};

export type CreateWithdrawalRequest = {
  type: typeof CREATE_WITHDRAWAL_REQUEST;
  payload?: CreateWithdrawalParams;
};

type GetWithdrawalsActionType = {
  type: typeof GET_WITHDRAWALS_SUCCESS | typeof GET_WITHDRAWALS_FAILURE;
  payload?: any;
};

export type GetWithdrawalsRequest = {
  type: typeof GET_WITHDRAWALS_REQUEST;
  payload: GetWithdrawalsParams;
};

type DeclineWithdrawalActionType = {
  type: typeof DECLINE_WITHDRAWAL_SUCCESS | typeof DECLINE_WITHDRAWAL_FAILURE;
  payload?: any;
};

export type DeclineWithdrawalRequest = {
  type: typeof DECLINE_WITHDRAWAL_REQUEST;
  payload?: DeclineWithdrawalParams;
};

export type SetWithdrawalsLoading = {
  type: typeof SET_WITHDRAWALS_LOADING;
  payload: GetWithdrawalsParams;
};

// refactored for shorter version
export type CreateWithdrawalTypes = CreateWithdrawalActionType | CreateWithdrawalRequest;
export type GetWithdrawalsTypes = GetWithdrawalsActionType | GetWithdrawalsRequest;
export type DeclineWithdrawalTypes = DeclineWithdrawalActionType | DeclineWithdrawalRequest;

export type WithdrawalsActionTypes =
  | CreateWithdrawalTypes
  | GetWithdrawalsTypes
  | DeclineWithdrawalTypes
  | SetWithdrawalsLoading;

export default {
  CREATE_WITHDRAWAL_REQUEST,
  CREATE_WITHDRAWAL_SUCCESS,
  CREATE_WITHDRAWAL_FAILURE,
  GET_WITHDRAWALS_REQUEST,
  GET_WITHDRAWALS_SUCCESS,
  GET_WITHDRAWALS_FAILURE,
  DECLINE_WITHDRAWAL_REQUEST,
  DECLINE_WITHDRAWAL_SUCCESS,
  DECLINE_WITHDRAWAL_FAILURE,
  SET_WITHDRAWALS_LOADING,
} as const;
