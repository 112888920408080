import styled from 'styled-components';

export const TabsContent: any = styled.div``;

export const TabsControl: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  .tab-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: var(--cream);
    border: 1px solid #F2F4F7;
    border-radius: var(--border-radius);
    padding: 6px;
  }
`;

export const TabsControlItem: any = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray);
  text-align: center;
  border-radius: var(--border-radius);
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  outline: none;

  &:not(:last-child) {
    margin-right: 6px;
  }

  &:focus {
    text-shadow: 1px 1px 2px #E0EAFF;
  }
  
  &:hover {

  }

  &.active {
    background: var(--white);
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05);
    color: var(--dark-gray);
  }
`;

export const TabsContentItem: any = styled.div`
  display: none;
  animation: fadeIn 0.8s;

  &.active {
    display: block;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
