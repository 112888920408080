import React, {useCallback, useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import {
  Button,
  Container, Input, Loader,
  MainContent, Select,
  Tabs, CopyInput
} from '../../elements';
import {SettingsContainer} from "./Settings.Styles";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import {TabsContentItem} from "../../elements/Tabs/Tabs.Styles";
import {
  ActivateTFAParams,
  EmailData, Store,
  UpdatePasswordParams,
  UserData,
} from "../../api";
import AuthCode, {AuthCodeRef} from "react-auth-code-input";
import {AppStateType} from "../../store";
import {selectLoadingByKey} from "../../store/loadingsErrors/selectors";
import types from "../../store/actionTypes";
import {COUNTRIES, CountryWithLangs} from "../../const/countries.constants";
import QRCode from 'react-qr-code';
import {UserReducerState} from "../../store/user/reducers";
import {
  getTFA,
  activateTFA,
  turnOffTFA,
  turnOnTFA,
  clear,
  getProfile,
  updateProfile,
  updatePassword,
  updateEmail
} from "../../store/user/actions";
import {validateEmail, validatePassword} from "../../common/utils/validators";
import useToast from "../../hooks/useToast";
import {LOADING_TYPES} from "../../const/app.constants";
import {PATHS} from "../../const/paths.constants";
import {StoresReducerState} from "../../store/stores/reducers";
import find from "lodash/find";

export const SETTINGS_TABS_LIST = ['PROFILE', 'CHANGE_PASSWORD', 'SECURITY_2FA'];
export enum SETTINGS_TABS {
  PROFILE = 'PROFILE',
  SECURITY_2FA = 'SECURITY_2FA',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

export type SettingsParams = {
  tab?: string;
  country: string;
  first_name: string;
  last_name: string;
  email: string;
};

export interface SettingsProps {
  user: UserReducerState;
  stores: StoresReducerState;
  loadingTFA: boolean;
  loadingActivateTFA: boolean;
  loadingTurnOffTFA: boolean;
  loadingTurnOnTFA: boolean;
  loadingUpdateProfile: boolean;
  loadingUpdatePassword: boolean;
  loadingUpdateEmail: boolean;
  getTFA: () => void;
  activateTFA: (payload: ActivateTFAParams) => void;
  turnOffTFA: (payload: ActivateTFAParams) => void;
  turnOnTFA: (payload: ActivateTFAParams) => void;
  getProfile: () => void;
  updateProfile: (payload: UserData) => void;
  updatePassword: (payload: UpdatePasswordParams) => void;
  updateEmail: (payload: EmailData) => void;
  clear: () => void;
}

const Settings: React.FC<SettingsProps> = (props: SettingsProps) => {
  const { user, stores, getTFA, activateTFA, turnOffTFA, turnOnTFA, getProfile, updateProfile, updatePassword, updateEmail, clear, loadingTFA, loadingActivateTFA, loadingTurnOffTFA, loadingTurnOnTFA, loadingUpdateProfile, loadingUpdatePassword, loadingUpdateEmail } = props;

  const { t } = useTranslation();
  const tfaActivateInputRef = useRef<AuthCodeRef>(null);
  const tfaOffInputRef = useRef<AuthCodeRef>(null);
  const tfaOnInputRef = useRef<AuthCodeRef>(null);
  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const countriesList = COUNTRIES.map((country: CountryWithLangs) => {
    return {
      value: country.code.toLowerCase(),
      text: country.en

    }
  })

  const [values, setValues] = useState<{ [key: string]: string }>({
    tab: tab || SETTINGS_TABS.PROFILE,
    country: user.profile.country || '',
    first_name: user.profile.first_name || '',
    last_name: user.profile.last_name || '',
    email: user.profile.email || '',
    old_password: '',
    new_password: '',
    re_password: '',
    password: '',
    tfa_code: '',
  });
  const [emailValues, setEmailValues] = useState<{ [key: string]: string }>({
    email: '',
    // password: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [emailErrors, setEmailErrors] = useState<{ [key: string]: string }>({});
  const [profileUpdated, setProfileUpdated] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<{ [key: string]: boolean }>({
    old_password: false,
    new_password: false,
    re_password: false,
    password: false,
  });
  const [hasApiKey, setHasApiKey] = useState<boolean>(false);
  const [hasInvoice, setHasInvoice] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setInitialData = () => {
    if (tab === SETTINGS_TABS.SECURITY_2FA) {
      setValues(prev => ({
        ...prev,
        password: '',
        tfa_code: '',
      }));

      tfaActivateInputRef.current?.clear();
      tfaOffInputRef.current?.clear();
      tfaOnInputRef.current?.clear();
    }

    if (tab === SETTINGS_TABS.CHANGE_PASSWORD) {
      setValues(prev => ({
        ...prev,
        old_password: '',
        new_password: '',
        re_password: '',
      }));

      setEmailValues(prev => ({
        ...prev,
        password: '',
        email: '',
      }));
    }
  }

  useEffect(() => {

    if (user.useTestnet) {
      if (stores.activeStore) {
        navigate(PATHS.DASHBOARD.replace(':store', stores.activeStore))
      } else {
        navigate(PATHS.NEW_DASHBOARD)
      }
    }

    if (values.tab === SETTINGS_TABS.PROFILE && user.profileLoaded === LOADING_TYPES.NOT_LOADED) {
      getProfile();
    }

    if (values.tab === SETTINGS_TABS.PROFILE && user.profileLoaded === LOADING_TYPES.LOADED && user.confirmationCodeSanded) {
      getProfile();
    }

    if (values.tab === SETTINGS_TABS.PROFILE && user.profileLoaded === LOADING_TYPES.LOADED && !profileUpdated) {
      setValues(prev => ({
        ...prev,
        country: user.profile.country || '',
        first_name: user.profile.first_name || '',
        last_name: user.profile.last_name || '',
        email: user.profile.email || '',
      }));
      setProfileUpdated(true);
    }

    if (values.tab === SETTINGS_TABS.PROFILE && user.profileLoaded === LOADING_TYPES.LOADED && user.profile.email !== values.email) {
      setValues(prev => ({
        ...prev,
        country: user.profile.country || '',
        first_name: user.profile.first_name || '',
        last_name: user.profile.last_name || '',
        email: user.profile.email || '',
      }));
      setProfileUpdated(true);
    }

    if (tab === SETTINGS_TABS.SECURITY_2FA && !user.tfa.loaded) {
      getTFA();
    }

    // @ts-ignore
    const newShop: Store | undefined = find(stores.stores.list, { id: stores.activeStore });
    if (newShop) {
      setHasApiKey(!!newShop?.has_api_key);
      setHasInvoice(!!newShop?.has_invoice);
    }

    if (user.answer?.success) {
      toastSuccess(user.answer?.success);
      if (user.profile.unconfirmed_email?.toLowerCase().replace(/\s/g, '') === emailValues.email.toLowerCase().replace(/\s/g, '')) {
        navigate(PATHS.CONFIRM_EMAIL);
      }
      if (!hasApiKey && !hasInvoice && user.answer.success === 'Two-factor authentication successfully activated') {
        getTFA();
        navigate(PATHS.DASHBOARD.replace(':store', `${stores.activeStore}`));
      }
      clear();
      setInitialData();
    }

    if (user.answer?.error) {
      if (user.answer.error.error) {
        toastError(user.answer.error.error);
      }

      const newErrors: any = {
        country: '',
        first_name: '',
        last_name: '',
        email: '',
        old_password: '',
        new_password: '',
        re_password: '',
        password: '',
      };

      const newEmailErrors: any = {
        email: '',
        password: '',
      };
      Object.keys(user.answer.error).forEach((key: string) => {
        newErrors[key] = user.answer.error[key];
      });

      Object.keys(user.answer.error).forEach((key: string) => {
        newEmailErrors[key] = user.answer.error[key];
      });

      setErrors(newErrors);
      setEmailErrors(newEmailErrors);

      if (user.answer?.error?.error === '2fa code is invalid') {
        tfaActivateInputRef.current?.clear();
        tfaActivateInputRef.current?.focus();
      }

      if (user.answer?.error?.error === 'Max number of failed attempts reached. All action with 2FA blocked. Please contact with the administration') {
        tfaActivateInputRef.current?.clear();
        tfaActivateInputRef.current?.focus();
      }

      clear();
    }
  }, [tab, user, stores, values, emailValues.email, navigate, getTFA, getProfile, setValues, toastSuccess, toastError, clear, setInitialData, profileUpdated, setProfileUpdated, hasApiKey, hasInvoice]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFormErrors = (data: any) => {
    const { country, first_name, last_name, email, old_password, new_password, re_password, password } = data;
    const newErrors: any = {
      country: '',
      first_name: '',
      last_name: '',
      email: '',
      old_password: '',
      new_password: '',
      re_password: '',
      password: '',
    };

    if (data.tab === SETTINGS_TABS.PROFILE) {
      if (!country) newErrors.country = 'Select country';
      if (!first_name) newErrors.first_name = 'Enter first name';
      if (!last_name) newErrors.last_name = 'Enter last name';
      if (!email) newErrors.email = 'Enter email';
      if (email && !validateEmail(email)) newErrors.email = 'Please enter a valid email';
    }

    if (data.tab === SETTINGS_TABS.CHANGE_PASSWORD) {
      if (!old_password) newErrors.old_password = 'Enter password';
      if (!new_password) newErrors.new_password = 'Enter password';
      if (new_password && !validatePassword(new_password)) newErrors.new_password = 'Please enter a valid password';
      if (!re_password) newErrors.re_password = 'Enter password';
      if (re_password && !validatePassword(re_password)) newErrors.re_password = 'Please enter a valid password';
      if (new_password && re_password && new_password !== re_password) newErrors.re_password = "Passwords don't match";
    }


    if (data.tab === SETTINGS_TABS.SECURITY_2FA) {
      if (!user.tfa.tfa_confirmed) {
        if (!password) newErrors.password = 'Enter password';
      }
    }

    return newErrors;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getEmailFormErrors = (data: any) => {
    const {
      email,
      // password
    } = data;
    const newEmailErrors: any = {
      email: '',
      // password: '',
    };

    if (!email) newEmailErrors.email = 'Enter email';
    if (email && !validateEmail(email)) newEmailErrors.email = 'Please enter a valid email';
    // if (!password) newEmailErrors.password = 'Enter password';
    // if (password && !validatePassword(password)) newEmailErrors.password = 'Please enter a valid password';

    return newEmailErrors;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: SettingsParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }

    if (field === 'tab') {
      setSearchParams({ tab: value });
      setShowInput({
        old_password: false,
        new_password: false,
        re_password: false,
        password: false,
      });
      if (value === SETTINGS_TABS.SECURITY_2FA && !user.tfa.loaded) {
        getTFA();
      }
      setErrors({});
    }
  };

  const onEmailChange = (field: string, value: string) => {
    setEmailValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setEmailErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newEmailValues = cloneDeep(emailValues);
      newEmailValues[field] = value;
      const newEmailErrors: SettingsParams = getFormErrors(newEmailValues);

      setEmailErrors({
        ...errors,
        [field]: newEmailErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: any = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onEmailBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newEmailValues = cloneDeep(emailValues);
      const newEmailErrors: any = getFormErrors(newEmailValues);

      setEmailErrors({
        ...errors,
        [field]: newEmailErrors[field],
      });
    }
  };

  const handleAuthCode = (res: string) => {
    onChange('tfa_code', res);
  };

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onToggleShow = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(showInput, field)) {
      setShowInput({
        ...showInput,
        [field]: !showInput[field],
      });
    }
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: any = getFormErrors(values);
      setErrors(newErrors);

      const data: UserData = {
        country: values.country,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
      };

      const passwordData: UpdatePasswordParams = {
        current_password: values.old_password,
        new_password: values.new_password,
        repeat_new_password: values.re_password,
      };

      const tfaData: ActivateTFAParams = {
        tfa_code: values.tfa_code
      };

      if (!checkErrors(newErrors)) {
        if (values.tab === SETTINGS_TABS.PROFILE) {
          updateProfile(data)
        }

        if (values.tab === SETTINGS_TABS.CHANGE_PASSWORD) {
          updatePassword(passwordData)
        }

        if (values.tab === SETTINGS_TABS.SECURITY_2FA) {
          if (user.tfa.tfa_confirmed) {
            if (user.tfa.tfa_enabled) {
              turnOffTFA(tfaData);
            } else {
              turnOnTFA(tfaData);
            }
          } else {
            tfaData.password = values.password;
            activateTFA(tfaData);
          }
        }
      }
    },
    [values, user, getFormErrors, activateTFA, turnOffTFA, turnOnTFA, updateProfile, updatePassword]
  );

  const onEmailSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newEmailErrors: any = getEmailFormErrors(emailValues);
      setErrors(newEmailErrors);

      const data: EmailData = {
        email: emailValues.email,
        // current_password: emailValues.password,
      };

      if (!checkErrors(newEmailErrors)) {
        updateEmail(data);
      }
    },
    [emailValues, updateEmail]
  );

  return (
    <MainContent className="content-main">
      <Container>
        <SettingsContainer className="settings">
          <div className="settings-head">
            <span className="settings-head__title">Settings</span>
          </div>
          <div className="settings-tabs__wrap">
            <Tabs
              className="settings-tabs"
              tabHeaderClassName="settings-tabs__tab"
              tabsList={SETTINGS_TABS_LIST.map((item: string) => {
                return {
                  key: item,
                  name: t(`settings.tabs.${item}`),
                };
              })}
              field="tab"
              activeTab={values.tab}
              onChange={onChange}
            >
              <TabsContentItem className={`setting-tab ${values.tab === SETTINGS_TABS.PROFILE ? 'active' : ''}`}>
                <div className="profile">
                  <div className="profile-title__wrap">
                    <span className="profile-title">Profile</span>
                    <span className="profile-desc">Update your profile details here.</span>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="profile-container">
                      <div className="profile-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="profile-name">Country</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Select
                              className="profile-input"
                              name="country"
                              value={values.country}
                              placeholder="Country"
                              list={countriesList}
                              error={errors.country}
                              withFlag
                              fullWidth
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="profile-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="profile-name">Your first name</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='profile-input'
                              type="text"
                              name="first_name"
                              value={values.first_name}
                              placeholder="Enter your first name"
                              error={errors.first_name}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="profile-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="profile-name">Your last name</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='profile-input'
                              type="text"
                              name="last_name"
                              value={values.last_name}
                              placeholder="Enter your last name"
                              error={errors.last_name}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="profile-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="profile-name">Email address</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='profile-input'
                              type="email"
                              name="email"
                              value={values.email}
                              placeholder="Enter your email"
                              disabled
                              error={errors.email}
                              image={`/img/email.svg`}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="profile-btns">
                        <Button
                          className="profile-btn"
                          type="submit"
                          disabled={loadingUpdateProfile}
                        >
                          Save
                          {loadingUpdateProfile ? <Loader /> : null}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </TabsContentItem>
              <TabsContentItem className={`setting-tab ${values.tab === SETTINGS_TABS.CHANGE_PASSWORD ? 'active' : ''}`}>
                <div className="profile">
                  <div className="profile-title__wrap">
                    <span className="profile-title">Security</span>
                    <span className="profile-desc">Update your password here.</span>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="profile-container">
                      <div className="profile-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="profile-name">Old password</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='profile-input'
                              type="password"
                              name="old_password"
                              value={values.old_password}
                              placeholder="Old password"
                              error={errors.old_password}
                              onChange={onChange}
                              onBlur={onBlur}
                              show={showInput.old_password}
                              onShow={onToggleShow}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="profile-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="profile-name">New password</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='profile-input'
                              type="password"
                              name="new_password"
                              value={values.new_password}
                              placeholder="New password"
                              error={errors.new_password}
                              onChange={onChange}
                              onBlur={onBlur}
                              show={showInput.new_password}
                              onShow={onToggleShow}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="profile-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="profile-name">Repeat new password</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='profile-input'
                              type="password"
                              name="re_password"
                              value={values.re_password}
                              placeholder="Repeat new password"
                              error={errors.re_password}
                              onChange={onChange}
                              onBlur={onBlur}
                              show={showInput.re_password}
                              onShow={onToggleShow}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="profile-btns">
                        <Button
                          className="profile-btn loading-btn"
                          type="submit"
                          disabled={loadingUpdatePassword}
                        >
                          Save
                          {loadingUpdatePassword ? <Loader /> : null}
                        </Button>
                      </div>
                    </div>
                  </form>

                  <div className="profile-title__wrap">
                    <span className="profile-title">Email</span>
                    <span className="profile-desc">Update your email here.</span>
                  </div>
                  <form onSubmit={onEmailSubmit}>
                    <div className="profile-container">
                      <div className="profile-row">
                        <div className="grid-x grid-margin-x">
                          <div className="cell small-3 xlarge-35">
                            <span className="profile-name">New email</span>
                          </div>
                          <div className="cell small-6 xlarge-6">
                            <Input
                              className='profile-input'
                              type="email"
                              name="email"
                              value={emailValues.email}
                              placeholder="Email"
                              error={emailErrors.email}
                              onChange={onEmailChange}
                              onBlur={onEmailBlur}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="profile-btns">
                        <Button
                          className="profile-btn loading-btn"
                          type="submit"
                          disabled={loadingUpdateEmail}
                        >
                          Save
                          {loadingUpdateEmail ? <Loader /> : null}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </TabsContentItem>
              <TabsContentItem className={`setting-tab ${values.tab === SETTINGS_TABS.SECURITY_2FA ? 'active' : ''}`}>
                {
                  loadingTFA ? (
                    <Loader />
                  ) : (
                    <div className="tfa">
                      <div className="tfa-title__wrap">
                        <span className="tfa-title">Two-factor authentication</span>
                      </div>

                      {
                        user.tfa.tfa_confirmed ? (
                          <>
                            {
                              user.tfa.tfa_enabled ? (
                                <form onSubmit={onSubmit}>
                                  <div className="grid-x tfa-wrap">
                                    <div className="cell small-4 xlarge-3 tfa-row">
                                      <p className="tfa-item">Verify Code</p>
                                    </div>
                                    <div className="cell small-8 xlarge-9 tfa-row">
                                      <div className="tfa-code__wrap">
                                        <AuthCode
                                          ref={tfaOffInputRef}
                                          containerClassName="tfa-code__container"
                                          inputClassName="tfa-code"
                                          allowedCharacters="numeric"
                                          length={6}
                                          placeholder="0"
                                          autoFocus={false}
                                          onChange={handleAuthCode}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="tfa-btns">
                                    <Button
                                      className="loading-btn beneficial-btn"
                                      type="submit"
                                      disabled={values.tfa_code.length !== 6 || loadingTurnOffTFA}
                                    >
                                      Turn Off
                                      {loadingTurnOffTFA ? <Loader/> : null}
                                    </Button>
                                  </div>
                                </form>
                              ) : (
                                <form onSubmit={onSubmit}>
                                  <div className="grid-x tfa-wrap">
                                    <div className="cell small-4 xlarge-3 tfa-row">
                                      <p className="tfa-item">Verify Code</p>
                                    </div>
                                    <div className="cell small-8 xlarge-9 tfa-row">
                                      <div className="tfa-code__wrap">
                                        <AuthCode
                                          ref={tfaOnInputRef}
                                          containerClassName="tfa-code__container"
                                          inputClassName="tfa-code"
                                          allowedCharacters="numeric"
                                          length={6}
                                          placeholder="0"
                                          autoFocus={false}
                                          onChange={handleAuthCode}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="tfa-btns">
                                    <Button
                                      className="loading-btn beneficial-btn"
                                      type="submit"
                                      disabled={values.tfa_code.length !== 6 || loadingTurnOnTFA}
                                    >
                                      Turn On
                                      {loadingTurnOnTFA ? <Loader/> : null}
                                    </Button>
                                  </div>
                                </form>
                              )
                            }
                          </>
                        ) : (
                          <form onSubmit={onSubmit}>
                            <div className="grid-x tfa-wrap">
                              <div className="cell small-4 xlarge-3 tfa-row">
                                <p className="tfa-item">Scan QR Code</p>
                              </div>
                              <div className="cell small-8 xlarge-9 tfa-row">
                                <div className="tfa-qrcode">
                                  {
                                    user.tfa.provisioning_uri ? (
                                      <QRCode value={user.tfa.provisioning_uri} size={224} />
                                    ) : null
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="grid-x tfa-wrap">
                              <div className="cell small-4 xlarge-3 tfa-row">
                                <p className="tfa-item">Or Copy Code Into Your App</p>
                              </div>
                              <div className="cell small-8 xlarge-9 tfa-row">
                                <div className="tfa-copy">
                                  <CopyInput
                                    name="secretKey"
                                    type="text"
                                    value={user.tfa.secret || ''}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="grid-x tfa-wrap">
                              <div className="cell small-4 xlarge-3 tfa-row">
                                <p className="tfa-item">Verify Code</p>
                              </div>
                              <div className="cell small-8 xlarge-9 tfa-row">
                                <div className="tfa-code__wrap">
                                  <AuthCode
                                    ref={tfaActivateInputRef}
                                    containerClassName="tfa-code__container"
                                    inputClassName="tfa-code"
                                    allowedCharacters="numeric"
                                    length={6}
                                    placeholder="0"
                                    autoFocus={false}
                                    onChange={handleAuthCode}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="grid-x tfa-wrap">
                              <div className="cell small-4 xlarge-3 tfa-row">
                                <p className="tfa-item">Your Password</p>
                              </div>
                              <div className="cell small-8 xlarge-9 tfa-row">
                                <div className="tfa-code__wrap">
                                  <Input
                                    className='login-input'
                                    type="password"
                                    name="password"
                                    value={values.password}
                                    placeholder="Password"
                                    error={errors.password}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    show={showInput.password}
                                    onShow={onToggleShow}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="tfa-btns">
                              <Button
                                className="loading-btn beneficial-btn"
                                type="submit"
                                disabled={values.tfa_code.length !== 6 || loadingActivateTFA}
                              >
                                Activate
                                {loadingActivateTFA ? <Loader /> : null}
                              </Button>
                            </div>
                          </form>
                        )
                      }
                    </div>
                  )
                }

              </TabsContentItem>
            </Tabs>
          </div>
        </SettingsContainer>
      </Container>
    </MainContent>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { user, stores } = state;

  return {
    user,
    stores,
    loadingTFA: selectLoadingByKey(state, types.GET_TFA_REQUEST),
    loadingActivateTFA: selectLoadingByKey(state, types.ACTIVATE_TFA_REQUEST),
    loadingTurnOffTFA: selectLoadingByKey(state, types.TURN_OFF_TFA_REQUEST),
    loadingTurnOnTFA: selectLoadingByKey(state, types.TURN_ON_TFA_REQUEST),
    loadingUpdateProfile: selectLoadingByKey(state, types.UPDATE_PROFILE_REQUEST),
    loadingUpdatePassword: selectLoadingByKey(state, types.UPDATE_PASSWORD_REQUEST),
    loadingUpdateEmail: selectLoadingByKey(state, types.UPDATE_EMAIL_REQUEST),
  };
};

export default connect(mapStateToProps, { getTFA, activateTFA, turnOffTFA, turnOnTFA, getProfile, updateProfile, updateEmail, updatePassword, clear })(Settings);
