import styled from 'styled-components';

export const InvoicesContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--inter);

  .invoices {
    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &__title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: var(--black);
      }

      &__btns {
        display: flex;
        flex-direction: row;
        gap: 12px;
      }

      &__btn {
        display: flex;
        background: var(--white);
        border: 1px solid var(--light-gray);
        padding: 9px 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
        align-items: center;

        &:before {
          content: '';
          display: inline-block;
          margin-right: 12px;
          margin-left: 1px;
          width: 17px;
          height: 17px;
          background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjUgMTEuNVYxNC44MzMzQzE2LjUgMTUuMjc1NCAxNi4zMjQ0IDE1LjY5OTMgMTYuMDExOCAxNi4wMTE4QzE1LjY5OTMgMTYuMzI0NCAxNS4yNzU0IDE2LjUgMTQuODMzMyAxNi41SDMuMTY2NjdDMi43MjQ2NCAxNi41IDIuMzAwNzIgMTYuMzI0NCAxLjk4ODE2IDE2LjAxMThDMS42NzU1OSAxNS42OTkzIDEuNSAxNS4yNzU0IDEuNSAxNC44MzMzVjExLjVNNC44MzMzMyA3LjMzMzMzTDkgMTEuNU05IDExLjVMMTMuMTY2NyA3LjMzMzMzTTkgMTEuNVYxLjUiIHN0cm9rZT0iIzM0NDA1NCIgc3Ryb2tlLXdpZHRoPSIxLjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
          background-repeat: no-repeat;
          background-size: cover;
        }

        &.-purple {
          background: var(--purple);
          border: 1px solid var(--purple);
          color: var(--white);

          &:before {
            width: 19px;
            height: 20px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjgxMjkgMy41MTgzOEMxMC44MTM5IDIuNDk1MjIgOC43Mjc1NiAxLjY2NjUgNi4xNTY5MyAxLjY2NjVDMy41ODYzIDEuNjY2NSAxLjUwMTg2IDIuNDk2MTQgMS41IDMuNTE4MzhNMS41IDMuNTE4MzhDMS41IDQuNTQxNTMgMy41ODQ0NCA1LjM3MDI1IDYuMTU2OTMgNS4zNzAyNUM4LjcyOTQyIDUuMzcwMjUgMTAuODEzOSA0LjU0MTUzIDEwLjgxMzkgMy41MTgzOEwxMC44MTM5IDEwLjcwMzZNMS41IDMuNTE4MzhWMTQuNjI5NUMxLjUwMDkzIDE1LjY1MjcgMy41ODUzNyAxNi40ODE0IDYuMTU2OTMgMTYuNDgxNEM3LjQwNDA2IDE2LjQ4MTQgOC41MzAxIDE2LjI4NDIgOS4zNjU1NiAxNS45NjY2TTEuNTAwOTMgNy4yMjIxQzEuNTAwOTMgOC4yNDUyNiAzLjU4NTM4IDkuMDczOTcgNi4xNTc4NyA5LjA3Mzk3QzguNzMwMzYgOS4wNzM5NyAxMC44MTQ4IDguMjQ1MjYgMTAuODE0OCA3LjIyMjFNOS40MjE0NCAxMi4yNDU0QzguNTgxMzMgMTIuNTc0MSA3LjQyOTIxIDEyLjc3NzggNi4xNTY5MyAxMi43Nzc4QzMuNTg1MzcgMTIuNzc3OCAxLjUwMDkzIDExLjk0OTEgMS41MDA5MyAxMC45MjU5TTE2LjEwNiAxMS4yMjA0QzE3Ljc0MjQgMTIuODQ3MiAxNy43NDI0IDE1LjQ4NjIgMTYuMTA2IDE3LjExM0MxNC40Njk2IDE4LjczOTkgMTEuODE1MSAxOC43Mzk5IDEwLjE3ODcgMTcuMTEzQzguNTQyMjEgMTUuNDg2MiA4LjU0MjIxIDEyLjg0NzIgMTAuMTc4NyAxMS4yMjA0QzExLjgxNTEgOS41OTM1IDE0LjQ2OTYgOS41OTM1IDE2LjEwNiAxMS4yMjA0WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
          }
        }
      }
    }

    &-wrap {
      padding-top: 32px;
    }
  }
`;

export const CreateContainer: any = styled.div`
  font-family: var(--inter);
  
  .create {
    display: flex;
    flex-wrap: wrap;
    padding-top: 32px;
    padding-bottom: 32px;
    
    &-title {
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      color: var(--black);

      &__wrap {
        margin-top: 20px;
      }
    }

    &-input {
      margin-bottom: 24px;
      
      &__wrap {
        position: relative;
      }

      &.-big {
        width: 50%;
      }
      
      &.-adjustment {
        margin-left: -12px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    
    &-textarea {
      margin-left: -12px;
      margin-top: 11px;
      margin-bottom: -5px;
    }
    
    &-select {
      margin-bottom: -9px;
    }
    
    &-additional {
      margin-top: -3px;
      
      &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
        
        &_wrap {
          margin-bottom: 12px;
        }
      }
      
      &__row {
        margin-bottom: 16px;
        padding-left: 12px;
        
        .MuiTypography-root {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--dark-gray);
        }
      }
      
      &__switch {
        margin-right: 7px;

        .Mui-checked + .MuiSwitch-track {
          background-color: var(--purple)!important;
          opacity: 1;
        }
      }
      
      &.-adjustment {
        margin-top: 23px;
      }
    }
    
    &-calendar {
      margin-top: 23px;
      margin-bottom: 24px;
    }
    
    &-items {
      &__row {
        display: flex;
        gap: 16px;
        position: relative;
        
        &.-second {
          margin-top: -9px;
        }
      }

      &__btn {
        display: flex;
        align-items: center;
        background: transparent;
        border: 0;
        outline: none;
        margin: 0;
        padding: 0;
        margin-top: -9px;
        margin-left: 3px;
        cursor: pointer;
      }

      &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-purple);
        margin-left: 11px;
      }
      
      &__remove {
        position: absolute;
        right: -40px;
        top: 36px;
        background: transparent;
        border: 0;
        outline: none;
        margin: 0;
        padding: 0;
        cursor: pointer;

        svg {
          stroke: #667085;
        }
      }
    }
    
    &-total {
      display: flex;
      flex-direction: column;
      margin-top: 6px;
      padding-top: 17px;
      border-top: 1px solid var(--dark-white);
      
      &__title {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
        
        &_sub {
          margin-top: 15px;
        }
      }
      
      &__amount {
        display: block;
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;
        color: var(--dark-gray);
        margin-top: 4px;

        .loader {
          display: inline;
          margin-left: 0;
          
          svg {
            stroke: var(--dark-gray);
            width: 25px;
            height: 25px;
            
            circle {
              stroke-width: 15;
            }
          }
        }

        &_sub {
          font-size: 20px;
          line-height: 24px;
        }
      }
      
      &__fee {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray);
        margin-left: 0;
      }
    }
    
    &-btns {
      display: flex;
      gap: 12px;
      margin-top: 16px;
    }

    &-btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 9px 15px;
    }
  }
`;

export const DetailsContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--inter);
  
  .details {
    &-head {
      margin-top: 18px;
      
      &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      &__btns {
        display: flex;
        align-items: center;
        gap: 12px;
      }
      
      &__btn {
        display: flex;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 16px;

        &:before {
          content: '';
          display: inline-block;
          background-repeat: no-repeat;
          background-size: cover;
        }
        
        &.-download {
          &:before {
            margin-right: 6px;
            width: 20px;
            height: 18px;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNjY2NjEgMTMuMTY2Nkw5Ljk5OTk0IDE2LjQ5OTlNOS45OTk5NCAxNi40OTk5TDEzLjMzMzMgMTMuMTY2Nk05Ljk5OTk0IDE2LjQ5OTlWOC45OTk5NE0xNy4zOTk5IDE0LjA3NDlDMTguMTI0NCAxMy41NjU1IDE4LjY2NzcgMTIuODM4NCAxOC45NTEgMTEuOTk5MkMxOS4yMzQzIDExLjE2MDEgMTkuMjQyOCAxMC4yNTI1IDE4Ljk3NTMgOS40MDgxM0MxOC43MDc4IDguNTYzODEgMTguMTc4MiA3LjgyNjY5IDE3LjQ2MzMgNy4zMDM3NUMxNi43NDg1IDYuNzgwODEgMTUuODg1NiA2LjQ5OTI1IDE0Ljk5OTkgNi40OTk5NEgxMy45NDk5QzEzLjY5OTMgNS41MjMxNyAxMy4yMzA0IDQuNjE1OTggMTIuNTc4NCAzLjg0NjY4QzExLjkyNjQgMy4wNzczNyAxMS4xMDg0IDIuNDY1OTkgMTAuMTg2IDIuMDU4NTdDOS4yNjM1IDEuNjUxMTUgOC4yNjA2NSAxLjQ1ODMgNy4yNTI4OCAxLjQ5NDU0QzYuMjQ1MTIgMS41MzA3OCA1LjI1ODcxIDEuNzk1MTcgNC4zNjc5MSAyLjI2NzhDMy40NzcxMSAyLjc0MDQzIDIuNzA1MTMgMy40MDg5OCAyLjExMDEgNC4yMjMxNEMxLjUxNTA3IDUuMDM3MjkgMS4xMTI0OSA1Ljk3NTgyIDAuOTMyNjYyIDYuOTY4MDdDMC43NTI4MzYgNy45NjAzMiAwLjgwMDQ1MyA4Ljk4MDQ0IDEuMDcxOTMgOS45NTE2M0MxLjM0MzQgMTAuOTIyOCAxLjgzMTY2IDExLjgxOTggMi40OTk5NCAxMi41NzQ5IiBzdHJva2U9IiMzNDQwNTQiIHN0cm9rZS13aWR0aD0iMS42NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
          }
        }

        &.-view {
          &:before {
            margin-right: 7px;
            width: 18px;
            height: 18px;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0IDkuODMzMzNWMTQuODMzM0MxNCAxNS4yNzU0IDEzLjgyNDQgMTUuNjk5MyAxMy41MTE4IDE2LjAxMThDMTMuMTk5MyAxNi4zMjQ0IDEyLjc3NTQgMTYuNSAxMi4zMzMzIDE2LjVIMy4xNjY2N0MyLjcyNDY0IDE2LjUgMi4zMDA3MiAxNi4zMjQ0IDEuOTg4MTYgMTYuMDExOEMxLjY3NTU5IDE1LjY5OTMgMS41IDE1LjI3NTQgMS41IDE0LjgzMzNWNS42NjY2N0MxLjUgNS4yMjQ2NCAxLjY3NTU5IDQuODAwNzIgMS45ODgxNiA0LjQ4ODE2QzIuMzAwNzIgNC4xNzU1OSAyLjcyNDY0IDQgMy4xNjY2NyA0SDguMTY2NjdNMTEuNSAxLjVIMTYuNU0xNi41IDEuNVY2LjVNMTYuNSAxLjVMNy4zMzMzMyAxMC42NjY3IiBzdHJva2U9IiMzNDQwNTQiIHN0cm9rZS13aWR0aD0iMS42NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
          }
        }

        &.-send {
          &:before {
            margin-right: 8px;
            width: 20px;
            height: 20px;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4LjMzMzMgMS42NjY1TDkuMTY2NjMgMTAuODMzMk0xOC4zMzMzIDEuNjY2NUwxMi41IDE4LjMzMzJMOS4xNjY2MyAxMC44MzMyTTE4LjMzMzMgMS42NjY1TDEuNjY2NjMgNy40OTk4NEw5LjE2NjYzIDEwLjgzMzIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS42NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
          }
        }
      }
    }
    
    &-title {
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      color: var(--black);
      margin-right: 6px;

      &__wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    
    &-status {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border-radius: 16px;
      padding: 2px 8px 2px 11px;

      &:before {
        content: '';
        display: inline-block;
        margin-right: 5px;
        background-repeat: no-repeat;
        background-size: cover;
      }

      &.-new {
        color: #B54708;
        background: #FFFAEB;

        &:before {
          width: 12px;
          height: 12px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgNFY2TTYgOEg2LjAwNU0xMSA2QzExIDguNzYxNDIgOC43NjE0MiAxMSA2IDExQzMuMjM4NTggMTEgMSA4Ljc2MTQyIDEgNkMxIDMuMjM4NTggMy4yMzg1OCAxIDYgMUM4Ljc2MTQyIDEgMTEgMy4yMzg1OCAxMSA2WiIgc3Ryb2tlPSIjQjU0NzA4IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
        }
      }

      &.-pending {
        color: #363F72;
        background: #F8F9FC;

        &:before {
          width: 12px;
          height: 12px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgM1Y2TDggN00xMSA2QzExIDguNzYxNDIgOC43NjE0MiAxMSA2IDExQzMuMjM4NTggMTEgMSA4Ljc2MTQyIDEgNkMxIDMuMjM4NTggMy4yMzg1OCAxIDYgMUM4Ljc2MTQyIDEgMTEgMy4yMzg1OCAxMSA2WiIgc3Ryb2tlPSIjMzYzRjcyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
        }
      }
      
      &.-paid {
        color: #027A48;
        background: #ECFDF3;

        &:before {
          width: 12px;
          height: 12px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDUuNTM5OTNWNS45OTk5M0MxMC45OTk0IDcuMDc4MTQgMTAuNjUwMyA4LjEyNzI3IDEwLjAwNDcgOC45OTA4NEM5LjM1OTA4IDkuODU0NDIgOC40NTE2NCAxMC40ODYyIDcuNDE3NjggMTAuNzkxOUM2LjM4MzcyIDExLjA5NzYgNS4yNzg2MyAxMS4wNjA5IDQuMjY3MjQgMTAuNjg3MkMzLjI1NTg0IDEwLjMxMzYgMi4zOTIzMyA5LjYyMjk4IDEuODA1NDggOC43MTg0N0MxLjIxODYzIDcuODEzOTUgMC45Mzk4OTYgNi43NDM5NiAxLjAxMDg0IDUuNjY4MDlDMS4wODE3OCA0LjU5MjIxIDEuNDk4NiAzLjU2ODEgMi4xOTkxNCAyLjc0ODQ3QzIuODk5NjggMS45Mjg4NCAzLjg0NjM5IDEuMzU3NjIgNC44OTgwOSAxLjEyMDAxQzUuOTQ5OCAwLjg4MjM4NyA3LjA1MDEzIDAuOTkxMTAxIDguMDM1IDEuNDI5OTNNMTEgMS45OTk5M0w2IDcuMDA0OTNMNC41IDUuNTA0OTMiIHN0cm9rZT0iIzAyN0E0OCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
        }
      }

      &.-confirmed {
        color: #363F72;
        background: #F8F9FC;

        &:before {
          width: 12px;
          height: 12px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgM1Y2TDggN00xMSA2QzExIDguNzYxNDIgOC43NjE0MiAxMSA2IDExQzMuMjM4NTggMTEgMSA4Ljc2MTQyIDEgNkMxIDMuMjM4NTggMy4yMzg1OCAxIDYgMUM4Ljc2MTQyIDEgMTEgMy4yMzg1OCAxMSA2WiIgc3Ryb2tlPSIjMzYzRjcyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
        }
      }

      &.-overpaid {
        color: #5925DC;
        background: #F4F3FF;

        &:before {
          width: 9px;
          height: 9px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41IDMuNUw0IDFMMS41IDMuNU02LjUgN0w0IDQuNUwxLjUgNyIgc3Ryb2tlPSIjNTkyNURDIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
        }
      }

      &.-unpaid {
        color: #B54708;
        background: #FFFAEB;

        &:before {
          width: 12px;
          height: 12px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgNFY2TTYgOEg2LjAwNU0xMSA2QzExIDguNzYxNDIgOC43NjE0MiAxMSA2IDExQzMuMjM4NTggMTEgMSA4Ljc2MTQyIDEgNkMxIDMuMjM4NTggMy4yMzg1OCAxIDYgMUM4Ljc2MTQyIDEgMTEgMy4yMzg1OCAxMSA2WiIgc3Ryb2tlPSIjQjU0NzA4IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
        }
      }

      &.-complete {
        color: #027A48;
        background: #ECFDF3;

        &:before {
          width: 12px;
          height: 12px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDUuNTM5OTNWNS45OTk5M0MxMC45OTk0IDcuMDc4MTQgMTAuNjUwMyA4LjEyNzI3IDEwLjAwNDcgOC45OTA4NEM5LjM1OTA4IDkuODU0NDIgOC40NTE2NCAxMC40ODYyIDcuNDE3NjggMTAuNzkxOUM2LjM4MzcyIDExLjA5NzYgNS4yNzg2MyAxMS4wNjA5IDQuMjY3MjQgMTAuNjg3MkMzLjI1NTg0IDEwLjMxMzYgMi4zOTIzMyA5LjYyMjk4IDEuODA1NDggOC43MTg0N0MxLjIxODYzIDcuODEzOTUgMC45Mzk4OTYgNi43NDM5NiAxLjAxMDg0IDUuNjY4MDlDMS4wODE3OCA0LjU5MjIxIDEuNDk4NiAzLjU2ODEgMi4xOTkxNCAyLjc0ODQ3QzIuODk5NjggMS45Mjg4NCAzLjg0NjM5IDEuMzU3NjIgNC44OTgwOSAxLjEyMDAxQzUuOTQ5OCAwLjg4MjM4NyA3LjA1MDEzIDAuOTkxMTAxIDguMDM1IDEuNDI5OTNNMTEgMS45OTk5M0w2IDcuMDA0OTNMNC41IDUuNTA0OTMiIHN0cm9rZT0iIzAyN0E0OCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
        }
      }

      &.-refunded {
        color: #344054;
        background: #F2F4F7;

        &:before {
          width: 10px;
          height: 10px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuNSA2TDEgMy41TTEgMy41TDMuNSAxTTEgMy41SDdDNy41MzA0MyAzLjUgOC4wMzkxNCAzLjcxMDcxIDguNDE0MjEgNC4wODU3OUM4Ljc4OTI5IDQuNDYwODYgOSA0Ljk2OTU3IDkgNS41VjkiIHN0cm9rZT0iIzM0NDA1NCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
        }
      }

      &.-invalid {
        color: #B42318;
        background: #FEF3F2;

        &:before {
          width: 9px;
          height: 9px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNyAxTDEgN00xIDFMNyA3IiBzdHJva2U9IiNCNDIzMTgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
        }
      }

      &.-cancelled {
        color: #B42318;
        background: #FEF3F2;

        &:before {
          width: 9px;
          height: 9px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNyAxTDEgN00xIDFMNyA3IiBzdHJva2U9IiNCNDIzMTgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
        }
      }
      
      &__item {
        
      }
    }
    
    &-date {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--gray);
      
      &__wrap {
        margin-top: 3px;
      }
    }
    
    &-summary {
      margin-top: 32px;
      
      &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: var(--black);
        
        &_wrap {
          
        }
      }
      
      &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: 1px solid var(--dark-white);
        margin-top: 20px;
        padding-top: 16px;
        
        &_row {
          display: grid;
          grid-gap: 1em;
          align-items: center;
          padding: 8px 0;

          grid-template-columns: 0.9fr 4fr 0.9fr 3fr;
        }
        
        &_item {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--black);
          
          &.-name {
            font-weight: 400;
            color: var(--gray);
          }

          &.-empty {
            color: #98A2B3;
          }
        }
      }
    }
    
    &-table {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 23px;
      border: 1px solid var(--dark-white);
      box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 4px 20px -2px rgba(50, 50, 71, 0.08);
      border-radius: var(--border-radius);
      margin-bottom: 24px;
      
      &__head {
        display: grid;
        grid-gap: 1em;
        align-items: center;
        padding: 13px 24px;
        background: var(--cream);
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
        border-bottom: 1px solid var(--dark-white);

        //grid-template-columns: 4fr 1fr 0.7fr 1fr 52px;
        grid-template-columns: 4fr 1fr 1fr 1fr;
        
        &_item {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: var(--gray);
          text-align: right;

          &.-name {
            text-align: left;
          }
        }
      }
      
      &__body {
        &_row {
          display: grid;
          grid-gap: 1em;
          align-items: center;
          padding: 12px 24px;
          border-bottom: 1px solid var(--dark-white);
          //grid-template-columns: 4fr 1fr 0.7fr 1fr 52px;
          grid-template-columns: 4fr 1fr 1fr 1fr;
        }

        &_item {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray);
          text-align: right;
          
          &.-name {
            font-weight: 500;
            color: var(--black);
            text-align: left;
          }
        }
        
        &_btn {
          background: none;
          border: 0;
          outline: none;
          cursor: pointer;
          padding: 0;
          margin: 0;
          text-align: right;
        }
      }
      
      &__footer {
        padding-top: 8px;
        padding-bottom: 7px;
        
        &_row {
          display: grid;
          grid-gap: 1em;
          align-items: center;
          padding: 8px 24px;
          //grid-template-columns: 1fr 0.7fr 4fr 1fr 52px;
          grid-template-columns: 1fr 1fr 4fr 1fr;
        }

        &_item {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--black);
          text-align: right;

          &.-name {
            font-weight: 500;
          }
        }
      }
    }
    
    &-footer {
      &__btns {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid var(--dark-white);
        padding-top: 16px;
        gap: 12px;
      }

      &__btn {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 16px;
      }
    }
  }
`;

