import React from "react";
import {Button, Icon} from "../../../elements";
import {ShowApiKey} from "./GoToTFA.styles"
import {PATHS} from "../../../const/paths.constants";
import {useNavigate} from "react-router-dom";
import {SETTINGS_TABS} from "../../../pages/Settings/Settings";

interface ModalKeyProps {
  closeModal: () => void;
}

const GoToTFA: React.FC<ModalKeyProps> = (props: ModalKeyProps) => {
  const navigate = useNavigate();
  const { closeModal } = props;

  const goToSettings = () => {
    navigate(`${PATHS.SETTINGS}?tab=${SETTINGS_TABS.SECURITY_2FA}`);
    closeModal();
  }

  return (
    <ShowApiKey>
      <div className="addKey-icon">
        <Icon name="show_key" size="56" />
      </div>
      <div className="addKey-texts">
        <span className="addKey-texts__title">Activate 2FA</span>
        <span className="addKey-texts__description">To switch to test mode, you must enable 2FA authentication</span>
      </div>
      <div>
        <div className="addKey-form__actions">
          <div className="addKey-form__btns">
            <Button
              className="addKey-form__btn -white"
              type="button"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
            <Button
              className="addKey-form__btn"
              type="submit"
              onClick={() => goToSettings()}
            >
              Go to Settings
            </Button>
          </div>
        </div>
      </div>
    </ShowApiKey>
  );
};

export default GoToTFA;
