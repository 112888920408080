import { makeHandler } from './makeHandler';
import {APPLICATION_UID} from "../const/general.constants";

export const logIn = makeHandler(
  'logIn',
  (data: LogInParams) => ({
    method: 'POST',
    path: '/auth/sign_in',
    data,
  }),
  (res: LogInResponse) => res.data
);

export const registration = makeHandler(
  'registration',
  (data: RegistrationParams) => ({
    method: 'POST',
    path: '/auth/sign_up',
    data,
  }),
  (res: RegistrationResponse) => res.data
);

export const forgotPassword = makeHandler(
  'forgotPassword',
  (data: ForgotPasswordParams) => ({
    method: 'POST',
    path: '/auth/forgot_password',
    data,
  }),
  (res: LogInResponse) => res.data
);

export const updateForgottenPassword = makeHandler(
  'updateForgottenPassword',
  (data: UpdateForgottenParams) => ({
    method: 'POST',
    path: '/auth/update_forgotten_password',
    data,
  }),
  (res: LogInResponse) => res.data
);

export const confirmEmailCode = makeHandler(
  'confirmEmailCode',
  (data: ConfirmEmailCodeParams) => ({
    method: 'POST',
    path: '/auth/confirm_email',
    data,
  }),
  (res: RegistrationResponse) => res.data
);

export const resendConfirmationCode = makeHandler(
  'resendConfirmationCode',
  (data: ResendConfirmationCodeParams) => ({
    method: 'POST',
    path: '/auth/resend_confirmation',
    data,
  }),
  (res: RegistrationResponse) => res.data
);

export const updatePassword = makeHandler(
  'updatePassword',
  (data: UpdatePasswordParams) => ({
    method: 'POST',
    path: '/auth/change_password',
    data,
  }),
  (res: RegistrationResponse) => res.data
);

export const getTestnetToken = makeHandler(
  'getTestnetToken',
  (data: GetTestnetTokenParams) => ({
    method: 'GET',
    path: `/auth/oauth_user_token?application_uid=${APPLICATION_UID}`,
  }),
  (res: GetTestnetTokenResponse) => res.data
);

export const signInTestnet = makeHandler(
  'signInTestnet',
  (data: SignInTestnetParams) => ({
    method: 'POST',
    path: '/auth/oauth_sing_in',
    data,
  }),
  (res: LogInResponse) => res.data
);

export const OAuthlogIn = makeHandler(
  'OAuthlogIn',
  (data: OAuthlogInParams) => ({
    method: 'POST',
    path: '/auth/oauth_sing_in',
    data,
  }),
  (res: LogInResponse) => res.data
);

export type LogInParams = {
  /** e-mail */
  email: string;
  /** password */
  password: string;
};

export type OAuthlogInParams = {
  token: string;
  oauth_provider: string;
};

export type RegistrationParams = {
  /** country */
  country?: string;
  /** first name */
  first_name: string;
  /** last name */
  last_name: string;
  /** e-mail */
  email: string;
  /** password */
  password: string;
  /** password */
  repassword?: string;
  /** terms */
  terms?: string
};

export type ForgotPasswordParams = {
  /** e-mail */
  email?: string;
};

export type UpdateForgottenParams = {
  reset_password_token: string;
  password: string;
  password_confirmation: string;
};

export type ConfirmEmailCodeParams = {
  /** token */
  confirmation_token?: string;
};

export type ResendConfirmationCodeParams = {
  /** e-mail */
  email?: string;
};

export type UpdatePasswordParams = {
  current_password: string;
  new_password: string;
  repeat_new_password: string;
};

export type LogInResponse = {
  data: {
    auth_owner_uid: string;
    auth_token: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    country?: string;
  }
};

export type GetTestnetTokenParams = {
  uid: string;
};

export type GetTestnetTokenResponse = {
  data: {
    token: string;
  }
};

export type SignInTestnetParams = {
  token: string;
  oauth_provider: string;
};

export type RegistrationResponse = {
  data: {}
};
