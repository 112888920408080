import jsSHA from "jssha";
import BigNum from "browserify-bignum";
import { sha512_256 as sha512256 } from "js-sha512";
import Blake256 from "./blake256";
import Blake2B from "./blake2b";
import { keccak256 } from "./sha3";

export const numberToHex = (number, length) => {
    var hex = number.toString(16);
    if (hex.length % 2 === 1) {
        hex = '0' + hex;
    }
    return hex.padStart(length, '0');
}

function isHexChar(c) {
    if ((c >= 'A' && c <= 'F') ||
        (c >= 'a' && c <= 'f') ||
        (c >= '0' && c <= '9')) {
        return 1;
    }
    return 0;
}

/* Convert a hex char to value */
function hexChar2byte(c) {
    var d = 0;
    if (c >= 'A' && c <= 'F') {
        d = c.charCodeAt(0) - 'A'.charCodeAt(0) + 10;
    }
    else if (c >= 'a' && c <= 'f') {
        d = c.charCodeAt(0) - 'a'.charCodeAt(0) + 10;
    }
    else if (c >= '0' && c <= '9') {
        d = c.charCodeAt(0) - '0'.charCodeAt(0);
    }
    return d;
}

/* Convert a byte to string */
function byte2hexStr(byte) {
    var hexByteMap = "0123456789ABCDEF";
    var str = "";
    str += hexByteMap.charAt(byte >> 4);
    str += hexByteMap.charAt(byte & 0x0f);
    return str;
}

export const byteArray2hexStr = (byteArray) => {
    var str = "";
    for (var i = 0; i < (byteArray.length - 1); i++) {
        str += byte2hexStr(byteArray[i]);
    }
    str += byte2hexStr(byteArray[i]);
    return str;
}

export const hexStr2byteArray = (str) => {
    var byteArray = [];
    var d = 0;
    var i;
    var j = 0;
    var k = 0;

     for (i = 0; i < str.length; i++) {
        var c = str.charAt(i);
        if (isHexChar(c)) {
            d <<= 4;
            d += hexChar2byte(c);
            j++;
            if (0 === (j % 2)) {
                byteArray[k++] = d;
                d = 0;
            }
        }
    }
    return byteArray;
}

export const toHex = (arrayOfBytes) => {
    var hex = '';
    for (var i = 0; i < arrayOfBytes.length; i++) {
        hex += numberToHex(arrayOfBytes[i]);
    }
    return hex;
};

export const sha256 = (payload, format = 'HEX') => {
    var sha = new jsSHA('SHA-256', format);
    sha.update(payload);
    return sha.getHash(format);
};

export const sha256x2 = (buffer, format = 'HEX') => {
    return sha256(sha256(buffer, format), format);
};

export const sha256Checksum = (payload) => {
    return sha256(sha256(payload)).substr(0, 8);
};

export const sha512_256 = (payload, format = 'HEX') => {
    const hash = sha512256.create()
    hash.update(Buffer.from(payload, format))
    return hash.hex().toUpperCase();
};

export const blake256 = (hexString) => {
    return new Blake256().update(hexString, 'hex').digest('hex');
};

export const blake256Checksum = (payload) => {
    return blake256(blake256(payload)).substr(0, 8);
};

export const blake2b = (hexString, outlen) => {
    return new Blake2B(outlen).update(Buffer.from(hexString, 'hex')).digest('hex');
};

// export const keccak256 = (hexString) => {
//     return keccak256(hexString);
// };

export const keccak256Checksum = (payload) => {
    return keccak256(payload).toString().substr(0, 8);
};

export const blake2b256 = (hexString) => {
    return new Blake2B(32).update(Buffer.from(hexString, 'hex'), 32).digest('hex');
}

export const bigNumberToBuffer = (bignumber, size) => {
    return new BigNum(bignumber).toBuffer({ size, endian: 'big' });
};

// base58: base58.decode,
// base32: base32
