import styled from 'styled-components';

export const BreadCrumbList: any = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 4px 8px;
  font-family: var(--inter);
`;

export const BreadCrumbListItem: any = styled.li`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-gray);
  font-family: var(--inter);

  &:not(:last-child)::after {
    content: '';
    display: inline-block;
    width: 6px;
    height: 10px;
    margin: 0 21px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDlMNSA1TDEgMSIgc3Ryb2tlPSIjRDBENUREIiBzdHJva2Utd2lkdGg9IjEuMzMzMzMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    background-size: cover;
  }

  a {
    transition: none;
    color: var(--gray);
  }
`;
