import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Icon, Loader} from "../../../elements";
import AuthCode, {AuthCodeRef} from "react-auth-code-input";
import {APIKey, DeleteAPIKeyParams} from "../../../api";
import {DeleteApiKey} from "./DeleteKey.styles"
import {connect} from "react-redux";
import {AppStateType} from "../../../store";
import {selectLoadingByKey} from "../../../store/loadingsErrors/selectors";
import types from "../../../store/actionTypes";
import {deleteAPIKey} from "../../../store/stores/actions";
import {StoresReducerState} from "../../../store/stores/reducers";

interface ModalKeyProps {
  stores: StoresReducerState;
  closeModal: () => void;
  apiKey?: APIKey | any;
  title: string;
  description: string;
  deleteAPIKey: (payload: DeleteAPIKeyParams) => void;
  loadingDeleteAPIKey: boolean;
  storeId: string;
}

const DeleteKey: React.FC<ModalKeyProps> = (props: ModalKeyProps) => {
  const { stores, closeModal, deleteAPIKey, apiKey, title, description, loadingDeleteAPIKey, storeId } = props;

  const [tfaCode, setTfaCode] = useState<string>('');
  const tfaInputRef = useRef<AuthCodeRef>(null);

  useEffect(() => {
    if (stores.answer?.success) {
      if (stores.answer?.success === 'You API key was successfully revoked') {
        closeModal();
      }
    }

    if (stores.answer?.error?.error) {
      if (stores.answer?.error?.error === '2fa code is invalid') {
        tfaInputRef.current?.clear();
        tfaInputRef.current?.focus();
      }

      if (stores.answer?.error?.error === 'Max number of failed attempts reached. All action with 2FA blocked. Please contact with the administration') {
        tfaInputRef.current?.clear();
        tfaInputRef.current?.focus();
      }
    }
  }, [closeModal, stores]);

  const handleAuthCode = (res: string) => {
    setTfaCode(res);
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();

      const deleteData: DeleteAPIKeyParams = {
        data: {
          tfa_code: tfaCode,
        },
        storeId,
        apiKeyId: apiKey?.id,
      };

      deleteAPIKey(deleteData);
    },
    [tfaCode, storeId, apiKey?.id, deleteAPIKey]
  );

  return (
    <DeleteApiKey>
      <div className="addKey-icon">
        <Icon name="remove_key" size="56" />
      </div>
      <div className="addKey-texts">
        <span className="addKey-texts__title">{title} <span className="-black">{ apiKey?.name }</span></span>
        <span className="addKey-texts__description">{description}</span>
      </div>
      <div>
        <form className="addKey-form" onSubmit={onSubmit}>
          <div className="addKey-code__wrap">
            <AuthCode
              ref={tfaInputRef}
              containerClassName="addKey-code__container"
              inputClassName="addKey-code"
              allowedCharacters="numeric"
              length={6}
              placeholder="0"
              autoFocus={false}
              onChange={handleAuthCode}
            />
          </div>
          <div className="addKey-form__actions">
            <div className="addKey-form__btns">
              <Button
                className="addKey-form__btn -white"
                type="button"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              <Button
                className="addKey-form__btn loading-btn"
                type="submit"
                disabled={loadingDeleteAPIKey || tfaCode.length !== 6}
              >
                Continue
                {loadingDeleteAPIKey ? <Loader /> : null}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </DeleteApiKey>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { stores } = state;
  return {
    stores,
    loadingDeleteAPIKey: selectLoadingByKey(state, types.DELETE_API_KEY_REQUEST),
  };
};

export default connect(mapStateToProps, {deleteAPIKey})(DeleteKey);
