import { Autocomplete, MenuItem, TextField } from '@mui/material';
import styled from 'styled-components';

export const SelectContainer: any = styled.div`
  position: relative;

  &.-error {
    input {
      box-shadow: 4px 4px 40px rgba(176, 22, 22, 0.07);
    }

    p {
      animation: fadeIn 0.8s;
      display: block;
      color: var(--red);
    }
  }
  
  .select {
    &-img {
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      z-index: 1;
      top: 36px;
      left: 14px;
    }
    
    &-remove {
      display: none;
      background: transparent;
      border: 0;
      outline: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
    
    &-check {
      &__item {
        align-items: center;
        text-align: left;
        display: flex;
        margin-right: 74px;
        margin-left: 2px;

        a {
          display: inline;
          color: var(--dark-purple);
        }

        .MuiSvgIcon-root {
          width: 23px;
          height: 23px;
        }

        .MuiFormControlLabel-label {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          font-family: var(--inter);
        }

        .MuiCheckbox-root {
          padding: 0;
          margin-right: 10px;
          color: var(--light-gray);

          &.Mui-checked {
            color: var(--purple);
          }
        }
      }
      
      &__text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        border-radius: 16px;
        padding: 2px 8px 2px 9px;
        
        &.-paid {
          color: #027A48;
          background: #ECFDF3;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 5px;
            width: 10px;
            height: 8px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik05IDFMMy41IDYuNUwxIDQiIHN0cm9rZT0iIzAyN0E0OCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-confirmed {
          color: #363F72;
          background: #F8F9FC;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 5px;
            width: 12px;
            height: 12px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgM1Y2TDggN00xMSA2QzExIDguNzYxNDIgOC43NjE0MiAxMSA2IDExQzMuMjM4NTggMTEgMSA4Ljc2MTQyIDEgNkMxIDMuMjM4NTggMy4yMzg1OCAxIDYgMUM4Ljc2MTQyIDEgMTEgMy4yMzg1OCAxMSA2WiIgc3Ryb2tlPSIjMzYzRjcyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-overpaid {
          color: #5925DC;
          background: #F4F3FF;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 6px;
            width: 9px;
            height: 9px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41IDMuNUw0IDFMMS41IDMuNU02LjUgN0w0IDQuNUwxLjUgNyIgc3Ryb2tlPSIjNTkyNURDIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-unpaid {
          color: #B54708;
          background: #FFFAEB;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 4px;
            width: 12px;
            height: 12px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgNFY2TTYgOEg2LjAwNU0xMSA2QzExIDguNzYxNDIgOC43NjE0MiAxMSA2IDExQzMuMjM4NTggMTEgMSA4Ljc2MTQyIDEgNkMxIDMuMjM4NTggMy4yMzg1OCAxIDYgMUM4Ljc2MTQyIDEgMTEgMy4yMzg1OCAxMSA2WiIgc3Ryb2tlPSIjQjU0NzA4IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-complete {
          color: #027A48;
          background: #ECFDF3;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 4px;
            width: 12px;
            height: 12px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDUuNTM5OTNWNS45OTk5M0MxMC45OTk0IDcuMDc4MTQgMTAuNjUwMyA4LjEyNzI3IDEwLjAwNDcgOC45OTA4NEM5LjM1OTA4IDkuODU0NDIgOC40NTE2NCAxMC40ODYyIDcuNDE3NjggMTAuNzkxOUM2LjM4MzcyIDExLjA5NzYgNS4yNzg2MyAxMS4wNjA5IDQuMjY3MjQgMTAuNjg3MkMzLjI1NTg0IDEwLjMxMzYgMi4zOTIzMyA5LjYyMjk4IDEuODA1NDggOC43MTg0N0MxLjIxODYzIDcuODEzOTUgMC45Mzk4OTYgNi43NDM5NiAxLjAxMDg0IDUuNjY4MDlDMS4wODE3OCA0LjU5MjIxIDEuNDk4NiAzLjU2ODEgMi4xOTkxNCAyLjc0ODQ3QzIuODk5NjggMS45Mjg4NCAzLjg0NjM5IDEuMzU3NjIgNC44OTgwOSAxLjEyMDAxQzUuOTQ5OCAwLjg4MjM4NyA3LjA1MDEzIDAuOTkxMTAxIDguMDM1IDEuNDI5OTNNMTEgMS45OTk5M0w2IDcuMDA0OTNMNC41IDUuNTA0OTMiIHN0cm9rZT0iIzAyN0E0OCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-refunded {
          color: #344054;
          background: #F2F4F7;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 5px;
            width: 10px;
            height: 10px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuNSA2TDEgMy41TTEgMy41TDMuNSAxTTEgMy41SDdDNy41MzA0MyAzLjUgOC4wMzkxNCAzLjcxMDcxIDguNDE0MjEgNC4wODU3OUM4Ljc4OTI5IDQuNDYwODYgOSA0Ljk2OTU3IDkgNS41VjkiIHN0cm9rZT0iIzM0NDA1NCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-invalid {
          color: #B42318;
          background: #FEF3F2;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 6px;
            width: 9px;
            height: 9px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNyAxTDEgN00xIDFMNyA3IiBzdHJva2U9IiNCNDIzMTgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-cancelled {
          color: #B42318;
          background: #FEF3F2;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 6px;
            width: 9px;
            height: 9px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNyAxTDEgN00xIDFMNyA3IiBzdHJva2U9IiNCNDIzMTgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }
  }
`;

export const SelectLabel: any = styled.label`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-gray);
  margin-bottom: 6px;
`;

export const SelectInput: any = styled(TextField)`
  font-family: var(--inter);
  
  .MuiInputBase-root {
    padding: 9.3px 14px;
    width: 100%;
    color: var(--black);
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid var(--light-gray);
    outline: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    transition: box-shadow 0.3s ease-in-out;
    font-family: var(--inter);
    cursor: pointer;
    
    &.Mui-focused {
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #E0EAFF;
    }
  }
  
  .MuiInputBase-input {
    padding: 0!important;
    cursor: pointer;
    
    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }

    &::placeholder {
      opacity: 1;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--gray);
      transition: opacity 0.3s ease-in-out;
    }

    &:disabled {
      color: var(--gray);
    }
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
  
  .MuiAutocomplete-clearIndicator {
    display: none;
  }

  .MuiSelect-select {
    padding: 9.3px 14px!important;
  }

  &.-image {
    padding-left: 46px;
  }

  &.Mui-focused {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #E0EAFF;
  }

  &.Mui-disabled {
    background: #F9FAFB;
    color: #667085;
  }

  &.status {

    .MuiMenu-list {
      padding: 0;
    }
  }

  &.-flag {
    .flag {
      display: flex;
      align-items: center;

      &-image {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
      }

      &-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--black);
      }
    }
  }
`;

export const SelectElement: any = styled(Autocomplete)`
  cursor: pointer;
  
  .MuiSelect-select {
    padding: 0;
  }

  &.-image {
    padding-left: 46px;
  }
  
  &.Mui-disabled {
    background: #F9FAFB;
    color: #667085;
  }

  &.status {

    .MuiMenu-list {
      padding: 0;
    }
  }
  
  &.-flag {
    .flag {
      display: flex;
      align-items: center;
      
      &-image {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
      }
      
      &-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--black);
      }
    }
  }

  .MuiSelect-icon {
    right: 10px;
    color: var(--gray);
  }

  .MuiSelect-selectMenu {
    padding: 12px 40px 12px 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #eaeaea;
    background: #eaeaea;
    box-shadow: #eaeaea;
    border-radius: var(--border-radius);
  }

  .MuiMenu-paper {
    border-radius: var(--border-radius);
    background: var(--white);
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.06);
    margin-top: 8px;
    max-height: 316px;
  }

  .MuiMenu-list {
    padding: 4px 0;
  }

  .MuiMenuItem-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px 11px 10px 14px;

    &.Mui-selected {
      background: transparent;

      &:hover {
        background: #F5F8FF;
      }
      
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 13px;
        width: 16px;
        height: 11px;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjY2NjMgMUw1LjQ5OTY3IDEwLjE2NjdMMS4zMzMwMSA2IiBzdHJva2U9IiM0NDRDRTciIHN0cm9rZS13aWR0aD0iMS42NjY2NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    
    &:hover {
      background: #F5F8FF;
      
      & .select-remove {
        display: block;
      }
    }
  }

  .MuiListItem-button {
    border-radius: 8px;
    padding: 10px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.06) 71.01%,
        rgba(255, 255, 255, 0) 99.52%
      );
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &:hover,
    &:focus {
      background-color: transparent;
      background-image: linear-gradient(
        90deg,
        rgba(232, 232, 232, 0.12) 0%,
        rgba(196, 196, 196, 0) 78.12%
      );
    }

    &.Mui-selected {
      background-color: transparent;
    }
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: transparent;
  }

  &.dropdown-menu {
    background-color: transparent;
    border-radius: 5px;
    width: auto;
    margin-bottom: 0;

    .MuiSelect-select {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: var(--white);
      background-color: transparent;
    }

    .MuiSelect-selectMenu {
      height: auto;
      border-radius: 0;
    }

    .MuiMenu-paper {
      border-radius: var(--border-radius);
      box-shadow: 0 0 11px rgba(0, 0, 0, 0.04);
    }

    .MuiList-root {
      background: linear-gradient(
        to top,
        rgba(147, 90, 237, 0.21) 0.14%,
        rgba(41, 47, 57, 0) 99.86%
      );
      border-radius: 13px;
    }

    .MuiList-padding {
      padding: 11px 8px;
    }

    .MuiListItem-button {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: var(--white);
      border-radius: 8px;

      a {
        color: var(--white);
      }

      &::after {
        display: none;
      }

      &:focus,
      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }

      &.Mui-selected {
        background-color: transparent;
        text-decoration: underline;
      }
    }

    .MuiMenuItem-root {
      min-height: auto;
    }

    &.-disable-rotate {
      &.Mui-focused {
        box-shadow: none;
      }
      & .MuiSelect-iconOpen {
        transform: none;
      }
    }
  }

  &.social-dropdown {
    .MuiSelect-selectMenu {
      padding: 0;
    }

    .MuiSelect-icon {
      top: auto;
      right: auto;
    }
  }
`;

export const SelectListItem: any = styled(MenuItem)`
  &.Mui-focused {
    background-color: #F5F8FF!important;
  }
  
  svg {
    stroke: #667085;
  }

  .flag {
    display: flex;
    align-items: center;

    &-image {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 8px;
    }

    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--black);
    }
  }
`;
export const Placeholder: any = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray);
`;

export const FormControlInputError: any = styled.p`
  margin: 8px 0;
  color: var(--red);
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  display: none;
  white-space: pre-line;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
`;
