import styled from "styled-components";

export const ShowApiKey: any = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--inter);
  
  .addKey {
    &-icon {
      text-align: center;
    }
    
    &-texts {
      display: block;
      position: relative;
      text-align: center;
      margin-top: 10px;
      
      &__title {
        display: block;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: var(--black);
        
        & .-black {
          color: var(--gray);
        }
      }

      &__description {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray);
        margin-top: 7px;
      }
    }
    
    &-form {
      margin-top: 20px;
      
      &__actions {
        margin-top: 32px;
      }
      
      &__btns {
        display: flex;
        justify-content: space-between;
        gap: 1em;
      }
      
      &__btn {
        display: flex;
        justify-content: center;
        flex: 1;
      }
    }
    
    &-input {
      &__wrap {
      }
    }

    &-code {
      width: 48px;
      height: 48px;
      border: 1px solid var(--light-gray);
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: var(--border-radius);
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      letter-spacing: -0.02em;
      color: var(--purple);
      outline: none;

      &::placeholder {
        color: var(--light-gray);
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
      }

      &:focus {
        &::placeholder {
          opacity: 0;
        }
      }

      &__wrap {
        margin-top: 16px;
      }

      &__container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
      }
    }

    &-copy {
      display: flex;
      
      & input[name="secretKey"] {
        min-width: 222px;
      }

      &__wrap {
        margin-top: 16px;
      }
    }
  }
`;
