import styled from 'styled-components';

import { FormControl } from '../Input/Input.Styles';
import {MenuItem, Select} from "@mui/material";

export const PhoneInputStyles = styled(FormControl)`
  position: relative;

  input {
    position: relative;
    padding: 9px 14px;
    width: 100%;
    color: var(--black);
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid var(--light-gray);
    outline: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    transition: box-shadow 0.3s ease-in-out;

    &:focus {
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #E0EAFF;
    }

    &:disabled {
      color: var(--gray);
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    textarea:-webkit-autofill:active,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus,
    select:-webkit-autofill:active {
      -webkit-text-fill-color: var(--black);
      transition: background-color 5000s ease-in-out 0s;
      filter: none;
    }
  }
  
  & .hidden {
    
  }

  &.-error {
    input {
      border: 1px solid #FDA29B;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    }

    .input-wrap {
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        right: 14px;
        top: 14px;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuOTk5OTIgNS4zMzM1VjguMDAwMTZNNy45OTk5MiAxMC42NjY4SDguMDA2NTlNMTQuNjY2NiA4LjAwMDE2QzE0LjY2NjYgMTEuNjgyMSAxMS42ODE4IDE0LjY2NjggNy45OTk5MiAxNC42NjY4QzQuMzE4MDIgMTQuNjY2OCAxLjMzMzI1IDExLjY4MjEgMS4zMzMyNSA4LjAwMDE2QzEuMzMzMjUgNC4zMTgyNiA0LjMxODAyIDEuMzMzNSA3Ljk5OTkyIDEuMzMzNUMxMS42ODE4IDEuMzMzNSAxNC42NjY2IDQuMzE4MjYgMTQuNjY2NiA4LjAwMDE2WiIgc3Ryb2tlPSIjRjA0NDM4IiBzdHJva2Utd2lkdGg9IjEuMzMzMzMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    &.-label {
      .input-wrap {
        &:after {
          top: 40px;
        }
      }
    }

    p {
      animation: fadeIn 0.8s;
      display: block;
      color: var(--red);
    }
  }
  
  &.-with-countries {
    input {
      padding-left: 72px;
    }
    
    .MuiSvgIcon-root {
      right: 10px;
    }
  }
`;

export const FormControlInputError: any = styled.p`
  margin: 6px 0;
  color: var(--red);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  display: block;
  white-space: pre-line;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
`;

export const SelectElement: any = styled(Select)`
  &.MuiInputBase-root {
    display: flex;
    align-items: center;
    position: absolute;
    padding: 10px 14px;
    top: 0;
    left: 0;
    height: 44px;
    width: 73px;
    color: var(--black);
    outline: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    transition: box-shadow 0.3s ease-in-out;
    font-family: var(--inter);
    z-index: 1;
    border-radius: var(--border-radius);
    

    .MuiOutlinedInput-notchedOutline {
      display: none;
    }

    .MuiSelect-select {
      padding: 0;
    }

    &.Mui-focused {
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #E0EAFF;
    }
  }

  .MuiMenu-paper {
    border-radius: var(--border-radius);
    background: var(--white);
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.06);
    margin-top: 8px;
    max-height: 271px;
  }
`;

export const Placeholder: any = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray);
`;

export const SelectListItem: any = styled(MenuItem)``;
