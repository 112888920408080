import React from 'react';
import {Pulse, Waves} from "./Skeleton.Styles";

export const ANIMATION = {
  WAVES: 'waves',
  PULSE: 'pulse',
} as const

export const VARIANT = {
  RECT: 'rect',
  CIRCLE: 'circle',
} as const

export type Animation = typeof ANIMATION[keyof typeof ANIMATION]
export type Variant = typeof VARIANT[keyof typeof VARIANT]

interface SkeletonProps {
  animation?: Animation
  variant?: Variant
}

const Skeleton: React.FC<SkeletonProps> = (props: SkeletonProps) => {
  const { variant = VARIANT.RECT, animation = ANIMATION.PULSE } = props;

  if (animation === ANIMATION.WAVES) {
    return <Waves variant={variant} {...props} />
  }

  return <Pulse variant={variant} {...props} />
}

export default Skeleton
