import styled from 'styled-components';

export const MainContentBlock: any = styled.main`
  width: 100%;
  height: calc(100% - 104px);
  flex: 1 1 auto;
  font-family: var(--montserrat);
  
  .testnet {
    display: block;
    position: relative;
    width: 100%;
    height: 44px;
    padding: 13px 15px;
    background: var(--red);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--white);
    
    a {
      color: var(--dark-purple);
      margin-left: 5px;
    }
  }

  &.content-main_bg-1 {
    background: url('/img/bg/bg_1.svg') bottom right / contain no-repeat;
  }

  &.content-main_bg-2 {
    background: url('/img/bg/bg_2.svg') right bottom / 65% no-repeat;
  }

  &.content-main_bg-3 {
    background: url('/img/bg/bg_3.svg') right bottom / 50% no-repeat;
  }
`;
