import React from 'react';
import { connect } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clone from "lodash/clone";
import {CalendarBlock, CalendarModal, CalendarPanel} from "./ModalCalendar.Styles";
import { Button } from "../Button/Button";
import {closeModal, openModal} from "../../store/app/actions";

dayjs.extend(require('dayjs/plugin/utc'));

const RangeShortcut = {
  today: 'TODAY',
  yesterday: 'YESTERDAY',
  thisWeek: 'THIS_WEEK',
  lastWeek: 'LAST_WEEK',
  thisMonth: 'THIS_MONTH',
  lastMonth: 'LAST_MONTH',
  thisYear: 'THIS_YEAR',
  lastYear: 'LAST_YEAR',
  reset: 'RESET',
};

type RangeShortcutType = keyof typeof RangeShortcut;

const rangeShortcuts = [
  {
    range: RangeShortcut.today,
    label: 'Today',
  },
  {
    range: RangeShortcut.yesterday,
    label: 'Yesterday',
  },
  {
    range: RangeShortcut.thisWeek,
    label: 'This week',
  },
  {
    range: RangeShortcut.lastWeek,
    label: 'Last week',
  },
  {
    range: RangeShortcut.thisMonth,
    label: 'This month',
  },
  {
    range: RangeShortcut.lastMonth,
    label: 'Last month',
  },
  {
    range: RangeShortcut.thisYear,
    label: 'This year',
  },
  {
    range: RangeShortcut.lastYear,
    label: 'Last year',
  },
  {
    range: RangeShortcut.reset,
    label: 'Reset',
  },
];

const buildHandleRangeClick =
  (setValue: React.Dispatch<React.SetStateAction<DateRange<Dayjs>>>) =>
    (range: RangeShortcutType) => {
      const today = dayjs();
      switch (range) {
        case RangeShortcut.today:
          setValue([today.startOf('day'), today.endOf('day')]);
          break;
        case RangeShortcut.yesterday:
          setValue([
            today.startOf('day').subtract(1, 'day'),
            today.endOf('day').subtract(1, 'day'),
          ]);
          break;
        case RangeShortcut.thisWeek:
          setValue([today.startOf('week'), today.endOf('week')]);
          break;
        case RangeShortcut.lastWeek:
          setValue([
            today.startOf('week').subtract(1, 'week'),
            today.endOf('week').subtract(1, 'week'),
          ]);
          break;
        case RangeShortcut.thisMonth:
          setValue([today.startOf('month'), today]);
          break;
        case RangeShortcut.lastMonth:
          setValue([
            today.startOf('month').subtract(1, 'month'),
            today.endOf('month').subtract(1, 'month'),
          ]);
          break;
        case RangeShortcut.thisYear:
          setValue([today.startOf('year'), today]);
          break;
        case RangeShortcut.lastYear:
          setValue([
            today.startOf('year').subtract(1, 'year'),
            today.endOf('year').subtract(1, 'year'),
          ]);
          break;
        case RangeShortcut.reset:
          setValue([null, null]);
          break;
        default:
          break;
      }
    };

interface PanelProps {
  dataRange: DateRange<Dayjs>;
  closeModal: () => void;
  applyModal: (payload: DateRange<Dayjs>) => void;
}

const StaticRangeShortcutsPanel: React.FC<PanelProps> = (props: PanelProps) => {
  const { closeModal, applyModal, dataRange } = props;
  const [value, setValue] = React.useState<DateRange<Dayjs>>(dataRange);

  const handleRangeClick = React.useCallback(
    (range: RangeShortcutType) => setValue && buildHandleRangeClick(setValue)(range),
    [setValue],
  );
  return (
    <CalendarPanel>
      <div className="calendar-shortcuts">
        {rangeShortcuts.map(({ range, label }) => (
          <div
            key={range}
            className="calendar-shortcuts__item"
          >
            <button
              className="calendar-shortcuts__btn"
              type="button"
              onClick={() => handleRangeClick(range as RangeShortcutType)}
            >
              {label}
            </button>
          </div>
        ))}
      </div>
      <div className="calendar-block">
        <div className="calendar-block__calendar">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CalendarModal
              displayStaticWrapperAs="desktop"
              onChange={(newValue: any) => setValue(newValue)}
              value={value}
              dayOfWeekFormatter={(day: string) => day.slice(0, 2)}
              showDaysOutsideCurrentMonth={true}
              renderInput={() => <div />}
              components={{ PaperContent: StaticRangeShortcutsPanel }}
              componentsProps={{ paperContent: { sx: { m: 2 }, setValue } }}
            />
          </LocalizationProvider>
        </div>
        <div className="calendar-block__actions">
          <div className="calendar-block__inputs">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                onChange={(newValue) => setValue(newValue)}
                value={value}
              />
            </LocalizationProvider>
          </div>
          <div className="calendar-block__btns">
            <div className="calendar-block__btns_wrap">
              <Button
                className="calendar-block__btn -white"
                type="button"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              <Button
                className="calendar-block__btn"
                type="button"
                onClick={() => applyModal(value)}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CalendarPanel>
  );
};

interface Props {
  className?: string;
  openModal?: (payload: any) => void;
  closeModal?: () => void;
  dataRange: DateRange<Dayjs>;
  setDataRange: (payload: DateRange<Dayjs>) => void;
}

const ModalCalendar: React.FC<Props> = (props: Props) => {
  const { className, openModal, closeModal, dataRange, setDataRange } = props;


  const modalCalendar = () => (
    <div className="modal-content">
      <div className="modal-calendar">
        <StaticRangeShortcutsPanel closeModal={handleCloseModal} applyModal={handleApplyModal} dataRange={dataRange} />
      </div>
    </div>
  );

  const handleOpenModal = (payload: any) => {
    if (!openModal) return
    openModal(payload)
  };

  const handleCloseModal = () => {
    if (!closeModal) return
    closeModal()
  };

  const handleApplyModal = (payload: DateRange<Dayjs>) => {
    if (!closeModal) return
    closeModal();
    const newDates = clone(payload)
    newDates[1] = newDates[1] ? newDates[1]?.add(23, 'hours').add(59, 'minutes') : null;
    setDataRange(newDates)
  };

  return (
    <CalendarBlock className={`calendar ${className}`}>
      <Button
        className={`calendar__btn ${dataRange[0] && dataRange[1] ? '-has-dates' : ''}`}
        type="button"
        onClick={
          () => handleOpenModal({
            closeModal: closeModal,
            className: "modal modalCalendar",
            content: modalCalendar
          })
        }
      >
        {!dataRange[0] && !dataRange[1] ? 'Select dates' : ''}
        {dataRange[0] ? dataRange[0].format('MMM D, YYYY') : ''}
        {dataRange[1] ? ' - ' + dataRange[1].format('MMM D, YYYY') : ''}
      </Button>
    </CalendarBlock>
  );
};

export default connect(null, { openModal, closeModal })(ModalCalendar);
