import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle: any = createGlobalStyle`
  ${normalize}
    // Variables
  :root {
    --inter: 'Inter', sans-serif;
    --cousine: 'Cousine', monospace;

    --white: #ffffff;
    --dark-white: #E4E7EC;
    --cream: #F9FAFB;
    --black: #101828;
    --light-black: #475467;
    --gray: #667085;
    --dark-gray: #344054;
    --light-gray: #D0D5DD;
    --purple: #444CE7;
    --light-purple: #dfeaff;
    --dark-purple: #3538CD;
    --red: #F04438;
    --green: #009688;
    --light-blue: #EEF4FF;
    --blue: #6172F3;

    --border-radius: 8px;

    --horizontal-border: linear-gradient(90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.06) 71.01%,
    rgba(255, 255, 255, 0) 99.52%);
    --vertical-border: linear-gradient(rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.06) 71.01%,
    rgba(255, 255, 255, 0) 99.52%);
  }

  html {
    box-sizing: border-box;
  }

  *,
  ::after,
  ::before {
    box-sizing: inherit;
  }

  body {
    min-width: 320px;
    font-family: var(--inter);
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    scrollbar-width: none;
    background-color: var(--white);

    ::-webkit-scrollbar {
      display: none;
    }
  }

  html, body, #app {
    height: 100%;
    font-family: var(--inter);
  }

  img {
    width: 100%;
  }

  a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: var(--gray);
    }

    &:focus {
      outline: none;
      color: var(--purple);
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
    }
  }

  ::selection {
    background-color: rgba(196, 196, 196, 0.5);
    color: var(--white);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .MuiDateRangeCalendar-root {
    & > div:first-child {
      display: none;
    }
  }
`;
