import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Button, Container, MainContent,} from '../../elements';
import {InvoicesContainer} from "./Invoices.Styles";
import Transactions from "../../components/Transactions";
import {Transaction, TransactionRows, TransactionType} from "../../components/Transactions/Transactions";
import {StoresReducerState} from "../../store/stores/reducers";
import {GetInvoicesParams, Invoice, Store} from "../../api";
import useToast from "../../hooks/useToast";
import {DEFAULT_PAGE_LIMIT, LOADING_TYPES} from "../../const/app.constants";
import {InvoicesReducerState, PaginatedInvoice} from "../../store/invoices/reducers";
import {AppStateType} from "../../store";
import {selectLoadingByKey} from "../../store/loadingsErrors/selectors";
import types from "../../store/actionTypes";
import {getInvoices, setInvoicesLoading} from "../../store/invoices/actions";
import {clear} from "../../store/user/actions";
import {PATHS} from "../../const/paths.constants";
import {Link, useSearchParams} from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import {getStore} from "../../store/stores/actions";
import {formatNumber} from "../../common/utils/formatters";

interface Props {
  stores: StoresReducerState;
  invoices: InvoicesReducerState;
  loading: boolean;
  getInvoices: (payload: GetInvoicesParams) => void;
  getStore: (payload: {storeId: string}) => void;
  setInvoicesLoading: (payload: GetInvoicesParams) => void;
  clear: () => void;
}

const statusesList = [
  {
    value: "New",
    text: "New"
  },
  {
    value: "Pending",
    text: "Pending"
  },
  {
    value: "Paid",
    text: "Paid"
  },
  {
    value: "Cancelled",
    text: "Cancelled"
  }
];

const Invoices: React.FC<Props> = (props: Props) => {
  const {stores, invoices, loading, getInvoices, getStore, clear, setInvoicesLoading} = props;
  const { toastError, toastSuccess } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryLimit = searchParams.get('limit') || 0;
  const queryPage = searchParams.get('page') || 0;
  const queryStatus = searchParams.get('status') || null;
  const queryFromDay = searchParams.get('from_day') || null;
  const queryToDay = searchParams.get('to_day') || null;
  const querySortBy = searchParams.get('sort_by') || null;
  const queryDirection = searchParams.get('direction') || null;

  const [activeStore, setActiveStore] = useState<string | null>(null);
  const [paginatedInvoice, setPaginatedInvoice] = useState<PaginatedInvoice | null>(null);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [update, setUpdate] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<GetInvoicesParams>({
    status: queryStatus || '',
    from_day: queryFromDay || '',
    to_day: queryToDay || '',
    limit: queryLimit || '',
    page: queryPage || '',
    sort_by: querySortBy || '',
    direction: queryDirection || ''
  });
  const [transactionsLoaded, setTransactionsLoaded] = useState<boolean>(!(invoices.invoices[stores.activeStore || '']?.loaded === LOADING_TYPES.LOADED));

  const getDate = (date: string | undefined): number => {
    const newDate: Date = date ? new Date(date) : new Date()
    return Math.floor(newDate.getTime() / 1000);
  };

  const createTransactions = (inv: Invoice[]) => {
    const newTransactions: Transaction[] = inv.map((i: Invoice): Transaction => {
      return {
        id: i.id || '',
        number: i.number || '',
        date: getDate(i.created_at),
        customer: i.customer_email || '',
        status: TransactionType[i.status as keyof typeof TransactionType],
        paid: `${formatNumber(i.received_crypto_amount, 0, 4)} ${i.received_crypto_currency ? i.received_crypto_currency : ''}`,
        deposited: `${formatNumber(i.amount, 0, 2)} ${i.currency}`,
      }
    });
    setTransactions(newTransactions)
  };

  const getSortBy = (sort: string) => {
    let newSort = sort;
    switch (sort) {
      case 'paid':
        newSort = 'received_crypto_amount';
        break;
      default:
        break;
    }
    return newSort
  }

  const updateSearchParams = (params: GetInvoicesParams) => {
    const queryObj: any = {};
    const newFilterParams = cloneDeep(filterParams);

    queryObj.page = `${params.page}`;
    newFilterParams.page = `${params.page}`;
    queryObj.limit = `${params.limit}`;
    newFilterParams.limit = `${params.limit}`;
    queryObj.status = `${params.status}`;
    newFilterParams.status = `${params.status}`;
    queryObj.from_day = `${params.from_day}`;
    newFilterParams.from_day = `${params.from_day}`;
    queryObj.to_day = `${params.to_day}`;
    newFilterParams.to_day = `${params.to_day}`;
    queryObj.sort_by = `${params.sort_by}`;
    newFilterParams.sort_by = `${params.sort_by}`;
    queryObj.direction = `${params.direction}`;
    newFilterParams.direction = `${params.direction}`;

    setFilterParams(newFilterParams);
    setSearchParams(queryObj);
  }

  useEffect(() => {
    if (stores.activeStore && stores.stores.loadedShop === LOADING_TYPES.LOADED) {

      if (!invoices.invoices[stores.activeStore] || invoices.invoices[stores.activeStore]?.loaded === LOADING_TYPES.NOT_LOADED ) {
        setActiveStore(stores.activeStore);
        getInvoices({
          storeId: stores.activeStore,
          status: filterParams.status,
          from_day: filterParams.from_day,
          to_day: filterParams.to_day,
          page: filterParams.page || 1,
          limit: filterParams.limit || DEFAULT_PAGE_LIMIT,
          sort_by: getSortBy(filterParams.sort_by || '') || 'created_at',
          direction: filterParams.direction || 'desc'
        })
      }

      if (activeStore && (activeStore !== stores.activeStore)) {
        setActiveStore(stores.activeStore);
        setTransactions([]);
        setPaginatedInvoice(null);
        getInvoices({
          storeId: stores.activeStore,
          status: filterParams.status,
          from_day: filterParams.from_day,
          to_day: filterParams.to_day,
          page: filterParams.page || 1,
          limit: filterParams.limit || DEFAULT_PAGE_LIMIT,
          sort_by: getSortBy(filterParams.sort_by || '') || 'created_at',
          direction: filterParams.direction || 'desc'
        })
      }

      if (invoices.invoices[stores.activeStore]?.loaded === LOADING_TYPES.LOADED && !transactionsLoaded) {
        setTransactionsLoaded(true);
        setInvoicesLoading({
          storeId: stores.activeStore,
          status: filterParams.status,
          from_day: filterParams.from_day,
          to_day: filterParams.to_day,
          page: filterParams.page || 1,
          limit: filterParams.limit || DEFAULT_PAGE_LIMIT,
          sort_by: getSortBy(filterParams.sort_by || '') || 'created_at',
          direction: filterParams.direction || 'desc'
        });
      }

      if (invoices.invoices[stores.activeStore]?.loaded === LOADING_TYPES.LOADED && transactionsLoaded) {
        if (invoices.invoices[stores.activeStore].list.length > 0 && transactions.length === 0) {
          const pagInvoice = invoices.invoices[stores.activeStore];
          createTransactions(pagInvoice.list)
          setPaginatedInvoice(pagInvoice)
          updateSearchParams({
            limit: pagInvoice.limit,
            page: pagInvoice.page,
            status: pagInvoice.status,
            from_day: pagInvoice.from_day,
            to_day: pagInvoice.to_day,
            sort_by: pagInvoice.sort_by,
            direction: pagInvoice.direction,
          })

          const shop: Store | undefined = find(stores.stores.list, { id: stores.activeStore });
          if (shop && !shop?.has_invoice && stores.stores.loaded === LOADING_TYPES.LOADED) {
            getStore({storeId: shop.id});
          }
        }

        if (
          update &&
          invoices.invoices[stores.activeStore].status === filterParams.status &&
          invoices.invoices[stores.activeStore].from_day === filterParams.from_day &&
          invoices.invoices[stores.activeStore].to_day === filterParams.to_day &&
          invoices.invoices[stores.activeStore].page === Number(filterParams.page) &&
          invoices.invoices[stores.activeStore].limit === Number(filterParams.limit) &&
          invoices.invoices[stores.activeStore].sort_by === filterParams.sort_by &&
          invoices.invoices[stores.activeStore].direction === filterParams.direction
        ) {
          setUpdate(false);
          createTransactions(invoices.invoices[stores.activeStore].list);
          setPaginatedInvoice(invoices.invoices[stores.activeStore]);
        }
      }
    }

    if (invoices.answer?.success) {
      toastSuccess(invoices.answer?.success);
      clear();
    }

    if (invoices.answer?.error?.error) {
      toastError(stores.answer.error.error);
      clear();
    }
  }, [stores, invoices, update, filterParams, activeStore, transactions.length, getInvoices, toastError, toastSuccess, clear, createTransactions, updateSearchParams, getStore, transactionsLoaded]);

  const onUpdateFilterParams = (params: GetInvoicesParams) => {
    const newParams = cloneDeep(params);
    newParams.storeId = stores.activeStore || '';
    newParams.sort_by = getSortBy(newParams.sort_by);
    updateSearchParams(newParams)
    getInvoices(newParams)
    setUpdate(true);
  }

  return (
    <MainContent className="content-main">
      <Container>
        <InvoicesContainer className="invoices">
          <div className="invoices-head">
            <span className="invoices-head__title">Invoices</span>
            <div className="invoices-head__btns">
              {/*<Button*/}
              {/*  className="invoices-head__btn"*/}
              {/*  type="button"*/}
              {/*>*/}
              {/*  Export*/}
              {/*</Button>*/}
              <Button
                as={Link}
                to={PATHS.CREATE_INVOICE.replace(':store', `${stores.activeStore}`)}
                className="invoices-head__btn -purple"
                type="button"
              >
                Create
              </Button>
            </div>
          </div>
          <div className="invoices-wrap">
            <Transactions
              items={transactions}
              loading={loading}
              link={PATHS.INVOICE_DETAILS.replace(':store', `${stores.activeStore}`)}
              linkId="id"
              statusesList={statusesList}
              from_day={queryFromDay || paginatedInvoice?.from_day}
              to_day={queryToDay || paginatedInvoice?.to_day}
              status={queryStatus || paginatedInvoice?.status}
              sort_by={querySortBy || paginatedInvoice?.sort_by}
              direction={queryDirection || paginatedInvoice?.direction}
              paginationLimit={queryLimit || paginatedInvoice?.limit}
              paginationPage={queryPage || paginatedInvoice?.page}
              total_count={paginatedInvoice?.total_count || 0}
              updateFilterParams={onUpdateFilterParams}
              rows={[TransactionRows.NUMBER, TransactionRows.DATE, TransactionRows.PAID, TransactionRows.DEPOSITED, TransactionRows.STATUS, TransactionRows.CUSTOMER]}
            />
          </div>
        </InvoicesContainer>
      </Container>
    </MainContent>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { stores, invoices } = state;
  return {
    stores,
    invoices,
    loading: selectLoadingByKey(state, types.GET_INVOICES_REQUEST),
  };
};

export default connect(mapStateToProps, { getInvoices, getStore, clear, setInvoicesLoading })(Invoices);
