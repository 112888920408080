import styled from 'styled-components';

export const TermsStyles: any = styled.div`
  position: relative;
  min-height: 100vh;
  min-width: 1030px;
  display: flex;
  font-family: var(--inter);

  .terms {
    &-container {
      display: flex;
      flex-direction: column;
    }
    &-header {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-top: 2rem;
      padding: 0 32px;
    }

    &-body {
      width: 100%;
      margin: 0 auto;
      padding: 32px;
      
      .page-title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: var(--black);
        text-align: center;
      }
      
      .list {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: var(--black);
      }
      
      .block {
        text-indent: 2rem;

        a {
          color: var(--purple);
          text-indent: 0;
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-top: 2rem;
      padding: 0 32px;
    }
  }
  
  .logo {
    display: block!important;
    &-top {
      
    }
  }
`;
