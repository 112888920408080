import styled from 'styled-components';

export const SearchInputBlock: any = styled.div`
  font-family: var(--inter);
  position: relative;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    -webkit-text-fill-color: var(--black);
    transition: background-color 5000s ease-in-out 0s;
    filter: none;
  }

  &.-error {
    input {
      box-shadow: 4px 4px 40px rgba(176, 22, 22, 0.07);
    }

    p {
      animation: fadeIn 0.8s;
      display: block;
      color: var(--red);
    }
  }
  
  .search {
    &-icon {
      position: absolute;
      top: 11px;
      left: 15px;
    }
  }
`;

export const FormControlInput: any = styled.input`
  padding: 8px 16px 10px 40px;
  width: 280px;
  color: var(--black);
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--light-gray);
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  transition: box-shadow 0.3s ease-in-out;

  &:focus {
    &::placeholder {
      opacity: 0;
    }
  }

  &::placeholder {
    opacity: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray);
    transition: opacity 0.3s ease-in-out;
  }

  &:disabled {
    color: var(--gray);
  }
`;
