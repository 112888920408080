import styled from "styled-components";

export const AddModalWithdrawal: any = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--inter);
  
  .withdrawal {
    &-icon {
      text-align: center;
    }
    
    &-texts {
      display: block;
      position: relative;
      text-align: center;
      margin-top: 10px;
      
      &__title {
        display: block;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: var(--black);
        
        & .-black {
          color: var(--gray);
        }
      }

      &__description {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray);
        margin-top: 7px;
      }
    }

    &-token {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__img {
        width: 36px;
        height: 36px;
        margin-right: 12px;
      }

      &__amounts {
        display: flex;
        flex-direction: column;
      }

      &__amount {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);

        &.-usd {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: var(--gray);
        }
      }
    }

    &-switch {
      &__wrap {
        .MuiFormControlLabel-root {
          margin-right: -8px;
        }
      }
    }

    &-form {
      display: flex;
      flex-direction: column;
      background: var(--white);

      &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--light-blue);
        border: 1px solid #A4BCFD;
        border-radius: 8px 8px 0 0;
        padding: 14px 15px;
      }

      &__body {
        display: flex;
        flex-direction: column;
        padding: 16px;
      }

      &__percentage {
        display: flex;
        flex-direction: column;

        &_head {
          display: flex;
          justify-content: space-between;
        }

        &_title {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray);

          &_wrap {

          }
        }

        &_count {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--dark-purple);

          &_wrap {
            background: var(--light-blue);
            border-radius: 16px;
            padding: 2px 10px;
          }
        }

        &_slider {

        }
      }

      &__address {
        margin-top: 13px;

        &_container {
          margin: 0 auto;
        }

        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray);
        }
      }

      &__row {
        display: flex;
        flex-direction: column;
        margin-top: 1em;
      }

      &__footer {
        display: flex;
        justify-content: flex-end;
        padding: 14px 23px;
        border-top: 1px solid var(--dark-white);
        gap: 1em;

        &_btn {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          padding: 10px 16px;
        }
      }
    }

    &-auth {
      margin-top: 15px;

      &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
      }

      &__text {
        display: block;
        width: 348px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: var(--gray);
        margin-top: 16px;
      }
    }

    &-code {
      width: 48px;
      height: 48px;
      border: 1px solid var(--light-gray);
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: var(--border-radius);
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      letter-spacing: -0.02em;
      color: var(--purple);
      outline: none;

      &::placeholder {
        color: var(--light-gray);
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
      }

      &:focus {
        &::placeholder {
          opacity: 0;
        }
      }

      &__wrap {
        margin-top: 6px;
      }

      &__container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
      }
    }
  }
`;
