import { makeHandler } from './makeHandler';
import {InvoiceMeta} from "./codecs.invoices";

export const createWithdrawal = makeHandler(
  'createWithdrawal',
  (data: CreateWithdrawalParams) => ({
    method: 'POST',
    path: `/stores/${data.storeId}/withdrawals`,
    data: data.data,
  }),
  (res: CreateWithdrawalResponse) => res.data
);

export const getWithdrawals = makeHandler(
  'getWithdrawals',
  (data: GetWithdrawalsParams) => ({
    method: 'GET',
    path: `/stores/${data.storeId}/withdrawals?status=${data.status.toLowerCase()}&date_from=${data.from_day}&date_to=${data.to_day}&limit=${data.limit}&page=${data.page}&sort_by=${data.sort_by}&sort_order=${data.direction}`,
  }),
  (res: GetWithdrawalsResponse) => res
);

export const declineWithdrawal = makeHandler(
  'declineWithdrawal',
  (data: DeclineWithdrawalParams) => ({
    method: 'POST',
    path: `/stores/${data.storeId}/withdrawals/${data.withdrawalId}/decline`
  }),
  (res: {data: {}}) => res.data
);

export enum WithdrawalStatus {
  NEW = 'NEW',
  PAID = 'PAID',
}

export type Withdrawal = {
  id?: string;
  amount: string;
  withdrawal_requisite_id: string;
  status?: WithdrawalStatus;
  created_at?: string;
};

export type CreateWithdrawalParams = {
  storeId: string;
  data: {
    tfa_code: string;
    withdrawal: Withdrawal
  }
};

export type CreateWithdrawalResponse = {
  data: Withdrawal
};

export type GetWithdrawalsParams = {
  storeId?: string;
  status: string;
  from_day: string;
  to_day: string;
  limit: string | number;
  page: string | number;
  sort_by: string;
  direction: string;
};

export type WithdrawalItem = {
  id?: string;
  address: string;
  amount: string;
  currency?: string;
  finished_at?: string;
  in_progress_at?: string;
  initiated_at?: string;
  status?: string;
  created_at?: string;
};

export type GetWithdrawalsResponse = {
  data: WithdrawalItem[],
  meta: InvoiceMeta
};

export type DeclineWithdrawalParams = {
  storeId: string;
  withdrawalId: string;
};
