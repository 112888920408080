import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  ReferralCopy,
  ReferralField,
  ReferralInputBlock,
} from './CopyInput.Styles';

interface Props {
  id?: string;
  className?: string;
  name: string;
  type: string;
  placeholder?: string;
  value?: string;
  text?: string;
  onClick?: () => void;
}

export const CopyInput: React.FC<Props> = (props: Props) => {
  const { id, className, name, type, placeholder, value, onClick } = props;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <ReferralInputBlock className={`ref-input ${className}`}>
      <div className='ref-input__wrap'>
        <ReferralField
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          disabled
          defaultValue={value}
        />
      </div>
      <CopyToClipboard
        text={`${value}`}
        onCopy={onClick}
      >
        <ReferralCopy
          className={`ref-input__btn ${copied ? '-copied' : ''}`}
          onClick={() => setCopied(true)}
          type="button"
        >
          <span
            className="copy"
          >
            {copied ? 'Copied' : 'Copy'}
          </span>
        </ReferralCopy>
      </CopyToClipboard>
    </ReferralInputBlock>
  );
};

export default CopyInput;
