import React from 'react'
import {
  Icon
} from '../Icon/Icon';
import { AlertProps } from './types'
import {CloseHandler, Details, StyledAlert} from "./Alert.Styles";

const Alert: React.FC<AlertProps> = ({ title, children, variant, onClick }) => {

  return (
    <StyledAlert>
      <Details className={`-${variant}`} hasHandler={!!onClick}>
        <p className="text">{title} {children}</p>
      </Details>
      {onClick && (
        <CloseHandler onClick={onClick}>
          <Icon name="close" size="16" />
        </CloseHandler>
      )}
    </StyledAlert>
  )
}

export default Alert;
