import {Payment} from '../../api';
import types, { AppActionTypes } from '../actionTypes';
import cloneDeep from 'lodash/cloneDeep';
import {DEFAULT_PAGE_LIMIT, LOADING_TYPES} from "../../const/app.constants";

export type PaginatedPayments = {
  list: Payment[];
  page: number;
  limit: number;
  total_count: number;
  from_day: string;
  to_day: string;
  status: string;
  sort_by: string;
  direction: string;
  loaded: LOADING_TYPES;
};

export type WalletReducerState = {
  payments: { [key: string]: PaginatedPayments };
  answer: any;
};

const INITIAL_STATE: WalletReducerState = {
  payments: {},
  answer: {},
};

const walletReducers = (state = INITIAL_STATE, action: AppActionTypes): WalletReducerState => {
  switch (action.type) {
    case types.GET_PAYMENTS_REQUEST:
      const getPaymentsRequest = cloneDeep(state.payments);
      getPaymentsRequest[action.payload.storeId || ''] = {
        list: [],
        page: Number(action.payload.page) || 1,
        limit: Number(action.payload.limit) || DEFAULT_PAGE_LIMIT,
        total_count: 0,
        from_day: action.payload.from_day || '',
        to_day: action.payload.to_day || '',
        status: action.payload.status || '',
        sort_by: action.payload.sort_by || 'created_at',
        direction: action.payload.direction || 'desc',
        loaded: LOADING_TYPES.LOADING
      };

      return {
        ...state,
        payments: getPaymentsRequest,
        answer: {},
      };
    case types.GET_PAYMENTS_SUCCESS:
      const getPaymentsSuccess = cloneDeep(state.payments);
      const newGetPaymentsSuccess = getPaymentsSuccess[action.payload.storeId || ''];
      newGetPaymentsSuccess.list = action.payload.payments;
      newGetPaymentsSuccess.total_count = action.payload.meta.total_count;
      newGetPaymentsSuccess.loaded = LOADING_TYPES.LOADED;

      newGetPaymentsSuccess[action.payload.storeId || ''] = newGetPaymentsSuccess;

      return {
        ...state,
        payments: getPaymentsSuccess,
      };
    case types.GET_PAYMENTS_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.EXPORT_PAYMENTS_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.EXPORT_PAYMENTS_SUCCESS:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.EXPORT_PAYMENTS_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.CLEAR:
      return {
        ...state,
        answer: {},
      };
    case types.SET_PAYMENTS_LOADING:
      const setPaymentsLoading = cloneDeep(state.payments);
      setPaymentsLoading[action.payload.storeId || ''] = {
        list: [],
        page: Number(action.payload.page) || 1,
        limit: Number(action.payload.limit) || DEFAULT_PAGE_LIMIT,
        total_count: 0,
        from_day: action.payload.from_day || '',
        to_day: action.payload.to_day || '',
        status: action.payload.status || '',
        sort_by: action.payload.sort_by || 'created_at',
        direction: action.payload.direction || 'desc',
        loaded: LOADING_TYPES.NOT_LOADED
      };

      return {
        ...state,
        payments: setPaymentsLoading,
        answer: {},
      };
    case types.LOGOUT_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default walletReducers;
