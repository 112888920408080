import React from 'react';

import { Logo } from '../../../elements';
import { TermsStyles } from '../Terms/Terms.Styles';
import {PATHS} from "../../../const/paths.constants";

const Privacy: React.FC = () => {

  return (
    <TermsStyles>
      <div className="terms-container">
        <div className="terms-header">
          <div className="logo-top">
            <Logo className="logo" to={PATHS.NEW_DASHBOARD}>
              <img src="/img/main/logo.svg" alt="cp" />
            </Logo>
          </div>
        </div>
        <div className="terms-body">
          <h1 className="page-title">Privacy Policy</h1>
          <h2 className="list">
            <div className="block">Last Modified: July 6, 2020</div>
            <div className="block">
              This Privacy Policy (the “Policy”) describes how CryptoProcessing, Inc (referred to as
              "CryptoProcessing.io"
              or "we" or “us” or “our”) collects, uses, and handles your personal information when you use websites,
              pages,
              mobile applications, features, or content owned or operated by CryptoProcessing, or any products and
              services
              (collectively the “Services”). Please read carefully through this Policy and the Terms of Use, which
              governs
              your use of the Services.
            </div>
            <div className="address">
              Address: Office 29, Clifton House, Fitzwilliam Street Lower
              Dublin 2, D02 XT91, REPUBLIC OF IRELAND
              Ireland International: +353-81-837-0020
              Ireland Toll-Free: 1-800-300-004
            </div>
          </h2>
          <div className="list">
            <div className="block">
              <b>1. ACCEPTANCE</b>
              <p>By accessing or using the Services, you warrant and represent that you accept the data practices and
                terms
                detailed in this Policy. If you do not agree with this Policy, please discontinue your access or use of
                the
                Services immediately. This Policy does not apply to services offered by other companies or other sites
                linked from our Services.
              </p>
            </div>
            <div className="block">
              <b>2. OUR RELATIONSHIP WITH YOU</b>
              <ul>
                CryptoProcessing has the following relationships with individuals on its Services:
                <li>
                  ● “Users” are individuals providing personal information to us via our website, pages, social media
                  accounts, or other means. For example, Users can sign up to be added to the public Lists, receive
                  marketing communications or newsletters, or interact with us on our social media accounts.
                  CryptoProcessing is in a “data controller” relationship with Users.
                </li>
                <li>
                  ● “Customers” are individuals that have purchased or obtained the CryptoProcessing Services. Here,
                  CryptoProcessing is in a “data controller” relationship with Customers.
                </li>
              </ul>
              <p>Hereinafter, we may refer to Users and Customers collectively as “you.”
              </p>
            </div>
            <div className="block">
              <b>3. CHANGES TO THIS PRIVACY POLICY</b>
              <p>We may amend this Policy at any time by posting the most current version on our website. If we make any
                material changes your rights under this Policy, we will notify you by means of a prominent notice in
                advance
                of such changes coming into effect. A prominent notice may include sending email notifications to the
                email
                address we have on file, or providing you with an in-application notice such as a banner or pop-up
                alerting
                you of the changes. We will update the “Last Modified” date at the top of the Policy when we post the
                new
                Policy. Your continued use of the Services after posting of the Policy will constitute your acceptance
                of
                the changes.
              </p>
              <p>In addition, we may provide you with "just-in-time" disclosures or additional information about our
                data
                collection, purposes for processing, or other relevant information. The purpose of such just-in-time
                notices
                is to supplement or clarify our privacy practices or provide you with additional choices about how we
                process your personal information.
              </p>
            </div>
            <div className="block">
              <b>4. WHAT INFORMATION IS COLLECTED</b>
              <p>When you use our Services, we collect personal information about your use of our Services and
                information
                that you send us. We may also obtain information about you from third party sources.
              </p>
              <p>Throughout this Policy, we use the term "personal information" to describe information that identifies
                you
                or makes you identifiable. We do not consider personal information to include information that has been
                anonymized so that it does not identify a specific individual.
              </p>
              <ul>
                We may collect personal information in the following ways:
                <li>
                  ● Information you give us. We may collect information that you provide to us, such as when you create
                  an
                  CryptoProcessing Account, make a comment on our blog, or when you otherwise correspond with us
                  regarding
                  our Services (e.g., inquiry to customer support team, social media, etc.)
                </li>
                <li>
                  ● Cookies. We use technologies like cookies, web beacons and pixel tags to gather information about
                  how
                  you are interacting with the Services, which may include identifying your IP address, browser type,
                  and
                  referring page. Please see more information about how we use Cookies below.
                </li>
                <li>
                  ● Contact Information. To engage in cryptocurrency transactions via email, to interact with others via
                  our
                  Services, or to invite others to sign up for CryptoProcessing via our referral program, you may share
                  other individuals’ contact information with us.
                </li>
                <li>
                  ● Account Registration. To create an account on CryptoProcessing, you must provide us with your first
                  name, last name, and email address.
                </li>
                <li>
                  ● Transaction Information. When you engage in transactions via our Services, we collect
                  transaction-related information such as amount sent, date, and information about the payment
                  instrument
                  used to complete your transaction (e.g. cryptocurrency public address or credit card).
                </li>
                <li>
                  ● Performance and Log Data. Our servers automatically record information created by your use of our
                  Services, which may include information such as, but not limited to, your IP address, browser type,
                  operating system, command line information, diagnostic information related to the Services (i.e.,
                  crash
                  activity reports), the referring web page, pages visited, location, your mobile carrier, device and
                  application IDs, and search terms.
                </li>
              </ul>
              <p>
                Parts of our Services are public, such as parts of our Lists and social media accounts, and any
                information
                that is disclosed on such public parts of our Services may appear on search engines or other publicly
                available platforms, and may be “crawled,” searched and used by third parties or other Users and
                Customers
                of the Services. Please do not post any information that you do not want to reveal publicly.
              </p>
            </div>
            <div className="block">
              <b>5. HOW IS COLLECTED INFORMATION USED</b>
              <p>Generally, we collect and use information from you to provide, protect, and improve our Services, and
                to
                provide you with a personalized experience when using our Services. For example, when you provide
                CryptoProcessing with your email address, we may send you updates on new or expanded Services, notices
                about
                security updates or change to our policies, and other administrative or transactional information. Much
                of
                the information you provide us through our Services is information that is designed to be made public,
                such
                as your CryptoProcessing Account information.
              </p>
              <ul>
                Some specific examples of how CryptoProcessing may use your personal information include:
                <li>
                  1. To provide you with the Services. We process your personal information when you sign up for and use
                  our
                  Services (including the Lists and Tasks), make transactions, or participate in our referral program.
                  When
                  you sign up for an CryptoProcessing account, we let you choose which Services you would like to
                  enable.
                  When you enable a new part of the Services, we will disclose how we handle your personal information
                  and
                  ask for any necessary permissions to provide the Services to you. When you enable new Services, you
                  may
                  allow us to collect additional personal information about you or others as necessary to provide the
                  Services. We share your personal information with our service providers and partners to the extent
                  necessary to provide you with the Services. We cannot provide you with the Services without such
                  information.
                </li>
                <li>
                  2. To provide you with administrative communications. We will contact you to keep you updated about
                  your
                  account and the Services, such as changes to this Privacy Policy, our Terms of Use and our other
                  policies,
                  security updates, or to provide transaction-related information to you. Without such communications,
                  we
                  cannot provide you with important information about your continued use of our Services.
                </li>
                <li>
                  3. For security purposes. We process your personal information to enhance the security of our Services
                  and
                  to combat spam, malware or other security risks. Our processing includes monitoring your activities on
                  our
                  Services and verifying your identity and access to the Services. Without processing your personal
                  information, we may not be able to ensure the security of our Services.
                </li>
                <li>
                  4. To provide customer service. When you contact us with questions, concerns, disputes, feedback or
                  any
                  other reason, we process your personal information to respond to you. Without your personal
                  information,
                  we cannot respond to you or ensure your continued enjoyment of our Services.
                </li>
                <li>
                  5. To enforce our terms, agreements and policies. We process your personal information to actively
                  monitor, investigate, prevent and mitigate any alleged or actual prohibited, illicit or illegal
                  activities
                  on our Services or violations of our terms and agreements with you. In addition, we may process your
                  personal information to enforce our agreements with third parties and partners, and/or collect fees
                  based
                  on your use of our Services. We cannot perform our Services in accordance with our terms, agreements
                  or
                  policies without processing your personal information for such purposes.
                </li>
              </ul>
              <ul>
                Designated Countries: For all purposes listed above, pursuant to the EU General Data Protection
                Regulation
                (“GDPR”) or any equivalent legislation (collectively “Data Protection Laws”), CryptoProcessing processes
                the
                personal information of Users and Customers located in the European Economic Area (“EEA”), United
                Kingdom
                and Switzerland (collectively, the “Designated Countries”) based on its contract with such individuals.
                <li>
                  1. To maintain legal and regulatory compliance, and to prevent fraud. We process your personal
                  information
                  to comply with sanctions laws, tax law, or as otherwise necessary to fulfill our other business
                  obligations; identify, prevent, and mitigate fraud on our Services; and/or to manage risk as required
                  under applicable law. We cannot perform the Services in accordance with our legal and regulatory
                  requirements without processing your personal information for such purposes.
                </li>
              </ul>
              <ul>
                Designated Countries: Pursuant to the Data Protection Laws, CryptoProcessing processes the personal
                information of Users and Customers located in the Designated Countries to comply with its legal
                obligations.
                <li>
                  1. Designated Countries: For Users and Customers located in the Designated Countries, please see
                  “Marketing Activities” under Section 12 below for more information.
                </li>
                <li>
                  2. To engage in marketing and promotional activities. CryptoProcessing may send you marketing
                  communications to alert you about new products or services. You can opt-out of our marketing
                  activities at
                  any time. When you share your friends’ contact information with us, we may reach out to them to invite
                  them to use our Services and to continue receiving our communications. However, you are responsible
                  for
                  determining whether the individuals you invite on our Services would like to receive such
                  communications
                  from you – CryptoProcessing does not require you to provide such information to continue enjoying our
                  Services. Please note, transactional communications about your account or our Services are not
                  considered
                  “marketing” communications.
                </li>
                <li>
                  3. Designated Countries: For Users and Customers located in the Designated Countries, please see
                  “Marketing Activities” under Section 12 below for more information.
                </li>
                <li>
                  4. To conduct research and development. In order to offer you new, customized or improved features on
                  our
                  Services, we process your personal information for research and development purposes. Your personal
                  information helps us better understand the way you use and interact with our Services. Without such
                  processing, we cannot ensure your continued enjoyment of part or all of our Services.
                </li>
                <li>
                  5. To enhance your experience on the Services. We process your personal information to provide you
                  with a
                  unique and personalized experience on our Services. Without such processing, we may not be able to
                  ensure
                  your continued enjoyment of part or all of our Services.
                </li>
              </ul>
            </div>
            <div className="block">
              <b>6. THIRD PARTY SOURCES OF INFORMATION</b>
              <ul>
                When you use our Services, we may collect personal information about you from the following sources:
                <li>
                  ● Social Media Platforms. We collect information about your publicly available social media profiles
                  (e.g.
                  LinkedIn) or other linked accounts (e.g., GitHub, Google+, etc.). We collect this information to
                  better
                  understand you, to maintain and improve the accuracy of the records we hold about you, and to help us
                  monitor, prevent and detect fraud.
                </li>
              </ul>
            </div>
            <div className="block">
              <b>7. CHILDREN</b>
              <p>
                We do not knowingly request to collect personal information from any person under the age of 18. If a
                User
                or Customers submitting personal information is suspected of being younger than 18 years of age, we will
                require the User or Customer to close his or her account and will not allow continued use of the
                Services.
                We will also take steps to delete the information as soon as possible, unless an exception applies.
                Please
                notify us if you know of any individuals under the age of 18 using our Services so we can take action to
                prevent access to our Services.
              </p>
            </div>
            <div className="block">
              <b>8. INFORMATION WE SHARE</b>
              <ul>
                We do not sell, exchange, transfer, or give your personal information to any other company or individual
                for
                any reason except as set forth below.
                <li>
                  1. We will share personal information with companies or individuals when we have your consent to do
                  so.
                </li>
                <li>
                  2. If we are involved in a merger or acquisition, your personal information may be transferred as part
                  of
                  that transaction. In such event, we will require that your personal information be protected in
                  accordance
                  with this Policy. We will notify you of any change in applicable policy.
                </li>
                <li>
                  3. We may provide personal information to our trusted services providers to assist us in providing
                  Services to you, and only based on our instructions and in adherence with this Policy and applicable
                  confidentiality and security measures.
                </li>
                <li>
                  4. We will share personal information with law enforcement or other third parties if we: (1) are
                  compelled
                  to do so to comply with any applicable law or legal process; or (2) believe in good faith that
                  disclosure
                  is necessary to prevent physical harm, illegal activity, or harm to the rights, property, or safety of
                  CryptoProcessing or others.
                </li>
              </ul>
            </div>
            <div className="block">
              <b>9. HOW WE PROTECT YOUR INFORMATION</b>
              <p>
                CryptoProcessing takes reasonable precautions to protect your personal information from loss, misuse,
                unauthorized access, disclosure, alteration, and destruction. We maintain physical, technical and
                procedural
                safeguards to protect the confidentiality and security of your personal information. Such safeguards
                include
                the use of firewalls and data encryption, enforcing physical access controls to our buildings and files,
                and
                limiting access to personal information only to those employees, agents or third parties who need access
                to
                that information to process it for us. We contractually require all third-party service providers to
                maintain appropriate security measures to protect your personal information.
              </p>
              <p>
                However, you are also responsible for keeping your personal information confidential and secure.
                CryptoProcessing cannot guarantee that your personal information will be 100% safe while using its
                Services.
                You should choose a password that is complex (e.g., special characters and numbers, sufficient length,
                etc.)
                and keep your password confidential. Do not leave your device unlocked so that other individuals may
                access
                your device or CryptoProcessing account. CryptoProcessing is not in control of your wireless connection
                or
                the devices you use to log into the CryptoProcessing Services, so you should make sure you trust the
                devices
                and connections you use to access the Services.
              </p>
              <p>
                If you believe that you have experienced unauthorized access or use of your CryptoProcessing account,
                please
                contact us immediately at <a
                href="/cdn-cgi/l/email-protection#61080f070e2102131811150e11130e02041212080f064f080e"><span
                className="__cf_email__"
                data-cfemail="3950575f56795a4b40494d56494b565a5c4a4a50575e175056">[email&#160;protected]</span></a>
              </p>
            </div>
            <div className="block">
              <b>10. RETENTION OF YOUR INFORMATION</b>
              <ul>
                We store your personal information for as long as we need it to provide you the Services, to serve the
                purpose(s) for which your personal information was processed, or as necessary to comply with our legal
                obligations, resolve disputes, or enforce our agreements to the extent permitted by law. While retention
                requirements can vary by country, we generally apply the retention periods noted below.
                <li>
                  ● Marketing. We store information used for marketing purposes indefinitely until you unsubscribe. Once
                  you
                  unsubscribe from marketing communications, we add your contact information to our suppression list to
                  ensure we respect your unsubscribe request.
                </li>
                <li>
                  ● Your Interactions and Content on Our Services. We may store any information about your interactions
                  on
                  our Services or any content created, posted or shared by you on our Services (e.g., pictures,
                  comments,
                  support tickets, and other content) as long as necessary after the closure your account for the
                  establishment or defense of legal claims, audit and crime prevention purposes.
                </li>
                <li>
                  ● Web Behavior Data. We retain any information collected via cookies, clear gifs, flash cookies, web
                  page
                  counters and other technical or analytics tools.
                </li>
              </ul>
            </div>
            <div className="block">
              <b>11. COOKIES & SIMILAR TRACKING TECHNOLOGIES</b>
              <ul>
                When you access our Services, we may place small data files called “cookies” or “pixel tags” on your
                device.
                We use these files to understand, secure, operate, and provide our Services. In some cases, we may use
                cookies and similar technologies to collect personal information, or information that becomes personal
                information if we combine it with other information. Cookies can store information in different ways:
                <li>
                  ● Session cookies. A session cookie lasts while your browser is open and is automatically deleted when
                  you
                  close your browser.
                </li>
                <li>
                  ● Persistent cookies. A persistent cookie lasts until you or your browser deletes the cookies or they
                  expire.
                </li>
              </ul>
              <p>
                We may place "first party cookies” or “third-party cookies” on our Services. A first party cookie is a
                cookie that we set on our Services, while a third-party cookie is set by parties other than
                CryptoProcessing. A third-party cookie can recognize your computer both when it visits our Services and
                when
                it visits other websites or applications. CryptoProcessing does not control how third-party cookies are
                used. You should check the third party’s website for more information about how they use cookies.
                <ul>
                  1. Types of Cookies Collected. We collect the following types of cookies:
                  <li>
                    ● Strictly Necessary Cookies. These cookies are essential because they enable you to move around our
                    Services and use certain features on our Services. For example, strictly necessary cookies allow you
                    to
                    access secure areas. Without these cookies, some Services cannot be provided.
                  </li>
                  <li>
                    ● Functionality Cookies. These cookies allow us to remember the choices you make and to tailor our
                    Services to your preferences so we can provide relevant content to you. For example, a functionality
                    cookie can remember your preferences (e.g., country or language selection), or your username.
                    Without
                    these cookies, you may not be able to refer other users to our Services to collect earnings. Without
                    these
                    cookies, you may not be able to enjoy some of the Services.
                  </li>
                  <li>
                    ● Performance/Analytics Cookies. These cookies collect information about how you use a website. For
                    example, a performance or analytics cookie will collect information about which pages you go to most
                    often, if you get error messages from certain pages, or how much time you spend on a page. These
                    cookies
                    are important because they help us maintain and improve our Services.
                  </li>
                  <li>
                    ● Advertising Cookies. These cookies allow us to provide you advertisements based on your interests
                    and
                    preferences. They collect information to measure the efficacy of an advertisement, such as which
                    advertisements you clicked on, whether you made a purchase, and what the advertisement was about.
                  </li>
                </ul>
                <ul>
                  2. Do Not Track. Some Internet browsers allow you to transmit “Do Not Track” signals, but our Services
                  do
                  not respond to “Do Not Track” signals because we do not track our Users or Customers over time and
                  across
                  third-party websites to provide targeted advertising.
                </ul>
                <ul>
                  3. Managing Your Cookies.
                  <li>
                    ● First Party Cookies: To manage first-party cookies, you can follow the instructions provided by
                    your
                    browser or device (see your “Settings” or “Preferences”) to modify your cookie settings. Please
                    note, if
                    you choose to disable or not accept cookies, parts of the Services may lose functionality or become
                    inaccessible.
                  </li>
                  <li>
                    ● Designated Countries: Pursuant to the Data Protection Laws, CryptoProcessing processes your
                    personal
                    information to satisfy its legitimate interests as described above. As required by law,
                    CryptoProcessing
                    will ask for your consent.
                  </li>
                  <li>
                    ● Mobile Advertising: To manage advertising on your mobile device, go to your settings or
                    preferences on
                    your mobile device. If you opt out of mobile advertising cookies, advertising cookies will no longer
                    collect information about you and all information already collected about you will be removed,
                    including
                    your random ID.
                  </li>
                </ul>
              </p>
            </div>
            <div className="block">
              <b>12. ACCESS & MODIFICATION OF YOUR INFORMATION</b>
              <p>
                If you would like to access the information we hold on you or would like to modify the information we
                have
                on file, please contact us at <a
                href="/cdn-cgi/l/email-protection#3b52555d547b5849424b4f544b4954585e484852555c155254"><span
                className="__cf_email__"
                data-cfemail="234a4d454c6340515a53574c53514c404650504a4d440d4a4c">[email&#160;protected]</span></a>. We
                reserve the right to limit your access or modifications if your requests are too frequent and/or
                burdensome,
                or as necessary to manage our risks or comply with our contractual or legal obligations.
              </p>
              <p>
                For individuals residing in Designated Countries, please refer to Section 15 entitled “Designated
                Countries
                Privacy Rights” below for more information.
              </p>
            </div>
            <div className="block">
              <b>13. OUR COMMUNICATIONS TO YOU</b>
              <p>
                We want to keep you up-to-date with new or expanded Services, provide you with important information
                about
                your account or transactions, and send you information we believe interests you. Any communications from
                us
                that are not Service-related or transactional in nature will offer you an "unsubscribe" option so that
                you
                can opt out of receiving such messages.
              </p>
              <p>
                For individuals residing in Designated Countries, please refer to the “Designated Countries Privacy
                Rights”
                section below for more information.
              </p>
            </div>
            <div className="block">
              <b>14. DESIGNATED COUNTRIES PRIVACY RIGHTS</b>
              <ul>
                This Section only applies to Users and Customers of the Services that are located in the European
                Economic
                Area, United Kingdom and/or Switzerland (collectively, the “Designated Countries”) at the time of data
                collection. We may ask you to identify which country you are located in when you use some of the
                Services,
                or we may rely on your IP address to identify which country you are located in. Where we rely only on
                your
                IP address, we cannot apply the terms of this Section to any User or Customer that masks or otherwise
                obfuscates their location information so as not to appear located in the Designated Countries. If any
                terms
                in this Section conflict with other terms contained in this Policy, the terms in this Section shall
                apply to
                Users and Customers in the Designated Countries.
                <li>
                  1. Our Relationship to You. CryptoProcessing is a data controller with regard to any personal
                  information
                  collected from Customers or Users of its Services. A “data controller” is an entity that determines
                  the
                  purposes for which and the manner in which any personal information is processed. Any third parties
                  that
                  act as our service providers are “data processors” that handle your personal information in accordance
                  with our instructions.
                </li>
                <li>
                  2. Lawful Basis for Processing Your Personal Information. We describe our lawful bases for processing
                  in
                  Section 6 (“How Is Collected Information Used”) and Section 7 (“Information We Share”) under headings
                  entitled “Designated Countries.” The lawful bases on which we rely to process your personal
                  information
                  include: your consent to the processing; satisfaction of our legal obligations; to protect your vital
                  interests; to carry out our obligations arising from any contracts we entered with you or to take
                  steps at
                  your request prior to entering into a contract with you; in the public interest; or for our legitimate
                  interests as described in those sections.
                </li>
                <li>
                  3. Marketing Activities. Direct marketing includes any communications we send to you that are only
                  based
                  on advertising or promoting products and services. Transactional communications about your account or
                  our
                  Services are not considered “direct marketing” communications. We will only contact Users or Customers
                  by
                  electronic means (including email or SMS) based on our legitimate interest or their consent. When we
                  rely
                  on legitimate interest, we will only send you information about our Services that are similar to those
                  which were the subject of a previous sale or negotiations of a sale to you.
                </li>
                <li>
                  4. If you do not want us to use your personal information in this way, please click the unsubscribe
                  link
                  in your emails, or contact us at <a
                  href="/cdn-cgi/l/email-protection#80e9eee6efc0e3f2f9f0f4eff0f2efe3e5f3f3e9eee7aee9ef"><span
                  className="__cf_email__"
                  data-cfemail="533a3d353c1330212a23273c23213c303620203a3d347d3a3c">[email&#160;protected]</span></a>.
                  You can object to direct marketing at any time and free of charge.
                </li>
                <li>
                  5. Individual Rights. We provide you with the rights described below when you use our Services. Please
                  contact us at <a
                  href="/cdn-cgi/l/email-protection#71181f171e3112030801051e01031e12140202181f165f181e"><span
                  className="__cf_email__"
                  data-cfemail="2b42454d446b4859525b5f445b5944484e585842454c054244">[email&#160;protected]</span></a>.
                  if you would like to
                  exercise your rights under applicable law. When we receive an individual rights request from you,
                  please
                  make sure you are ready to verify your identity.
                </li>
                <li>
                  <ul>
                    6. Please be advised that there are limitations to your individual rights. We may limit your
                    individual
                    rights in the following ways: (i) where denial of access is required or authorized by law; (ii) when
                    granting access would have a negative impact on other's privacy; (iii) to protect our rights and
                    properties; and (iv) where the request is frivolous or burdensome.
                    <li>
                      1) Right to withdraw consent. If we rely on consent to process your personal information, you have
                      the
                      right to withdraw your consent at any time. A withdrawal of consent will not affect the lawfulness
                      of
                      our processing or the processing of any third parties based on consent before your withdrawal.
                    </li>
                    <li>
                      2) Right of access and rectification. If you request a copy of your personal information that we
                      hold,
                      we will provide you with a copy without undue delay and free of charge, except where we are
                      permitted
                      by law to charge a fee. We may limit your access if such access would adversely affect the rights
                      and
                      freedoms of other individuals. You may request to correct or update any of your personal
                      information
                      held by us, unless you can already do so directly via the Services.
                    </li>
                    <li>
                      3) Right to erasure (the “Right to be Forgotten”). You may request us to erase any of your
                      personal
                      information held by us that: is no longer necessary in relation to the purposes for which it was
                      collected or otherwise processed; was collected in relation to processing that you previously
                      consented to, but later withdrew such consent; or was collected in relation to processing
                      activities
                      to which you object, and there are no overriding legitimate grounds for our processing.
                    </li>
                    <li>
                      <ul>
                        4) Right to restriction. You have the right to restrict our processing your personal information
                        where one of the following applies:
                        <li>
                          a) You contest the accuracy of your personal information that we processed. We will restrict
                          the
                          processing of your personal information, which may result in an interruption of some or all of
                          the
                          Services, during the period necessary for us to verify the accuracy of your personal
                          information.
                        </li>
                        <li>
                          b) The processing is unlawful and you oppose the erasure of your personal information and
                          request
                          the restriction of its use instead.
                        </li>
                        <li>
                          c) We no longer need your personal information for the purposes of the processing, but it is
                          required by you to establish, exercise or defense of legal claims.
                        </li>
                        <li>
                          d) You have objected to processing, pending the verification whether the legitimate grounds of
                          our
                          processing override your rights.
                        </li>
                        <li>
                          e) We will only process your restricted personal information with your consent or for the
                          establishment, exercise or defense of legal claims or for the protection of the rights of
                          another
                          natural or legal person or for reasons of important public interest. We will inform you if or
                          when
                          the restriction is lifted.
                        </li>
                      </ul>
                    </li>
                    <li>
                      5) Right to object to processing. You may object to our processing at any time and as permitted by
                      applicable law if we process your personal information on the legal basis of consent, contract or
                      legitimate interests. We can continue to process your personal information if it is necessary for
                      the
                      defense of legal claims, or for any other exceptions permitted by applicable law.
                    </li>
                    <li>
                      6) Right to data portability. If we process your personal information based on a contract with you
                      or
                      based on your consent, or the processing is carried out by automated means, you may request to
                      receive
                      your personal information in a structured, commonly used and machine-readable format, and to have
                      us
                      transfer your personal information directly to another “controller”, where technically feasible,
                      unless exercise of this right adversely affects the rights and freedoms of others.
                    </li>
                    <li>
                      7) Notification to third-parties. If we share your personal information with third parties, we
                      will
                      notify them of any requests for rectification, erasure or restriction of your personal
                      information,
                      unless this proves impossible or involves disproportionate effort. Upon your request, we will
                      identify
                      such third parties.
                    </li>
                    <li>
                      8) Automated individual decision-making, including profiling. You have the right not to be subject
                      to
                      a decision based solely on automated processing of your personal information, including profiling,
                      which produces legal or similarly significant effects on you, unless an exception applies under
                      applicable law.
                    </li>
                    <li>
                      9) Right to Lodge a Complaint. If you believe we have infringed or violated your privacy rights,
                      please contact us at <a
                      href="/cdn-cgi/l/email-protection#7a13141c153a1908030a0e150a0815191f090913141d541315"><span
                      className="__cf_email__"
                      data-cfemail="91f8fff7fed1f2e3e8e1e5fee1e3fef2f4e2e2f8fff6bff8fe">[email&#160;protected]</span></a> so
                      that we
                      can work to resolve your concerns. You also have a right to lodge a complaint with a competent
                      supervisory authority situated in a Member State of your habitual residence, place of work, or
                      place
                      of alleged infringement.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="block">
              <b>15. CONTACT US</b>
              <p>
                If you have questions or concerns regarding this Policy or would like to access, change or delete your
                personal information, or have a complaint, please contact us on our support page or by emailing us at
                <a href="/cdn-cgi/l/email-protection#365f5850597655444f464259464459555345455f5851185f59"><span
                  className="__cf_email__"
                  data-cfemail="51383f373e1132232821253e21233e32342222383f367f383e">[email&#160;protected]</span></a>
              </p>
            </div>
          </div>
        </div>
        <div className="terms-footer">
          <div className="logo-top">
            <Logo className="logo" to={PATHS.NEW_DASHBOARD}>
              <img src="/img/main/logo.svg" alt="cp" />
            </Logo>
          </div>
        </div>
      </div>
    </TermsStyles>
  );
};

export default Privacy;
