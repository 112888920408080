import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import API from '../../api/executor';
import { setLoading } from '../loadingsErrors/actions';
import {
  CreateInvoiceRequest,
  GetInvoicesRequest,
  GetInvoiceRequest,
  MakePaidInvoiceRequest,
  DeactivateInvoiceRequest,
} from './actionTypes';
import types from '../actionTypes';
import {DEFAULT_PAGE_LIMIT} from "../../const/app.constants";

// calls
const createInvoiceCall = (payload?: any) => API.call('createInvoice', payload);
const getInvoicesCall = (payload?: any) => API.call('getInvoices', payload);
const getInvoiceCall = (payload?: any) => API.call('getInvoice', payload);
const makePaidInvoiceCall = (payload?: any) => API.call('makePaidInvoice', payload);
const deactivateInvoiceCall = (payload?: any) => API.call('deactivateInvoice', payload);

// call types
type GetInvoices = SagaReturnType<typeof getInvoicesCall>;
type GetInvoice = SagaReturnType<typeof getInvoiceCall>;
type DeactivatedInvoice = SagaReturnType<typeof deactivateInvoiceCall>;

function* createInvoice({ payload }: CreateInvoiceRequest) {
  yield put(setLoading(types.CREATE_INVOICE_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  try {
    yield call(() => createInvoiceCall(payload));
    const res: GetInvoices = yield call(() => getInvoicesCall({
      storeId: storeId,
      status: '',
      from_day: '',
      to_day: '',
      page: 1,
      limit: DEFAULT_PAGE_LIMIT,
      sort_by: 'created_at',
      direction: 'desc'
    }));

    yield all([
      put({
        type: types.CREATE_INVOICE_SUCCESS,
        payload: {
          storeId,
          invoices: res.data,
          meta: res.meta,
          answer: {
            success: 'You Invoice was successfully created'
          }
        }
      }),
      put(setLoading(types.CREATE_INVOICE_REQUEST, false)),
    ]);
  } catch (e: any) {
    if (e?.errObj) {
      const error = e?.errObj ? e.errObj : {error: e.toString()};

      yield all([
        put(setLoading(types.CREATE_INVOICE_REQUEST, false)),
        put({
          type: types.CREATE_INVOICE_FAILURE,
          payload: {error: error},
        }),
      ]);
    }
  }
}

function* getInvoices({ payload }: GetInvoicesRequest) {
  yield put(setLoading(types.GET_INVOICES_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  try {
    const res: GetInvoices = yield call(() => getInvoicesCall(payload));
    yield all([
      put({
        type: types.GET_INVOICES_SUCCESS,
        payload: {
          storeId,
          invoices: res.data,
          meta: res.meta
        }
      }),
      put(setLoading(types.GET_INVOICES_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.GET_INVOICES_REQUEST, false)),
      put({
        type: types.GET_INVOICES_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* getInvoice({ payload }: GetInvoiceRequest) {
  yield put(setLoading(types.GET_INVOICE_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  try {
    const res: GetInvoice = yield call(() => getInvoiceCall(payload));
    yield all([
      put({
        type: types.GET_INVOICE_SUCCESS,
        payload: {
          storeId,
          invoice: res
        }
      }),
      put(setLoading(types.GET_INVOICE_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.GET_INVOICE_REQUEST, false)),
      put({
        type: types.GET_INVOICE_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* makePaidInvoice({ payload }: MakePaidInvoiceRequest) {
  yield put(setLoading(types.MAKE_PAID_INVOICE_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  const invoiceId: string = payload?.invoiceId || '';
  try {
    yield call(() => makePaidInvoiceCall(payload));

    yield all([
      put({
        type: types.MAKE_PAID_INVOICE_SUCCESS,
        payload: {
          storeId,
          invoiceId,
          answer: {
            success: 'You Invoice was successfully paid'
          }
        }
      }),
      put(setLoading(types.MAKE_PAID_INVOICE_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.MAKE_PAID_INVOICE_REQUEST, false)),
      put({
        type: types.MAKE_PAID_INVOICE_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* deactivateInvoice({ payload }: DeactivateInvoiceRequest) {
  yield put(setLoading(types.DEACTIVATE_INVOICE_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  const invoiceId: string = payload?.invoiceId || '';
  try {
    const invoice: DeactivatedInvoice = yield call(() => deactivateInvoiceCall(payload));
    yield all([
      put({
        type: types.DEACTIVATE_INVOICE_SUCCESS,
        payload: {
          invoice,
          storeId,
          invoiceId,
          answer: {
            success: 'You Invoice was successfully deactivated'
          }
        }
      }),
      put(setLoading(types.DEACTIVATE_INVOICE_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.DEACTIVATE_INVOICE_REQUEST, false)),
      put({
        type: types.DEACTIVATE_INVOICE_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

export default function* invoicesSagas() {
  yield takeLatest(types.CREATE_INVOICE_REQUEST, createInvoice);
  yield takeLatest(types.GET_INVOICES_REQUEST, getInvoices);
  yield takeLatest(types.GET_INVOICE_REQUEST, getInvoice);
  yield takeLatest(types.MAKE_PAID_INVOICE_REQUEST, makePaidInvoice);
  yield takeLatest(types.DEACTIVATE_INVOICE_REQUEST, deactivateInvoice);
}
