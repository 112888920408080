import { all, fork } from 'redux-saga/effects';

import appSagas from './app/sagas';
import usersSagas from './user/sagas';
import storesSagas from './stores/sagas';
import invoicesSagas from './invoices/sagas';
import withdrawalsSagas from './withdrawals/sagas';
import walletSagas from './wallet/sagas';

export default function* rootSaga() {
  yield all([
    fork(appSagas),
    fork(usersSagas),
    fork(storesSagas),
    fork(invoicesSagas),
    fork(withdrawalsSagas),
    fork(walletSagas),
  ]);
}
