import styled from 'styled-components';

export const ButtonContainer: any = styled.button`
  display: inline-block;
  text-align: center;
  color: var(--white);
  border-radius: var(--border-radius);
  border: 1px solid var(--purple);
  background-color: var(--purple);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  padding: 11px 20px;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  cursor: pointer;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--inter);

  &:focus {
    color: var(--white);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #E0EAFF;
  }

  &:hover {
  }

  &:active {
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background: #C7D7FE;
    border: 1px solid #C7D7FE;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  }

  &.loading-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-left: 10px;
      width: 16px;
      height: 16px;
      stroke: var(--white);
    }
  }

  &.registration__link {
    margin-bottom: 40px;
    text-decoration: underline;
    background-color: transparent;
    border: none;

    &:hover {
      background-color: transparent;
      border: none;
    }

    &:focus {
      color: var(--light-gray);
      box-shadow: none;
    }
  }
  
  &.-white {
    background: var(--white);
    border: 1px solid var(--light-gray);
    color: var(--dark-gray);
    
    .loader {
      svg {
        stroke: var(--dark-gray);
      }
    }
  }
  
  &.-pink {
    background: #FEF3F2;
    border: 1px solid #FEF3F2;
    color: #B42318;
  }
`;
