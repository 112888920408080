import styled from 'styled-components';
import Slider from '@mui/material/Slider';

export const SettingsContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--inter);

  .settings {
    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      
      &__title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: var(--black);
      }
      
      &__btns {
        display: flex;
        flex-direction: row;
        gap: 12px;
      }
      
      &__btn {
        display: flex;
        background: var(--white);
        border: 1px solid var(--light-gray);
        padding: 9px 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
        align-items: center;
        
        &:before {
          content: '';
          display: inline-block;
          margin-right: 9px;
          margin-left: 1px;
          width: 18px;
          height: 18px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0IDkuODMzMzNWMTQuODMzM0MxNCAxNS4yNzU0IDEzLjgyNDQgMTUuNjk5MyAxMy41MTE4IDE2LjAxMThDMTMuMTk5MyAxNi4zMjQ0IDEyLjc3NTQgMTYuNSAxMi4zMzMzIDE2LjVIMy4xNjY2N0MyLjcyNDY0IDE2LjUgMi4zMDA3MiAxNi4zMjQ0IDEuOTg4MTYgMTYuMDExOEMxLjY3NTU5IDE1LjY5OTMgMS41IDE1LjI3NTQgMS41IDE0LjgzMzNWNS42NjY2N0MxLjUgNS4yMjQ2NCAxLjY3NTU5IDQuODAwNzIgMS45ODgxNiA0LjQ4ODE2QzIuMzAwNzIgNC4xNzU1OSAyLjcyNDY0IDQgMy4xNjY2NyA0SDguMTY2NjdNMTEuNSAxLjVIMTYuNU0xNi41IDEuNVY2LjVNMTYuNSAxLjVMNy4zMzMzMyAxMC42NjY3IiBzdHJva2U9IiMzNDQwNTQiIHN0cm9rZS13aWR0aD0iMS42NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    &-tabs {
      &__wrap {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
      }
    }
  }
  
  .conversion {
    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: var(--black);
      
      &__wrap {
        margin-top: 31px;
        padding-bottom: 19px;
        border-bottom: 1px solid var(--dark-white);
      }
    }
    
    &-wrap {
      margin-top: 24px;
      padding-bottom: 9px;
      border-bottom: 1px solid var(--dark-white);
    }
    
    &-row {
      
    }
    
    &-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      color: var(--dark-gray);
    }
    
    &-switch {
      &__wrap {
        margin-bottom: 11px;
        margin-left: 55px;
        
        .MuiFormControlLabel-label {
          font-family: var(--inter);
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--dark-gray);
        }
        
        .MuiSwitch-root {
          margin-right: 7px;
        }
      }
    }
  }

  .withdrawal {
    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: var(--black);
      
      &__wrap {
        margin-top: 32px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--dark-white);
      }
    }

    &-wrap {
      margin-top: 25px;
    }

    &-row {

    }

    &-item {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--dark-gray);
    }

    &-subitem {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray);
    }

    &-token {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__img {
        width: 36px;
        height: 36px;
        margin-right: 12px;
      }

      &__amounts {
        display: flex;
        flex-direction: column;
      }

      &__amount {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);

        &.-usd {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: var(--gray);
        }
      }
    }
    
    &-switch {
      &__wrap {
        .MuiFormControlLabel-root {
          margin-right: -8px;
        }
      }
    }
    
    &-form {
      display: flex;
      flex-direction: column;
      background: var(--white);
      border: 1px solid var(--dark-white);
      border-radius: 8px;
      margin-left: 45px;
      
      &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--light-blue);
        border: 1px solid #A4BCFD;
        border-radius: 8px 8px 0 0;
        padding: 14px 15px;
      }

      &__body {
        display: flex;
        flex-direction: column;
        padding: 16px;
      }
      
      &__percentage {
        display: flex;
        flex-direction: column;
        
        &_head {
          display: flex;
          justify-content: space-between;
        }
        
        &_title {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray);
          
          &_wrap {
            
          }
        }

        &_count {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--dark-purple);
          
          &_wrap {
            background: var(--light-blue);
            border-radius: 16px;
            padding: 2px 10px;
          }
        }
        
        &_slider {
          
        }
      }
      
      &__address {
        margin-top: 13px;
        
        &_container {
          margin: 0 auto;
        }

        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray);
        }
      }

      &__footer {
        display: flex;
        justify-content: flex-end;
        padding: 14px 23px;
        border-top: 1px solid var(--dark-white);
        
        &_btn {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          padding: 10px 16px;
          
          &.-remove {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background: none;
            border: 0;
            outline: none;
            cursor: pointer;
            padding: 0;
            margin: 0;
            text-align: right;

            & span {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: var(--gray);
              margin-left: 8px;
            }

            & svg {
              stroke: var(--gray);
            }
          }
        }
      }
    }

    &-auth {
      margin-top: 15px;

      &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
      }

      &__text {
        display: block;
        width: 348px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: var(--gray);
        margin-top: 16px;
      }
    }

    &-code {
      width: 48px;
      height: 48px;
      border: 1px solid var(--light-gray);
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: var(--border-radius);
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      letter-spacing: -0.02em;
      color: var(--purple);
      outline: none;

      &::placeholder {
        color: var(--light-gray);
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
      }

      &:focus {
        &::placeholder {
          opacity: 0;
        }
      }

      &__wrap {
        margin-top: 6px;
      }

      &__container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
      }
    }

    &__table {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 25px;
      border: 1px solid var(--dark-white);
      box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 4px 20px -2px rgba(50, 50, 71, 0.08);
      border-radius: var(--border-radius);

      &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 19px 24px;

        &_title {
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          color: var(--black);
        }

        &_btns {
          display: flex;
          align-items: center;
          gap: 12px
        }

        &_btn {
          display: flex;
          background: var(--white);
          border: 1px solid var(--light-gray);
          padding: 9px 15px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--dark-gray);
          align-items: center;

          &:before {
            content: '';
            display: inline-block;
            margin-left: 1px;

            background-repeat: no-repeat;
            background-size: cover;
          }
          &.-generate {
            &:before {
              margin-right: 9px;
              width: 20px;
              height: 18px;
              background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5LjE2NjMgMi4zMzMzMVY3LjMzMzMyTTE5LjE2NjMgNy4zMzMzMkgxNC4xNjYzTTE5LjE2NjMgNy4zMzMzMkwxNS4yOTk3IDMuNjk5OThDMTQuNDA0MSAyLjgwMzkxIDEzLjI5NiAyLjE0OTMyIDEyLjA3OSAxLjc5NzI5QzEwLjg2MiAxLjQ0NTI3IDkuNTc1NiAxLjQwNzI3IDguMzM5OTEgMS42ODY4NkM3LjEwNDIzIDEuOTY2NDUgNS45NTk1MSAyLjU1NDUxIDUuMDEyNTYgMy4zOTYxNkM0LjA2NTYyIDQuMjM3ODIgMy4zNDczMSA1LjMwNTY0IDIuOTI0NjcgNi40OTk5OE0wLjgzMzAwOCAxNS42NjY2VjEwLjY2NjZNMC44MzMwMDggMTAuNjY2Nkg1LjgzMzAxTTAuODMzMDA4IDEwLjY2NjZMNC42OTk2NyAxNC4zQzUuNTk1MyAxNS4xOTYxIDYuNzAzMzIgMTUuODUwNiA3LjkyMDM1IDE2LjIwMjdDOS4xMzczOCAxNi41NTQ3IDEwLjQyMzggMTYuNTkyNyAxMS42NTk0IDE2LjMxMzFDMTIuODk1MSAxNi4wMzM1IDE0LjAzOTggMTUuNDQ1NSAxNC45ODY4IDE0LjYwMzhDMTUuOTMzNyAxMy43NjIxIDE2LjY1MiAxMi42OTQzIDE3LjA3NDcgMTEuNSIgc3Ryb2tlPSIjMzQ0MDU0IiBzdHJva2Utd2lkdGg9IjEuNjciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
            }
          }

          &.-add {
            &:before {
              margin-left: 3px;
              margin-right: 10px;
              width: 14px;
              height: 14px;
              background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMDAwMzMgMS4xNjY3NVYxMi44MzM0TTEuMTY2OTkgNy4wMDAwOEgxMi44MzM3IiBzdHJva2U9IiMzNDQwNTQiIHN0cm9rZS13aWR0aD0iMS42NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
            }

            svg {
              stroke: var(--gray);
            }
          }
        }
      }

      &_head {
        display: grid;
        grid-gap: 1em;
        align-items: center;
        padding: 12px 24px;
        background: var(--cream);
        //border-top-left-radius: var(--border-radius);
        //border-top-right-radius: var(--border-radius);
        border-top: 1px solid var(--dark-white);
        border-bottom: 1px solid var(--dark-white);

        grid-template-columns: 2fr 6fr 2fr 3fr 109px;

        &_item {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: var(--gray);
          cursor: pointer;
        }

        &__arrow {
          font-size: 15px;
        }
      }

      &_body {
        &_row {
          display: grid;
          grid-gap: 1em;
          align-items: center;
          padding: 18px 24px;
          border-bottom: 1px solid var(--dark-white);
          grid-template-columns: 2fr 6fr 2fr 3fr 109px;
          cursor: pointer;

          &:last-child {
            border: 0;
          }

          &.-disabled {
            pointer-events: none;
          }

          &.-show {
            pointer-events: none;
          }
        }

        &_item {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray);

          &.-name {
            font-weight: 500;
            color: var(--black);
          }
        }

        &_btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background: none;
          border: 0;
          outline: none;
          cursor: pointer;
          padding: 0;
          margin: 0;
          text-align: right;

          &_icon {
          }

          &_item {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: var(--gray);
            margin-left: 8px;
          }

          & svg {
            stroke: var(--gray);
          }
        }
      }

      &_zero {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 23px;
        border-bottom: 1px solid var(--dark-white);
      }
    }

    &__loading {
      display: grid;
      grid-gap: 1em;
      align-items: center;
      padding: 18px 24px;
      border-bottom: 1px solid var(--dark-white);
      grid-template-columns: 2fr 6fr 2fr 3fr 109px;
    }
  }
  
  .business {
    &-title {
      display: block;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: var(--black);
      
      &__wrap {
        margin-top: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--dark-white);
      }
    }
    
    &-desc {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray);
      margin-top: 4px;
    }
    
    &-container {}
    
    &-row {
      margin-top: 25px;
      padding-bottom: 14px;
      border-bottom: 1px solid var(--dark-white);
    }
    
    &-name {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--dark-gray);
    }
    
    &-input {}
    &-textarea {
      textarea {
        height: 92px;
      }
    }
    
    &-upload {
      position: relative;
      
      &__area {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: var(--white);
        border: 1px solid var(--dark-white);
        border-radius: var(--border-radius);
        padding: 15px 24px;
      }
      
      &__image {
        width: 40px;
        height: 40px;
      }
      
      &__title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray);
        margin-top: 12px;
        
        & .-blue {
          font-weight: 500;
          color: var(--dark-purple);
        }
      }
      
      &__text {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: var(--gray);
        margin-top: 4px;
      }
      
      &__input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0;
      }
      
      &__files {
        display: flex;
        flex-direction: column;
      }
      
      &__file {
        display: flex;
        position: relative;
        align-items: flex-start;
        justify-content: space-between;
        background: var(--white);
        border: 1px solid var(--blue);
        border-radius: var(--border-radius);
        padding: 13px 17px 15px 15px;
        margin-top: 16px;
        
        &_img {
          width: 32px;
          height: 32px;
          z-index: 1;
        }
        
        &_texts {
          display: flex;
          flex-direction: column;
          margin-right: auto;
          margin-left: 14px;
          margin-top: 2px;
          z-index: 1;
        }
        
        &_title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--dark-gray);
        }
        
        &_size {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray);
        }
        
        &_icon {
          z-index: 1;
        }
        
        &_again {
          display: flex;
          background: transparent;
          border: 0;
          outline: none;
          margin: 0;
          padding: 0;
          cursor: pointer;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #B42318;
          margin-top: 4px;
        }

        &_remove {
          background: transparent;
          border: 0;
          outline: none;
          margin: 0;
          padding: 0;
          cursor: pointer;
          
          svg {
            stroke: #B42318;
          }
        }
        
        &.-error {
          background: #FFFBFA;
          border: 1px solid #FDA29B;
        }
        
        &.-progress {
          border: 1px solid #E4E7EC;
        }
      }
    }
    
    &-btns {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 16px;
      
      &.-hidden {
        display: none;
      }
    }

    &-btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 9px 15px;
    }
  }
  
  .beneficial {
    &-title {
      display: block;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: var(--black);

      &__wrap {
        margin-top: 32px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--dark-white);
      }
    }

    &-desc {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray);
      margin-top: 4px;
    }

    &-container {}

    &-row {
      margin-top: 25px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--dark-white);
    }

    &-name {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--dark-gray);
    }

    &-input {}
    &-textarea {
      textarea {
        height: 92px;
      }
    }

    &-btns {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 16px;
    }

    &-btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 9px 15px;
    }
  }

  .tfa {
    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: var(--black);

      &__wrap {
        margin-top: 31px;
        padding-bottom: 19px;
        border-bottom: 1px solid var(--dark-white);
      }
    }

    &-wrap {
      margin-top: 24px;
      padding-bottom: 9px;
      border-bottom: 1px solid var(--dark-white);
    }

    &-row {

    }

    &-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      color: var(--dark-gray);
    }
    
    &-qrcode {
      
    }
    
    &-copy {
      display: flex;
      min-width: 370px;
    }

    &-code {
      width: 48px;
      height: 48px;
      border: 1px solid var(--light-gray);
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: var(--border-radius);
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      letter-spacing: -0.02em;
      color: var(--purple);
      outline: none;

      &::placeholder {
        color: var(--light-gray);
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
      }

      &:focus {
        &::placeholder {
          opacity: 0;
        }
      }

      &__wrap {
        margin-top: 6px;
      }

      &__container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
      }
    }

    &-btns {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 16px;
    }

    &-btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 9px 15px;
    }
  }

  .advanced {
    &-title {
      display: block;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: var(--black);

      &__wrap {
        margin-top: 32px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--dark-white);
      }
    }

    &-desc {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray);
      margin-top: 4px;
    }

    &-container {}

    &-row {
      margin-top: 25px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--dark-white);
    }

    &-name {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--dark-gray);
    }

    &-input {}
    &-textarea {
      textarea {
        height: 92px;
      }
    }

    &-btns {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 16px;
    }

    &-btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 9px 15px;
    }
  }
`;

export const SettingsSlider: any = styled(Slider)`
  &.MuiSlider-root {
    border-radius: 4px;
    height: 8px;
  }
  
  .MuiSlider-rail {
    background-color: var(--dark-white);
    opacity: 1;
  }
  
  .MuiSlider-track {
    background-color: var(--purple);
  }
  
  .MuiSlider-thumb {
    background: var(--white);
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 4px 20px -2px rgba(50, 50, 71, 0.08);
    
    &:hover {
      box-shadow: none;
    } 
    
    &.Mui-focusVisible {
      box-shadow: none;
    }

    &.Mui-active {
      box-shadow: none;
    }
  }
`;

export const FileLoader: any = styled.div<{ progress?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({ progress }) => progress ? progress + '%' : '0'};
  background: #F9FAFB;
`;
