import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { connect } from 'react-redux';
import {
  Container,
  MainContent,
  Button,
  Skeleton,
  // Switch,
  Icon, Loader
} from '../../elements';
import {DevelopersContainer} from "./Developers.Styles";
import {Link, useNavigate} from "react-router-dom";
// import {FormControlLabel} from "@mui/material";
import {closeModal, openModal} from "../../store/app/actions";
import cloneDeep from "lodash/cloneDeep";
import dayjs from "dayjs";
import {
  APIKey,
  DeleteAPIKeyParams,
  ShowAPIKeyParams,
  Store,
  Webhook,
  IPWL,
} from "../../api";
import {getAPIKeys, getIPWL, showAPIKey} from "../../store/stores/actions";
import {clear} from "../../store/user/actions";
import {AppStateType} from "../../store";
import {StoresReducerState} from "../../store/stores/reducers";
import {selectLoadingByKey} from "../../store/loadingsErrors/selectors";
import types from "../../store/actionTypes";
import useToast from "../../hooks/useToast";
import findIndex from "lodash/findIndex";
import find from "lodash/find";
import AddKey from "../../components/Modals/AddKey";
import DeleteKey from "../../components/Modals/DeleteKey";
import ShowKey from "../../components/Modals/ShowKey";
import AddEndpoint from "../../components/Modals/AddEndpoint/AddEndpoint";
import DeleteEndpoint from "../../components/Modals/DeleteEndpoint/DeleteEndpoint";
import AddIPWL from "../../components/Modals/AddIPWL/AddIPWL";
import DeleteIPWL from "../../components/Modals/DeleteIPWL/DeleteIPWL";

const SORT_FIELD = {
  name: 'name',
  created_at: 'created_at',
  last_time_used: 'last_time_used',
};

export enum ApiWebhookStatuses {
  ACTIVE = 'Active',
}

// export enum ApiWebhookTypes {
//   DIRECT = 'Direct',
// }

interface Props {
  openModal: (payload: any) => void;
  closeModal: () => void;
  getAPIKeys: (payload: { storeId: string }) => void;
  showAPIKey: (payload: ShowAPIKeyParams) => void;
  getIPWL: (payload: { storeId: string }) => void;
  stores: StoresReducerState;
  loadingAPIKeys: boolean;
  loadingCreateAPIKey: boolean;
  loadingDeleteApiKey: boolean;
  loadingShowApiKey: boolean;
  loadingCreateWebhook: boolean;
  loadingDeleteWebhook: boolean;
  loadingIPWL: boolean;
  loadingUpdateIPWL: boolean;
  clear: () => void;
}

const Developers: React.FC<Props> = (props: Props) => {
  const {
    openModal,
    closeModal,
    getAPIKeys,
    showAPIKey,
    getIPWL,
    stores,
    loadingAPIKeys,
    loadingCreateAPIKey,
    loadingDeleteApiKey,
    loadingShowApiKey,
    loadingCreateWebhook,
    loadingDeleteWebhook,
    loadingIPWL,
    loadingUpdateIPWL,
    clear,
  } = props;
  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();
  const [sortField, setSortField] = useState(SORT_FIELD.created_at)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [activeStore, setActiveStore] = useState<string | null>(null)
  const [apiKeys, setApiKeys] = useState<APIKey[]>([])
  const [webhooks, setWebhooks] = useState<Webhook[]>([])
  const [ipwls, setIpwls] = useState<IPWL[]>([])
  const [deletingProgressApiKey] = useState<string | null>(null)
  const [showProgressApiKey, setShowProgressApiKey] = useState<string | null>(null)
  const [deletingProgressWebhook] = useState<string | null>(null)
  const [hasApiKey, setHasApiKey] = useState<boolean>(false);
  const [deletingProgressIPWL] = useState<string | null>(null)

  const modalAddKey = () => (
    <div className="modal-content">
      <div className="modal-addKey">
        <AddKey closeModal={handleCloseModal} storeId={activeStore || ''} title="Create new API key" description="Please enter a name for this API key" placeholder="API key name" />
      </div>
    </div>
  );

  const modalDeleteKey = ({apiKey}: { apiKey: APIKey }) => (
    <div className="modal-content">
      <div className="modal-addKey">
        <DeleteKey closeModal={handleCloseModal} apiKey={apiKey} storeId={activeStore || ''} title="Delete API key:" description="Please enter a 2FA code for deleting this API key" />
      </div>
    </div>
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const modalShowKey = ({apiKey}: { apiKey: APIKey }) => (
    <div className="modal-content">
      <div className="modal-addKey">
        <ShowKey closeModal={handleCloseModal} applyModal={handleApplyShowModal} apiKey={apiKey} />
      </div>
    </div>
  );

  const modalAddWebhook = () => (
    <div className="modal-content">
      <div className="modal-addKey">
        <AddEndpoint closeModal={handleCloseModal} storeId={activeStore || ''} title="Create new endpoint" description="Please enter a name for this endpoint" placeholder="Endpoint url" inputType="url" />
      </div>
    </div>
  );

  const modalDeleteWebhook = ({webhook}: { webhook: Webhook }) => (
    <div className="modal-content">
      <div className="modal-addKey">
        <DeleteEndpoint closeModal={handleCloseModal} webhook={webhook} storeId={activeStore || ''} title="Delete endpoint" />
      </div>
    </div>
  );

  const modalAddIPWL = () => (
    <div className="modal-content">
      <div className="modal-addKey">
        <AddIPWL closeModal={handleCloseModal} storeId={activeStore || ''} ipwls={ipwls} title="Add new IP address to whitelist" description="Please enter a IP address" placeholder="IP address" inputType="text" />
      </div>
    </div>
  );

  const modalDeleteIPWL = ({ipwl, ipwlIndex}: { ipwl: IPWL, ipwlIndex: number }) => (
    <div className="modal-content">
      <div className="modal-addKey">
        <DeleteIPWL closeModal={handleCloseModal} ipwls={ipwls} ipwl_index={ipwlIndex} ipwl={ipwl} storeId={activeStore || ''} title="Delete IP address" />
      </div>
    </div>
  );

  useEffect(() => {
    if (stores.activeStore) {
      const shop: Store | undefined = find(stores.stores.list, { id: stores.activeStore });
      if (!stores.apiKeys[stores.activeStore]) {
        getAPIKeys({storeId: stores.activeStore})
      }

      if (!stores.ip_wls[stores.activeStore]) {
        getIPWL({storeId: stores.activeStore})
      }

      if (activeStore !== stores.activeStore) {
        setActiveStore(stores.activeStore)
        const newShop: Store | undefined = find(stores.stores.list, { id: stores.activeStore });
        setWebhooks(newShop?.webhook_urls || []);
        setHasApiKey(!!newShop?.has_api_key);
        setIpwls(stores.ip_wls[stores.activeStore] || []);
      }

      if (stores.apiKeys[stores.activeStore] && (stores.apiKeys[stores.activeStore]?.length !== apiKeys.length)) {
        setApiKeys(stores.apiKeys[stores.activeStore]);
        setHasApiKey(!!shop?.has_api_key);
      }

      if (shop && (webhooks?.length !== shop?.webhook_urls?.length)) {
        setWebhooks(shop?.webhook_urls || []);
      }

      if (stores.ip_wls[stores.activeStore] && (stores.ip_wls[stores.activeStore]?.length !== ipwls.length)) {
        setIpwls(stores.ip_wls[stores.activeStore]);
      }
    }

    if (showProgressApiKey) {
      const showedApiKeyIndex: number = findIndex(apiKeys, { id: showProgressApiKey});
      if (apiKeys[showedApiKeyIndex]?.id && stores.apiKeys[activeStore || ''][showedApiKeyIndex]?.key) {
        const newApiKeys = cloneDeep(stores.apiKeys[activeStore || ''])
        setShowProgressApiKey(null);
        setApiKeys(newApiKeys)
        openModal({
          closeModal: closeModal,
          className: "modal modalAddKey",
          content: modalShowKey,
          contentParams: { apiKey: newApiKeys[showedApiKeyIndex] }
        })
      }
    }

    if (stores.answer?.success) {
      toastSuccess(stores.answer?.success);
      clear();
    }

    if (stores.answer?.error?.error) {
      toastError(stores.answer.error.error);
      clear();
    }
  }, [closeModal, openModal, modalShowKey, stores, getAPIKeys, toastSuccess, toastError, clear, setApiKeys, setActiveStore, activeStore, apiKeys, showProgressApiKey, setShowProgressApiKey, hasApiKey, navigate]);

  const sortedApiKeys = useMemo(() => {
    return apiKeys
      ? apiKeys
        .slice()
        .sort((a, b) => {
          if (a && b) {
            return a[sortField as keyof APIKey] > b[sortField as keyof APIKey]
              ? (sortDirection ? -1 : 1) * 1
              : (sortDirection ? -1 : 1) * -1
          }
          return -1
        })
      : []
  }, [sortField, sortDirection, apiKeys])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField],
  )

  const arrow = useCallback(
    (field: string) => {
      const directionArrow = !sortDirection ? '↑' : '↓'
      return sortField === field ? directionArrow : ''
    },
    [sortDirection, sortField],
  )

  const handleOpenModal = (payload: any) => {
    if (!openModal) return
    openModal(payload)
  };

  const handleCloseModal = () => {
    if (!closeModal) return
    closeModal()
  };

  const handleOpenModalDelete = useCallback(
    (e: React.ChangeEvent<any>, payload: any) => {
      e.preventDefault();
      e.stopPropagation();

      if (!openModal) return
      openModal(payload)
    },
    [openModal]
  );

  const handleApplyShowModal = (payload: any) => {
    const tfaData = cloneDeep(payload)
    const showData: DeleteAPIKeyParams = {
      data: tfaData.data,
      storeId: stores.activeStore || '',
      apiKeyId: tfaData.apiKeyId,
    };

    setShowProgressApiKey(showData.apiKeyId);
    showAPIKey(showData);
  };

  const handleOpenModalDeleteWebhook = useCallback(
    (e: React.ChangeEvent<any>, payload: any) => {
      e.preventDefault();
      e.stopPropagation();

      if (!openModal) return
      openModal(payload)
    },
    [openModal]
  );

  const handleOpenModalDeleteIPWL = useCallback(
    (e: React.ChangeEvent<any>, payload: any) => {
      e.preventDefault();
      e.stopPropagation();

      if (!openModal) return
      openModal(payload)
    },
    [openModal]
  );

  return (
    <MainContent className="content-main">
      <Container>
        <DevelopersContainer className="developers">
          <div className="developers-head">
            <span className="developers-head__title">Developers</span>
            <div className="developers-head__btns">
              <Button
                className="developers-head__btn -white"
                type="button"
                as={Link}
                to="https://docs-api.cryptoprocessing.io"
                target="_blank"
              >
                API docs
              </Button>
            </div>
          </div>
          {/*<div className="developers-widgets">*/}
          {/*  <div className="developers-widgets__title_wrap">*/}
          {/*    <span className="developers-widgets__title">CMS widgets</span>*/}
          {/*  </div>*/}
          {/*  <div className="developers-widgets__container">*/}
          {/*    <div className="grid-x grid-margin-x">*/}
          {/*      <div className="cell small-12 xlarge-3">*/}
          {/*        <div className="developers-widgets__item">*/}
          {/*          <Button*/}
          {/*            as={Link}*/}
          {/*            className="developers-widgets__link"*/}
          {/*            type="button"*/}
          {/*          >*/}
          {/*            <img className="developers-widgets__icon" src="/img/widgets/shopify.svg" alt="cp" />*/}
          {/*          </Button>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="cell small-12 xlarge-3">*/}
          {/*        <div className="developers-widgets__item">*/}
          {/*          <Button*/}
          {/*            as={Link}*/}
          {/*            className="developers-widgets__link"*/}
          {/*            type="button"*/}
          {/*          >*/}
          {/*            <img className="developers-widgets__icon" src="/img/widgets/shopify.svg" alt="cp" />*/}
          {/*          </Button>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="cell small-12 xlarge-3">*/}
          {/*        <div className="developers-widgets__item">*/}
          {/*          <Button*/}
          {/*            as={Link}*/}
          {/*            className="developers-widgets__link"*/}
          {/*            type="button"*/}
          {/*          >*/}
          {/*            <img className="developers-widgets__icon" src="/img/widgets/shopify.svg" alt="cp" />*/}
          {/*          </Button>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="cell small-12 xlarge-3">*/}
          {/*        <div className="developers-widgets__item">*/}
          {/*          <Button*/}
          {/*            as={Link}*/}
          {/*            className="developers-widgets__link"*/}
          {/*            type="button"*/}
          {/*          >*/}
          {/*            <img className="developers-widgets__icon" src="/img/widgets/shopify.svg" alt="cp" />*/}
          {/*          </Button>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="grid-x grid-margin-x">*/}
          {/*      <div className="cell small-12 xlarge-6 -big">*/}
          {/*        <div className="developers-widgets__item">*/}
          {/*          <Button*/}
          {/*            as={Link}*/}
          {/*            className="developers-widgets__link"*/}
          {/*            type="button"*/}
          {/*          >*/}
          {/*            <img className="developers-widgets__icon" src="/img/widgets/shopify.svg" alt="cp" />*/}
          {/*          </Button>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="cell small-12 xlarge-6 -big">*/}
          {/*        <div className="developers-widgets__item">*/}
          {/*          <Button*/}
          {/*            as={Link}*/}
          {/*            className="developers-widgets__link"*/}
          {/*            type="button"*/}
          {/*          >*/}
          {/*            <img className="developers-widgets__icon" src="/img/widgets/shopify.svg" alt="cp" />*/}
          {/*          </Button>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="developers-api">
            <div className="developers-api__head">
              <div className="developers-api__title_wrap">
                <span className="developers-api__title">API keys</span>
                <span className="developers-api__desc">Viewing test API keys. Toggle to view live keys.</span>
              </div>
              {/*<div className="developers-api__switch_wrap">*/}
              {/*  <FormControlLabel*/}
              {/*    className="switch"*/}
              {/*    control={*/}
              {/*      <Switch*/}
              {/*        className="developers-api__switch"*/}
              {/*        checked={testData}*/}
              {/*        onChange={() => setTestData(!testData)}*/}
              {/*        name="testData"*/}
              {/*        color="primary"*/}
              {/*      />*/}
              {/*    }*/}
              {/*    label="Viewing test data"*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
            <div className="developers-api__table">
              <div className="developers-api__table_header">
                <span className="developers-api__table_header_title">My keys</span>
                <div className="developers-api__table_header_btns">
                  {/*<Button*/}
                  {/*  className="developers-api__table_header_btn -white -generate"*/}
                  {/*  type="button"*/}
                  {/*>*/}
                  {/*  Generate E-commerce key*/}
                  {/*</Button>*/}
                  <Button
                    className="developers-api__table_header_btn -white -add"
                    type="button"
                    disabled={loadingCreateAPIKey}
                    onClick={
                      () => handleOpenModal({
                        closeModal: closeModal,
                        className: "modal modalAddKey",
                        content: modalAddKey
                      })
                    }
                  >
                    Add key
                    {loadingCreateAPIKey ? <Loader/> : null}
                  </Button>
                </div>
              </div>
              <div className="developers-api__table_head">
                <span
                  className="developers-api__table_head_item"
                  onClick={() => handleSort(SORT_FIELD.name)}
                >
                  Label <span className="developers-api__table_head__arrow">{arrow(SORT_FIELD.name)}</span>
                </span>
                <span
                  className="developers-api__table_head_item"
                  onClick={() => handleSort(SORT_FIELD.last_time_used)}
                >
                  Date Last Used <span
                  className="developers-api__table_head__arrow">{arrow(SORT_FIELD.last_time_used)}</span>
                </span>
                <span
                  className="developers-api__table_head_item"
                  onClick={() => handleSort(SORT_FIELD.created_at)}
                >
                  Date Created <span className="developers-api__table_head__arrow">{arrow(SORT_FIELD.created_at)}</span>
                </span>
                <span className="developers-api__table_head_item"/>
              </div>
              <div className="developers-api__table_body">
                {sortedApiKeys.map((apiKey, index) => {
                  if (apiKey) {
                    return (
                      <DataRow
                        key={`apiKey-${index}`}
                        closeModal={closeModal}
                        apiKey={apiKey}
                        loading={loadingDeleteApiKey || loadingShowApiKey}
                        onDelete={handleOpenModalDelete}
                        deleteContent={modalDeleteKey}
                        deletingProgressApiKey={deletingProgressApiKey}
                        onShow={handleOpenModal}
                        showContent={modalShowKey}
                        showProgressApiKey={showProgressApiKey}
                      />
                    )
                  }
                  return null
                })}

                {sortedApiKeys.length === 0 ? (
                  <span className="developers-api__table_zero">No Api keys</span>
                ) : null}

                {
                  loadingAPIKeys ? <DataRowLoader/> : null
                }
              </div>
            </div>
          </div>

          <div className="developers-webhooks">
            <div className="developers-webhooks__head">
              <div className="developers-webhooks__title_wrap">
                <span className="developers-webhooks__title">Webhooks</span>
              </div>
            </div>
            <div className="developers-webhooks__table">
              <div className="developers-webhooks__table_header">
                <span className="developers-webhooks__table_header_title">Endpoints</span>
                <div className="developers-webhooks__table_header_btns">
                  <Button
                    className="developers-webhooks__table_header_btn -white -add"
                    type="button"
                    disabled={loadingCreateWebhook}
                    onClick={
                      () => handleOpenModal({
                        closeModal: closeModal,
                        className: "modal modalAddKey",
                        content: modalAddWebhook
                      })
                    }
                  >
                    Add endpoint
                    {loadingCreateWebhook ? <Loader/> : null}
                  </Button>
                </div>
              </div>
              <div className="developers-webhooks__table_head">
                <span
                  className="developers-webhooks__table_head_item"
                >
                  URL
                </span>
                <span
                  className="developers-webhooks__table_head_item"
                >
                  Status
                </span>
                <span className="developers-webhooks__table_head_item"/>
              </div>
              <div className="developers-webhooks__table_body">
                {webhooks.map((webhook, index) => {
                  if (webhook) {
                    return (
                      <DataRowWebhook
                        key={`webhook-${index}`}
                        webhook={webhook}
                        closeModal={closeModal}
                        loading={loadingDeleteWebhook}
                        onDelete={handleOpenModalDeleteWebhook}
                        deleteContent={modalDeleteWebhook}
                        deletingProgressWebhook={deletingProgressWebhook}
                      />
                    )
                  }
                  return null
                })}

                {webhooks.length === 0 ? (
                  <span className="developers-webhooks__table_body_zero">No Webhooks</span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="developers-whitelist">
            <div className="developers-whitelist__head">
              <div className="developers-whitelist__title_wrap">
                <span className="developers-whitelist__title">IP whitelist</span>
              </div>
            </div>
            <div className="developers-whitelist__table">
              <div className="developers-whitelist__table_header">
                <span className="developers-whitelist__table_header_title">IP Addresses</span>
                <div className="developers-whitelist__table_header_btns">
                  <Button
                    className="developers-whitelist__table_header_btn -white -add"
                    type="button"
                    disabled={loadingUpdateIPWL}
                    onClick={
                      () => handleOpenModal({
                        closeModal: closeModal,
                        className: "modal modalAddKey",
                        content: modalAddIPWL
                      })
                    }
                  >
                    Add IP Address
                    {loadingUpdateIPWL ? <Loader/> : null}
                  </Button>
                </div>
              </div>
              <div className="developers-whitelist__table_head">
                <span
                  className="developers-whitelist__table_head_item"
                >
                  URL
                </span>
                <span className="developers-whitelist__table_head_item"/>
              </div>
              <div className="developers-whitelist__table_body">
                {ipwls.map((ipwl, index) => {
                  if (ipwl) {
                    return (
                      <DataRowIPWL
                        key={`ip_address-${index}`}
                        ipwl={ipwl}
                        ipwlIndex={index}
                        closeModal={closeModal}
                        loading={loadingIPWL}
                        onDelete={handleOpenModalDeleteIPWL}
                        deleteContent={modalDeleteIPWL}
                        deletingProgressIPWL={deletingProgressIPWL}
                      />
                    )
                  }
                  return null
                })}

                {ipwls.length === 0 ? (
                  <span className="developers-whitelist__table_body_zero">No IP Addresses</span>
                ) : null}
              </div>
            </div>
          </div>

        </DevelopersContainer>
      </Container>
    </MainContent>
  );
};

const DataRow: React.FC<{
  apiKey: APIKey,
  deletingProgressApiKey: string | null,
  showProgressApiKey: string | null,
  loading: boolean,
  onDelete: (e: any, payload: any) => void,
  deleteContent: any,
  onShow: (payload: any) => void,
  showContent: any,
  closeModal: () => void
}> = ({
        apiKey,
        deletingProgressApiKey,
        showProgressApiKey,
        loading,
        onDelete,
        deleteContent,
        onShow,
        showContent,
        closeModal
      }) => {

  return (
    <div
      className={`developers-api__table_body_row ${loading ? '-disabled' : ''} ${showProgressApiKey ? '-show' : ''}`}
      onClick={
        () => onShow({
          closeModal: closeModal,
          className: "modal modalAddKey",
          content: showContent,
          contentParams: {apiKey}
        })
      }
    >
      <span className="developers-api__table_body_item -name">{apiKey.name}</span>
      <span
        className="developers-api__table_body_item">{dayjs(apiKey.last_time_used).isValid() ? dayjs(apiKey.last_time_used).format('MMM D YYYY, h:mm A') : 'Not used yet'}</span>
      <span className="developers-api__table_body_item">{dayjs(apiKey.created_at).format('MMM D YYYY, h:mm A')}</span>
      <button
        className="developers-api__table_body_btn"
        disabled={loading}
        tabIndex={-1}
        type="button"
        onClick={
          (e) => onDelete(e, {
            closeModal: closeModal,
            className: "modal modalAddKey",
            content: deleteContent,
            contentParams: {apiKey}
          })
        }
      >
        {
          loading && deletingProgressApiKey === apiKey.id ? <Loader/> : (
            <>
              <Icon className="developers-api__table_body_btn_icon" name="trash" size="20"/>
              <span className="developers-api__table_body_btn_item">Revoke</span>
            </>
          )
        }

      </button>
    </div>
  )
}

const DataRowLoader: React.FC = () => {
  const loadingRow = (
    <div className="developers-api__loading">
      <Skeleton/>
      <Skeleton/>
      <Skeleton/>
      <Skeleton/>
    </div>
  )
  return (
    <>
      {loadingRow}
      {loadingRow}
      {loadingRow}
      {loadingRow}
    </>
  )
}

const DataRowWebhook: React.FC<{
  webhook: Webhook,
  deletingProgressWebhook: string | null,
  loading: boolean,
  onDelete: (e: any, payload: any) => void,
  deleteContent: any,
  closeModal: () => void
}> = ({webhook, deletingProgressWebhook, loading, onDelete, deleteContent, closeModal}) => {
  return (
    <div className="developers-webhooks__table_body_row">
      <span className="developers-webhooks__table_body_item -name">
        <Link
          className="developers-webhooks__table_body_link"
          target="_blank"
          rel="noreferrer"
          to={webhook}
        >
          {webhook}
        </Link>
      </span>
      <span className="developers-webhooks__table_body_item">
        <span className={`developers-webhooks__table_body_status -${ApiWebhookStatuses.ACTIVE.toLowerCase()}`}>{ApiWebhookStatuses.ACTIVE}</span>
      </span>
      <button
        className="developers-webhooks__table_body_btn"
        disabled={loading}
        type="button"
        tabIndex={-1}
        onClick={
          (e) => onDelete(e,{
            closeModal: closeModal,
            className: "modal modalAddKey",
            content: deleteContent,
            contentParams: { webhook }
          })
        }
      >
        {
          loading && deletingProgressWebhook === webhook ? <Loader /> : (
            <>
              <Icon className="developers-webhooks__table_body_btn_icon" name="trash" size="20" />
              <span className="developers-webhooks__table_body_btn_item">Delete</span>
            </>
          )
        }

      </button>
    </div>
  )
}

const DataRowIPWL: React.FC<{
  ipwl: IPWL,
  ipwlIndex: number,
  deletingProgressIPWL: string | null,
  loading: boolean,
  onDelete: (e: any, payload: any) => void,
  deleteContent: any,
  closeModal: () => void
}> = ({ipwl, ipwlIndex, deletingProgressIPWL, loading, onDelete, deleteContent, closeModal}) => {
  return (
    <div className="developers-whitelist__table_body_row">
      <span className="developers-whitelist__table_body_item -name">
        {ipwl}
      </span>
      <button
        className="developers-whitelist__table_body_btn"
        disabled={loading}
        type="button"
        tabIndex={-1}
        onClick={
          (e) => onDelete(e,{
            closeModal: closeModal,
            className: "modal modalAddKey",
            content: deleteContent,
            contentParams: { ipwl, ipwlIndex }
          })
        }
      >
        {
          loading && deletingProgressIPWL === ipwl ? <Loader /> : (
            <>
              <Icon className="developers-whitelist__table_body_btn_icon" name="trash" size="20" />
              <span className="developers-whitelist__table_body_btn_item">Delete</span>
            </>
          )
        }

      </button>
    </div>
  )
}

const mapStateToProps = (state: AppStateType) => {
  const { stores } = state;
  return {
    stores,
    loadingAPIKeys: selectLoadingByKey(state, types.GET_API_KEYS_REQUEST),
    loadingCreateAPIKey: selectLoadingByKey(state, types.CREATE_API_KEY_REQUEST),
    loadingDeleteApiKey: selectLoadingByKey(state, types.DELETE_API_KEY_REQUEST),
    loadingShowApiKey: selectLoadingByKey(state, types.SHOW_API_KEY_REQUEST),
    loadingCreateWebhook: selectLoadingByKey(state, types.CREATE_WEBHOOK_REQUEST),
    loadingDeleteWebhook: selectLoadingByKey(state, types.DELETE_WEBHOOK_REQUEST),
    loadingIPWL: selectLoadingByKey(state, types.GET_IPWL_REQUEST),
    loadingUpdateIPWL: selectLoadingByKey(state, types.UPDATE_IPWL_REQUEST),
  };
};

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  getAPIKeys,
  showAPIKey,
  getIPWL,
  clear,
})(Developers);
