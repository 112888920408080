import React from 'react';

import { TabsContent, TabsControl, TabsControlItem } from './Tabs.Styles';

export interface Props {
  className: string;
  tabHeaderClassName: string;
  tabsList: any[];
  field: string;
  activeTab: string;
  onChange: (field: string, value: string) => void;
  children: any;
  width?: string;
  marginRight?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  borderColor?: string;
}

export const Tabs: React.FC<Props> = (props: Props) => {
  const {
    className,
    tabHeaderClassName,
    tabsList,
    field,
    activeTab,
    onChange,
    children,
  } = props;

  return (
    <>
      <TabsControl className={`${className}`}>
        <div className="tab-container">
          {tabsList.map((item: any) => (
            <TabsControlItem
              key={`tab-header-item-${item.key}`}
              onClick={() => onChange(field, item.key)}
              className={`${tabHeaderClassName} ${
                activeTab === item.key ? 'active' : ''
              }`}
              tabIndex={activeTab === item.key ? -1 : 0}
            >
              {item.name}
            </TabsControlItem>
          ))}
        </div>
      </TabsControl>
      <TabsContent>{children}</TabsContent>
    </>
  );
};

export default Tabs;
