import {Invoice} from '../../api';
import types, {AppActionTypes} from '../actionTypes';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import {InvoiceStatus} from "../../api/codecs.invoices";
import {DEFAULT_PAGE_LIMIT, LOADING_TYPES} from "../../const/app.constants";

export type PaginatedInvoice = {
  list: Invoice[];
  page: number;
  limit: number;
  total_count: number;
  from_day: string;
  to_day: string;
  status: string;
  sort_by: string;
  direction: string;
  loaded: LOADING_TYPES;
};

export type InvoicesReducerState = {
  invoices: { [key: string]: PaginatedInvoice };
  invoice: {
    item: Invoice | null;
    storeId: string | null;
    loaded: LOADING_TYPES;
  };
  answer: any;
};

const INITIAL_STATE: InvoicesReducerState = {
  invoices: {},
  invoice: {
    item: null,
    storeId: null,
    loaded: LOADING_TYPES.NOT_LOADED
  },
  answer: {},
};

const invoicesReducers = (state = INITIAL_STATE, action: AppActionTypes): InvoicesReducerState => {
  switch (action.type) {
    case types.CREATE_INVOICE_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.CREATE_INVOICE_SUCCESS:
      const createInvoiceSuccess = cloneDeep(state.invoices);
      const oldInvoices = createInvoiceSuccess[action.payload.storeId] || {
        list: [],
        page: 1,
        limit: 10,
        total_count: 0,
        from_day: '',
        to_day: '',
        status: '',
        sort_by: 'created_at',
        direction: 'desc',
        loaded: LOADING_TYPES.LOADED
      };

      oldInvoices.list = action.payload.invoices;
      oldInvoices.page = 1;
      oldInvoices.limit = 10;
      oldInvoices.from_day = '';
      oldInvoices.sort_by = 'created_at';
      oldInvoices.to_day = '';
      oldInvoices.status = '';
      oldInvoices.direction = 'desc';
      oldInvoices.total_count = action.payload.meta.total_count;
      oldInvoices.loaded = LOADING_TYPES.LOADED;
      createInvoiceSuccess[action.payload.storeId] = oldInvoices
      return {
        ...state,
        invoices: createInvoiceSuccess,
        answer: action.payload.answer || {},
      };
    case types.CREATE_INVOICE_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.GET_INVOICES_REQUEST:
      const getInvoicesRequest = cloneDeep(state.invoices);
      getInvoicesRequest[action.payload.storeId || ''] = {
        list: [],
        page: Number(action.payload.page) || 1,
        limit: Number(action.payload.limit) || DEFAULT_PAGE_LIMIT,
        total_count: 0,
        from_day: action.payload.from_day || '',
        to_day: action.payload.to_day || '',
        status: action.payload.status || '',
        sort_by: action.payload.sort_by || 'created_at',
        direction: action.payload.direction || 'desc',
        loaded: LOADING_TYPES.LOADING
      };
      return {
        ...state,
        invoices: getInvoicesRequest,
        answer: {},
      };
    case types.GET_INVOICES_SUCCESS:
      const getInvoicesSuccess = cloneDeep(state.invoices);
      const newGetInvoicesSuccess = getInvoicesSuccess[action.payload.storeId || ''];
      newGetInvoicesSuccess.list = action.payload.invoices;
      newGetInvoicesSuccess.total_count = action.payload.meta.total_count;
      newGetInvoicesSuccess.loaded = LOADING_TYPES.LOADED;

      getInvoicesSuccess[action.payload.storeId || ''] = newGetInvoicesSuccess;

      return {
        ...state,
        invoices: getInvoicesSuccess,
      };
    case types.GET_INVOICES_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.GET_INVOICE_REQUEST:
      return {
        ...state,
        invoice: {
          item: null,
          storeId: null,
          loaded: LOADING_TYPES.LOADING
        },
        answer: {},
      };
    case types.GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: {
          item: action.payload.invoice,
          storeId: action.payload.storeId,
          loaded: LOADING_TYPES.LOADED
        },
      };
    case types.GET_INVOICE_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.MAKE_PAID_INVOICE_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.MAKE_PAID_INVOICE_SUCCESS:
      const makePaidInvoicesSuccess = cloneDeep(state.invoices);
      const makePaidInvoiceSuccess = cloneDeep(state.invoice);
      const makePaidInvoiceIndex: number = findIndex(makePaidInvoicesSuccess[action.payload.storeId].list, { id: action.payload.invoiceId});
      makePaidInvoicesSuccess[action.payload.storeId].list[makePaidInvoiceIndex].status = InvoiceStatus.PAID;
      makePaidInvoicesSuccess[action.payload.storeId].list[makePaidInvoiceIndex].received_crypto_amount = makePaidInvoicesSuccess[action.payload.storeId].list[makePaidInvoiceIndex].amount;
      makePaidInvoicesSuccess[action.payload.storeId].list[makePaidInvoiceIndex].received_crypto_currency = 'USDTTRX';
      if (makePaidInvoiceSuccess.item) {
        makePaidInvoiceSuccess.item.status = InvoiceStatus.PAID;
        makePaidInvoiceSuccess.item.received_crypto_amount = makePaidInvoiceSuccess.item.amount;
        makePaidInvoiceSuccess.item.received_crypto_currency = 'USDTTRX';
      }

      return {
        ...state,
        invoices: makePaidInvoicesSuccess,
        invoice: makePaidInvoiceSuccess,
        answer: action.payload.answer || {},
      };
    case types.MAKE_PAID_INVOICE_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.DEACTIVATE_INVOICE_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.DEACTIVATE_INVOICE_SUCCESS:
      const deactivateInvoiceSuccess = cloneDeep(state.invoice);
      deactivateInvoiceSuccess.item = action.payload.invoice;
      const deactivateInvoicesSuccess = cloneDeep(state.invoices);
      if (Object.keys(deactivateInvoicesSuccess).length) {
        const deactivateInvoicesIndex: number = findIndex(deactivateInvoicesSuccess[action.payload.storeId].list, { id: action.payload.invoiceId});
        deactivateInvoicesSuccess[action.payload.storeId].list[deactivateInvoicesIndex].status = InvoiceStatus.CANCELLED;
      }
      return {
        ...state,
        invoice: deactivateInvoiceSuccess,
        invoices: deactivateInvoicesSuccess,
        answer: action.payload.answer || {},
      };
    case types.DEACTIVATE_INVOICE_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.SET_INVOICES_LOADING:
      const setInvoicesLoading = cloneDeep(state.invoices);
      setInvoicesLoading[action.payload.storeId || ''] = {
        list: [],
        page: Number(action.payload.page) || 1,
        limit: Number(action.payload.limit) || DEFAULT_PAGE_LIMIT,
        total_count: 0,
        from_day: action.payload.from_day || '',
        to_day: action.payload.to_day || '',
        status: action.payload.status || '',
        sort_by: action.payload.sort_by || 'created_at',
        direction: action.payload.direction || 'desc',
        loaded: LOADING_TYPES.NOT_LOADED
      };
      return {
        ...state,
        invoices: setInvoicesLoading,
      };
    case types.CLEAR:
      return {
        ...state,
        answer: {},
      };
    case types.LOGOUT_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default invoicesReducers;
