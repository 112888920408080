import React, {useCallback, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import AuthCode from 'react-auth-code-input';

import {
  Container,
  MainContent,
  BreadCrumb, Button, Select, Input, Icon, Loader
} from '../../../elements';
import { AppStateType } from '../../../store';
import {WithdrawContainer} from "../Home.Styles";
import {
  CalcFeeParams,
  CreateWithdrawalParams,
  CreateWithdrawalRequisiteParams,
  DeleteWithdrawalRequisiteParams,
  Store,
  WithdrawalRequisite
} from "../../../api";
import cloneDeep from "lodash/cloneDeep";
import {selectLoadingByKey} from "../../../store/loadingsErrors/selectors";
import types from "../../../store/actionTypes";
import useToast from "../../../hooks/useToast";
import {closeModal, openModal} from "../../../store/app/actions";
import {
  createWithdrawalRequisite,
  deleteWithdrawalRequisite,
  getWithdrawalRequisites
} from "../../../store/stores/actions";
import {clear} from "../../../store/user/actions";
import {StoresReducerState} from "../../../store/stores/reducers";
import {LOADING_TYPES} from "../../../const/app.constants";
import find from "lodash/find";
import {createWithdrawal} from "../../../store/withdrawals/actions";
import {WithdrawalsReducerState} from "../../../store/withdrawals/reducers";
import {formatNumber} from "../../../common/utils/formatters";
import CreateWithdrawalRequisite from "../../../components/Modals/CreateWithdrawalRequisite";
import {PATHS} from "../../../const/paths.constants";
import {useNavigate} from "react-router-dom";
import {FeeTypeTypes} from "../../../api/codecs.invoices";
import API from "../../../api/executor";

type WithdrawParams = {
  amount: string;
  address: string;
  tfa_code: string;
};

export interface WithdrawProps {
  withdrawals: WithdrawalsReducerState;
  stores: StoresReducerState;
  loading: boolean;
  loadingWithdrawalRequisites: boolean;
  loadingCreateWithdrawalRequisite: boolean;
  loadingDeleteWithdrawalRequisite: boolean;
  openModal: (payload: any) => void;
  closeModal: () => void;
  createWithdrawalRequisite: (payload: CreateWithdrawalRequisiteParams) => void;
  getWithdrawalRequisites: (payload: { storeId: string }) => void;
  deleteWithdrawalRequisite: (payload: DeleteWithdrawalRequisiteParams) => void;
  createWithdrawal: (payload: CreateWithdrawalParams) => void;
  clear: () => void;
}

const Withdraw: React.FC<WithdrawProps> = (props: WithdrawProps) => {
  const { stores, withdrawals, openModal, closeModal, createWithdrawalRequisite, getWithdrawalRequisites, deleteWithdrawalRequisite, createWithdrawal, clear, loading, loadingWithdrawalRequisites, loadingCreateWithdrawalRequisite, loadingDeleteWithdrawalRequisite } = props;

  // const amountList = [
  //   {
  //     value: "usd",
  //     text: "USD"
  //   },
  //   {
  //     value: "eur",
  //     text: "EUR"
  //   },
  // ];

  // const amountPrefixList = {
  //   usd: "$",
  //   eur: "€"
  // };

  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();
  const [values, setValues] = useState<{ [key: string]: string }>({
    amount: '',
    address: '',
    tfa_code: ''
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  // const [amountCurrency, setAmountCurrency] = useState<string>(amountList[0].value);
  const [activeShop, setActiveShop] = useState<Store | undefined>(undefined);
  const [lastFeeParams, setLastFeeParams] = useState<{ [key: string]: number }>({
    amount: 0
  });
  const [priceWithFeeLoading, setPriceWithFeeLoading] = useState<boolean>(false);
  const [priceWithFee, setPriceWithFee] = useState<string>(formatNumber(0));
  const modalAddWithdrawalRequisite = () => (
    <div className="modal-content">
      <div className="modal-addWithdrawal">
        <CreateWithdrawalRequisite closeModal={handleCloseModal} applyModal={handleApplyModal} />
      </div>
    </div>
  );

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: WithdrawParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  useEffect(() => {
    if (stores.stores.loaded === LOADING_TYPES.LOADED) {
      if (stores.activeStore) {
        const shop: Store | undefined = find(stores.stores.list, { id: stores.activeStore });

        if (stores.activeStore !== activeShop?.id) {
          setActiveShop(shop);
        }

        if (shop?.loadedWithdrawals === LOADING_TYPES.NOT_LOADED) {
          getWithdrawalRequisites({storeId: stores.activeStore});
        }

        if ((shop?.loadedWithdrawals === LOADING_TYPES.LOADED) && shop.withdrawals?.length !== activeShop?.withdrawals?.length) {
          const newWithdrawalsReq = shop?.withdrawals?.filter((withdrawalsReq) => {
            const wq: any = find(activeShop?.withdrawals, { id: withdrawalsReq.id });
            return !wq
          })

          if (newWithdrawalsReq?.length === 1) {
            onChange('address', newWithdrawalsReq[0].address)
          }
          setActiveShop(shop);
        }
      }
    }

    if (stores.answer?.success) {
      toastSuccess(stores.answer?.success);
      clear();
    }

    if (stores.answer.error?.error) {
      toastError(String(stores.answer.error?.error));
      clear();
    }

    if (withdrawals.answer?.success) {
      toastSuccess(withdrawals.answer?.success);
      navigate(PATHS.DASHBOARD.replace(':store', `${stores.activeStore}`));
      clear();
    }

    if (withdrawals.answer?.error) {
      if (withdrawals.answer.error.error) {
        toastError(String(withdrawals.answer.error.error));
      }

      const newErrors: WithdrawParams = {
        amount: '',
        address: '',
        tfa_code: ''
      };
      Object.keys(withdrawals.answer.error).forEach((key: string) => {
        newErrors[key] = withdrawals.answer.error[key];
      });

      setErrors(newErrors);

      clear();
    }
  }, [stores, withdrawals, clear, toastSuccess, toastError, setValues, activeShop, setActiveShop, getWithdrawalRequisites, navigate, onChange]);

  const handleOpenModal = (payload: any) => {
    if (!openModal) return
    openModal(payload)
  };

  const handleCloseModal = () => {
    if (!closeModal) return
    closeModal()
  };

  const handleApplyModal = (payload: any) => {
    if (!closeModal) return
    closeModal();
    const newWithdrawal = cloneDeep(payload)
    const createData: CreateWithdrawalRequisiteParams = {
      data: {
        tfa_code: newWithdrawal.tfa_code,
        withdrawal_requisite: {
          name: newWithdrawal.name,
          currency: newWithdrawal.currency,
          address: newWithdrawal.address
        },
        required: newWithdrawal.required
      },
      storeId: stores.activeStore || ''
    };

    createWithdrawalRequisite(createData);
  };

  const handleDeleteWithdrawal = (name: string, value: string) => {
    const withdrawalRequisite: WithdrawalRequisite | undefined = find(activeShop?.withdrawals, { address: value });

    if (withdrawalRequisite) {
      const deleteData: DeleteWithdrawalRequisiteParams = {
        storeId: stores.activeStore || '',
        withdrawalId: withdrawalRequisite.id
      };

      deleteWithdrawalRequisite(deleteData);
    }

  };

  let getFormErrors: (data: { [p: string]: string }) => WithdrawParams;
  getFormErrors = (data: { [key: string]: string }) => {
    const {amount, address, tfa_code} = data;
    const newErrors: WithdrawParams = {
      amount: '',
      address: '',
      tfa_code: ''
    };

    if (!amount) newErrors.amount = 'Enter amount';
    if (!address) newErrors.address = 'Select address';
    if (!tfa_code) newErrors.tfa_code = 'Enter two-factor authentification';

    if (Number(priceWithFee) > Number(activeShop?.balance)) newErrors.amount = 'Amount with fee greater than balance';

    return newErrors;
  };

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const handleAuthCode = (res: string) => {
    onChange('tfa_code', res);
  };

  const calcPriceWithFee = async () => {
    const priceWithFeeProps: CalcFeeParams = {
      storeId: stores.activeStore || '',
      feeType: `${FeeTypeTypes.SETTLEMENT}`,
      amount: Number(values.amount),
      currency: 'USD',
    };
    const getPriceWithFeeCall = (payload: CalcFeeParams) => API.call('calcFee', payload);
    setPriceWithFeeLoading(true);
    await getPriceWithFeeCall(priceWithFeeProps).then((feeAmount: any) => {
      setPriceWithFee(formatNumber(feeAmount));
      setPriceWithFeeLoading(false);
    })
  }

  const onBlur = (field: string, value?: number | string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: WithdrawParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }

    if (field === 'amount') {
      if (Number(lastFeeParams[field]) !== Number(value)) {
        calcPriceWithFee().then(() => {
          setLastFeeParams(prev => ({
            ...prev,
            [field]: Number(value),
          }))
        });
      }
    }
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: WithdrawParams = getFormErrors(values);
      setErrors(newErrors);

      const withdrawalRequisite: WithdrawalRequisite | undefined = find(activeShop?.withdrawals, { address: values.address });

      if (withdrawalRequisite) {
        const data: CreateWithdrawalParams = {
          storeId: stores.activeStore || '',
          data: {
            tfa_code: values.tfa_code,
            withdrawal: {
              amount: priceWithFee,
              withdrawal_requisite_id: withdrawalRequisite.id,
            }
          }
        };

        if (!checkErrors(newErrors)) {
          createWithdrawal(data);
        }
      }
    },
    [values, stores.activeStore, createWithdrawal, getFormErrors, activeShop]
  );

  return (
    <MainContent className="content-main">
      <Container>
        <BreadCrumb />
        <WithdrawContainer>
          <div className="balance">
            <div className="balance-token">
              <img className="balance-token__img" src="/img/tokens/usdx.svg" alt="Tier" />
              <div className="balance-token__amounts">
                <span className="balance-token__amount">{formatNumber(activeShop?.balance || 0)} USDX</span>
                <span className="balance-token__amount -usd">{formatNumber(activeShop?.balance || 0)} USD</span>
              </div>
            </div>
          </div>
          <form onSubmit={onSubmit}>
            <div className="grid-x withdraw">
              <div className="cell small-12 xlarge-55">

                <Input
                  className='withdraw-input'
                  type="text"
                  name="amount"
                  value={values.amount}
                  placeholder="100.00"
                  label="Amount"
                  error={errors.amount}
                  disabled={priceWithFeeLoading}
                  onChange={onChange}
                  onBlur={onBlur}
                />

                {/*<InputWithSelect*/}
                {/*  className="withdraw-input"*/}
                {/*  name="amount"*/}
                {/*  value={values.amount}*/}
                {/*  placeholder="100.00"*/}
                {/*  error={errors.amount}*/}
                {/*  list={amountList}*/}
                {/*  label="Amount"*/}
                {/*  onChange={onChange}*/}
                {/*  fullWidth*/}
                {/*  prefix={amountPrefixList[amountCurrency]}*/}
                {/*  onSelectChange={(field: string, value: string) => setAmountCurrency(value)}*/}
                {/*  selectName="amountCurrency"*/}
                {/*  selectValue={amountCurrency}*/}
                {/*/>*/}

                {/*<Select*/}
                {/*  className="withdraw-select"*/}
                {/*  name="currency"*/}
                {/*  value={values.currency}*/}
                {/*  placeholder="Currency"*/}
                {/*  error={errors.currency}*/}
                {/*  list={currenciesList}*/}
                {/*  image="/img/tokens/usdx.svg"*/}
                {/*  label="Currency"*/}
                {/*  fullWidth*/}
                {/*  onChange={onChange}*/}
                {/*/>*/}
                <Select
                  className="withdraw-select"
                  name="address"
                  value={values.address}
                  placeholder="Paste address"
                  error=""
                  list={activeShop?.withdrawals?.length ? activeShop?.withdrawals.map((wr: WithdrawalRequisite) => {
                    return {
                      value: wr.address,
                      text: `${wr.address} (${wr.currency})`
                    }
                  }) : []}
                  label="Your address"
                  disabled={loadingWithdrawalRequisites || loadingCreateWithdrawalRequisite || loadingDeleteWithdrawalRequisite || !activeShop?.withdrawals?.length}
                  fullWidth
                  onChange={onChange}
                  onRemove={handleDeleteWithdrawal}
                />

                <button
                  className="withdraw-input__btn"
                  onClick={
                    () => handleOpenModal({
                      closeModal: closeModal,
                      className: "modal modalWithdrawal",
                      content: modalAddWithdrawalRequisite
                    })
                  }
                  type="button"
                >
                  <Icon name="plus" size="14" />
                  <span className="withdraw-input__text">Add new address</span>
                </button>

                {/*{*/}
                {/*  newAddress ? (*/}
                {/*    <div className='withdraw-input__wrap -new'>*/}
                {/*      <Input*/}
                {/*        className='withdraw-input'*/}
                {/*        type="text"*/}
                {/*        name="address"*/}
                {/*        value={values.address}*/}
                {/*        placeholder="New address"*/}
                {/*        error={errors.address}*/}
                {/*        onChange={onChange}*/}
                {/*        onBlur={onBlur}*/}
                {/*      />*/}
                {/*      <button*/}
                {/*        className="withdraw-input__btn -fixed"*/}
                {/*        onClick={() => setNewAddress(false)}*/}
                {/*        type="button"*/}
                {/*      >*/}
                {/*        <Icon name="trash" size="20" />*/}
                {/*      </button>*/}
                {/*    </div>*/}
                {/*  ) : (*/}
                {/*    <button*/}
                {/*      className="withdraw-input__btn"*/}
                {/*      onClick={*/}
                {/*        () => handleOpenModal({*/}
                {/*          closeModal: closeModal,*/}
                {/*          className: "modal modalWithdrawal",*/}
                {/*          content: modalAddWithdrawalRequisite*/}
                {/*        })*/}
                {/*      }*/}
                {/*      type="button"*/}
                {/*    >*/}
                {/*      <Icon name="plus" size="14" />*/}
                {/*      <span className="withdraw-input__text">Add new address</span>*/}
                {/*    </button>*/}
                {/*  )*/}
                {/*}*/}
              </div>
            </div>
            <div className="withdraw-total">
              <div className="grid-x withdraw">
                <div className="cell small-12 xlarge-55">
                  <div className="withdraw-total__row">
                    <span className="withdraw-total__title">Fee</span>
                    {
                      priceWithFeeLoading ? (
                        <span className="withdraw-total__amount"><Loader /></span>
                      ) : (
                        <span className="withdraw-total__amount">{formatNumber(Number(lastFeeParams.amount) - Number(priceWithFee))} USD</span>
                      )
                    }
                  </div>
                  <div className="withdraw-total__row">
                    <span className="withdraw-total__title">Total</span>
                    {
                      priceWithFeeLoading ? (
                        <span className="withdraw-total__amount"><Loader /></span>
                      ) : (
                        <span className="withdraw-total__amount">{priceWithFee} USD</span>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="withdraw-auth">
              <span className="withdraw-auth__title">Two-factor authentification</span>
              <div className="withdraw-code__wrap">
                <AuthCode
                  containerClassName="withdraw-code__container"
                  inputClassName="withdraw-code"
                  allowedCharacters="numeric"
                  length={6}
                  placeholder="0"
                  autoFocus={false}
                  onChange={handleAuthCode}
                />
              </div>

              <Button
                className="withdraw-btn loading-btn"
                type="submit"
                disabled={loading || !Number(priceWithFee) || !values.address || !values.tfa_code}
              >
                Send
                {loading ? <Loader /> : null}
              </Button>

              <span className="withdraw-auth__text">After you confirm the transfer with 2FA, the funds will be sent in 10 minutes</span>
            </div>
          </form>

        </WithdrawContainer>
      </Container>
    </MainContent>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { withdrawals, stores } = state;
  return {
    withdrawals,
    stores,
    loading: selectLoadingByKey(state, types.CREATE_WITHDRAWAL_REQUEST),
    loadingWithdrawalRequisites: selectLoadingByKey(state, types.GET_WITHDRAWALS_REQUISITES_REQUEST),
    loadingCreateWithdrawalRequisite: selectLoadingByKey(state, types.CREATE_WITHDRAWAL_REQUISITES_REQUEST),
    loadingDeleteWithdrawalRequisite: selectLoadingByKey(state, types.DELETE_WITHDRAWAL_REQUISITES_REQUEST),
  };
};

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  createWithdrawalRequisite,
  getWithdrawalRequisites,
  deleteWithdrawalRequisite,
  createWithdrawal,
  clear
})(Withdraw);
