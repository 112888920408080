import { makeHandler } from './makeHandler';

export const createInvoice = makeHandler(
  'createInvoice',
  (data: CreateInvoicesParams) => ({
    method: 'POST',
    path: `/stores/${data.storeId}/invoices`,
    data: data.body,
  }),
  (res: CreateInvoiceResponse) => res.data
);

export const getInvoices = makeHandler(
  'getInvoices',
  (data: GetInvoicesParams) => ({
    method: 'GET',
    path: `/stores/${data.storeId}/invoices?status=${data.status}&from_day=${data.from_day}&to_day=${data.to_day}&limit=${data.limit}&page=${data.page}&sort_by=${data.sort_by}&sort_order=${data.direction}`,
  }),
  (res: GetInvoicesResponse) => res
);

export const getInvoice = makeHandler(
  'getInvoice',
  (data: GetInvoiceParams) => ({
    method: 'GET',
    path: `/stores/${data.storeId}/invoices/${data.invoiceId}`,
  }),
  (res: CreateInvoiceResponse) => res.data
);

export const makePaidInvoice = makeHandler(
  'makePaidInvoice',
  (data: MakePaidInvoiceParams) => ({
    method: 'POST',
    path: `/stores/${data.storeId}/invoices/${data.invoiceId}/make_paid`
  }),
  (res: {data: {}}) => res.data
);

export const deactivateInvoice = makeHandler(
  'deactivateInvoice',
  (data: DeactivateInvoiceParams) => ({
    method: 'PUT',
    path: `/stores/${data.storeId}/invoices/${data.invoiceId}/deactivate`
  }),
  (res: {data: {}}) => res.data
);

export const calcFee = makeHandler(
  'calcFee',
  (data: CalcFeeParams) => ({
    method: 'GET',
    path: `/stores/${data.storeId}/calc_fee?fee_types=${data.feeType}&amount=${data.amount}&currency=${data.currency}`
  }),
  (res: {data: CalcFeeResponse}) => res.data.result
);

export enum InvoiceStatus {
  NEW = 'NEW',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
}

export type Invoice = {
  id?: string;
  amount: string;
  received_crypto_amount: string;
  received_crypto_currency: string;
  btc_address?: string;
  btc_confirmations_count?: number;
  currency: string;
  customer_email: string;
  daieth_address?: string;
  daieth_confirmations_count?: number;
  number?: string;
  status?: InvoiceStatus;
  trx_address?: string;
  trx_confirmations_count?: number;
  usdceth_address?: string;
  usdceth_confirmations_count?: number;
  usdteth_address?: string;
  usdteth_confirmations_count?: number;
  usdtrx_confirmations_count?: number;
  created_at?: string;
  data?: CreateInvoicesParamsData
};

export type InvoiceMeta = {
  limit: number;
  page: number;
  total_count: number;
}

export type CreateInvoiceParams = {
  storeId: string;
  data: Invoice
};

export type CreateInvoiceResponse = {
  data: Invoice
};

export type GetInvoicesParams = {
  storeId?: string;
  status: string;
  from_day: string;
  to_day: string;
  limit: string | number;
  page: string | number;
  sort_by: string;
  direction: string;
};

export type GetInvoicesResponse = {
  data: Invoice[]
  meta: InvoiceMeta
};

export type MakePaidInvoiceParams = {
  storeId: string;
  invoiceId: string;
};

export type DeactivateInvoiceParams = {
  storeId: string;
  invoiceId: string;
}

export type CreateInvoicesParamsData = {
  customer_name: string;
  customer_address: string;
  address: string;
  notes: string;
  due_date: string;
  discount: string;
  items?: any
};

export type CreateInvoicesParamsBody = {
  customer_email: string;
  currency: string;
  amount: number;
  data: CreateInvoicesParamsData
};

export type CreateInvoicesParams = {
  storeId: string;
  body: CreateInvoicesParamsBody
};

export type GetInvoiceParams = {
  storeId: string;
  invoiceId: string;
};

export enum FeeTypeTypes {
  DISPLAY = 'display',
  DEPOSIT = 'deposit',
  CREDIT = 'credit',
  SETTLEMENT = 'settlement'
}

export type CalcFeeParams = {
  storeId: string;
  feeType: string;
  amount: number;
  currency: string;
}

export type CalcFeeResponse = {
  result: string;
}

