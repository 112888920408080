import appTypes, { LoadingActionTypes } from './app/actionTypes';
import loadingErrorsTypes, { LoadingErrorsTypes } from './loadingsErrors/actionTypes';
import usersTypes, { UserActionTypes } from './user/actionTypes';
import storesTypes, { StoresActionTypes } from './stores/actionTypes';
import invoicesTypes, { InvoicesActionTypes } from './invoices/actionTypes';
import withdrawalsTypes, { WithdrawalsActionTypes } from './withdrawals/actionTypes';
import walletTypes, { WalletActionTypes } from './wallet/actionTypes';

const appActionTypes = {
  ...appTypes,
  ...loadingErrorsTypes,
  ...usersTypes,
  ...storesTypes,
  ...invoicesTypes,
  ...withdrawalsTypes,
  ...walletTypes
} as const;

export type AppActionTypes =
  | LoadingActionTypes
  | LoadingErrorsTypes
  | UserActionTypes
  | StoresActionTypes
  | InvoicesActionTypes
  | WithdrawalsActionTypes
  | WalletActionTypes;

export default appActionTypes;
