import styled from 'styled-components';

export const ConfirmEmailStyles: any = styled.div`
  position: relative;
  min-height: 100vh;
  min-width: 1030px;
  display: flex;
  font-family: var(--inter);

  .confirmEmail {
    &-container {
      position: relative;
      flex: 1;
      background: var(--white);
    }
    
    &-grid {
      display: flex;
      height: 100%;
      flex-direction: column;
    }
    
    &-top {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-top: 2rem;
    }
    
    &-logo {
      
    }
    
    &-center {
      display: flex;
      position: relative;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
    }
    
    &-box {
      display: flex;
      flex-direction: column;
      min-width: 360px;
      width: 55%;
    }
    
    &-title {
      
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 30px;
    }
    
    &-entrance {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      p {
        margin: 0;
        color: var(--gray);
      }

      a {
        color: var(--dark-purple);
        margin-left: 4px;

        &:focus {
          text-shadow: 1px 1px 2px #E0EAFF;
        }
      }
    }
    
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 2rem;
      
      &__copyright {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray);
        
        &_wrap {
          
        }
      }
      
      &__link {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray);
        
        &_wrap {
          z-index: 10;
        }
      }
    }
    
    &-image {
      display: flex;
      position: relative;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex: 1;
      background: linear-gradient(45deg, #101828 0%, #475467 100%);
      
      &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      
      &__box {
        display: flex;
        flex-direction: column;
        width: 73%;
        min-width: 400px;
      }
      
      &__star {
        width: 80px;
        height: 80px;
      }
      
      &__title {
        font-weight: 500;
        font-size: 48px;
        line-height: 60px;
        letter-spacing: -0.02em;
        color: var(--white);
        margin-top: 32px;
      }

      &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: var(--dark-white);
        margin-top: 24px;
      }
      &__avatars {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 30px;
        position: relative;
        
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--dark-white);
          margin-top: 9px;
        }
      }

      &__avatar {
        margin-right: 30px;
        
        &_img {
          width: 43px;
          height: 43px;
          margin-right: -15px;
        }
      }
      
      &__arrow {
        width: 333px;
        height: 100px;
        position: absolute;
        top: 58px;
        left: -243px;
      }
    }

    &-input {
      margin-top: 20px;
    }

    &-tfa {
      &__code {
        width: 48px;
        height: 48px;
        border: 1px solid var(--light-gray);
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: var(--border-radius);
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        letter-spacing: -0.02em;
        color: var(--purple);
        outline: none;

        &::placeholder {
          color: var(--light-gray);
          font-weight: 500;
          font-size: 36px;
          line-height: 44px;
          transition: opacity 0.3s ease-in-out;
          opacity: 1;
        }

        &:focus {
          &::placeholder {
            opacity: 0;
          }
        }

        &__wrap {
          margin-top: 6px;
        }

        &_container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          margin-top: 20px;
        }
      }
    }
    
    &-resend {
      margin-top: 32px;
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      p {
        margin: 0;
        color: var(--gray);
      }
      
      &__purple {
        color: var(--dark-purple);
      }
      
      &__btn {
        color: var(--dark-purple);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        background: transparent;
        border: 0;
        box-shadow: none;
        padding: 0;
        margin: 0;
        margin-left: 4px;
      }
    }
  }

  @media only screen and (max-width: 85.3125em) {
    .confirmEmail {
      &-image {
        &__arrow {
          display: none;
        }
      }
    }
  }
`;
