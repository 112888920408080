import React, {useCallback, useState, useEffect} from 'react';
import { connect } from 'react-redux';

import {
  Container,
  MainContent,
  Button,
  Loader,
  Input,
} from '../../../elements';
import { ConfirmationEmailStyles } from './ConfirmationEmail.Styles';
import {ConfirmUpdateEmailCodeParams} from "../../../api";
import {useNavigate} from "react-router-dom";
import {PATHS} from "../../../const/paths.constants";
import { AppStateType } from '../../../store';
import types from '../../../store/actionTypes';
import { selectLoadingByKey } from '../../../store/loadingsErrors/selectors';
import {clear, confirmChangeEmail} from '../../../store/user/actions';
import {UserReducerState} from "../../../store/user/reducers";
import useToast from "../../../hooks/useToast";
import cloneDeep from "lodash/cloneDeep";

export interface ConfirmEmailProps {
  user: UserReducerState;
  confirmChangeEmail: (payload: ConfirmUpdateEmailCodeParams) => void;
  clear: () => void;
  loading: boolean;
}

const ConfirmationEmail: React.FC<ConfirmEmailProps> = (props: ConfirmEmailProps) => {
  const { user, confirmChangeEmail, clear, loading } = props;
  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();

  const [values, setValues] = useState<{ [key: string]: string }>({
    old_email_confirmation_token: '',
    new_email_confirmation_token: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [timer, setTimer] = useState<number>(0);

  const setInitialData = () => {
    setValues(prev => ({
      ...prev,
      old_email_confirmation_token: '',
      new_email_confirmation_token: '',
    }));
  }

  let getFormErrors: (data: { [p: string]: string }) => ConfirmUpdateEmailCodeParams;
  getFormErrors = (data: { [key: string]: string }) => {
    const {old_email_confirmation_token, new_email_confirmation_token} = data;
    const newErrors: ConfirmUpdateEmailCodeParams = {
      old_email_confirmation_token: '',
      new_email_confirmation_token: '',
    };

    if (!user.profile.email?.includes('cryptoprocessing.io')) {
      if (!old_email_confirmation_token) newErrors.old_email_confirmation_token = 'Enter old email confirmation code';
    }
    if (!new_email_confirmation_token) newErrors.new_email_confirmation_token = 'Enter new email confirmation code';

    return newErrors;
  };

  const onSubmit = useCallback(
    (e?: React.ChangeEvent<any>) => {
      e?.preventDefault();
      const newErrors: ConfirmUpdateEmailCodeParams = getFormErrors(values);
      setErrors(newErrors);

      const confirmData: ConfirmUpdateEmailCodeParams = {
        new_email_confirmation_token: values.new_email_confirmation_token,
      };

      if (!user.profile.email?.includes('cryptoprocessing.io')) {
        confirmData.old_email_confirmation_token = values.old_email_confirmation_token;
      }

      if (!checkErrors(newErrors)) {
        confirmChangeEmail(confirmData);
      }
    },
    [values, confirmChangeEmail, getFormErrors]
  );

  useEffect(() => {
    if (user.answer?.success) {
      toastSuccess(user.answer?.success);
      navigate(PATHS.SETTINGS);
      clear();
      setInitialData();
    }

    if (user.answer?.error?.old_email_confirmation_token || user.answer?.error?.new_email_confirmation_token) {
      const newErrors: ConfirmUpdateEmailCodeParams = {
        old_email_confirmation_token: '',
        new_email_confirmation_token: ''
      };
      Object.keys(user.answer.error).forEach((key: string) => {
        newErrors[key] = user.answer.error[key];
      });

      setErrors(newErrors);

      clear();
    }

    if (user.answer?.error?.error) {
      toastError(user.answer?.error?.error);
      clear();
    }

    if (user.answer?.error?.base) {
      toastError(user.answer?.error?.base);
      clear();
    }

    if (user.answer?.error?.email) {
      toastError(user.answer?.error?.email);
      navigate(PATHS.SETTINGS);
      clear();
    }
  }, [user, toastError, toastSuccess, clear, navigate]);

  useEffect(() => {
    const s = timer - 1;

    if (s < 1) {
      setTimer(0);
      return () => {};
    }

    const intervalId: ReturnType<typeof setTimeout> = setTimeout(() => {
      setTimer(s);
    }, 1000);

    return () => {
      clearTimeout(intervalId);
    };
  }, [timer]);

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: ConfirmUpdateEmailCodeParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: ConfirmUpdateEmailCodeParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  return (
    <MainContent className="content-main">
      <Container>
        <ConfirmationEmailStyles className='confirmEmail'>
          <div className="confirmEmail-head">
            <span className="confirmEmail-head__title">Email confirmation</span>
          </div>
          <div className="confirmEmail-container">
            <div className="confirmEmail-box">
              <form onSubmit={onSubmit}>
                {
                  !user.profile.email?.includes('cryptoprocessing.io') ? (
                    <Input
                      className='confirmEmail-input'
                      type="text"
                      name="old_email_confirmation_token"
                      value={values.old_email_confirmation_token}
                      placeholder="Enter your old email code"
                      error={errors.old_email_confirmation_token}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  ) : null
                }
                <Input
                  className='confirmEmail-input'
                  type="text"
                  name="new_email_confirmation_token"
                  value={values.new_email_confirmation_token}
                  placeholder="Enter your new email code"
                  error={errors.new_email_confirmation_token}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                <Button
                  className="confirmEmail-btn"
                  type="submit"
                  disabled={loading}
                >
                  Verify
                  {loading ? <Loader /> : null}
                </Button>
              </form>
            </div>
          </div>
        </ConfirmationEmailStyles>
      </Container>
    </MainContent>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
    loading: selectLoadingByKey(state, types.CONFIRM_EMAIL_REQUEST),
  };
};

export default connect(mapState, { confirmChangeEmail, clear })(ConfirmationEmail);
