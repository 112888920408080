import { combineReducers } from 'redux';

import appReducer from './app/reducers';
import loadingErrorsReducer from './loadingsErrors/reducer';
import usersReducers from './user/reducers';
import storesReducers from './stores/reducers';
import invoicesReducers from './invoices/reducers';
import withdrawalsReducers from './withdrawals/reducers';
import walletReducers from './wallet/reducers';

const reducer = combineReducers({
  app: appReducer,
  loadings: loadingErrorsReducer,
  user: usersReducers,
  stores: storesReducers,
  invoices: invoicesReducers,
  withdrawals: withdrawalsReducers,
  wallet: walletReducers
});

export default reducer;
