import BTCValidator from './bitcoin_validator';
import ETHValidator from './ethereum_validator';
import TRXValidator from './tron_validator';
import { CurrencyType, OptsType } from "./types";

const checkBothValidators = (address: string, currency: CurrencyType, opts: OptsType) => {
    const btcResult = BTCValidator.isValidAddress(address, currency, opts);
    const ercResult = BTCValidator.isValidAddress(address, currency, opts);
    const trxResult = TRXValidator.isValidAddress(address, currency, opts);

    return btcResult || ercResult || trxResult;
}

const isValidAddress = (address: string, currency: CurrencyType, opts: OptsType) => {
    if (opts) {
        if (opts.chainType === 'erc20') {
            return ETHValidator.isValidAddress(address, currency, opts);
        } else if (opts.chainType === 'omni') {
            return BTCValidator.isValidAddress(address, currency, opts);
        } else if (opts.chainType === 'trc20') {
            return TRXValidator.isValidAddress(address, currency, opts);
        }
    }
    return checkBothValidators(address, currency, opts);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    isValidAddress
};
