import React from 'react';

import { MainContentBlock } from './MainContent.Styles';
import {connect} from "react-redux";
import {AppStateType} from "../../store";
import {UserReducerState} from "../../store/user/reducers";
import {PATHS} from "../../const/paths.constants";
import {Link} from "react-router-dom";

interface Props {
  className?: string;
  children: any;
  user: UserReducerState;
}

const MainContent: React.FC<Props> = ({ className, children, user }) => {
  return (
    <MainContentBlock className={className}>
      {
        user.useTestnet ? (
          <div className="testnet">
            You are using testnet!
          </div>
        ) : null
      }

      {
        !user.tfa.tfa_confirmed && window.location.href.includes('dashboard') && !user.useTestnet ? (
          <div className="testnet">
            If you don't have a password, you can
            <Link to={PATHS.OAUTH_PASSWORD}>recover it</Link>
          </div>
        ) : null
      }

      {children}
    </MainContentBlock>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
  };
};

export default connect(mapStateToProps, {})(MainContent);
