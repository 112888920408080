import { Buffer } from 'buffer';
import { CurrencyType, OptsType } from "./types";
import { isValidAddress as segwitIsValidAddress } from "./crypto/segwit_addr";
import { decode as base58Decode } from "./crypto/base58";
import { blake2b256, toHex, keccak256Checksum, blake256Checksum, sha256Checksum } from "./crypto/utils";


function getDecoded(address: string) {
    try {
        return base58Decode(address);
    } catch (e) {
        // if decoding fails, assume invalid address
        return null;
    }
}

function getChecksum(hashFunction: string, payload: any) {
    // Each currency may implement different hashing algorithm
    switch (hashFunction) {
        // blake then keccak hash chain
        case 'blake256keccak256':
            var blake = blake2b256(payload);
            return keccak256Checksum(Buffer.from(blake, 'hex'));
        case 'blake256':
            return blake256Checksum(payload);
        case 'keccak256':
            return keccak256Checksum(payload);
        case 'sha256':
        default:
            return sha256Checksum(payload);
    }
}

function getAddressType(address: string, currency: CurrencyType) {
    currency = currency || {};
    // should be 25 bytes per btc address spec and 26 decred
    const expectedLength = currency.expectedLength || 25;
    const hashFunction = currency.hashFunction || 'sha256';
    const decoded = getDecoded(address);

    if (decoded) {
        const length = decoded.length;

        if (length !== expectedLength) {
            return null;
        }

        if(currency.regex) {
            if(!currency.regex.test(address)) {
                return false;
            }
        }

        const checksum = toHex(decoded.slice(length - 4, length));
        const body = toHex(decoded.slice(0, length - 4));
        const goodChecksum = getChecksum(hashFunction, body);

        return checksum === goodChecksum ? toHex(decoded.slice(0, expectedLength - 24)) : null;
    }

    return null;
}

function isValidP2PKHandP2SHAddress(address: string, currency: CurrencyType, opts: OptsType) {
    const { networkType } = opts

    let correctAddressTypes;
    const addressType = getAddressType(address, currency);

    if (addressType) {
        if (networkType === 'prod' || networkType === 'testnet') {
            correctAddressTypes = currency.addressTypes ? currency.addressTypes[networkType] : ['00', '05'];
        } else if (currency.addressTypes) {
            correctAddressTypes = currency.addressTypes.prod.concat(currency.addressTypes.testnet);
        } else {
            return false;
        }

        return correctAddressTypes.indexOf(addressType) >= 0;
    }

    return false;
}

const isValidAddress = (address: string, currency: CurrencyType, opts: OptsType) => {
    return isValidP2PKHandP2SHAddress(address, currency, opts) || segwitIsValidAddress(address, currency, opts);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    isValidAddress
};
