import types from '../actionTypes';
import {
  CreateInvoicesParams,
  GetInvoicesParams,
  GetInvoiceParams,
  MakePaidInvoiceParams, DeactivateInvoiceParams
} from "../../api";

const createInvoice = (payload: CreateInvoicesParams) => ({
  type: types.CREATE_INVOICE_REQUEST,
  payload,
});

const getInvoices = (payload: GetInvoicesParams) => ({
  type: types.GET_INVOICES_REQUEST,
  payload,
});

const getInvoice = (payload: GetInvoiceParams) => ({
  type: types.GET_INVOICE_REQUEST,
  payload,
});

const makePaidInvoice = (payload: MakePaidInvoiceParams) => ({
  type: types.MAKE_PAID_INVOICE_REQUEST,
  payload,
});

const deactivateInvoice = (payload: DeactivateInvoiceParams) => ({
  type: types.DEACTIVATE_INVOICE_REQUEST,
  payload,
});

const setInvoicesLoading = (payload: GetInvoicesParams) => ({
  type: types.SET_INVOICES_LOADING,
  payload,
});

export {
  createInvoice,
  getInvoices,
  getInvoice,
  makePaidInvoice,
  deactivateInvoice,
  setInvoicesLoading,
};
