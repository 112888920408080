import { getByNameOrSymbol, getAll } from './multicoin-address-validator/currencies';
import {OptsType} from "./multicoin-address-validator/types";

const DEFAULT_CURRENCY_NAME = 'bitcoin';
const DEFAULT_NETWORK_TYPE = 'prod';

export const validate = (address: string, currencyNameOrSymbol: string, opts?: OptsType) => {
  const currency = getByNameOrSymbol(currencyNameOrSymbol || DEFAULT_CURRENCY_NAME);
  const newOpts: OptsType = {
    networkType: opts?.networkType || DEFAULT_NETWORK_TYPE,
    chainType: opts?.chainType || null,
  }

    if (currency && currency.validator) {
        return currency.validator.isValidAddress(address, currency, newOpts);
    }

    throw new Error('Missing validator for currency: ' + currencyNameOrSymbol);
};

export const getCurrencies = () => {
    return getAll();
};

export const findCurrency = (symbol: string) => {
    return getByNameOrSymbol(symbol) || null ;
}
