import {GOOGLE_OAUTH_CLIENT_ID, OAUTH_REDIRECT_URI} from "../../const/general.constants";

export const getGoogleUrl = () => {
  const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

  const options = {
    access_type: "offline",
    client_id: GOOGLE_OAUTH_CLIENT_ID as string,
    redirect_uri: OAUTH_REDIRECT_URI as string,
    response_type: "code",
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
    ].join(" "),
    state: 'google_oauth2',
  };

  const qs = new URLSearchParams(options);

  return `${rootUrl}?${qs}`;
};
