import {WithdrawalItem} from '../../api';
import types, { AppActionTypes } from '../actionTypes';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import {WithdrawalStatus} from "../../api/codecs.withdrawals";
import {DEFAULT_PAGE_LIMIT, LOADING_TYPES} from "../../const/app.constants";

export type PaginatedWithdrawals = {
  list: WithdrawalItem[];
  page: number;
  limit: number;
  total_count: number;
  from_day: string;
  to_day: string;
  status: string;
  sort_by: string;
  direction: string;
  loaded: LOADING_TYPES;
};

export type WithdrawalsReducerState = {
  withdrawals: { [key: string]: PaginatedWithdrawals };
  answer: any;
};

const INITIAL_STATE: WithdrawalsReducerState = {
  withdrawals: {},
  answer: {},
};

const withdrawalsReducers = (state = INITIAL_STATE, action: AppActionTypes): WithdrawalsReducerState => {
  switch (action.type) {
    case types.CREATE_WITHDRAWAL_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.CREATE_WITHDRAWAL_SUCCESS:
      const createWithdrawalSuccess = cloneDeep(state.withdrawals);
      const oldWithdrawals = createWithdrawalSuccess[action.payload.storeId] || {
        list: [],
        page: 1,
        limit: 10,
        total_count: 0,
        from_day: '',
        to_day: '',
        status: 'NEW',
        sort_by: 'created_at',
        direction: 'desc',
        loaded: LOADING_TYPES.LOADED
      };

      oldWithdrawals.list = action.payload.withdrawals;
      createWithdrawalSuccess[action.payload.storeId] = oldWithdrawals
      return {
        ...state,
        withdrawals: createWithdrawalSuccess,
        answer: action.payload.answer || {},
      };
    case types.CREATE_WITHDRAWAL_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.GET_WITHDRAWALS_REQUEST:
      const getWithdrawalsRequest = cloneDeep(state.withdrawals);
      getWithdrawalsRequest[action.payload.storeId || ''] = {
        list: [],
        page: Number(action.payload.page) || 1,
        limit: Number(action.payload.limit) || DEFAULT_PAGE_LIMIT,
        total_count: 0,
        from_day: action.payload.from_day || '',
        to_day: action.payload.to_day || '',
        status: action.payload.status || '',
        sort_by: action.payload.sort_by || 'created_at',
        direction: action.payload.direction || 'desc',
        loaded: LOADING_TYPES.LOADING
      };

      return {
        ...state,
        withdrawals: getWithdrawalsRequest,
        answer: {},
      };
    case types.GET_WITHDRAWALS_SUCCESS:
      const getWithdrawalsSuccess = cloneDeep(state.withdrawals);
      const newGetWithdrawalsSuccess = getWithdrawalsSuccess[action.payload.storeId || ''];
      newGetWithdrawalsSuccess.list = action.payload.withdrawals;
      newGetWithdrawalsSuccess.total_count = action.payload.meta.total_count;
      newGetWithdrawalsSuccess.loaded = LOADING_TYPES.LOADED;

      newGetWithdrawalsSuccess[action.payload.storeId || ''] = newGetWithdrawalsSuccess;

      return {
        ...state,
        withdrawals: getWithdrawalsSuccess,
      };
    case types.GET_WITHDRAWALS_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.DECLINE_WITHDRAWAL_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.DECLINE_WITHDRAWAL_SUCCESS:
      const declineWithdrawalSuccess = cloneDeep(state.withdrawals);
      const newDeclineWithdrawalsSuccess = declineWithdrawalSuccess[action.payload.storeId || ''];

      const declineWithdrawalIndex: number = findIndex(newDeclineWithdrawalsSuccess[action.payload.storeId], { id: action.payload.withdrawalId});
      newDeclineWithdrawalsSuccess[action.payload.storeId][declineWithdrawalIndex].status = WithdrawalStatus.PAID;
      declineWithdrawalSuccess[action.payload.storeId || ''] = newDeclineWithdrawalsSuccess;

      return {
        ...state,
        withdrawals: declineWithdrawalSuccess,
        answer: action.payload.answer || {},
      };
    case types.DECLINE_WITHDRAWAL_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.SET_WITHDRAWALS_LOADING:
      const setWithdrawalsLoading = cloneDeep(state.withdrawals);
      console.log('setWithdrawalsLoading', setWithdrawalsLoading);
      setWithdrawalsLoading[action.payload.storeId || ''] = {
        list: [],
        page: Number(action.payload.page) || 1,
        limit: Number(action.payload.limit) || DEFAULT_PAGE_LIMIT,
        total_count: 0,
        from_day: action.payload.from_day || '',
        to_day: action.payload.to_day || '',
        status: action.payload.status || '',
        sort_by: action.payload.sort_by || 'created_at',
        direction: action.payload.direction || 'desc',
        loaded: LOADING_TYPES.NOT_LOADED
      };

      return {
        ...state,
        withdrawals: setWithdrawalsLoading,
        answer: {},
      };
    case types.CLEAR:
      return {
        ...state,
        answer: {},
      };
    case types.LOGOUT_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default withdrawalsReducers;
