import styled from 'styled-components';

export const PaymentsContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--inter);

  .payments {
    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      
      &__title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: var(--black);
      }
      
      &__btns {
        display: flex;
        flex-direction: row;
        gap: 12px;
      }
      
      &__btn {
        display: flex;
        background: var(--white);
        border: 1px solid var(--light-gray);
        padding: 9px 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
        align-items: center;
        
        &:before {
          content: '';
          display: inline-block;
          margin-right: 12px;
          margin-left: 1px;
          width: 17px;
          height: 17px;
          background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjUgMTEuNVYxNC44MzMzQzE2LjUgMTUuMjc1NCAxNi4zMjQ0IDE1LjY5OTMgMTYuMDExOCAxNi4wMTE4QzE1LjY5OTMgMTYuMzI0NCAxNS4yNzU0IDE2LjUgMTQuODMzMyAxNi41SDMuMTY2NjdDMi43MjQ2NCAxNi41IDIuMzAwNzIgMTYuMzI0NCAxLjk4ODE2IDE2LjAxMThDMS42NzU1OSAxNS42OTkzIDEuNSAxNS4yNzU0IDEuNSAxNC44MzMzVjExLjVNNC44MzMzMyA3LjMzMzMzTDkgMTEuNU05IDExLjVMMTMuMTY2NyA3LjMzMzMzTTkgMTEuNVYxLjUiIHN0cm9rZT0iIzM0NDA1NCIgc3Ryb2tlLXdpZHRoPSIxLjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
          background-repeat: no-repeat;
          background-size: cover;
        }
        
        &.-purple {
          background: var(--purple);
          border: 1px solid var(--purple);
          color: var(--white);

          &:before {
            margin-left: 2px;
            //width: 14px;
            //height: 14px;
            //background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMDAwMzMgMS4xNjY1VjEyLjgzMzJNMS4xNjY5OSA2Ljk5OTg0SDEyLjgzMzciIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS42NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
            width: 17px;
            height: 17px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjUgMTIuNVYxNS44MzMzQzE3LjUgMTYuMjc1NCAxNy4zMjQ0IDE2LjY5OTMgMTcuMDExOCAxNy4wMTE4QzE2LjY5OTMgMTcuMzI0NCAxNi4yNzU0IDE3LjUgMTUuODMzMyAxNy41SDQuMTY2NjdDMy43MjQ2NCAxNy41IDMuMzAwNzIgMTcuMzI0NCAyLjk4ODE2IDE3LjAxMThDMi42NzU1OSAxNi42OTkzIDIuNSAxNi4yNzU0IDIuNSAxNS44MzMzVjEyLjVNNS44MzMzMyA4LjMzMzMzTDEwIDEyLjVNMTAgMTIuNUwxNC4xNjY3IDguMzMzMzNNMTAgMTIuNVYyLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS42NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }

    &-wrap {
      padding-top: 32px;
    }
  }
`;
