import React from 'react';

import { Logo } from '../../../elements';
import { TermsStyles } from './Terms.Styles';
import {PATHS} from "../../../const/paths.constants";

const Terms: React.FC = () => {

  return (
    <TermsStyles>
      <div className="terms-container">
        <div className="terms-header">
          <div className="logo-top">
            <Logo className="logo" to={PATHS.NEW_DASHBOARD}>
              <img src="/img/main/logo.svg" alt="cp" />
            </Logo>
          </div>
        </div>
        <div className="terms-body">
          <h1 className="page-title">Cryptoprocessing service usage agreement</h1>
          <h2 className="list">
            <div className="block">
              ATTENTION! Before you start using the Cryptoprocessing service license, please read the complete rules and
              terms stipulated in the present Agreement. The use of the Cryptoprocessing service is possible only under the
              conditions established by this Agreement. If you do not accept the terms of the Agreement in full, please do
              not use the Cryptoprocessing service.
            </div>
            <div className="address">
              Address: Office 29, Clifton House, Fitzwilliam Street Lower
              Dublin 2, D02 XT91, REPUBLIC OF IRELAND
              Ireland International: +353-81-837-0020
              Ireland Toll-Free: 1-800-300-004
            </div>
          </h2>
          <div className="list">
            <div className="block">
              ORDER OF MAGNITUDE LP hereinafter referred to as the Licensor, on the one hand, and legal body or an
              individual, hereinafter referred to as the Licensee, on the other hand, referred together herein as the
              Parties, who have made this Agreement as follows:
            </div>
            <ul>
              Terms and definitions:
              <li>
                <b>● Offer</b> - a public offer of the Licensor addressed to any person, to conclude a licensing agreement
                with him (hereinafter referred to as the "Agreement") on the existing terms and conditions contained in the
                Agreement;
              </li>
              <li>
                <b>● Licence</b> - the right to use the cryptoprocessing service provided to Licensee;
              </li>
              <li>
                <b>● Cryptoprocessing service</b> - a set of software and services provided to the Licensee by the
                Licensor;
              </li>
              <li>
                <b>● Acceptance</b> – by filling in the registration form by the User, marking (by a tick) the "Sign up"
                window and sending the Registration Form. Acceptance confirms familiarization with the text of the
                Agreement, and implies full consent, understanding of the content, and being authorized to accept the
                Agreement;
              </li>
              <li>
                <b>● The Licensor's website</b> is a public resource located at
                <a href="https://cryptoprocessing.io">https://cryptoprocessing.io</a>
              </li>
              <li>
                <b>● API documentation</b> - documentation located at
                <a href="https://docs-api.cryptoprocessing.io">https://docs-api.cryptoprocessing.io</a>
              </li>
              <li>
                <b>● API</b> - public methods for remote function call via HTTP protocol, for managing the Platform
                functionality, Data Handler services and Data Controller services;
              </li>
              <li>
                <b>● Transaction</b> - an operation to move crypto / fiat assets; the result of the transfer of an asset is
                the change in the state of the account / balance of participants in the payment infrastructure;
              </li>
              <li>
                <b>● Blockchain</b> - a decentralized way of storing information using Blocks and Transactions;
              </li>
              <li>
                <b>● Available Blockchain Networks</b> –networks available for use on the Platform, a complete list of
                which is located in the API documentation;
              </li>
              <li>
                <b>● Tariff</b> - terms of payment agreed with the Company for using the Platform API. The general
                rate-making of the service is available at:
                <a href="https://cryptoprocessing.io">https://cryptoprocessing.io</a>;
              </li>
              <li>
                <b>● Account</b> - WEB-interface of the system of self-service and services management;
              </li>
              <li>
                <b>● KYC</b> - user identification procedure for the Platform;
              </li>
              <li>
                <b>● GDPR</b> - General Data Protection Regulation (GDPR) (Regulation (European Union) 2016/679) is a
                European Union regulation through which the European Parliament, the Council of the European Union and the
                European Commission strengthen and unify the protection of personal data all persons in the European Union
                (EU);
              </li>
              <li>
                <b>● SLA</b> - Service Level Agreement - a regulation describing the parameters of the service provided;
              </li>
              <li>
                <b>● Registration form</b> - a universal electronic form on this site, filled in when registering Licensee
                is using the private account.
              </li>
            </ul>
          </div>

          <div className="list">
            <div className="block">
              <b>General terms and conditions</b>
              <p>The license is granted by giving the Licensee access to the cryptoprocessing.io, the
                cryptoprocessing platform, API interfaces, mobile applications and any software services
                provided by the Licensor, as well as to all written or electronic materials, including software,
                data, text, audio, video, images, photographs, graphics or other content (hereinafter referred
                to as "Content").
              </p>
              <p>
                This Agreement shall be deemed to be concluded and shall acquire the power of the
                contract of accession from the moment of filling in and sending a registration form.
                From the moment of acceptance of the Agreement, the Licensee shall be considered to be
                familiar with the contents of this Agreement and the terms.
                The integral parts of this Agreement are the conditions for rendering additional services of
                the Cryptoprocessing Service connected by the User, as well as the Tariffs
                (<a href="https://cryptoprocessing.io">https://cryptoprocessing.io</a>)
              </p>
            </div>
            <div className="block">
              <p>1.1. On digital assets
              </p>
              <p>The Licensor does not accept, send or handle money, including but not limited to: national
                and / or foreign currency, etc. As a bitcoin purse, the Licensor allows the Licensee to interact
                with the blockchain network in order to view and transmit information about the public
                cryptographic key ("Bitcoin address" or "bitcoin account").
              </p>
              <p>To transfer account information in the blockchain network one needs a private key (keys)
                corresponding to the account in the blockchain network. After the network recognizes the
                information sent by the Licensor and checks it, it is transferred to other persons and
                companies on the network, and the cryptoprocessing service cannot be used by the
                Licensee to cancel transactions of the cryptocurrency type. Similar rules apply to other
                crypto and digital assets for which the Licensor provides purses ("Digital Asset"), including
                (as of the last update of these Terms) Bitcoin, Ethereum and Litecoin etc.
              </p>
            </div>
            <div className="block">
              <p>1.2. Digital Asset Protocols and Network Charges
              </p>
              <p>The Licensor does not own or operate the underlying software protocols that regulate the
                operation of digital currencies. Protocols of digital assets can be changed by protocol rules,
                which can influence the value, function or name of Digital Asset accordingly.
              </p>
              <p>By accepting the terms of this Agreement, the Licensee expresses the full and irrevocable
                condition that the Licensor is not responsible for the operation of the underlying protocols of
                digital assets, nor does it guarantee their functionality, security or availability; in the event of
                a change in digital assets by the rules of the protocols, the Licensor may unilaterally
                suspend the operation of the Service for cryptoprocessing and Content related to the
                affected digital asset; in this case the Licensor has the right to unilaterally decide not to fullysupport
                the modification of the protocol or reconfigure the work of the Service in order for
                Licensee to take advantage of the opportunity to transfer the affected digital asset.
              </p>
            </div>
            <div className="block">
              <p>1.3. Refund Policy - Completed Transactions
              </p>
              <p>The Licensee using the cryptoprocessing service hereby expresses its full consent to the
                Non-repayment Policy provided by the terms of use of the Service. The Licensor's
                Non-repayment policy assumes that all the transactions carried out by the User using the
                Service and any of its Content are not retroactive, are final and irretrievable.
              </p>
            </div>
            <div className="block">
              <p>1.4. Platform features
              </p>
              <p>The functionality of the Cryptoprocessing platform allows Users to manage the account and
                generate unique addresses for work within the regulated public blockchain networks through
                the Application Programming Interface (API). The full set of available functions is described
                in the documentation <a href="https://docs.cryptoprocessing.io">https://docs.cryptoprocessing.io</a>
              </p>
            </div>
            <div className="block">
              <p>1.5. Passwords and security</p>
              <p>The Licensee is independently and fully responsible for maintaining proper security and
                control of all identifiers, passwords, private keys, personal identification numbers (PINs) and
                any other codes that the Licensee uses to access the Service. The licensee also
                independently bears full responsibility for the secret keys that are provided by the Service or
                which the Licensee creates for the wallets used on the Service, and maintaining the
                necessary backup copies.
              </p>
              <p>The Licensee must independently undertake all necessary actions aimed at preventing
                unauthorized access to the Service or its usage, using account credentials or private keys,
                and agrees to notify the Service immediately in case of unauthorized access to or use of
                data on the Service. The Licensee must independently and confidentially store his account
                ID, passwords and any other account data and not disclose or provide access to the Service
                to any third parties, except for the preliminary receipt of approval from the Licensor with the
                mechanism of such use.
              </p>
              <p>The Licensee undertakes to promptly report (as soon as possible) all cases of unauthorized
                access or other violations to <a href="/cdn-cgi/l/email-protection#e794929797889593a784959e979388979588848294948e8980c98e88">
                  <span className="__cf_email__" data-cfemail="681b1d1818071a1c280b1a11181c07181a070b0d1b1b01060f460107">[email protected]</span>
              </a>,
                and also provide the Licensor
                with all feasible assistance in any investigation of any alleged unauthorized access or use of
                the Service with the use of account data or private account keys and any violation of the
                security of the User's account, system or network, providing the User with the results of the
                activity of any third parties.
              </p>
              <p>The Licensee independently bears personal responsibility, and the Licensor is exempt from
                any kind of liability, for all actions (inaction) directly or indirectly related to the use of the
                user's credentials, regardless of whether the right to its use has been granted to third parties
                or not.
              </p>
            </div>
            <div className="block">
              <p>1.6. Authentication
              </p>
              <p>To register an account in the Service the Licensor (individual) must be at least 18 years old if
                a higher age threshold is not provided-for by current legislation for the use of the Service and
                Content. When using the Service, the Licensee undertakes to provide the Licensor (and its
                affiliates) with accurate, up-to-date and complete information about himself, in accordance
                with the data required at registration, or in the event that the Licensor solicits additionalinformation and
                / or updates it. The Licensee hereby grants the Licensor the right to file any
                requests that will be required to verify the identity of the User. The Licensee also agrees to
                the involvement of the Licensor by third parties to obtain complete and necessary
                information about the User.
              </p>
            </div>
            <div className="block">
              <p>1.7. Risks
              </p>
              <p>The Licensee hereby grants consent, expresses its understanding of the terms, and confirms
                its awareness of the fact that the use of digital assets, their networks and protocols entails
                serious risks. The Licensee must independently analyze all possible risks. The Licensee
                agrees that even if the Licensor notifies the User of certain risks associated with digital
                assets, their protocols and networks, the Licensor neither bears any responsibility for the
                consequences of their occurrence, nor controls or makes any statements regarding the
                value of digital assets or the security of their networks or protocols.
              </p>
            </div>
            <div className="block">
              <b>2. Privacy policy</b>
            </div>
            <div className="block">
              <p>2.1. Your privacy
              </p>
              <p>The Licensee undertakes to read the privacy policy located at
                https://www.CryptoProcessing.io/privacy-policy ("Privacy Policy") before using the Service. The
                Licensee hereby expresses its full consent to the collection, use and disclosure of
                information, as described in the Privacy Policy.
              </p>
            </div>
            <div className="block">
              <p>2.2. User accounts
              </p>
              <p>The Licensee hereby agrees to receive emails (notifications, updates or changes) from the
                Licensor to the e-mail address provided by the User upon registration. Emails can contain
                promotional information and materials about the Licensor's products and services.
              </p>
            </div>
            <div className="block">
              <p>2.3. Licensor's rights
              </p>
              <p>The Licensor reserves the right to temporarily suspend or terminate access to the Service at
                any time in its sole discretion, with or without cause, with or without notice, without any
                responsibility, including suspending or terminating the Licensee's access to the Service in
                the following cases:
              </p>
              <p>(a) actual or alleged violation of the terms of use of the License;
              </p>
              <p>(b) use of the Service in such a way that the User's actions (inaction) could entail legal
                responsibility for the Licensor or interfere as a third party in the interaction of the Licensor
                and any other Licensee;
              </p>
              <p>(c) scheduled and recurring downtime, as well as unplanned technical problems and service
                failures.
              </p>
            </div>
            <div className="block">
              <p>2.4. Unlawful use
              </p>
              <p>The licensee assumes the obligation not to carry out action (inaction) independently, nor to
                assist third parties in the following cases:
              </p>
              <p>(a) use of any unauthorized means to access the Service or the use of any automated
                process or service to access or use the Service or to distribute instructions, software or tools
                for this purpose;
              </p>
              <p>(b) modification, copying or creation of derivative (similar) Service products (platforms);
                <p>(c) interference with the operation of equipment or networks used by the Licensor;
                </p>
              </p>
              <p>(d) damage, disconnection, the opening or other disruption to the Service (or any network
                connected to the Service);
              </p>
              <p>(e) use of the account of another user at any time, except for the mechanism provided by the
                Licensor and the properly authorized user of the Service;
              </p>
              <p>(f) use of the Service in any way or for any purpose other than as expressly permitted by this
                Agreement, the Privacy Policy, any Licensor's instructions and policies that are publicly
                available;
              </p>
              <p>(g) participation in any illegal or fraudulent activity, in illegal schemes or any other illegal
                activity;
              </p>
              <p>(h) sending of unsolicited or unapproved junk mail, spam, a chain of letters, schemes or any
                other form of duplicate or unsolicited messages, whether commercial or otherwise;
              </p>
              <p>(i) use the Service for promotional purposes in the absence of a special permit from the
                Licensor.
              </p>
            </div>
            <div className="block">
              <p>2.5. Changes (updates) to the Service
              </p>
              <p>The Licensor has the right, at its discretion, to perform at any time any necessary actions
                related to the introduction of changes, updates or improvements to the Service, as well as to
                the private account of the Licensee. The Licensor has the right to change the functional
                characteristics or capabilities of the Service, and also at any time at its discretion to stop
                (suspend) the functioning of the Service.
              </p>
              <p>In case of amending, updating or improving the Service, as well as the User's private
                account, the Licensee is entitled to review the changes within 3 (three) calendar days.
              </p>
              <p>In the event of continued use of the Service after the specified time, any changes shall be
                deemed accepted by the User in full in the absence of any comments.
              </p>
              <p>The Licensee has the right to refuse acceptance of changes, updates or improvements to
                the Service, as well as the personal account of the User, by deleting the account of the User
                located on the Service.
              </p>
            </div>
            <div className="block">
              <p>2.6. License and restrictions
              </p>
              <p>Cryptoprocessing grants the Licensee a personal, non-transferable, non-exclusive license
                (right) to use the Service on the terms and conditions established by this Agreement. The
                Licensee may not copy, modify, create a similar product using the Service data, reconstruct,
                decompile or otherwise attempt to extract the source code of the Service or any part thereof,
                except to the extent permitted by applicable law. The Licensee may not transfer his rights to
                use the Service or otherwise transfer any part of his rights to use the Service. Ownership
                rights or any other proprietary rights associated with the Service, as well as ownership of
                them, shall not assigned to the Licensee.
              </p>
            </div>
            <div className="block">
              <p>2.7. Intellectual rights
              </p>
              <p>The Service does not grant Licensee any right or consent to display or use in any way the
                Licensor's trademarks, service marks, logos or slogans without the Licensor's consent.
              </p>
            </div>
            <div className="block">
              <b>3. User-generated content</b>
            </div>
            <div className="block">
              <p>3.1 User's content
              </p>
              <p>The Licensee is responsible for the information that the Licensee provides to or through the
                Service ("User-generated Content").
              </p>
              <p>The Licensee guarantees that it will not store or transmit data:
              </p>
              <p>(a) containing illegal, defamatory, threatening, pornographic, abusive, libelous or otherwise
                unwanted material of any kind or nature;
              </p>
              <p>(b) containing any material that encourages unlawful conductor may contain signs of a
                criminal offense or
              </p>
              <p>(c) violating others' rights of intellectual property or the right to privacy;
              </p>
              <p>(d) containing or using software viruses or malicious computer code, files or programs or
                spyware; or
              </p>
              <p>(e) prejudicing, threatening or persecuting another person or organization.
              </p>
            </div>
            <div className="block">
              <b>4. Liability for breaching the Agreement</b>
            </div>
            <div className="block">
              <p>4.1. In order to clarify the circumstances of violations of the terms of this Agreement, the
                Licensor reserves the right to request an explanation (by e-mail) from the Licensee in regard
                to issues in dispute. In the event that Licensee does not respond within 5 (five) calendar
                days from the date of sending the request, the point at issue shall not to be considered in the
                interest of the Licensee.
              </p>
              <p>4.2. Within the period specified in Article 4.1 of the Agreement, the Licensee has the right to
                submit his / her/ its written motivated explanations (objections). In such a case, the Licensor
                shall decide whether there has been a violation, the fault or otherwise of the Licensee and
                the amount of damage (malicious consequences) caused in each individual case, based on
                the totality of available data, and shall make an appropriate decision in the absence of the
                fault of the Licensee or on the application of appropriate sanctions, including but not limited
                to: temporary suspension of access, termination of access, etc.
              </p>
            </div>
            <div className="block">
              <p><b>5. GDPR</b></p>
              <p>In the context of the use of information, the Licensor is identified as the Controller. The
                Licensee assumes the obligations of the processor, which determines the need to determine
                the purpose and value of processing personal data, and the processor is responsible for the
                direct processing of data. The Company and the Customer are both responsible for
                compliance with the GDPR (<a href="https://gdpr-info.eu" rel="nofollow">https://gdpr-info.eu</a>).
              </p>
            </div>
            <div className="block">
              <p><b>6. AML Policy</b></p>
              <p>The Licensor and the Licensee undertake to work in accordance with the Law on the
                Prevention of the Legalization of Proceeds from Crime and the Financing of Terrorism, which
                regulates and determines the procedure for the implementation and compliance with various
                special measures to prevent the laundering of proceeds from crime and the financing of
                terrorism, and also work in accordance with the requirements of Directives 2005/60 / EC and
                2006/70 / EC of the European Union.
              </p>
            </div>
            <div className="block">
              <p><b>7. Using Third-Party Data Handlers</b></p>
              <p>The Licensor reserves the right to use subcontractors as the main data handler agents of the
                Users. Interaction shall be carried out by calling the API of the provided services. SLA fromthe Company,
                provided to the Licensee, shall be transparently broadcast from the final Data
                Handler.
              </p>
            </div>
            <div className="block">
              <p><b>8. Right to be erasure</b></p>
              <p>The Licensor assumes responsibility for fulfilling any privacy removal requests by the
                Licensee. Any request containing a demand for a privacy removal must be sent to
                <a href="/cdn-cgi/l/email-protection#fa9b988f899fba9988838a8e958a8895999f898993949dd49395">
                  <span className="__cf_email__" data-cfemail="5a3b382f293f1a3928232a2e352a2835393f292933343d743335">[email protected]</span>
                </a>
                and executed by the Licensor within 30 days of receipt of the
                request.
              </p>
            </div>
            <div className="block">
              <b>9. Feedback</b>
            </div>
            <div className="block">
              9.1. Technical support and feedback from the Licensor shall be carried out by means of
              communication in a chat located in the private account and
              <a href="/cdn-cgi/l/email-protection#abded8c2c5ccc3cec7dbebc8d9d2dbdfc4dbd9c4c8ced8d8c2c5cc85c2c4">
                <span className="__cf_email__" data-cfemail="205553494e4748454c506043525950544f50524f43455353494e470e494f">[email protected]</span>
              </a>
            </div>
            <div className="block">
              <b>10. Service usage charge</b>
            </div>
            <div className="block">
              <p>10.1. The Licensee shall keep the balance of its account topped-up in the
                cryptoprocessing.io service.
              </p>
              <p>10.2. Payment for services shall occur by writing-off funds from the Licensee's balance on
                the basis of tariffs:
                <a href="https://cryptoprocessing.io">https://cryptoprocessing.io</a>
              </p>
            </div>
            <div className="block">
              <b>11. Addendums</b>
            </div>
            <div className="block">
              11.1. The Licensor and the Licensee have the right to enter into Addenda to this Agreement.
            </div>
          </div>
        </div>
        <div className="terms-footer">
          <div className="logo-top">
            <Logo className="logo" to={PATHS.NEW_DASHBOARD}>
              <img src="/img/main/logo.svg" alt="cp" />
            </Logo>
          </div>
        </div>
      </div>
    </TermsStyles>
  );
};

export default Terms;
