import styled from 'styled-components';

export const DevelopersContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--inter);

  .developers {
    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      
      &__title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: var(--black);
      }
      
      &__btns {
        display: flex;
        flex-direction: row;
        gap: 12px;
      }
      
      &__btn {
        display: flex;
        background: var(--white);
        border: 1px solid var(--light-gray);
        padding: 9px 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
        align-items: center;
        
        &:before {
          content: '';
          display: inline-block;
          margin-right: 9px;
          margin-left: 1px;
          width: 18px;
          height: 18px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0IDkuODMzMzNWMTQuODMzM0MxNCAxNS4yNzU0IDEzLjgyNDQgMTUuNjk5MyAxMy41MTE4IDE2LjAxMThDMTMuMTk5MyAxNi4zMjQ0IDEyLjc3NTQgMTYuNSAxMi4zMzMzIDE2LjVIMy4xNjY2N0MyLjcyNDY0IDE2LjUgMi4zMDA3MiAxNi4zMjQ0IDEuOTg4MTYgMTYuMDExOEMxLjY3NTU5IDE1LjY5OTMgMS41IDE1LjI3NTQgMS41IDE0LjgzMzNWNS42NjY2N0MxLjUgNS4yMjQ2NCAxLjY3NTU5IDQuODAwNzIgMS45ODgxNiA0LjQ4ODE2QzIuMzAwNzIgNC4xNzU1OSAyLjcyNDY0IDQgMy4xNjY2NyA0SDguMTY2NjdNMTEuNSAxLjVIMTYuNU0xNi41IDEuNVY2LjVNMTYuNSAxLjVMNy4zMzMzMyAxMC42NjY3IiBzdHJva2U9IiMzNDQwNTQiIHN0cm9rZS13aWR0aD0iMS42NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    &-widgets {
      padding-top: 32px;
      
      &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: var(--black);
        
        &_wrap {
          padding-bottom: 20px;
          border-bottom: 1px solid var(--dark-white);
        }
      }
      
      &__container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin-top: 25px;

        .grid-margin-x {
          margin-left: -0.75rem;
          margin-right: -0.75rem;
          
          .cell {
            width: calc(25% - 1.5rem);
            margin-left: 0.75rem;
            margin-right: 0.75rem;
            
            &.-big {
              width: calc(50% - 1.5rem);
            }
          }
        }
      }
      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--white);
        box-shadow: 0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05);
        border-radius: var(--border-radius);
        padding: 10px 0;
      }
      
      &__link {
        background: transparent;
        border: 0;
        box-shadow: none;
      }
    }
    
    &-api {
      &__head {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 32px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--dark-white);
      }
      
      &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: var(--black);
        
        &_wrap {
          display: flex;
          flex-direction: column;
        }
      }
      
      &__desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray);
        margin-top: 3px;
      }
      
      &__switch {
        &_wrap {
          .MuiSwitch-root {
            margin-right: 5px;
          }
          
          .MuiFormControlLabel-root {
            margin-right: 0;
          }
          
          .MuiTypography-root {
            font-family: var(--inter);
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: var(--dark-gray);
          }
        }
      }
      
      &__table {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 25px;
        border: 1px solid var(--dark-white);
        box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 4px 20px -2px rgba(50, 50, 71, 0.08);
        border-radius: var(--border-radius);
        
        &_header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 19px 24px;

          &_title {
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: var(--black);
          }
          
          &_btns {
            display: flex;
            align-items: center;
            gap: 12px
          }
          
          &_btn {
            display: flex;
            background: var(--white);
            border: 1px solid var(--light-gray);
            padding: 9px 15px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: var(--dark-gray);
            align-items: center;

            &:before {
              content: '';
              display: inline-block;
              margin-left: 1px;
              
              background-repeat: no-repeat;
              background-size: cover;
            }
            &.-generate {
              &:before {
                margin-right: 9px;
                width: 20px;
                height: 18px;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5LjE2NjMgMi4zMzMzMVY3LjMzMzMyTTE5LjE2NjMgNy4zMzMzMkgxNC4xNjYzTTE5LjE2NjMgNy4zMzMzMkwxNS4yOTk3IDMuNjk5OThDMTQuNDA0MSAyLjgwMzkxIDEzLjI5NiAyLjE0OTMyIDEyLjA3OSAxLjc5NzI5QzEwLjg2MiAxLjQ0NTI3IDkuNTc1NiAxLjQwNzI3IDguMzM5OTEgMS42ODY4NkM3LjEwNDIzIDEuOTY2NDUgNS45NTk1MSAyLjU1NDUxIDUuMDEyNTYgMy4zOTYxNkM0LjA2NTYyIDQuMjM3ODIgMy4zNDczMSA1LjMwNTY0IDIuOTI0NjcgNi40OTk5OE0wLjgzMzAwOCAxNS42NjY2VjEwLjY2NjZNMC44MzMwMDggMTAuNjY2Nkg1LjgzMzAxTTAuODMzMDA4IDEwLjY2NjZMNC42OTk2NyAxNC4zQzUuNTk1MyAxNS4xOTYxIDYuNzAzMzIgMTUuODUwNiA3LjkyMDM1IDE2LjIwMjdDOS4xMzczOCAxNi41NTQ3IDEwLjQyMzggMTYuNTkyNyAxMS42NTk0IDE2LjMxMzFDMTIuODk1MSAxNi4wMzM1IDE0LjAzOTggMTUuNDQ1NSAxNC45ODY4IDE0LjYwMzhDMTUuOTMzNyAxMy43NjIxIDE2LjY1MiAxMi42OTQzIDE3LjA3NDcgMTEuNSIgc3Ryb2tlPSIjMzQ0MDU0IiBzdHJva2Utd2lkdGg9IjEuNjciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
              }
            }

            &.-add {
              &:before {
                margin-left: 3px;
                margin-right: 10px;
                width: 14px;
                height: 14px;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMDAwMzMgMS4xNjY3NVYxMi44MzM0TTEuMTY2OTkgNy4wMDAwOEgxMi44MzM3IiBzdHJva2U9IiMzNDQwNTQiIHN0cm9rZS13aWR0aD0iMS42NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
              }

              svg {
                stroke: var(--gray);
              }
            }
          }
        }

        &_head {
          display: grid;
          grid-gap: 1em;
          align-items: center;
          padding: 12px 24px;
          background: var(--cream);
          //border-top-left-radius: var(--border-radius);
          //border-top-right-radius: var(--border-radius);
          border-top: 1px solid var(--dark-white);
          border-bottom: 1px solid var(--dark-white);

          grid-template-columns: 6fr 2fr 2fr 109px;

          &_item {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: var(--gray);
            cursor: pointer;
          }

          &__arrow {
            font-size: 15px;
          }
        }

        &_body {
          &_row {
            display: grid;
            grid-gap: 1em;
            align-items: center;
            padding: 18px 24px;
            border-bottom: 1px solid var(--dark-white);
            grid-template-columns: 6fr 2fr 2fr 109px;
            cursor: pointer;
            
            &:last-child {
              border: 0;
            }
            
            &.-disabled {
              pointer-events: none;
            }
            
            &.-show {
              pointer-events: none;
            }
          }

          &_item {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--gray);

            &.-name {
              font-weight: 500;
              color: var(--black);
            }
          }

          &_btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background: none;
            border: 0;
            outline: none;
            cursor: pointer;
            padding: 0;
            margin: 0;
            text-align: right;

            &_icon {
            }

            &_item {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: var(--gray);
              margin-left: 8px;
            }
            
            & svg {
              stroke: var(--gray);
            }
          }
        }
        
        &_zero {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px 23px;
          border-bottom: 1px solid var(--dark-white);
        }
      }
      
      &__loading {
        display: grid;
        grid-gap: 1em;
        align-items: center;
        padding: 18px 24px;
        border-bottom: 1px solid var(--dark-white);
        grid-template-columns: 6fr 2fr 2fr 109px;
      }
    }

    &-webhooks {
      &__head {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 32px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--dark-white);
      }

      &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: var(--black);

        &_wrap {
          display: flex;
          flex-direction: column;
        }
      }
      
      &__table {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 24px;
        border: 1px solid var(--dark-white);
        box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 4px 20px -2px rgba(50, 50, 71, 0.08);
        border-radius: var(--border-radius);
        margin-bottom: 24px;

        &_header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 19px 23px;

          &_title {
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: var(--black);
          }

          &_btns {
            display: flex;
            align-items: center;
            gap: 12px
          }

          &_btn {
            display: flex;
            background: var(--white);
            border: 1px solid var(--light-gray);
            padding: 9px 15px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: var(--dark-gray);
            align-items: center;

            &:before {
              content: '';
              display: inline-block;
              background-repeat: no-repeat;
              background-size: cover;
            }

            &.-add {
              &:before {
                margin-right: 12px;
                margin-left: 2px;
                width: 14px;
                height: 14px;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMDAwMzMgMS4xNjY3NVYxMi44MzM0TTEuMTY2OTkgNy4wMDAwOEgxMi44MzM3IiBzdHJva2U9IiMzNDQwNTQiIHN0cm9rZS13aWR0aD0iMS42NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
              }
            }
          }
        }

        &_head {
          display: grid;
          grid-gap: 1em;
          align-items: center;
          padding: 13px 24px;
          background: var(--cream);
          //border-top-left-radius: var(--border-radius);
          //border-top-right-radius: var(--border-radius);
          border-top: 1px solid var(--dark-white);
          border-bottom: 1px solid var(--dark-white);

          grid-template-columns: 8fr 1fr 80px;

          &_item {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: var(--gray);
            cursor: pointer;
          }

          &__arrow {
            font-size: 15px;
          }
        }

        &_body {
          &_zero {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 23px;
            border-bottom: 1px solid var(--dark-white);
          }
          &_row {
            display: grid;
            grid-gap: 1em;
            align-items: center;
            padding: 17px 24px;
            border-bottom: 1px solid var(--dark-white);
            grid-template-columns: 8fr 1fr 80px;

            &:last-child {
              border: 0;
            }
          }

          &_item {
            display: flex;
            justify-content: flex-start;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--gray);

            &.-name {
              font-weight: 500;
              color: var(--black);
            }
          }

          &_btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background: none;
            border: 0;
            outline: none;
            cursor: pointer;
            padding: 0;
            margin: 0;
            text-align: right;

            &_icon {
              stroke: var(--gray);
            }

            &_item {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: var(--gray);
              margin-left: 8px;
            }
          }
          
          &_status {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            border-radius: 16px;
            padding: 2px 8px 2px 9px;

            &:before {
              content: '';
              display: inline-block;
              background-repeat: no-repeat;
              background-size: cover;
            }
            
            &.-active {
              color: #027A48;
              background: #ECFDF3;

              &:before {
                margin-right: 7px;
                width: 6px;
                height: 6px;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgNiA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSIzIiBjeT0iMyIgcj0iMyIgZmlsbD0iIzEyQjc2QSIvPgo8L3N2Zz4K);
              }
            }
          }
        }
      }
    }

    &-whitelist {
      &__head {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 32px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--dark-white);
      }

      &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: var(--black);

        &_wrap {
          display: flex;
          flex-direction: column;
        }
      }

      &__table {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 24px;
        border: 1px solid var(--dark-white);
        box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 4px 20px -2px rgba(50, 50, 71, 0.08);
        border-radius: var(--border-radius);
        margin-bottom: 24px;

        &_header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 19px 23px;

          &_title {
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: var(--black);
          }

          &_btns {
            display: flex;
            align-items: center;
            gap: 12px
          }

          &_btn {
            display: flex;
            background: var(--white);
            border: 1px solid var(--light-gray);
            padding: 9px 15px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: var(--dark-gray);
            align-items: center;

            &:before {
              content: '';
              display: inline-block;
              background-repeat: no-repeat;
              background-size: cover;
            }

            &.-add {
              &:before {
                margin-right: 12px;
                margin-left: 2px;
                width: 14px;
                height: 14px;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMDAwMzMgMS4xNjY3NVYxMi44MzM0TTEuMTY2OTkgNy4wMDAwOEgxMi44MzM3IiBzdHJva2U9IiMzNDQwNTQiIHN0cm9rZS13aWR0aD0iMS42NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
              }
            }
          }
        }

        &_head {
          display: grid;
          grid-gap: 1em;
          align-items: center;
          padding: 13px 24px;
          background: var(--cream);
          //border-top-left-radius: var(--border-radius);
          //border-top-right-radius: var(--border-radius);
          border-top: 1px solid var(--dark-white);
          border-bottom: 1px solid var(--dark-white);

          grid-template-columns: 1fr 80px;

          &_item {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: var(--gray);
            cursor: pointer;
          }

          &__arrow {
            font-size: 15px;
          }
        }

        &_body {
          &_zero {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 23px;
            border-bottom: 1px solid var(--dark-white);
          }
          &_row {
            display: grid;
            grid-gap: 1em;
            align-items: center;
            padding: 17px 24px;
            border-bottom: 1px solid var(--dark-white);
            grid-template-columns: 1fr 80px;

            &:last-child {
              border: 0;
            }
          }

          &_item {
            display: flex;
            justify-content: flex-start;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--gray);

            &.-name {
              font-weight: 500;
              color: var(--black);
            }
          }

          &_btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background: none;
            border: 0;
            outline: none;
            cursor: pointer;
            padding: 0;
            margin: 0;
            text-align: right;

            &_icon {
              stroke: var(--gray);
            }

            &_item {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: var(--gray);
              margin-left: 8px;
            }
          }

          &_status {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            border-radius: 16px;
            padding: 2px 8px 2px 9px;

            &:before {
              content: '';
              display: inline-block;
              background-repeat: no-repeat;
              background-size: cover;
            }

            &.-active {
              color: #027A48;
              background: #ECFDF3;

              &:before {
                margin-right: 7px;
                width: 6px;
                height: 6px;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgNiA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSIzIiBjeT0iMyIgcj0iMyIgZmlsbD0iIzEyQjc2QSIvPgo8L3N2Zz4K);
              }
            }
          }
        }
      }
    }
    
    
  }
`;
