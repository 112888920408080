import { CurrencyType, OptsType } from "./types";
import { decode as base58Decode} from "./crypto/base58";
import { sha256, byteArray2hexStr, hexStr2byteArray } from "./crypto/utils";

const decodeBase58Address = (base58Sting: string) => {
    if (typeof (base58Sting) !== 'string') {
        return false;
    }
    if (base58Sting.length <= 4) {
        return false;
    }

    try {
        var address = base58Decode(base58Sting);
    } catch (e) {
        return false
    }

    /*if (base58Sting.length <= 4) {
        return false;
    }*/
    var len = address.length;
    var offset = len - 4;
    var checkSum = address.slice(offset);
    address = address.slice(0, offset);
    var hash0 = sha256(byteArray2hexStr(address));
    var hash1 = hexStr2byteArray(sha256(hash0));
    var checkSum1 = hash1.slice(0, 4);
    if (checkSum[0] === checkSum1[0] && checkSum[1] === checkSum1[1] && checkSum[2]
        === checkSum1[2] && checkSum[3] === checkSum1[3]
    ) {
        return address;
    }

    return false;
}

const getEnv = (currency: CurrencyType, networkType: string) => {
    var evn = networkType || 'prod';

    if (evn !== 'prod' && evn !== 'testnet') evn = 'prod';

    return currency.trxAddressTypes ? currency.trxAddressTypes[evn][0] : [0x41];
}

const isValidAddress = (address: string, currency: CurrencyType, opts: OptsType) => {
    const networkType = opts ? opts.networkType : '';
    const mainAddress = decodeBase58Address(address);

    if (!mainAddress) {
        return false;
    }

    if (mainAddress.length !== 21) {
        return false;
    }

    return getEnv(currency, networkType) === mainAddress[0];
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    isValidAddress
};
