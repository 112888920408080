export const config = {
  REACT_APP_API_URL: 'https://api-staging.cryptoprocessing.io/api/lk/v1',
  REACT_APP_API_TESTNET_URL: 'https://testnet.staging.cryptoprocessing.io/api/lk/v1',
  REACT_APP_PAY_URL: 'https://pay-staging.cryptoprocessing.io',
  REACT_APP_PAY_TESTNET_URL: 'https://pay-testnet-staging.cryptoprocessing.io',
  REACT_APP_APPLICATION_UID: '1c6ef1a127d490a646c7f5362954308e5a99ee4215c61a003b1f2db08739717f',
  REACT_APP_GOOGLE_OAUTH_CLIENT_ID: '28469131122-lp99nl27j38bfkgrl98tq1d2oqohvluh.apps.googleusercontent.com',
  REACT_APP_GITHUB_OAUTH_CLIENT_ID: '7f84727861606d064f0d',
  REACT_APP_OAUTH_REDIRECT_URI: 'https://app-staging.cryptoprocessing.io',
}
