import styled from 'styled-components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DateField } from '@mui/x-date-pickers/DateField';

export const CalendarBlock: any = styled.div`
  font-family: var(--inter);
  
  .calendar {
    &-icon {
      
    }
    
    &-label {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--dark-gray);
      margin-bottom: 8px;
    }
    
    &-wrap {
      position: relative;
    }
    
    &__btn {
      display: flex;
      background: var(--white);
      border: 1px solid var(--light-gray);
      padding: 9px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray);
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: var(--border-radius);
      cursor: pointer;

      

      &:before {
        content: '';
        display: inline-block;
        margin-right: 9px;
        width: 18px;
        height: 20px;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjMzMzMgMS42NjY1VjQuOTk5ODRNNS42NjY2NyAxLjY2NjVWNC45OTk4NE0xLjUgOC4zMzMxN0gxNi41TTMuMTY2NjcgMy4zMzMxN0gxNC44MzMzQzE1Ljc1MzggMy4zMzMxNyAxNi41IDQuMDc5MzYgMTYuNSA0Ljk5OTg0VjE2LjY2NjVDMTYuNSAxNy41ODcgMTUuNzUzOCAxOC4zMzMyIDE0LjgzMzMgMTguMzMzMkgzLjE2NjY3QzIuMjQ2MTkgMTguMzMzMiAxLjUgMTcuNTg3IDEuNSAxNi42NjY1VjQuOTk5ODRDMS41IDQuMDc5MzYgMi4yNDYxOSAzLjMzMzE3IDMuMTY2NjcgMy4zMzMxN1oiIHN0cm9rZT0iIzM0NDA1NCIgc3Ryb2tlLXdpZHRoPSIxLjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
        background-repeat: no-repeat;
        background-size: cover;
      }
      
      &.-has-dates {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
      }
    }
    
    &-error {
      margin: 6px 0;
      color: var(--red);
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      display: block;
      white-space: pre-line;
      transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }
  }
`;

export const CalendarHidden: any = styled(DatePicker)`
  position: absolute!important;
  height: 1px;
  width: 1px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
  
  .MuiInputBase-root {
    padding: 0;
    height: 1px;
    width: 1px;
  }
`;

export const CalendarPanel: any = styled.div`
  display: flex;
  
  &.export-payments {
    top: -100px;
  }

  .calendar {
    &-block {
      display: flex;
      flex-direction: column;
      
      &__calendar {
        display: flex;
        flex-direction: column;
      }
      
      &__btns {
        display: flex;
        gap: 12px;
        padding: 16px 24px;
        border-top: 1px solid var(--dark-white);
        margin-top: -5px;
      }
      
      &__btn {
        width: 100%;
        padding: 9px 24px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
    
    &-header {
      display: flex;
      gap: 12px;
      margin-top: 79px;
      justify-content: center;
      padding: 0 24px;
    }
    
    &-shortcuts {
      &__btn {
        background: var(--white);
        border: 1px solid var(--light-gray);
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: var(--border-radius);
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 9px 16px;
        color: var(--dark-gray);
        cursor: pointer;
      }
    }
  }
`;

export const CalendarField: any = styled(DateField)`
  .MuiInputBase-root {
    font-family: var(--inter);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    background: var(--white);
    border: 1px solid var(--light-gray);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: var(--border-radius);
  }

  .MuiFormControl-root {
    &:last-child {
      margin-left: 12px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  .MuiFormLabel-root {
    display: none;
  }

  .MuiInputBase-input {
    padding: 7px 14px;
    width: auto;
    max-width: 166px;
    height: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
  }

  .MuiFormControl-root .MuiInputBase-input {
    &::placeholder,
    &::-webkit-input-placeholder {
      opacity: 1!important;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--gray);
      transition: opacity 0.3s ease-in-out;
    }

    &:focus {
      &::placeholder {
        opacity: 0!important;
      }
    }
  }

  .MuiTypography-root {
    font-family: var(--inter);
    font-weight: 900;
    font-size: 8px;
    line-height: 24px;
    margin-left: 12px;
    color: var(--gray);
  }
`;

export const CalendarMain: any = styled(DateCalendar)`
  &.MuiDateCalendar-root {
    position: relative;
    overflow: visible;
  }
  .MuiPickersCalendarHeader-root {
    position: absolute;
    width: 100%;
    top: -110px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    
    .MuiPickersCalendarHeader-labelContainer {
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      text-align: center;
      pointer-events: none;
    }
    
    .MuiPickersCalendarHeader-label {
      font-family: var(--inter);
      margin-right: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--dark-gray);
    }
    
    .MuiPickersCalendarHeader-switchViewButton {
      display: none;
    }
    
    .MuiPickersArrowSwitcher-root {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  
  .MuiPickersFadeTransitionGroup-root {
    .MuiPickersSlideTransition-root {
      min-height: 284px;
    }

    .MuiPickersArrowSwitcher-root {
      padding: 0 16px;
      padding-top: 22px;
      padding-bottom: 11px;

      .MuiTypography-root {
        font-family: var(--inter);
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--dark-gray);
      }
    }

    .MuiDayCalendar-header {
      margin-top: 13px;
      margin-bottom: 4px;
      
      .MuiTypography-root {
        font-family: var(--inter);
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
        height: 40px;
      }
    }

    .MuiDayCalendar-weekContainer {
      margin-bottom: 8px;
    }

    .MuiDayCalendar-monthContainer {
      .MuiPickersDay-root {
        font-family: var(--inter);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
        width: 36px;
        height: 36px;

        &.Mui-selected {
          background: var(--purple);
          color: var(--white);

          &:hover {
            background: var(--purple);
            color: var(--white);
          }
        }
        
        &.Mui-disabled {
          color: var(--gray);
        }
        
        &.MuiPickersDay-today {
          background: #F2F4F7;
          border-color: transparent;

          &.Mui-selected {
            background: var(--purple);
          }
        }
        
        &:hover {
          background: #EEF4FF;
        }
      }
    }

    .MuiDateRangePickerDay-rangeIntervalDayHighlight {
      background: var(--light-blue);

      .MuiPickersDay-root {
        color: var(--dark-purple);
      }
    }

    .MuiDateRangePickerDay-hiddenDayFiller {
      background: var(--white);
    }

    .MuiDateRangeCalendar-root {
      > div:first-child {
        display: none;
      }
    }

    .MuiDateRangeCalendar-monthContainer {
      padding: 0 8px;
    }
  }
`;

