import types from '../actionTypes';
import {
  ExportPaymentsParams,
  GetPaymentsParams,
} from "../../api";

const getPayments = (payload: GetPaymentsParams) => ({
  type: types.GET_PAYMENTS_REQUEST,
  payload,
});

const exportPayments = (payload: ExportPaymentsParams) => ({
  type: types.EXPORT_PAYMENTS_REQUEST,
  payload,
});

const setPaymentsLoading = (payload: GetPaymentsParams) => ({
  type: types.SET_PAYMENTS_LOADING,
  payload,
});

export {
  getPayments,
  exportPayments,
  setPaymentsLoading,
};
