import styled from 'styled-components';
import {MenuItem, Select} from "@mui/material";

export const FormControl: any = styled.div`
  font-family: var(--inter);
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    -webkit-text-fill-color: var(--black);
    transition: background-color 5000s ease-in-out 0s;
    filter: none;
  }

  &.-error {
    input {
      box-shadow: 4px 4px 40px rgba(176, 22, 22, 0.07);
    }

    p {
      animation: fadeIn 0.8s;
      display: block;
      color: var(--red);
    }
  }
`;

export const FormControlInputWrap: any = styled.div`
  position: relative;
`;

export const FormControlInputLabel: any = styled.label`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-gray);
  margin-bottom: 6px;
`;

export const FormControlInputPrefix: any = styled.span`
  display: block;
  position: absolute;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray);
  top: 37px;
  left: 14px;
`;

export const FormControlInput: any = styled.input`
  padding: 9px 14px 9px 33px;
  width: 100%;
  color: var(--black);
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--light-gray);
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  transition: box-shadow 0.3s ease-in-out;

  &:focus {
    &::placeholder {
      opacity: 0;
    }
  }

  &::placeholder {
    opacity: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray);
    transition: opacity 0.3s ease-in-out;
  }

  &:disabled {
    color: var(--gray);
  }
`;

export const FormControlInputError: any = styled.p`
  margin: 8px 0;
  color: var(--red);
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  display: none;
  white-space: pre-line;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
`;

export const SelectElement: any = styled(Select)`
  &.MuiInputBase-root {
    position: absolute;
    padding: 0;
    color: var(--black);
    outline: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    transition: box-shadow 0.3s ease-in-out;
    font-family: var(--inter);
    right: 0;
    width: auto;
  
  .MuiOutlinedInput-notchedOutline {
      display: none;
    }
  
  .MuiSelect-select {
      padding: 9.3px 14px;
    }
  }
  
  .MuiSelect-icon {
    right: 10px;
    color: var(--gray);
  }
  
  .MuiSelect-selectMenu {
    padding: 12px 40px 12px 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #eaeaea;
    background: #eaeaea;
    box-shadow: #eaeaea;
    border-radius: var(--border-radius);
  }
  
  .MuiMenu-paper {
    border-radius: var(--border-radius);
    background: var(--white);
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.06);
    margin-top: 8px;
  }

  .MuiMenu-list {
    padding: 4px 0;
  }

  .MuiMenuItem-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px 11px 10px 14px;

    &.Mui-selected {
      background: transparent;

      &:hover {
        background: #F5F8FF;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 13px;
        width: 16px;
        height: 11px;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjY2NjMgMUw1LjQ5OTY3IDEwLjE2NjdMMS4zMzMwMSA2IiBzdHJva2U9IiM0NDRDRTciIHN0cm9rZS13aWR0aD0iMS42NjY2NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    &:hover {
      background: #F5F8FF;

      & .select-remove {
        display: block;
      }
    }
  }

  .MuiListItem-button {
    border-radius: 8px;
    padding: 10px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.06) 71.01%,
              rgba(255, 255, 255, 0) 99.52%
      );
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &:hover,
    &:focus {
      background-color: transparent;
      background-image: linear-gradient(
              90deg,
              rgba(232, 232, 232, 0.12) 0%,
              rgba(196, 196, 196, 0) 78.12%
      );
    }

    &.Mui-selected {
      background-color: transparent;
    }
  }
  
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: transparent;
  }
  
  &.dropdown-menu {
    background-color: transparent;
    border-radius: 5px;
    width: auto;
    margin-bottom: 0;
  
  .MuiSelect-select {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: var(--white);
      background-color: transparent;
    }
  
  .MuiSelect-selectMenu {
      height: auto;
      border-radius: 0;
    }
  
  .MuiMenu-paper {
      border-radius: var(--border-radius);
      box-shadow: 0 0 11px rgba(0, 0, 0, 0.04);
    }
  
  .MuiList-root {
      background: linear-gradient(
        to top,
        rgba(147, 90, 237, 0.21) 0.14%,
        rgba(41, 47, 57, 0) 99.86%
    );
      border-radius: 13px;
    }
  
  .MuiList-padding {
      padding: 11px 8px;
    }
  
  .MuiListItem-button {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: var(--white);
      border-radius: 8px;
  
      a {
        color: var(--white);
      }
  
    &::after {
        display: none;
      }
  
    &:focus,
    &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
  
    &.Mui-selected {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  
  .MuiMenuItem-root {
      min-height: auto;
    }
  
  &.-disable-rotate {
    &.Mui-focused {
        box-shadow: none;
      }
    & .MuiSelect-iconOpen {
        transform: none;
      }
    }
  }
  
  &.social-dropdown {
  .MuiSelect-selectMenu {
      padding: 0;
    }
  
  .MuiSelect-icon {
      top: auto;
      right: auto;
    }
  }
`;

export const SelectListItem: any = styled(MenuItem)``;
