import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import API from '../../api/executor';
import { setLoading } from '../loadingsErrors/actions';
import {
  CreateWithdrawalRequest,
  GetWithdrawalsRequest,
  DeclineWithdrawalRequest,
} from './actionTypes';
import types from '../actionTypes';
import {DEFAULT_PAGE_LIMIT} from "../../const/app.constants";

// calls
const createWithdrawalCall = (payload?: any) => API.call('createWithdrawal', payload);
const getWithdrawalsCall = (payload?: any) => API.call('getWithdrawals', payload);
const declineWithdrawalCall = (payload?: any) => API.call('declineWithdrawal', payload);

// call types
type GetWithdrawals = SagaReturnType<typeof getWithdrawalsCall>;

function* createWithdrawal({ payload }: CreateWithdrawalRequest) {
  yield put(setLoading(types.CREATE_WITHDRAWAL_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  try {
    yield call(() => createWithdrawalCall(payload));
    const res: GetWithdrawals = yield call(() => getWithdrawalsCall({
      storeId: storeId,
      status: "NEW",
      from_day: '',
      to_day: '',
      page: 1,
      limit: DEFAULT_PAGE_LIMIT,
      sort_by: 'created_at',
      direction: 'desc'
    }));

    yield all([
      put({
        type: types.CREATE_WITHDRAWAL_SUCCESS,
        payload: {
          storeId,
          withdrawals: res.data,
          answer: {
            success: 'You Withdrawal was successfully created'
          }
        }
      }),
      put(setLoading(types.CREATE_WITHDRAWAL_REQUEST, false)),
    ]);
  } catch (e: any) {
    if (e?.errObj) {
      const error = e?.errObj ? e.errObj : {error: e.toString()};

      yield all([
        put(setLoading(types.CREATE_WITHDRAWAL_REQUEST, false)),
        put({
          type: types.CREATE_WITHDRAWAL_FAILURE,
          payload: {error: error},
        }),
      ]);
    }
  }
}

function* getWithdrawals({ payload }: GetWithdrawalsRequest) {
  yield put(setLoading(types.GET_WITHDRAWALS_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  try {
    const res: GetWithdrawals = yield call(() => getWithdrawalsCall(payload));
    yield all([
      put({
        type: types.GET_WITHDRAWALS_SUCCESS,
        payload: {
          storeId,
          withdrawals: res.data,
          meta: res.meta
        }
      }),
      put(setLoading(types.GET_WITHDRAWALS_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.GET_WITHDRAWALS_REQUEST, false)),
      put({
        type: types.GET_WITHDRAWALS_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* declineWithdrawal({ payload }: DeclineWithdrawalRequest) {
  yield put(setLoading(types.DECLINE_WITHDRAWAL_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  const withdrawalId: string = payload?.withdrawalId || '';
  try {
    yield call(() => declineWithdrawalCall(payload));

    yield all([
      put({
        type: types.DECLINE_WITHDRAWAL_SUCCESS,
        payload: {
          storeId,
          withdrawalId,
          answer: {
            success: 'You Withdrawal was successfully declined'
          }
        }
      }),
      put(setLoading(types.DECLINE_WITHDRAWAL_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.DECLINE_WITHDRAWAL_REQUEST, false)),
      put({
        type: types.DECLINE_WITHDRAWAL_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

export default function* withdrawalsSagas() {
  yield takeLatest(types.CREATE_WITHDRAWAL_REQUEST, createWithdrawal);
  yield takeLatest(types.GET_WITHDRAWALS_REQUEST, getWithdrawals);
  yield takeLatest(types.DECLINE_WITHDRAWAL_REQUEST, declineWithdrawal);
}
