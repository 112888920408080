import {makeHandler} from "./makeHandler";

export const getTFA = makeHandler(
  'getTFA',
  () => ({
    method: 'GET',
    path: '/tfa',
  }),
  (res: GetTFAResponse) => res.data
);

export const activateTFA = makeHandler(
  'activateTFA',
  (data: ActivateTFAParams) => ({
    method: 'POST',
    path: '/tfa/activate',
    data,
  }),
  (res: ToggleTFAResponse) => res.data
);

export const turnOnTFA = makeHandler(
  'turnOnTFA',
  (data: ActivateTFAParams) => ({
    method: 'POST',
    path: '/tfa/turn_on',
    data,
  }),
  (res: ToggleTFAResponse) => res.data
);

export const turnOffTFA = makeHandler(
  'turnOffTFA',
  (data: ActivateTFAParams) => ({
    method: 'POST',
    path: '/tfa/turn_off',
    data,
  }),
  (res: ToggleTFAResponse) => res.data
);

export const getProfile = makeHandler(
  'getProfile',
  () => ({
    method: 'GET',
    path: '/profile',
  }),
  (res: GetProfileResponse) => res.data
);

export const updateProfile = makeHandler(
  'updateProfile',
  (data: UserData) => ({
    method: 'PUT',
    path: '/profile',
    data,
  }),
  (res: UpdateProfileResponse) => res.data
);

export const updateEmail = makeHandler(
  'updateEmail',
  (data: EmailData) => ({
    method: 'PUT',
    path: '/profile/change_email',
    data,
  }),
  (res: UpdateProfileResponse) => res.data
);

export const confirmUpdateEmailCode = makeHandler(
  'confirmUpdateEmailCode',
  (data: ConfirmUpdateEmailCodeParams) => ({
    method: 'POST',
    path: '/auth/confirm_email_change',
    data,
  }),
  (res: ToggleTFAResponse) => res.data
);

export type ActivateTFAParams = {
  tfa_code: string;
  password?: string;
};

export type GetTFAResponse = {
  data: {
    tfa_confirmed: boolean;
    tfa_enabled: boolean;
    provisioning_uri: string;
  }
};

export type ToggleTFAResponse = {
  data: {}
};

export type GetProfileResponse = {
  data: UserData
};

export type UpdateProfileResponse = {
  data: UserData
};

export type UserData = {
  /** country */
  country?: string;
  /** first name */
  first_name?: string;
  /** last name */
  last_name?: string;
  /** e-mail */
  email?: string;
  unconfirmed_email?: string;
  /** Avatar */
  photo?: string;
  success?: any;
  error?: any;
};

export type EmailData = {
  email?: string;
  // current_password?: string;
};

export type ConfirmUpdateEmailCodeParams = {
  /** token */
  old_email_confirmation_token?: string;
  new_email_confirmation_token?: string;
};
