import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { BreadCrumbList, BreadCrumbListItem } from './BreadCrumb.Styles';

const BreadCrumb: React.FC<any> = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const array = pathname
    .split('/')
    .filter(val => !!val)
    .map((route, i, arr) => {
      return (i > 1) ? {
        url: arr.filter((item, index) => index <= i).join('/'),
        route,
      } : {};
    }).filter(val => val.url);

  if (array[0].route === 'invoices' && array[array.length - 1].route === 'details') {
    array.splice(1, 1);
  }

  return (
    <BreadCrumbList>
      {array.map((route, i) =>
        i === array.length - 1 ? (
          <BreadCrumbListItem key={`breadcrumb-item-${i + 1}`}>
            {t(`navBar.${route.route}`)}
          </BreadCrumbListItem>
        ) : (
          <BreadCrumbListItem key={`breadcrumb-item-${i + 1}`}>
            <NavLink tabIndex={-1} to={`/${route.url}`}>{t(`navBar.${route.route}`)}</NavLink>
          </BreadCrumbListItem>
        )
      )}
    </BreadCrumbList>
  );
};

export default BreadCrumb;
