import React, {useCallback, useState, useEffect} from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Title,
  Loader,
  Logo, Input,
} from '../../../elements';
import { ConfirmNewPasswordStyles } from './ConfirmNewPassword.Styles';
import {UpdateForgottenParams} from "../../../api";
import {Link, useNavigate} from "react-router-dom";
import {PATHS} from "../../../const/paths.constants";
import { AppStateType } from '../../../store';
import types from '../../../store/actionTypes';
import { selectLoadingByKey } from '../../../store/loadingsErrors/selectors';
import {clear, updateForgottenPassword} from '../../../store/user/actions';
import {UserReducerState} from "../../../store/user/reducers";
import useToast from "../../../hooks/useToast";
import cloneDeep from "lodash/cloneDeep";

export interface Props {
  user: UserReducerState;
  updateForgottenPassword: (payload: UpdateForgottenParams) => void;
  clear: () => void;
  loading: boolean;
}

const ConfirmNewPassword: React.FC<Props> = (props: Props) => {
  const { user, updateForgottenPassword, clear, loading } = props;
  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();
  const [values, setValues] = useState<{ [key: string]: string }>({
    reset_password_token: '',
    password: '',
    password_confirmation: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [timer, setTimer] = useState<number>(0);

  const setInitialData = () => {
    setValues(prev => ({
      ...prev,
      reset_password_token: '',
      password: '',
      password_confirmation: '',
    }));
  }

  let getFormErrors: (data: { [p: string]: string }) => UpdateForgottenParams;
  getFormErrors = (data: { [key: string]: string }) => {
    const {reset_password_token, password, password_confirmation} = data;
    const newErrors: UpdateForgottenParams = {
      reset_password_token: '',
      password: '',
      password_confirmation: '',
    };

    if (!reset_password_token) newErrors.reset_password_token = 'Enter change password code';
    if (!password) newErrors.password = 'Enter new password';
    if (!password_confirmation) newErrors.password_confirmation = 'Enter new password again';
    if (password !== password_confirmation) newErrors.password_confirmation = 'Passwords mismatch';

    return newErrors;
  };

  const onSubmit = useCallback(
    (e?: React.ChangeEvent<any>) => {
      e?.preventDefault();
      const newErrors: UpdateForgottenParams = getFormErrors(values);
      setErrors(newErrors);

      const confirmData: UpdateForgottenParams = {
        reset_password_token: values.reset_password_token,
        password: values.password,
        password_confirmation: values.password_confirmation,
      };

      if (!checkErrors(newErrors)) {
        updateForgottenPassword(confirmData);
      }
    },
    [values, updateForgottenPassword, getFormErrors]
  );

  useEffect(() => {
    if (user.answer?.error?.reset_password_token) {
      const newErrors: UpdateForgottenParams = {
        reset_password_token: '',
        password: '',
        password_confirmation: ''
      };
      Object.keys(user.answer.error).forEach((key: string) => {
        newErrors[key] = user.answer.error[key];
      });

      setErrors(newErrors);

      clear();
    }

    if (user.answer?.error?.error) {
      toastError(user.answer?.error?.error);
      clear();
    }

    if (user.answer?.success) {
      toastSuccess(user.answer?.success);
      navigate(PATHS.LOGIN);
      clear();
      setInitialData();
    }
  }, [user, toastError, toastSuccess, clear, onSubmit, navigate]);

  useEffect(() => {
    const s = timer - 1;

    if (s < 1) {
      setTimer(0);
      return () => {};
    }

    const intervalId: ReturnType<typeof setTimeout> = setTimeout(() => {
      setTimer(s);
    }, 1000);

    return () => {
      clearTimeout(intervalId);
    };
  }, [timer]);

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: UpdateForgottenParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: UpdateForgottenParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  return (
    <ConfirmNewPasswordStyles className='confirmEmail'>
      <div className="confirmEmail-container">
        <div className="grid-container confirmEmail-grid">
          <div className="confirmEmail-top">
            <Logo className="confirmEmail-logo" to={PATHS.NEW_DASHBOARD}>
              <img src="/img/main/logo.svg" alt="cp" />
            </Logo>
          </div>
          <div className="confirmEmail-center">
            <div className="confirmEmail-box">

              <Title className='confirmEmail-title'>
                Change Password confirmation
              </Title>
              <form onSubmit={onSubmit}>
                <Input
                  className='confirmEmail-input'
                  type="text"
                  name="reset_password_token"
                  value={values.reset_password_token}
                  placeholder="Enter change password code"
                  error={errors.reset_password_token}
                  onChange={onChange}
                  onBlur={onBlur}
                  autoComplete="one-time-code"
                />
                <Input
                  className='confirmEmail-input'
                  type="password"
                  name="password"
                  value={values.password}
                  placeholder="Enter new password"
                  error={errors.password}
                  onChange={onChange}
                  onBlur={onBlur}
                  autoComplete="one-time-code"
                />
                <Input
                  className='confirmEmail-input'
                  type="password"
                  name="password_confirmation"
                  value={values.password_confirmation}
                  placeholder="Enter new password again"
                  error={errors.password_confirmation}
                  onChange={onChange}
                  onBlur={onBlur}
                  autoComplete="one-time-code"
                />
                <Button
                  className="confirmEmail-btn"
                  type="submit"
                  disabled={loading}
                >
                  Change Password
                  {loading ? <Loader/> : null}
                </Button>
              </form>

              <div className="confirmEmail-entrance">
                <p>Remember your password? </p>
                <Link to={PATHS.LOGIN}>Login</Link>
              </div>
            </div>
          </div>
          <div className="confirmEmail-bottom">
            <div className="confirmEmail-bottom__copyright_wrap">
              <span className="confirmEmail-bottom__copyright">© Cryptoprocessing</span>
            </div>
            <div className="confirmEmail-bottom__link_wrap">
              <Link tabIndex={-1} className="confirmEmail-bottom__link" to={PATHS.PRIVACY_POLICE} target="_blank">Privacy</Link>
              <span className="confirmEmail-bottom__link"> & </span>
              <Link tabIndex={-1} className="confirmEmail-bottom__link" to={PATHS.TERMS_OF_USE} target="_blank">terms</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="confirmEmail-image">
        <div className="confirmEmail-image__container">
          <div className="confirmEmail-image__box">
            <div className="confirmEmail-image__star">
              <img src="/img/auth/star.svg" alt="Tier" />
            </div>
            <div className="confirmEmail-image__title">Start accepting crypto payments easily and securely.</div>
            <div className="confirmEmail-image__text">Create a free account and get full access to all features for 30-days. No credit card needed. Get started in 2 minutes.</div>
            <div className="confirmEmail-image__avatars">
              <div className="confirmEmail-image__avatar">
                <img
                  className="confirmEmail-image__avatar_img"
                  srcSet="/img/auth/ava1.png, /img/auth/ava1@2x.png 2x"
                  src="/img/auth/ava1.png"
                  alt="Tier"
                />
                <img
                  className="confirmEmail-image__avatar_img"
                  srcSet="/img/auth/ava2.png, /img/auth/ava2@2x.png 2x"
                  src="/img/auth/ava2.png"
                  alt="Tier"
                />
                <img
                  className="confirmEmail-image__avatar_img"
                  srcSet="/img/auth/ava3.png, /img/auth/ava3@2x.png 2x"
                  src="/img/auth/ava3.png"
                  alt="Tier"
                />
                <img
                  className="confirmEmail-image__avatar_img"
                  srcSet="/img/auth/ava4.png, /img/auth/ava4@2x.png 2x"
                  src="/img/auth/ava4.png"
                  alt="Tier"
                />
                <img
                  className="confirmEmail-image__avatar_img"
                  srcSet="/img/auth/ava5.png, /img/auth/ava5@2x.png 2x"
                  src="/img/auth/ava5.png"
                  alt="Tier"
                />
              </div>
              <p>Join 40,000+ users</p>
              <div className="confirmEmail-image__arrow">
                <img src="/img/auth/arrow.svg" alt="Tier" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfirmNewPasswordStyles>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
    loading: selectLoadingByKey(state, types.UPDATE_FORGOTTEN_PASSWORD_REQUEST),
  };
};

export default connect(mapState, { updateForgottenPassword, clear })(ConfirmNewPassword);
