import React, {useCallback, useState, useEffect} from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Title,
  Loader,
  Logo, Input,
} from '../../../elements';
import { ConfirmEmailStyles } from './ConfirmEmail.Styles';
import {ConfirmEmailCodeParams, ResendConfirmationCodeParams} from "../../../api";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {PATHS} from "../../../const/paths.constants";
import { AppStateType } from '../../../store';
import types from '../../../store/actionTypes';
import { selectLoadingByKey } from '../../../store/loadingsErrors/selectors';
import {clear, confirmEmail, resendConfirmation} from '../../../store/user/actions';
import {UserReducerState} from "../../../store/user/reducers";
import useToast from "../../../hooks/useToast";
import cloneDeep from "lodash/cloneDeep";

export interface ConfirmEmailProps {
  user: UserReducerState;
  confirmEmail: (payload: ConfirmEmailCodeParams) => void;
  resendConfirmation: (payload: ResendConfirmationCodeParams) => void;
  clear: () => void;
  loading: boolean;
}

const ConfirmEmail: React.FC<ConfirmEmailProps> = (props: ConfirmEmailProps) => {
  const { user, confirmEmail, resendConfirmation, clear, loading } = props;
  const { toastError, toastSuccess } = useToast();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const confirmationToken = searchParams.get('confirmation_token');
  const confirmationEmail = searchParams.get('confirmation_email');
  const [values, setValues] = useState<{ [key: string]: string }>({
    confirmation_token: confirmationToken || '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [timer, setTimer] = useState<number>(120);
  const [codeResended, setCodeResended] = useState<boolean>(false);

  const setInitialData = () => {
    setValues(prev => ({
      ...prev,
      confirmation_token: '',
    }));
  }

  let getFormErrors: (data: { [p: string]: string }) => ConfirmEmailCodeParams;
  getFormErrors = (data: { [key: string]: string }) => {
    const {confirmation_token} = data;
    const newErrors: ConfirmEmailCodeParams = {
      confirmation_token: '',
    };

    if (!confirmation_token) newErrors.confirmation_token = 'Enter confirmation code';

    return newErrors;
  };

  const onSubmit = useCallback(
    (e?: React.ChangeEvent<any>) => {
      e?.preventDefault();
      const newErrors: ConfirmEmailCodeParams = getFormErrors(values);
      setErrors(newErrors);

      const confirmData: ConfirmEmailCodeParams = {
        confirmation_token: values.confirmation_token,
      };

      if (!checkErrors(newErrors)) {
        confirmEmail(confirmData);
      }
    },
    [values, confirmEmail, getFormErrors]
  );

  useEffect(() => {
    if (confirmationToken && !user.confirmationCodeSanded) {
      onSubmit();
    }

    if (user.answer?.success) {
      toastSuccess(user.answer?.success);
      if (codeResended) {
        setCodeResended(false);
      } else {
        navigate(PATHS.LOGIN);
      }
      clear();
      setInitialData();
    }

    if (user.answer?.error?.confirmation_token) {
      const newErrors: ConfirmEmailCodeParams = {
        confirmation_token: ''
      };
      Object.keys(user.answer.error).forEach((key: string) => {
        newErrors[key] = user.answer.error[key];
      });

      setErrors(newErrors);

      clear();
    }

    if (user.answer?.error?.error) {
      toastError(user.answer?.error?.error);
      clear();
    }

    if (user.answer?.error?.email) {
      toastError(user.answer?.error?.email);
      clear();
    }
  }, [user, toastError, toastSuccess, clear, confirmationToken, onSubmit, navigate]);

  useEffect(() => {
    const s = timer - 1;

    if (s < 1) {
      setTimer(0);
      return () => {};
    }

    const intervalId: ReturnType<typeof setTimeout> = setTimeout(() => {
      setTimer(s);
    }, 1000);

    return () => {
      clearTimeout(intervalId);
    };
  }, [timer]);

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: ConfirmEmailCodeParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: ConfirmEmailCodeParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onResendCode = () => {
    const data: ResendConfirmationCodeParams = {
      email: confirmationEmail || ''
    };
    setTimer(120);
    setCodeResended(true);
    resendConfirmation(data);
  };

  return (
    <ConfirmEmailStyles className='confirmEmail'>
      <div className="confirmEmail-container">
        <div className="grid-container confirmEmail-grid">
          <div className="confirmEmail-top">
            <Logo className="confirmEmail-logo" to={PATHS.NEW_DASHBOARD}>
              <img src="/img/main/logo.svg" alt="cp" />
            </Logo>
          </div>
          <div className="confirmEmail-center">
            <div className="confirmEmail-box">

              <Title className='confirmEmail-title'>
                Email confirmation
              </Title>
              <form onSubmit={onSubmit}>
                <Input
                  className='confirmEmail-input'
                  type="text"
                  name="confirmation_token"
                  value={values.confirmation_token}
                  placeholder="Enter your code"
                  error={errors.confirmation_token}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                <Button
                  className="confirmEmail-btn"
                  type="submit"
                  disabled={loading}
                >
                  Verify
                  {loading ? <Loader /> : null}
                </Button>
              </form>

              {
                confirmationEmail ? (
                  <div className="confirmEmail-resend">
                    {
                      timer === 0 ? (
                        <>
                          <p>You didn't receive a code?</p>
                          <Button
                            className="confirmEmail-resend__btn"
                            disabled={loading}
                            type="button"
                            onClick={() => onResendCode()}
                          >
                            Send code again
                          </Button>
                        </>
                      ) : (
                        <p>
                          Didn't receive a letter? You can send again in <span className="confirmEmail-resend__purple">{timer}</span> seconds
                        </p>
                      )
                    }
                  </div>
                ) : null
              }


              <div className="confirmEmail-entrance">
                <p>Not registered yet? </p>
                <Link to={PATHS.REGISTRATION}>Register</Link>
              </div>
              <div className="confirmEmail-entrance">
                <Link to={PATHS.FORGOT_PASSWORD}>Forgot Password?</Link>
              </div>
            </div>
          </div>
          <div className="confirmEmail-bottom">
            <div className="confirmEmail-bottom__copyright_wrap">
              <span className="confirmEmail-bottom__copyright">© Cryptoprocessing</span>
            </div>
            <div className="confirmEmail-bottom__link_wrap">
              <Link tabIndex={-1} className="confirmEmail-bottom__link" to={PATHS.PRIVACY_POLICE} target="_blank">Privacy</Link>
              <span className="confirmEmail-bottom__link"> & </span>
              <Link tabIndex={-1} className="confirmEmail-bottom__link" to={PATHS.TERMS_OF_USE} target="_blank">terms</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="confirmEmail-image">
        <div className="confirmEmail-image__container">
          <div className="confirmEmail-image__box">
            <div className="confirmEmail-image__star">
              <img src="/img/auth/star.svg" alt="Tier" />
            </div>
            <div className="confirmEmail-image__title">Start accepting crypto payments easily and securely.</div>
            <div className="confirmEmail-image__text">Create a free account and get full access to all features for 30-days. No credit card needed. Get started in 2 minutes.</div>
            <div className="confirmEmail-image__avatars">
              <div className="confirmEmail-image__avatar">
                <img
                  className="confirmEmail-image__avatar_img"
                  srcSet="/img/auth/ava1.png, /img/auth/ava1@2x.png 2x"
                  src="/img/auth/ava1.png"
                  alt="Tier"
                />
                <img
                  className="confirmEmail-image__avatar_img"
                  srcSet="/img/auth/ava2.png, /img/auth/ava2@2x.png 2x"
                  src="/img/auth/ava2.png"
                  alt="Tier"
                />
                <img
                  className="confirmEmail-image__avatar_img"
                  srcSet="/img/auth/ava3.png, /img/auth/ava3@2x.png 2x"
                  src="/img/auth/ava3.png"
                  alt="Tier"
                />
                <img
                  className="confirmEmail-image__avatar_img"
                  srcSet="/img/auth/ava4.png, /img/auth/ava4@2x.png 2x"
                  src="/img/auth/ava4.png"
                  alt="Tier"
                />
                <img
                  className="confirmEmail-image__avatar_img"
                  srcSet="/img/auth/ava5.png, /img/auth/ava5@2x.png 2x"
                  src="/img/auth/ava5.png"
                  alt="Tier"
                />
              </div>
              <p>Join 40,000+ users</p>
              <div className="confirmEmail-image__arrow">
                <img src="/img/auth/arrow.svg" alt="Tier" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfirmEmailStyles>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
    loading: selectLoadingByKey(state, types.CONFIRM_EMAIL_REQUEST),
  };
};

export default connect(mapState, { confirmEmail, resendConfirmation, clear })(ConfirmEmail);
