import React, {useCallback, useState} from "react";
import {Button, CopyInput, Icon, Loader} from "../../../elements";
import AuthCode from "react-auth-code-input";
import {APIKey} from "../../../api";
import {ShowApiKey} from "./ShowKey.styles"

interface ModalKeyProps {
  closeModal: () => void;
  applyModal: (payload: any) => void;
  apiKey?: APIKey;
}

const ShowKey: React.FC<ModalKeyProps> = (props: ModalKeyProps) => {
  const { closeModal, applyModal, apiKey } = props;

  const [tfaCode, setTfaCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleAuthCode = (res: string) => {
    setTfaCode(res);
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();

      const data: any = {
        data: {
          tfa_code: tfaCode,
        },
        apiKeyId: apiKey?.id
      };

      setLoading(true)
      applyModal(data);
    },
    [tfaCode, applyModal, apiKey, setLoading]
  );

  return (
    <ShowApiKey>
      <div className="addKey-icon">
        <Icon name="show_key" size="56" />
      </div>
      <div className="addKey-texts">
        <span className="addKey-texts__title">Show API key: <span className="-black">{ apiKey?.name }</span></span>
        <span className="addKey-texts__description">Please enter a 2FA code for showing this API key</span>
      </div>
      <div>
        <form className="addKey-form" onSubmit={onSubmit}>
          {
            !apiKey?.key ? (
              <div className="addKey-code__wrap">
                <AuthCode
                  containerClassName="addKey-code__container"
                  inputClassName="addKey-code"
                  allowedCharacters="numeric"
                  length={6}
                  placeholder="0"
                  autoFocus={false}
                  onChange={handleAuthCode}
                />
              </div>
            ) : (
              <div className="addKey-copy__wrap">
                <CopyInput
                  className="addKey-copy"
                  name="secretKey"
                  type="text"
                  value={apiKey?.key || ''}
                />
              </div>
            )
          }
          <div className="addKey-form__actions">
            <div className="addKey-form__btns">
              <Button
                className="addKey-form__btn -white"
                type="button"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              {
                !apiKey?.key ? (
                  <Button
                    className="addKey-form__btn"
                    type="submit"
                    disabled={tfaCode.length !== 6 || loading}
                  >
                    Continue
                    {loading ? <Loader /> : null}
                  </Button>
                ) : null
              }

            </div>
          </div>
        </form>
      </div>
    </ShowApiKey>
  );
};

export default ShowKey;
