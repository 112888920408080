import React, {useCallback, useEffect, useRef, useState} from "react";
import cloneDeep from "lodash/cloneDeep";
import {Button, CopyInput, Icon, Input, Loader} from "../../../elements";
import AuthCode, {AuthCodeRef} from "react-auth-code-input";
import {APIKey, CreateAPIKeyParams} from "../../../api";
import {AddApiKey} from "./AddKey.styles"
import {InputType} from "../../../elements/Input/Input";
import {AppStateType} from "../../../store";
import {connect} from "react-redux";
import {createAPIKey} from "../../../store/stores/actions";
import {selectLoadingByKey} from "../../../store/loadingsErrors/selectors";
import types from "../../../store/actionTypes";
import {StoresReducerState} from "../../../store/stores/reducers";

interface ModalKeyProps {
  stores: StoresReducerState;
  closeModal: () => void;
  apiKey?: APIKey;
  title: string;
  description: string;
  placeholder: string;
  inputType?: InputType;
  createAPIKey: (payload: CreateAPIKeyParams) => void;
  loadingCreateAPIKey: boolean;
  storeId: string;
}

const AddKey: React.FC<ModalKeyProps> = (props: ModalKeyProps) => {
  const { closeModal, title, description, placeholder, inputType, createAPIKey, loadingCreateAPIKey, stores, storeId } = props;

  const [values, setValues] = useState<{ [key: string]: string }>({
    name: '',
    tfa_code: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [apiKey, setApiKey] = useState<APIKey | undefined>(undefined);
  const tfaInputRef = useRef<AuthCodeRef>(null);

  useEffect(() => {
    if (stores.answer?.success) {
      if (stores.answer?.success === 'You API key was successfully created') {
        setApiKey(stores.createdApiKey);
      }
    }

    if (stores.answer?.error?.error) {
      if (stores.answer?.error?.error === '2fa code is invalid') {
        tfaInputRef.current?.clear();
        tfaInputRef.current?.focus();
      }

      if (stores.answer?.error?.error === 'Max number of failed attempts reached. All action with 2FA blocked. Please contact with the administration') {
        tfaInputRef.current?.clear();
        tfaInputRef.current?.focus();
      }
    }
  }, [stores, setApiKey]);

  const getFormErrors = (data: any) => {
    const { name } = data;
    const newErrors: any = {
      name: '',
    };

    if (!name) newErrors.name = 'Enter name';

    return newErrors;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: any = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: any = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const handleAuthCode = (res: string) => {
    onChange('tfa_code', res);
  };

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: any = getFormErrors(values);
      setErrors(newErrors);

      const data: any = {
        name: values.name,
        tfa_code: values.tfa_code,
      };

      if (!checkErrors(newErrors)) {
        const createData: CreateAPIKeyParams = {
          data: data,
          storeId
        };

        createAPIKey(createData);
        // applyModal(data);
      }
    },
    [values, storeId, createAPIKey]
  );

  return (
    <AddApiKey>
      <div className="addKey-icon">
        <Icon name="add_key" size="56" />
      </div>
      <div className="addKey-texts">
        <span className="addKey-texts__title">{title}</span>
        <span className="addKey-texts__description">{description}</span>
      </div>
      {
        !!apiKey?.key ? (
          <>
            <div className="addKey-copy__wrap">
              <CopyInput
                className="addKey-copy"
                name="secretKey"
                type="text"
                value={apiKey?.key || ''}
              />
            </div>
            <div className="addKey-form__actions">
              <div className="addKey-form__btns">
                <Button
                  className="addKey-form__btn -white"
                  type="button"
                  onClick={() => closeModal()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div>
            <form className="addKey-form" onSubmit={onSubmit}>
              <div className='addKey-input__wrap'>
                <Input
                  className='addKey-input'
                  type={inputType ? inputType : "text"}
                  name="name"
                  value={values.name}
                  placeholder={placeholder}
                  error={errors.name}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </div>
              <div className="addKey-code__wrap">
                <AuthCode
                  ref={tfaInputRef}
                  containerClassName="addKey-code__container"
                  inputClassName="addKey-code"
                  allowedCharacters="numeric"
                  length={6}
                  placeholder="0"
                  autoFocus={false}
                  onChange={handleAuthCode}
                />
              </div>
              <div className="addKey-form__actions">
                <div className="addKey-form__btns">
                  <Button
                    className="addKey-form__btn -white"
                    type="button"
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="addKey-form__btn loading-btn"
                    type="submit"
                    disabled={loadingCreateAPIKey || values.tfa_code.length !== 6}
                  >
                    Continue
                    {loadingCreateAPIKey ? <Loader /> : null}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        )
      }
    </AddApiKey>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { stores } = state;
  return {
    stores,
    loadingCreateAPIKey: selectLoadingByKey(state, types.CREATE_API_KEY_REQUEST),
  };
};

export default connect(mapStateToProps, {createAPIKey})(AddKey);
