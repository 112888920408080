import styled from 'styled-components';

export const TitleBlock: any = styled.h1`
  font-family: var(--inter);
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: var(--black);
  margin: 0;
`;
