import cloneDeep from 'lodash/cloneDeep';
import React, {useCallback, useEffect, useState} from 'react';
import { connect } from 'react-redux';

import { validateEmail } from '../../../common/utils/validators';
import {
  Button,
  Title,
  Input,
  Loader,
  Logo,
} from '../../../elements';
import { ForgotPasswordStyles } from './ForgotPassword.Styles';
import {ForgotPasswordParams} from "../../../api";
import {Link, useNavigate} from "react-router-dom";
import {PATHS} from "../../../const/paths.constants";
import { AppStateType } from '../../../store';
import types from '../../../store/actionTypes';
import { selectLoadingByKey } from '../../../store/loadingsErrors/selectors';
import { forgotPassword, clear } from '../../../store/user/actions';
import useToast from "../../../hooks/useToast";
import {UserReducerState} from "../../../store/user/reducers";

export interface ForgotPasswordProps {
  user: UserReducerState;
  loading: boolean;
  clear: () => void;
  forgotPassword: (payload: ForgotPasswordParams | any) => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props: ForgotPasswordProps) => {
  const { user, clear, loading, forgotPassword } = props;
  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();
  const [values, setValues] = useState<{ [key: string]: string }>({
    email: ''
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const setInitialData = () => {
    setValues(prev => ({
      ...prev,
      email: '',
    }));
  }

  useEffect(() => {

    if (user.answer?.error) {
      if (user.answer.error.error) {
        toastError(String(user.answer.error.error));
      }
      const newErrors: ForgotPasswordParams = {
        email: ''
      };
      Object.keys(user.answer.error).forEach((key: string) => {
        newErrors[key] = user.answer.error[key];
      });

      setErrors(newErrors);

      clear();
      setInitialData();
    }

    if (user.answer?.success) {
      toastSuccess(user.answer?.success);
      if (user.answer?.success === "A recovery link has been sent to your email") {
        navigate(`${PATHS.CONFIRMATION_FORGOT_PASSWORD}?confirmation_email=${values.email}`);
      }
      clear();
      setInitialData();
    }
  }, [user.answer, toastError, toastSuccess, setErrors, clear]);

  let getFormErrors: (data: { [p: string]: string }) => ForgotPasswordParams;
  getFormErrors = (data: { [key: string]: string }) => {
    const {email} = data;
    const newErrors: ForgotPasswordParams = {
      email: '',
    };

    if (!email) newErrors.email = 'Enter email';
    if (email && !validateEmail(email)) newErrors.email = 'Please enter a valid email';

    return newErrors;
  };

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: ForgotPasswordParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: ForgotPasswordParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: ForgotPasswordParams = getFormErrors(values);
      setErrors(newErrors);

      const data: ForgotPasswordParams = {
        email: values.email.toLowerCase(),
      };
      if (!checkErrors(newErrors)) {
        forgotPassword(data);
      }
    },
    [values, getFormErrors, forgotPassword]
  );

  return (
    <ForgotPasswordStyles className='forgotPassword'>
      <div className="forgotPassword-container">
        <div className="grid-container forgotPassword-grid">
          <div className="forgotPassword-top">
            <Logo className="forgotPassword-logo" to={PATHS.NEW_DASHBOARD}>
              <img src="/img/main/logo.svg" alt="cp" />
            </Logo>
          </div>
          <div className="forgotPassword-center">
            <div className="forgotPassword-box">

              <Title className='forgotPassword-title'>
                Forgot password
              </Title>
              <form onSubmit={onSubmit}>
                <Input
                  className='forgotPassword-input'
                  type="email"
                  name="email"
                  value={values.email}
                  placeholder="Enter your email"
                  label="Email"
                  error={errors.email}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                <Button
                  className="forgotPassword-btn"
                  type="submit"
                  disabled={loading}
                >
                  Forgot password
                  {loading ? <Loader /> : null}
                </Button>
              </form>

              <div className="forgotPassword-entrance">
                <p>Remember your password? </p>
                <Link to={PATHS.LOGIN}>Login</Link>
              </div>
            </div>
          </div>
          <div className="forgotPassword-bottom">
            <div className="forgotPassword-bottom__copyright_wrap">
              <span className="forgotPassword-bottom__copyright">© Cryptoprocessing</span>
            </div>
            <div className="forgotPassword-bottom__link_wrap">
              <Link tabIndex={-1} className="forgotPassword-bottom__link" to={PATHS.PRIVACY_POLICE} target="_blank">Privacy</Link>
              <span className="forgotPassword-bottom__link"> & </span>
              <Link tabIndex={-1} className="forgotPassword-bottom__link" to={PATHS.TERMS_OF_USE} target="_blank">terms</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="forgotPassword-image">
        <div className="forgotPassword-image__container">
          <div className="forgotPassword-image__box">
            <div className="forgotPassword-image__star">
              <img src="/img/auth/star.svg" alt="Tier" />
            </div>
            <div className="forgotPassword-image__title">Start accepting crypto payments easily and securely.</div>
            <div className="forgotPassword-image__text">Create a free account and get full access to all features for 30-days. No credit card needed. Get started in 2 minutes.</div>
            <div className="forgotPassword-image__avatars">
              <div className="forgotPassword-image__avatar">
                <img
                  className="forgotPassword-image__avatar_img"
                  srcSet="/img/auth/ava1.png, /img/auth/ava1@2x.png 2x"
                  src="/img/auth/ava1.png"
                  alt="Tier"
                />
                <img
                  className="forgotPassword-image__avatar_img"
                  srcSet="/img/auth/ava2.png, /img/auth/ava2@2x.png 2x"
                  src="/img/auth/ava2.png"
                  alt="Tier"
                />
                <img
                  className="forgotPassword-image__avatar_img"
                  srcSet="/img/auth/ava3.png, /img/auth/ava3@2x.png 2x"
                  src="/img/auth/ava3.png"
                  alt="Tier"
                />
                <img
                  className="forgotPassword-image__avatar_img"
                  srcSet="/img/auth/ava4.png, /img/auth/ava4@2x.png 2x"
                  src="/img/auth/ava4.png"
                  alt="Tier"
                />
                <img
                  className="forgotPassword-image__avatar_img"
                  srcSet="/img/auth/ava5.png, /img/auth/ava5@2x.png 2x"
                  src="/img/auth/ava5.png"
                  alt="Tier"
                />
              </div>
              <p>Join 40,000+ users</p>
              <div className="forgotPassword-image__arrow">
                <img src="/img/auth/arrow.svg" alt="Tier" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ForgotPasswordStyles>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
    loading: selectLoadingByKey(state, types.FORGOT_PASSWORD_REQUEST),
  };
};

export default connect(mapState, { forgotPassword, clear })(ForgotPassword);
