import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Icon, Loader} from "../../../elements";
import {IPWL, UpdateIPWLParams} from "../../../api";
import {DeleteApiKey} from "./DeleteIPWL.styles"
import {connect} from "react-redux";
import {AppStateType} from "../../../store";
import {selectLoadingByKey} from "../../../store/loadingsErrors/selectors";
import types from "../../../store/actionTypes";
import {updateIPWL} from "../../../store/stores/actions";
import {StoresReducerState} from "../../../store/stores/reducers";
import AuthCode, {AuthCodeRef} from "react-auth-code-input";
import cloneDeep from "lodash/cloneDeep";

interface ModalKeyProps {
  stores: StoresReducerState;
  closeModal: () => void;
  ipwl: IPWL;
  ipwls: IPWL[];
  ipwl_index: number;
  title: string;
  updateIPWL: (payload: UpdateIPWLParams) => void;
  loadingDeleteIPWL: boolean;
  storeId: string;
}

const DeleteIPWL: React.FC<ModalKeyProps> = (props: ModalKeyProps) => {
  const { stores, closeModal, updateIPWL, ipwl, ipwls, ipwl_index, title, loadingDeleteIPWL, storeId } = props;

  const [tfaCode, setTfaCode] = useState<string>('');
  const tfaInputRef = useRef<AuthCodeRef>(null);

  const newList = cloneDeep(ipwls);
  newList.splice(ipwl_index, 1);

  useEffect(() => {
    if (stores.answer?.success) {
      closeModal();
    }

    if (stores.answer?.error?.error) {
      if (stores.answer?.error?.error === '2fa code is invalid') {
        tfaInputRef.current?.clear();
        tfaInputRef.current?.focus();
      }

      if (stores.answer?.error?.error === 'Max number of failed attempts reached. All action with 2FA blocked. Please contact with the administration') {
        tfaInputRef.current?.clear();
        tfaInputRef.current?.focus();
      }

      if (stores.answer?.error?.error === 'address whitelist is blank') {
        tfaInputRef.current?.clear();
        tfaInputRef.current?.focus();
      }
    }
  }, [closeModal, stores]);

  const handleAuthCode = (res: string) => {
    setTfaCode(res);
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();

      const deleteData: UpdateIPWLParams = {
        data: {
          address_whitelist: newList.join(','),
          tfa_code: tfaCode,
        },
        storeId
      };

      updateIPWL(deleteData);
    },
    [newList, tfaCode, storeId, updateIPWL]
  );

  return (
    <DeleteApiKey>
      <div className="addKey-icon">
        <Icon name="remove_key" size="56" />
      </div>
      <div className="addKey-texts">
        <span className="addKey-texts__title">{title}</span>
        <span className="addKey-texts__description">{ipwl}</span>
      </div>
      <div>
        <form className="addKey-form" onSubmit={onSubmit}>
          <div className="addKey-code__wrap">
            <AuthCode
              ref={tfaInputRef}
              containerClassName="addKey-code__container"
              inputClassName="addKey-code"
              allowedCharacters="numeric"
              length={6}
              placeholder="0"
              autoFocus={false}
              onChange={handleAuthCode}
            />
          </div>
          <div className="addKey-form__actions">
            <div className="addKey-form__btns">
              <Button
                className="addKey-form__btn -white"
                type="button"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              <Button
                className="addKey-form__btn loading-btn"
                type="submit"
                disabled={loadingDeleteIPWL || tfaCode.length !== 6}
              >
                Continue
                {loadingDeleteIPWL ? <Loader/> : null}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </DeleteApiKey>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const {stores} = state;
  return {
    stores,
    loadingDeleteIPWL: selectLoadingByKey(state, types.UPDATE_IPWL_REQUEST),
  };
};

export default connect(mapStateToProps, {updateIPWL})(DeleteIPWL);
