import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ToastsProvider } from './contexts/ToastsContext'
import { HashRouter as Router } from 'react-router-dom';

import i18n from './common/utils/i18n';

import App from './App';
import store from './store';

import './assets/styles/main.scss';
import reportWebVitals from './reportWebVitals';
window.Buffer = window.Buffer || require("buffer").Buffer;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Router>
          <ToastsProvider>
            <App />
          </ToastsProvider>
        </Router>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
