import React, { useMemo } from 'react';

import { SiteWrapper } from '../Dashboard/Layout.Styles';
import Loading from "../../Loading";
import {connect} from "react-redux";
import {AppStateType} from "../../../store";
import {selectLoadingByKey} from "../../../store/loadingsErrors/selectors";
import types from "../../../store/actionTypes";

interface Props {
  oAuthLoading: boolean;
  storesLoading: boolean;
  testnetLoading: boolean;
  children: any;
}

const AuthLayout: React.FC<Props> = (props: Props) => {
  const { oAuthLoading, storesLoading, testnetLoading, children } = props;

  const loading = useMemo(() => {
    return oAuthLoading || storesLoading || testnetLoading
  }, [oAuthLoading, storesLoading, testnetLoading]);

  return (
    <SiteWrapper className="auth">
      {children}

      {
        loading ? <Loading /> : null
      }
    </SiteWrapper>
  );
};

const mapState = (state: AppStateType) => {
  return {
    oAuthLoading: selectLoadingByKey(state, types.OAUTH_LOGIN_REQUEST),
    storesLoading: selectLoadingByKey(state, types.GET_STORES_REQUEST),
    testnetLoading: selectLoadingByKey(state, types.CHANGE_USING_TESTNET),
  };
};

export default connect(mapState, {})(AuthLayout);
