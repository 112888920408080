import styled from 'styled-components';

import { FormControl, FormControlInput } from '../Input/Input.Styles';

export const ReferralInputBlock: any = styled(FormControl)`
  display: flex;
  align-items: center;
  position: relative;
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--light-gray);

  .ref-input {
    &__wrap {
      display: flex;
      flex: 1;
      flex-direction: column;
      position: relative;
      height: 100%;
    }
  }
`;

export const ReferralField: any = styled(FormControlInput)`
  user-select: none;
  overflow-x: scroll;
  background: none;
  border-radius: 0;
  border: 0;
  padding: 9px 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  min-width: 370px;
  
  &::selection {
    color: var(--black);
    background-color: transparent;
  }

  &:disabled {
    color: var(--black);
  }
`;

export const ReferralCopy: any = styled.button`
  display: flex;
  border-radius: 0 8px 8px 0;
  border: 0;
  outline: none;
  cursor: pointer;
  height: 42px;
  align-items: center;
  justify-content: center;
  background: var(--purple);
  color: var(--white);
  padding: 9px 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  
  & .copy {
    width: 60px;
  }

  &:before {
    content: '';
    display: inline-block;
    margin-right: 10px;
    width: 21px;
    height: 21px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuMTY2NSAxMi40OTk4SDMuMzMzMTdDMi44OTExNCAxMi40OTk4IDIuNDY3MjIgMTIuMzI0MiAyLjE1NDY2IDEyLjAxMTdDMS44NDIxIDExLjY5OTEgMS42NjY1IDExLjI3NTIgMS42NjY1IDEwLjgzMzJWMy4zMzMxN0MxLjY2NjUgMi44OTExNCAxLjg0MjEgMi40NjcyMiAyLjE1NDY2IDIuMTU0NjZDMi40NjcyMiAxLjg0MjEgMi44OTExNCAxLjY2NjUgMy4zMzMxNyAxLjY2NjVIMTAuODMzMkMxMS4yNzUyIDEuNjY2NSAxMS42OTkxIDEuODQyMSAxMi4wMTE3IDIuMTU0NjZDMTIuMzI0MiAyLjQ2NzIyIDEyLjQ5OTggMi44OTExNCAxMi40OTk4IDMuMzMzMTdWNC4xNjY1TTkuMTY2NSA3LjQ5OTg0SDE2LjY2NjVDMTcuNTg3IDcuNDk5ODQgMTguMzMzMiA4LjI0NjAzIDE4LjMzMzIgOS4xNjY1VjE2LjY2NjVDMTguMzMzMiAxNy41ODcgMTcuNTg3IDE4LjMzMzIgMTYuNjY2NSAxOC4zMzMySDkuMTY2NUM4LjI0NjAzIDE4LjMzMzIgNy40OTk4NCAxNy41ODcgNy40OTk4NCAxNi42NjY1VjkuMTY2NUM3LjQ5OTg0IDguMjQ2MDMgOC4yNDYwMyA3LjQ5OTg0IDkuMTY2NSA3LjQ5OTg0WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-size: cover;
    stroke-dasharray: 50;
    stroke-dashoffset: 0;
    transition: all 100ms ease-in-out
  }

  &:hover {
    &:before {
      opacity: 1;
      visibility: visible;
      content: attr(data-title);
    }
  }
  
  &.-copied {
    &:before {
      content: '';
      display: inline-block;
      margin-right: 10px;
      width: 21px;
      height: 19px;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAyMSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOC40OTg3IDEuMzE2OUw2LjU2MjAyIDEyLjgzNjhMMy4zOTQ0NyA5LjQ1MjVDMi44MTA5NyA4LjkwMjM1IDEuODk0MDUgOC44NjkwMSAxLjIyNzIgOS4zMzU4QzAuNTc3MDE2IDkuODE5MjcgMC4zOTM2MzEgMTAuNjY5NSAwLjc5Mzc0MyAxMS4zNTNMNC41NDQ3OSAxNy40NTQ3QzQuOTExNTYgMTguMDIxNiA1LjU0NTA3IDE4LjM3MTcgNi4yNjE5NCAxOC4zNzE3QzYuOTQ1NDYgMTguMzcxNyA3LjU5NTY0IDE4LjAyMTYgNy45NjI0MSAxNy40NTQ3QzguNTYyNTggMTYuNjcxMiAyMC4wMTU4IDMuMDE3MzcgMjAuMDE1OCAzLjAxNzM3QzIxLjUxNjIgMS40ODM2MSAxOS42OTkgMC4xMzMyMzQgMTguNDk4NyAxLjMwMDIzVjEuMzE2OVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);
      background-repeat: no-repeat;
      background-size: cover;
      stroke-dasharray: 50;
      stroke-dashoffset: -50;
      transition: all 100ms ease-in-out
    }
  }
`;
