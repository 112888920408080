import React, {useState, useCallback, useEffect} from 'react';
import { FormControlLabel, Collapse, ClickAwayListener } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import { connect } from 'react-redux';
import {NavLink, useLocation, useNavigate, useParams} from 'react-router-dom';

import {
  Menu,
  MenuList,
  MenuListItem,
  MenuListLink,
  SidebarWrapper,
} from './Sidebar.Styles';
import {PATHS} from "../../const/paths.constants";
import {Icon, Logo, Skeleton, Switch} from '../../elements';
import {changeUsingTestnet, logout, clear, getTFA} from '../../store/user/actions';
import { AppStateType} from "../../store";
import {UserReducerState} from "../../store/user/reducers";
import {StoresReducerState} from "../../store/stores/reducers";
import {Store} from "../../api";
import {getStore, setStore} from "../../store/stores/actions";
import {LOADING_TYPES} from "../../const/app.constants";
import {selectLoadingByKey} from "../../store/loadingsErrors/selectors";
import types from "../../store/actionTypes";
import useToast from "../../hooks/useToast";
import GoToTFA from "../Modals/GoToTFA";
import {closeModal, openModal} from "../../store/app/actions";

type SidebarProps = {
  user: UserReducerState;
  stores: StoresReducerState;
  setStore: (id: string | null) => void;
  changeUsingTestnet: (payload: any) => void;
  logout: () => void;
  storesLoading: boolean;
  testnetLoading: boolean;
  clear: () => void;
  getTFA: () => void;
  openModal: (payload: any) => void;
  closeModal: () => void;
  getStore: (payload: {storeId: string}) => void;
  storeLoading: boolean;
};

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const { toastError, toastSuccess } = useToast();
  const { user, stores, setStore, changeUsingTestnet, logout, storesLoading, testnetLoading, clear, getTFA, openModal, closeModal, getStore, storeLoading } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { store } = useParams();
  const [testMode, setTestMode] = useState<boolean>(user.useTestnet);
  const [expanded, setExpanded] = useState<any>([]);
  const [activeShop, setActiveShop] = useState<Store | undefined>(undefined);
  const [createShop, setCreateShop] = useState<boolean>(false);

  const toggleAccordion = (id: number) => {
    let newShop: number[] = cloneDeep(expanded);

    if (expanded.includes(id)) {
      newShop = newShop.filter((exp: number) => exp !== id);
    } else {
      newShop.push(id);
    }

    setExpanded(newShop);
  };

  useEffect(() => {
    if (stores.stores.loaded === LOADING_TYPES.LOADED) {
      const shop: Store | undefined = find(stores.stores.list, { id: store });
      if (store !== stores.activeStore && stores.stores.list) {
        if (shop) {
          setStore(`${store}`);
          setActiveShop(shop);
        }
      }

      if (stores.activeStore !== activeShop?.id && stores.stores.list) {
        if (shop) {
          setActiveShop(shop);

          if (store !== stores.activeStore) {
            setStore(`${store}`)
            if (store) {
              getStore({storeId: store});
            }
          }
        }

        if (!shop && stores.activeStore) {
          const newShop: Store | undefined = find(stores.stores.list, { id: stores.activeStore });

          setActiveShop(newShop);

          if (pathname === PATHS.NEW_DASHBOARD) {
            if (!createShop) {
              navigate(PATHS.DASHBOARD.replace(':store', stores.activeStore))
            }

            if (stores.stores.created) {
              setCreateShop(false);
              navigate(PATHS.DASHBOARD.replace(':store', stores.activeStore))
            }

            if (stores.stores.changed) {
              setCreateShop(false);
              navigate(PATHS.DASHBOARD.replace(':store', stores.activeStore))
            }
          } else {
            navigate(pathname.replace(`${store}`, stores.activeStore))
          }
        }
      }

      if (!stores.stores.list.length && !createShop) {
        setCreateShop(true);
      }

      if (store && stores.stores.loadedShop === LOADING_TYPES.NOT_LOADED && !storeLoading) {
        getStore({storeId: store});
      }
    }

    if (!user.tfa.loaded && !user.useTestnet) {
      getTFA();
    }

    if (user.answer?.success) {
      toastSuccess(String(user.answer.success));
      clear();
      if (user.useTestnet !== testMode) {
        setStore(null);
        setActiveShop(undefined);
        if (expanded.length) {
          toggleAccordion(1);
        }
        navigate(PATHS.NEW_DASHBOARD);
        setTestMode(user.useTestnet);
      }
    }

    if (user.answer?.error?.error) {
      toastError(String(user.answer.error.error));
      clear();
    }

  }, [stores, storeLoading, activeShop, setActiveShop, createShop, store, setStore, navigate, pathname, user.useTestnet, testMode, setTestMode, setCreateShop, user.answer, toastSuccess, toastError, toggleAccordion, clear, expanded.length, user.tfa.loaded, getTFA]);

  const tfaDisabled = () => (
    <div className="modal-content">
      <div className="modal-addKey">
        <GoToTFA closeModal={handleCloseModal} />
      </div>
    </div>
  );

  const handleOpenModal = (payload: any) => {
    if (!openModal) return
    openModal(payload)
  };

  const handleCloseModal = () => {
    if (!closeModal) return
    closeModal()
  };

  const setShop = (shop: Store) => {
    setStore(shop.id);
    setActiveShop(shop);
    toggleAccordion(1);
    setCreateShop(false);
    navigate(`/stores/${shop.id}/dashboard`);
  };

  const setNewShop = () => {
    setStore(null);
    setActiveShop(undefined);
    setCreateShop(true);
    toggleAccordion(1);
    navigate(PATHS.NEW_DASHBOARD);
  };

  const toggleTestMode = useCallback(() => {
    if (!testMode && !user.tfa.tfa_enabled) {
      handleOpenModal({
        closeModal: closeModal,
        className: "modal modalAddKey",
        content: tfaDisabled
      })
    } else {
      changeUsingTestnet(!testMode);
    }
  }, [changeUsingTestnet, closeModal, handleOpenModal, testMode, tfaDisabled, user.tfa]);

  const handleLogOut = () => {
    logout();
  };

  return (
    <SidebarWrapper>
      <Logo className="logo" to={stores.activeStore ? PATHS.DASHBOARD.replace(':store', `${stores.activeStore}`) : PATHS.NEW_DASHBOARD}>
        <img src="/img/main/logo.svg" alt="Tier" />
      </Logo>

      <Menu className="menu">
        <MenuList className="menu__list">

          {
            storesLoading && !stores.stores.list.length ? (
              <MenuListItem className="menu__item -loading">
                <Skeleton />
              </MenuListItem>
            ) : null
          }

          {
            !stores.stores.list.length && createShop ? (
              <MenuListItem className="menu__item -new">
                <button
                  className="menu__link"
                  onClick={() => setNewShop()}
                  tabIndex={-1}
                >
                  <span className="accordion-text">Create new shop</span>
                </button>
              </MenuListItem>
            ) : null
          }

          {
            stores.stores.list.length ? (
              <ClickAwayListener onClickAway={() => setExpanded([])}>
                <MenuListItem className={`menu__item -dropdown ${createShop ? '-new_wrap' : ''}`}>
                  <div className='accordion'>
                    <button
                      className={`accordion-title ${expanded.includes(1) ? '-active' : ''} ${createShop ? '-new' : ''}`}
                      onClick={() => toggleAccordion(1)}
                      tabIndex={-1}
                    >
                      {
                        createShop ? (
                          <div className="menu__link">
                            <span className="accordion-text">Create new shop</span>
                          </div>
                        ) : (
                          <>
                            <Icon name="shop" size="20" />
                            <span className="accordion-text">{activeShop?.name}</span>
                          </>
                        )
                      }
                      <i className="accordion-arrow" />
                    </button>
                    <Collapse
                      className='accordion-content'
                      in={expanded.includes(1)}
                      timeout="auto"
                      unmountOnExit
                    >
                      <ul className="accordion-list">
                        {
                          stores.stores.list.map((shop, index) => (
                            <li
                              key={`shop-${index + 1}`}
                              className={`accordion-list__item ${shop.id === activeShop?.id && !createShop ? '-active' : ''}`}
                            >
                              <button
                                className="accordion-list__btn"
                                onClick={() => setShop(shop)}
                                tabIndex={-1}
                              >
                                <span className="accordion-text">{shop?.name}</span>
                              </button>
                            </li>
                          ))
                        }
                        <li
                          className={`accordion-list__item -new ${createShop ? '-active' : ''}`}
                        >
                          <button
                            className="accordion-list__btn"
                            onClick={() => setNewShop()}
                            tabIndex={-1}
                          >
                            <span className="accordion-text">Create new shop</span>
                          </button>
                        </li>
                      </ul>
                    </Collapse>
                  </div>
                </MenuListItem>
              </ClickAwayListener>
            ) : null
          }

          <MenuListItem className={(pathname === PATHS.DASHBOARD.replace(':store', `${stores.activeStore}`)) || (pathname === PATHS.NEW_DASHBOARD) ? 'menu__item -active' : 'menu__item'}>
            <MenuListLink tabIndex={-1} to={stores.activeStore ? PATHS.DASHBOARD.replace(':store', `${stores.activeStore}`) : PATHS.NEW_DASHBOARD} className='menu__link'>
              <Icon name="home" size="22" />
              <span className="menu__text">Home</span>
            </MenuListLink>
          </MenuListItem>
          {
            stores.activeStore ? (
              <>
                <MenuListItem className={pathname === PATHS.PAYMENTS.replace(':store', `${stores.activeStore}`) ? 'menu__item -active' : 'menu__item'}>
                  <MenuListLink tabIndex={-1} to={PATHS.PAYMENTS.replace(':store', `${stores.activeStore}`)} className='menu__link'>
                    <Icon name="payments" size="24" />
                    <span className="menu__text">Payments</span>
                  </MenuListLink>
                </MenuListItem>
                <MenuListItem className={pathname === PATHS.INVOICES.replace(':store', `${stores.activeStore}`) ? 'menu__item -active' : 'menu__item'}>
                  <MenuListLink tabIndex={-1} to={PATHS.INVOICES.replace(':store', `${stores.activeStore}`)} className='menu__link'>
                    <Icon name="payout" size="22" />
                    <span className="menu__text">Invoices</span>
                  </MenuListLink>
                </MenuListItem>
                <MenuListItem className={pathname === PATHS.DEVELOPERS.replace(':store', `${stores.activeStore}`) ? 'menu__item -active' : 'menu__item'}>
                  <MenuListLink tabIndex={-1} to={PATHS.DEVELOPERS.replace(':store', `${stores.activeStore}`)} className='menu__link'>
                    <Icon name="developers" size="22" />
                    <span className="menu__text">Developers</span>
                  </MenuListLink>
                </MenuListItem>
                <MenuListItem className={pathname === PATHS.STORE_SETTINGS.replace(':store', `${stores.activeStore}`) ? 'menu__item -active' : 'menu__item'}>
                  <MenuListLink tabIndex={-1} to={PATHS.STORE_SETTINGS.replace(':store', `${stores.activeStore}`)} className='menu__link'>
                    <Icon name="settings" size="24" />
                    <span className="menu__text">Settings</span>
                  </MenuListLink>
                </MenuListItem>
              </>
            ) : null
          }

          <MenuListItem className="menu__item -switch">
            <FormControlLabel
              className="switch"
              control={
                <Switch
                  className=""
                  checked={testMode}
                  onChange={toggleTestMode}
                  disabled={testnetLoading}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Test mode"
            />
            <div className="user">
              <NavLink
                className={`user-wrap ${user.useTestnet ? '-disabled' : ''}`}
                to={PATHS.SETTINGS}
                tabIndex={-1}
              >
                <div className="user-img__wrap">
                  <img className="user-img" src="/img/sidebar/user-placeholder.svg" alt="Tier" />
                </div>
                <div className="user-info">
                  <span className="user-info__name">{user.profile.first_name} {user.profile.last_name}</span>
                  <span className="user-info__email">{user.profile.email}</span>
                </div>
              </NavLink>
              <div className="user-logout">
                <button tabIndex={-1} className="header-logout" onClick={handleLogOut}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H7.5M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5" stroke="#667085" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </div>
            </div>
          </MenuListItem>
        </MenuList>
      </Menu>
    </SidebarWrapper>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { user, stores } = state;
  return {
    user,
    stores,
    storesLoading: selectLoadingByKey(state, types.GET_STORES_REQUEST),
    storeLoading: selectLoadingByKey(state, types.GET_STORE_REQUEST),
    testnetLoading: selectLoadingByKey(state, types.CHANGE_USING_TESTNET),
  };
};

export default connect(mapStateToProps, { logout, setStore, changeUsingTestnet, getTFA, clear, openModal, closeModal, getStore})(Sidebar);
