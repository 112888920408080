import {
  ExportPaymentsParams,
  GetPaymentsParams
} from '../../api';

const GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST';
const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
const GET_PAYMENTS_FAILURE = 'GET_PAYMENTS_FAILURE';
const EXPORT_PAYMENTS_REQUEST = 'EXPORT_PAYMENTS_REQUEST';
const EXPORT_PAYMENTS_SUCCESS = 'EXPORT_PAYMENTS_SUCCESS';
const EXPORT_PAYMENTS_FAILURE = 'EXPORT_PAYMENTS_FAILURE';
const SET_PAYMENTS_LOADING = 'SET_PAYMENTS_LOADING';

type GetPaymentsActionType = {
  type: typeof GET_PAYMENTS_SUCCESS | typeof GET_PAYMENTS_FAILURE;
  payload?: any;
};

export type GetPaymentsRequest = {
  type: typeof GET_PAYMENTS_REQUEST;
  payload: GetPaymentsParams;
};

type ExportPaymentsActionType = {
  type: typeof EXPORT_PAYMENTS_SUCCESS | typeof EXPORT_PAYMENTS_FAILURE;
  payload?: any;
};

export type ExportPaymentsRequest = {
  type: typeof EXPORT_PAYMENTS_REQUEST;
  payload: ExportPaymentsParams;
};

type SetPaymentsLoading = {
  type: typeof SET_PAYMENTS_LOADING;
  payload: GetPaymentsParams;
};

// refactored for shorter version
export type GetPaymentsTypes = GetPaymentsActionType | GetPaymentsRequest;
export type ExportPaymentsTypes = ExportPaymentsActionType | ExportPaymentsRequest;

export type WalletActionTypes =
  | GetPaymentsTypes
  | ExportPaymentsTypes
  | SetPaymentsLoading;

export default {
  GET_PAYMENTS_REQUEST,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_FAILURE,
  EXPORT_PAYMENTS_REQUEST,
  EXPORT_PAYMENTS_SUCCESS,
  EXPORT_PAYMENTS_FAILURE,
  SET_PAYMENTS_LOADING,
} as const;
