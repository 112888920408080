import React, {useCallback, useEffect, useState} from "react";
import cloneDeep from "lodash/cloneDeep";
import {Button, Icon, Input, Loader} from "../../../elements";
import {CreateWebhookParams} from "../../../api";
import {AddEndpointStyle} from "./AddEndpoint.styles"
import {InputType} from "../../../elements/Input/Input";
import {connect} from "react-redux";
import {AppStateType} from "../../../store";
import {selectLoadingByKey} from "../../../store/loadingsErrors/selectors";
import types from "../../../store/actionTypes";
import {createWebhook} from "../../../store/stores/actions";
import {StoresReducerState} from "../../../store/stores/reducers";

interface ModalKeyProps {
  stores: StoresReducerState;
  closeModal: () => void;
  title: string;
  description: string;
  placeholder: string;
  inputType?: InputType;
  createWebhook: (payload: CreateWebhookParams) => void;
  loadingCreateWebhook: boolean;
  storeId: string;
}

const AddEndpoint: React.FC<ModalKeyProps> = (props: ModalKeyProps) => {
  const { stores, closeModal, createWebhook, storeId, loadingCreateWebhook, title, description, placeholder, inputType } = props;

  const [values, setValues] = useState<{ [key: string]: string }>({
    name: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (stores.answer?.success) {
      if (stores.answer?.success === 'You endpoint was successfully created') {
        closeModal();
      }
    }

    if (stores.answer?.error?.error) {
      if (stores.answer?.error?.error === 'webhook url already exist') {
        setErrors({
          name: stores.answer?.error?.error
        });
      } else {
        closeModal();
      }
    }
  }, [closeModal, stores]);

  const getFormErrors = (data: any) => {
    const { name } = data;
    const newErrors: any = {
      name: '',
    };

    if (!name) newErrors.name = 'Enter name';

    return newErrors;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: any = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: any = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: any = getFormErrors(values);
      setErrors(newErrors);

      const createData: CreateWebhookParams = {
        data: {
          webhook_url: values.name
        },
        storeId
      };

      if (!checkErrors(newErrors)) {
        createWebhook(createData);
      }
    },
    [values, storeId, createWebhook]
  );

  return (
    <AddEndpointStyle>
      <div className="addKey-icon">
        <Icon name="add_key" size="56" />
      </div>
      <div className="addKey-texts">
        <span className="addKey-texts__title">{title}</span>
        <span className="addKey-texts__description">{description}</span>
      </div>
      <div>
        <form className="addKey-form" onSubmit={onSubmit}>
          <div className='addKey-input__wrap'>
            <Input
              className='addKey-input'
              type={inputType ? inputType : "text"}
              name="name"
              value={values.name}
              placeholder={placeholder}
              error={errors.name}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
          <div className="addKey-form__actions">
            <div className="addKey-form__btns">
              <Button
                className="addKey-form__btn -white"
                type="button"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              <Button
                className="addKey-form__btn"
                type="submit"
                disabled={loadingCreateWebhook}
              >
                Continue
                {loadingCreateWebhook ? <Loader /> : null}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </AddEndpointStyle>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { stores } = state;
  return {
    stores,
    loadingCreateWebhook: selectLoadingByKey(state, types.CREATE_WEBHOOK_REQUEST),
  };
};

export default connect(mapStateToProps, {createWebhook})(AddEndpoint);
