import styled from 'styled-components';
// import Slider from '@mui/material/Slider';

export const SettingsContainer: any = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--inter);

  .settings {
    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      
      &__title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: var(--black);
      }
      
      &__btns {
        display: flex;
        flex-direction: row;
        gap: 12px;
      }
      
      &__btn {
        display: flex;
        background: var(--white);
        border: 1px solid var(--light-gray);
        padding: 9px 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
        align-items: center;
        
        &:before {
          content: '';
          display: inline-block;
          margin-right: 9px;
          margin-left: 1px;
          width: 18px;
          height: 18px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0IDkuODMzMzNWMTQuODMzM0MxNCAxNS4yNzU0IDEzLjgyNDQgMTUuNjk5MyAxMy41MTE4IDE2LjAxMThDMTMuMTk5MyAxNi4zMjQ0IDEyLjc3NTQgMTYuNSAxMi4zMzMzIDE2LjVIMy4xNjY2N0MyLjcyNDY0IDE2LjUgMi4zMDA3MiAxNi4zMjQ0IDEuOTg4MTYgMTYuMDExOEMxLjY3NTU5IDE1LjY5OTMgMS41IDE1LjI3NTQgMS41IDE0LjgzMzNWNS42NjY2N0MxLjUgNS4yMjQ2NCAxLjY3NTU5IDQuODAwNzIgMS45ODgxNiA0LjQ4ODE2QzIuMzAwNzIgNC4xNzU1OSAyLjcyNDY0IDQgMy4xNjY2NyA0SDguMTY2NjdNMTEuNSAxLjVIMTYuNU0xNi41IDEuNVY2LjVNMTYuNSAxLjVMNy4zMzMzMyAxMC42NjY3IiBzdHJva2U9IiMzNDQwNTQiIHN0cm9rZS13aWR0aD0iMS42NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    &-tabs {
      &__wrap {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
      }
    }
  }

  .profile {
    &-title {
      display: block;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: var(--black);
      
      &__wrap {
        margin-top: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--dark-white);
      }
    }
    
    &-desc {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray);
      margin-top: 4px;
    }
    
    &-container {}
    
    &-row {
      margin-top: 25px;
      padding-bottom: 14px;
      border-bottom: 1px solid var(--dark-white);
    }
    
    &-name {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--dark-gray);
    }
    
    &-input {}
    &-textarea {
      textarea {
        height: 92px;
      }
    }
    
    &-upload {
      position: relative;
      
      &__area {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: var(--white);
        border: 1px solid var(--dark-white);
        border-radius: var(--border-radius);
        padding: 15px 24px;
      }
      
      &__image {
        width: 40px;
        height: 40px;
      }
      
      &__title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray);
        margin-top: 12px;
        
        & .-blue {
          font-weight: 500;
          color: var(--dark-purple);
        }
      }
      
      &__text {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: var(--gray);
        margin-top: 4px;
      }
      
      &__input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0;
      }
      
      &__files {
        display: flex;
        flex-direction: column;
      }
      
      &__file {
        display: flex;
        position: relative;
        align-items: flex-start;
        justify-content: space-between;
        background: var(--white);
        border: 1px solid var(--blue);
        border-radius: var(--border-radius);
        padding: 13px 17px 15px 15px;
        margin-top: 16px;
        
        &_img {
          width: 32px;
          height: 32px;
          z-index: 1;
        }
        
        &_texts {
          display: flex;
          flex-direction: column;
          margin-right: auto;
          margin-left: 14px;
          margin-top: 2px;
          z-index: 1;
        }
        
        &_title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--dark-gray);
        }
        
        &_size {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray);
        }
        
        &_icon {
          z-index: 1;
        }
        
        &_again {
          display: flex;
          background: transparent;
          border: 0;
          outline: none;
          margin: 0;
          padding: 0;
          cursor: pointer;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #B42318;
          margin-top: 4px;
        }

        &_remove {
          background: transparent;
          border: 0;
          outline: none;
          margin: 0;
          padding: 0;
          cursor: pointer;
          
          svg {
            stroke: #B42318;
          }
        }
        
        &.-error {
          background: #FFFBFA;
          border: 1px solid #FDA29B;
        }
        
        &.-progress {
          border: 1px solid #E4E7EC;
        }
      }
    }
    
    &-btns {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 16px;
    }

    &-btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 9px 15px;
    }
  }

  .tfa {
    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: var(--black);

      &__wrap {
        margin-top: 31px;
        padding-bottom: 19px;
        border-bottom: 1px solid var(--dark-white);
      }
    }

    &-wrap {
      margin-top: 24px;
      padding-bottom: 9px;
      border-bottom: 1px solid var(--dark-white);
    }

    &-row {

    }

    &-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      color: var(--dark-gray);
    }
    
    &-qrcode {
      
    }
    
    &-copy {
      display: flex;
      min-width: 370px;
    }

    &-code {
      width: 48px;
      height: 48px;
      border: 1px solid var(--light-gray);
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: var(--border-radius);
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      letter-spacing: -0.02em;
      color: var(--purple);
      outline: none;

      &::placeholder {
        color: var(--light-gray);
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
      }

      &:focus {
        &::placeholder {
          opacity: 0;
        }
      }

      &__wrap {
        margin-top: 6px;
      }

      &__container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
      }
    }

    &-btns {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 16px;
    }

    &-btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 9px 15px;
    }
  }
`;
