import {GITHUB_OAUTH_CLIENT_ID, OAUTH_REDIRECT_URI} from "../../const/general.constants";

export function getGitHubUrl() {
  const rootURl = "https://github.com/login/oauth/authorize";

  const options = {
    client_id: GITHUB_OAUTH_CLIENT_ID as string,
    redirect_uri: OAUTH_REDIRECT_URI as string,
    scope: "user:email",
    state: 'github',
  };

  const qs = new URLSearchParams(options);

  return `${rootURl}?${qs.toString()}`;
}
