import React from 'react';

import { LoadingStyles } from './Loading.Styles';

const Loading: React.FC = () => {
  return (
    <LoadingStyles>
      <svg width="183" height="183" viewBox="0 0 183 183" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_diiii_1019_5)">
          <path d="M158 91.5C158 128.227 128.227 158 91.5 158C54.7731 158 25 128.227 25 91.5C25 54.7731 54.7731 25 91.5 25" stroke="white"/>
        </g>
        <defs>
          <filter id="filter0_diiii_1019_5" x="1.5" y="1.5" width="180" height="180" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="11.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.207843 0 0 0 0 0.219608 0 0 0 0 0.803922 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1019_5"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1019_5" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="11.5"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.207843 0 0 0 0 0.219608 0 0 0 0 0.803922 0 0 0 1 0"/>
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1019_5"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="11.5"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.207843 0 0 0 0 0.219608 0 0 0 0 0.803922 0 0 0 1 0"/>
            <feBlend mode="normal" in2="effect2_innerShadow_1019_5" result="effect3_innerShadow_1019_5"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="11.5"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.207843 0 0 0 0 0.219608 0 0 0 0 0.803922 0 0 0 1 0"/>
            <feBlend mode="normal" in2="effect3_innerShadow_1019_5" result="effect4_innerShadow_1019_5"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="0.5"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.207843 0 0 0 0 0.219608 0 0 0 0 0.803922 0 0 0 1 0"/>
            <feBlend mode="normal" in2="effect4_innerShadow_1019_5" result="effect5_innerShadow_1019_5"/>
          </filter>
        </defs>
      </svg>
    </LoadingStyles>
  );
};

export default Loading;
