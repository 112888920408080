import styled from 'styled-components';
import { NavLink } from 'react-router-dom';


export const SidebarWrapper: any = styled.aside`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  flex: 0 0 312px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  background: var(--white);
  min-height: 100vh;
  font-family: var(--inter);
  border-right: 1px solid var(--dark-white);
  
  .logo {
    display: block;
    position: relative;
    width: 221px;
    margin-left: 8px;
  }
`;

export const Menu: any = styled.nav`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const MenuList: any = styled.ul`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 3px;
  flex-grow: 1;
`;

export const MenuListItem: any = styled.li`
  overflow: hidden;
  padding-left: 9px;
  margin-bottom: 7px;

  &:first-child {
    margin-top: 14px;
    padding-left: 10px;
  }

  &:last-child {
    margin-top: auto;
    padding-left: 0;
    margin-bottom: 0;
  }

  button {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;

    &.menu__link {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px 0 8px 24px;

      &:before {
        content: '';
        position: relative;
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--blue);
        margin-right: 13px;
      }
    }
  }
  
  &.-active {
    background: var(--light-blue);
    border-radius: 6px;
  }
  
  &.-new {
    display: flex;
    align-items: center;
    background: var(--cream);
    border-radius: 6px;
    margin-bottom: 32px;
    padding-left: 0;
  }
  
  &.-new_wrap {
    padding-left: 0;
  }

  &.-loading {
    display: grid;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 32px;
    height: 44px;
    padding-left: 0;
    
    & > div {
      height: 44px;
      border-radius: 6px;
    }
  }
  
  & .accordion {
    padding-bottom: 12px;

    &-arrow {
      width: 12px;
      height: 8px;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDEuNUw2IDYuNUwxMSAxLjUiIHN0cm9rZT0iIzk4QTJCMyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
      background-repeat: no-repeat;
      transition: transform 0.3s ease-in-out;
    }
    
    &-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-right: auto;
      color: var(--dark-gray);
    }
    
    &-title {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      min-height: 48px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--dark-gray);
      transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
      cursor: pointer;
      padding-right: 16px;

      svg {
        width: 22px;
        min-width: 22px;
        stroke: var(--gray);
        margin-right: 13px;
        transition: fill 0.3s ease-in-out, stroke 0.3s ease-in-out;
      }

      &.-active {
        .accordion-arrow {
          transform: rotate(-180deg);
        }
      }

      &.-new {
        background: var(--cream);
        border-radius: 6px;
        
        & .menu__link {
          margin-right: auto;
        }

        &:before {
          content: '';
          position: relative;
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: var(--blue);
          margin-right: 13px;
          margin-left: 22px;
        }
      }
    }
    
    &-list {
      display: flex;
      flex-direction: column;
      padding-left: 35px;
      padding-top: 4px;
      padding-bottom: 8px;
      
      &__item {
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--dark-gray);
        margin-bottom: 4px;

        &.-active {
          &:after {
            content: '';
            position: absolute;
            top: 13px;
            right: 14px;
            width: 16px;
            height: 12px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjY2NjMgMUw1LjQ5OTY3IDEwLjE2NjdMMS4zMzMwMSA2IiBzdHJva2U9IiM2MTcyRjMiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
            background-repeat: no-repeat;
            transition: transform 0.3s ease-in-out;
          }
        }

        &.-new {
          display: flex;
          align-items: center;
          margin-left: -33px;
          padding-left: 12px;
          background: var(--cream);
          border-radius: 6px;
          
          &:before {
            content: '';
            position: relative;
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: var(--blue);
            margin-right: 13px;
          }
        }
      }
      
      &__btn {
        display: block;
        cursor: pointer;
        padding: 8px 0;
        width: 100%;
        text-align: left;
      }
    }
  }
  
  & .switch {
    margin-left: 10px;
    margin-right: 0;
  }
  
  .user {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 21px;
    border-top: 1px solid #E4E7EC;
    margin-top: 24px;
    
    &-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-left: 5px;
      
      &.-disabled {
        pointer-events: none;
      }
    }
    
    &-img {
      width: 16px;
      height: auto;
      
      &__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: #EEF4FF;
        border-radius: 50%;
        margin-right: 12px;
      }
    }
    
    &-info {
      &__name {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
      }
      
      &__email {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray);
      }
    }
    
    &-logout {
      margin-right: 8px;
      margin-top: 3px;
      cursor: pointer;
    }
  }
`;

export const MenuListLink: any = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 41px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-gray);
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;

  span {
    display: block;
  }

  svg {
    width: 24px;
    min-width: 24px;
    stroke: var(--gray);
    margin-right: 12px;
    transition: fill 0.3s ease-in-out, stroke 0.3s ease-in-out;
  }

  &.active {
    color: var(--dark-purple);
    background-repeat: no-repeat;

    svg {
      stroke: var(--blue);
    }
  }
`;

