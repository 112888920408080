import React, {useRef} from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {CalendarBlock, CalendarField, CalendarHidden, CalendarMain, CalendarPanel} from "./Calendar.Styles";
import { Button } from "../Button/Button";

dayjs.extend(require('dayjs/plugin/utc'));

const Shortcut = {
  today: 'TODAY',
};

type ShortcutType = keyof typeof Shortcut;

const shortcuts = [
  {
    date: Shortcut.today,
    label: 'Today',
  }
];

const buildHandleClick =
  (setValue: React.Dispatch<React.SetStateAction<Dayjs | null>>) =>
    (date: ShortcutType) => {
      const today = dayjs();
      switch (date) {
        case Shortcut.today:
          setValue(today.startOf('day'));
          break;
        default:
          break;
      }
    };

interface PanelProps {
  data: Dayjs | null;
  close: () => void;
  apply: (payload: Dayjs | null) => void;
  className?: string;
}

const PickersLayout: React.FC<PanelProps> = (props: PanelProps) => {
  const { close, apply, data, className } = props;
  const [value, setValue] = React.useState<Dayjs | null>(data);

  const handleClick = React.useCallback(
    (date: ShortcutType) => setValue && buildHandleClick(setValue)(date),
    [setValue],
  );

  return (
    <CalendarPanel className={className}>
      <div className="calendar-block">
        <div className="calendar-block__calendar">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="calendar-header">
              <CalendarField
                onChange={(newValue: any) => setValue(newValue)}
                value={value}
                format="MMM D, YYYY"
              />
              <div className="calendar-shortcuts">
                {shortcuts.map(({ date, label }) => (
                  <div
                    key={date}
                    className="calendar-shortcuts__item"
                  >
                    <button
                      className="calendar-shortcuts__btn"
                      type="button"
                      onClick={() => handleClick(date as ShortcutType)}
                    >
                      {label}
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <CalendarMain
              onChange={(newValue: any) => setValue(newValue)}
              value={value}
              disablePast
              minDate={dayjs(new Date())}
              dayOfWeekFormatter={(day: string) => day.slice(0, 2)}
              showDaysOutsideCurrentMonth={true}
            />
          </LocalizationProvider>
        </div>
        <div className="calendar-block__btns">
          <Button
            className="calendar-block__btn -white"
            type="button"
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button
            className="calendar-block__btn"
            type="button"
            onClick={() => apply(value)}
          >
            Apply
          </Button>
        </div>
      </div>
    </CalendarPanel>
  );
};

interface Props {
  className?: string;
  label?: string;
  data: Dayjs | null;
  error?: string;
  setData: (payload: Dayjs | null) => void;
}

const Calendar: React.FC<Props> = (props: Props) => {
  const { className, label, data, error, setData } = props;
  const [calendarOpened, setCalendarOpened] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<Dayjs | null>(data);
  const buttonRef = useRef();

  const handleClose = () => {
    setCalendarOpened(false)
  };

  const handleApply = (payload: Dayjs | null) => {
    setCalendarOpened(false);
    setData(payload);
  };

  return (
    <CalendarBlock className={`calendar ${className}`}>
      {
        label ? (
          <label className="calendar-label">{label}</label>
        ) : null
      }

      <div className="calendar-wrap">
        <button
          // @ts-ignore
          ref={buttonRef}
          className={`calendar__btn ${data ? '-has-dates' : ''}`}
          type="button"
          onClick={
            () => setCalendarOpened(!calendarOpened)
          }
        >
          {!data ? 'Select date' : data.format('MMM D, YYYY')}
        </button>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CalendarHidden
            open={calendarOpened}
            onOpen={() => setCalendarOpened(true)}
            onClose={() => setCalendarOpened(false)}
            onChange={(newValue: any) => setValue(newValue)}
            value={value}
            renderInput={() => <div />}
            components={{
              Layout: PickersLayout
            }}
            slotProps={{
              popper: {
                placement: 'bottom-start',
                anchorEl: buttonRef.current,
              },
              layout: {data, close: handleClose, apply: handleApply, className: className},
            }}
          />
        </LocalizationProvider>
      </div>
      <p className="calendar-error">{error}</p>
    </CalendarBlock>
  );
};

export default Calendar;
