import styled from 'styled-components';

export const LoadingStyles: any = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  justify-content: center;
  align-items: center;
  background: rgba(155, 156, 157, 0.4);
  backdrop-filter: blur(20px);
  z-index: 9999;

  svg {
    width: 180px;
    height: 180px;
    animation: rotate 1s linear infinite;
  }
`;
