import { makeHandler } from './makeHandler';

export const createStore = makeHandler(
  'createStore',
  (data: CreateStoreParams) => ({
    method: 'POST',
    path: '/stores',
    data,
  }),
  (res: CreateStoreResponse) => res.data
);

export const getStores = makeHandler(
  'getStores',
  () => ({
    method: 'GET',
    path: '/stores',
  }),
  (res: GetStoresResponse) => res.data
);

export const createAPIKey = makeHandler(
  'createAPIKey',
  (data: CreateAPIKeyParams) => ({
    method: 'POST',
    path: `/stores/${data.storeId}/checkout_api_keys`,
    data: data.data,
  }),
  (res: CreateAPIKeyResponse) => res.data
);

export const getAPIKeys = makeHandler(
  'getAPIKeys',
  ({storeId}: { storeId: string }) => ({
    method: 'GET',
    path: `/stores/${storeId}/checkout_api_keys`,
  }),
  (res: GetAPIKeysResponse) => res.data
);

export const deleteAPIKey = makeHandler(
  'deleteAPIKey',
  (data: DeleteAPIKeyParams) => ({
    method: 'DELETE',
    path: `/stores/${data.storeId}/checkout_api_keys/${data.apiKeyId}`,
    data: data.data,
  }),
  (res: DeleteAPIKeysResponse) => res.data
);

export const showAPIKey = makeHandler(
  'showAPIKey',
  (data: ShowAPIKeyParams) => ({
    method: 'POST',
    path: `/stores/${data.storeId}/checkout_api_keys/${data.apiKeyId}`,
    data: data.data,
  }),
  (res: ShowAPIKeysResponse) => res.data
);

export const createWebhook = makeHandler(
  'createWebhook',
  (data: CreateWebhookParams) => ({
    method: 'POST',
    path: `/stores/${data.storeId}/add_webhook_url`,
    data: data.data,
  }),
  (res: CreateWebhookResponse) => res.data
);

export const deleteWebhook = makeHandler(
  'deleteWebhook',
  (data: DeleteWebhookParams) => ({
    method: 'POST',
    path: `/stores/${data.storeId}/remove_webhook_url`,
    data: data.data,
  }),
  (res: DeleteWebhookResponse) => res.data
);

export const getStoreInfo = makeHandler(
  'getStoreInfo',
  ({storeId}: { storeId: string }) => ({
    method: 'GET',
    path: `/stores/${storeId}/store_info`,
  }),
  (res: StoreInfoResponse) => res.data
);

export const updateStoreInfo = makeHandler(
  'updateStoreInfo',
  (data: UpdateStoreInfoParams) => ({
    method: 'PUT',
    path: `/stores/${data.storeId}/store_info`,
    data: data.data,
  }),
  (res: StoreInfoResponse) => res.data
);

export const createWithdrawalRequisites = makeHandler(
  'createWithdrawalRequisites',
  (data: CreateWithdrawalRequisiteParams) => ({
    method: 'POST',
    path: `/stores/${data.storeId}/withdrawal_requisites`,
    data: data.data,
  }),
  (res: CreateWithdrawalRequisiteResponse) => res.data
);

export const getWithdrawalsRequisites = makeHandler(
  'getWithdrawalsRequisites',
  ({storeId}: { storeId: string }) => ({
    method: 'GET',
    path: `/stores/${storeId}/withdrawal_requisites`,
  }),
  (res: GetWithdrawalRequisitesResponse) => res.data
);

export const deleteWithdrawalRequisites = makeHandler(
  'deleteWithdrawalRequisites',
  (data: DeleteWithdrawalRequisiteParams) => ({
    method: 'DELETE',
    path: `/stores/${data.storeId}/withdrawal_requisites/${data.withdrawalId}`,
    data: {},
  }),
  (res: DeleteWithdrawalRequisiteResponse) => res.data
);

export const getDocument = makeHandler(
  'getDocument',
  (data: GetDocumentParams) => ({
    method: 'GET',
    path: `/stores/${data.storeId}/${data.type}/${data.id}/pdf`
  }),
  (res: Blob) => res
);

export const updateStore = makeHandler(
  'updateStore',
  (data: {storeId: string; params: CreateStoreParams}) => ({
    method: 'PUT',
    path: `/stores/${data.storeId}`,
    data: data.params,
  }),
  (res: CreateStoreResponse) => res.data
);

export const getStore = makeHandler(
  'getStore',
  (data: {storeId: string;}) => ({
    method: 'GET',
    path: `/stores/${data.storeId}`,
  }),
  (res: CreateStoreResponse) => res.data
);

export const getIPWL = makeHandler(
  'getIPWL',
  (data: {storeId: string;}) => ({
    method: 'GET',
    path: `/stores/${data.storeId}/address_whitelist`,
  }),
  (res: GetIPWLResponse) => res.data
);

export const updateIPWL = makeHandler(
  'updateIPWL',
  (data: UpdateIPWLParams) => ({
    method: 'PUT',
    path: `/stores/${data.storeId}/address_whitelist`,
    data: data.data,
  }),
  (res: UpdateIPWLResponse) => res.data
);

export type CreateStoreParams = {
  storeId?: string;
  name: string;
  kind?: string;
  min_btc_confirmations: string;
  min_eth_confirmations: string;
  allowed_amount_diff_percent?: string;
  min_btc_tx_amount?: string;
  min_usdteth_tx_amount?: string;
  min_usdceth_tx_amount?: string;
  min_daieth_tx_amount?: string;
  min_usdttrx_tx_amount?: string;
};

export type CreateStoreResponse = {
  data: Store
};

export type GetStoresResponse = {
  data: Store[]
};

export type Store = {
  id: string;
  min_btc_confirmations?: number;
  min_btc_tx_amount?: string;
  min_daieth_tx_amount?: string;
  min_eth_confirmations?: number;
  min_usdceth_tx_amount?: string;
  min_usdteth_tx_amount?: string;
  min_usdttrx_tx_amount?: string;
  allowed_amount_diff_percent?: string;
  name: string;
  webhook_url?: string;
  webhook_urls: Webhook[];
  info?: StoreInfoParams
  loadedInfo?: string;
  has_api_key?: boolean;
  has_invoice?: boolean;
  has_store_info?: boolean;
  balance: string;
  withdrawals: WithdrawalRequisite[]
  loadedWithdrawals?: string;
};

export type CreateAPIKeyParams = {
  data: {
    name: string;
    tfa_code: string;
  }
  storeId: string;
};

export type CreateAPIKeyResponse = {
  data: APIKey
};

export type GetAPIKeysResponse = {
  data: APIKey[]
};

export type DeleteAPIKeyParams = {
  data: {
    tfa_code: string;
  }
  storeId: string;
  apiKeyId: string;
};

export type ShowAPIKeyParams = {
  data: {
    tfa_code: string;
  }
  storeId: string;
  apiKeyId: string;
};

export type DeleteAPIKeysResponse = {
  data: {}
};

export type ShowAPIKeysResponse = {
  data: APIKey
};

export type APIKey = {
  id: string;
  key: string;
  name: string;
  created_at: string;
  last_time_used: string;
};

export type CreateWebhookParams = {
  data: {
    webhook_url: string;
  }
  storeId: string;
};

export type CreateWebhookResponse = {
  data: {
    webhook_url: Webhook
  }
};

export type DeleteWebhookParams = {
  data: {
    webhook_url: string;
  }
  storeId: string;
};

export type DeleteWebhookResponse = {
  data: {
    webhook_url: Webhook
  }
};

export type Webhook = string;

export type IPWL = string;

export type UpdateIPWLParams = {
  data: {
    tfa_code: string;
    address_whitelist: string;
  }
  storeId: string;
};

export type GetIPWLResponse = {
  data: string[]
};

export type UpdateIPWLResponse = {
  data: string[]
};

export type UpdateStoreInfoParams = {
  data?: StoreInfoParams
  storeId: string;
};

export type StoreInfoParams = {
  beneficiary_email?: string;
  beneficiary_first_name?: string;
  beneficiary_last_name?: string;
  beneficiary_phone?: string;
  beneficiary_position?: string;
  company_address?: string;
  company_country?: string;
  company_legal_name?: string;
  company_reg_number?: string;
  company_website?: string;
  shop_name?: string;
  has_api_key?: boolean;
  has_invoice?: boolean;
  has_store_info?: boolean;
};

export type StoreInfoResponse = {
  data: StoreInfoParams
};

export type WithdrawalRequisite = {
  id: string;
  address: string;
  currency: string;
  name: string;
  created_at: string;
  auth?: string;
  error?: string;
};

export type CreateWithdrawalRequisiteParams = {
  data: {
    tfa_code: string,
    withdrawal_requisite: {
      name: string,
      currency: string,
      address: string
    },
    required: boolean
  }
  storeId: string;
};

export type CreateWithdrawalRequisiteResponse = {
  data: WithdrawalRequisite
};

export type GetWithdrawalRequisitesResponse = {
  data: WithdrawalRequisite[]
};

export type DeleteWithdrawalRequisiteParams = {
  storeId: string;
  withdrawalId: string;
};

export type GetDocumentParams = {
  storeId: string;
  type: string;
  id: string;
};

export type DeleteWithdrawalRequisiteResponse = {
  data: {}
};


