import styled from 'styled-components';

export const withHandlerSpacing = 32 + 12 + 8;
export const Details = styled.div<{ hasHandler: boolean }>`
  flex: 1;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: ${({ hasHandler }) => (hasHandler ? `${withHandlerSpacing}px` : '12px')};
  padding-top: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  
  &.-danger {
    color: var(--red);
  }

  &.-success {
    color: var(--green);
  }
  
  & .text {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: var(--inter);
    text-align: center;
    
    &:first-letter {
      text-transform: uppercase;
    }
  }
`

export const CloseHandler = styled.button`
  right: 24px;
  position: absolute;
  top: 19px;
  background: transparent;
  outline: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  opacity: 1;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  
  &:hover {
    opacity: 0.65;
  }
`

export const StyledAlert = styled.div`
  display: flex;
  position: relative;
  background-color: var(--white);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.02);
  border-radius: var(--border-radius);
  padding-left: 20px;
`
