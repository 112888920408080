import {
  CreateInvoicesParams,
  GetInvoicesParams,
  GetInvoiceParams,
  MakePaidInvoiceParams, DeactivateInvoiceParams,
} from '../../api';

const CREATE_INVOICE_REQUEST = 'CREATE_INVOICE_REQUEST';
const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS';
const CREATE_INVOICE_FAILURE = 'CREATE_INVOICE_FAILURE';
const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';
const GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST';
const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
const GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE';
const MAKE_PAID_INVOICE_REQUEST = 'MAKE_PAID_INVOICE_REQUEST';
const MAKE_PAID_INVOICE_SUCCESS = 'MAKE_PAID_INVOICE_SUCCESS';
const MAKE_PAID_INVOICE_FAILURE = 'MAKE_PAID_INVOICE_FAILURE';
const DEACTIVATE_INVOICE_REQUEST = 'DEACTIVATE_INVOICE_REQUEST';
const DEACTIVATE_INVOICE_SUCCESS = 'DEACTIVATE_INVOICE_SUCCESS';
const DEACTIVATE_INVOICE_FAILURE = 'DEACTIVATE_INVOICE_FAILURE';
const SET_INVOICES_LOADING = 'SET_INVOICES_LOADING';

type CreateInvoiceActionType = {
  type: typeof CREATE_INVOICE_SUCCESS | typeof CREATE_INVOICE_FAILURE;
  payload?: any;
};

export type CreateInvoiceRequest = {
  type: typeof CREATE_INVOICE_REQUEST;
  payload?: CreateInvoicesParams;
};

type GetInvoicesActionType = {
  type: typeof GET_INVOICES_SUCCESS | typeof GET_INVOICES_FAILURE;
  payload?: any;
};

export type GetInvoicesRequest = {
  type: typeof GET_INVOICES_REQUEST;
  payload: GetInvoicesParams;
};

type GetInvoiceActionType = {
  type: typeof GET_INVOICE_SUCCESS | typeof GET_INVOICE_FAILURE;
  payload?: any;
};

export type GetInvoiceRequest = {
  type: typeof GET_INVOICE_REQUEST;
  payload: GetInvoiceParams;
};

type MakePaidInvoiceActionType = {
  type: typeof MAKE_PAID_INVOICE_SUCCESS | typeof MAKE_PAID_INVOICE_FAILURE;
  payload?: any;
};

export type MakePaidInvoiceRequest = {
  type: typeof MAKE_PAID_INVOICE_REQUEST;
  payload?: MakePaidInvoiceParams;
};

type DeactivateInvoiceActionType = {
  type: typeof DEACTIVATE_INVOICE_SUCCESS | typeof DEACTIVATE_INVOICE_FAILURE;
  payload?: any;
};

export type DeactivateInvoiceRequest = {
  type: typeof DEACTIVATE_INVOICE_REQUEST;
  payload?: DeactivateInvoiceParams;
};

type SetInvoicesLoading = {
  type: typeof SET_INVOICES_LOADING;
  payload: GetInvoicesParams;
};

// refactored for shorter version
export type CreateInvoiceTypes = CreateInvoiceActionType | CreateInvoiceRequest;
export type GetInvoicesTypes = GetInvoicesActionType | GetInvoicesRequest;
export type GetInvoiceTypes = GetInvoiceActionType | GetInvoiceRequest;
export type MakePaidInvoiceTypes = MakePaidInvoiceActionType | MakePaidInvoiceRequest;
export type DeactivateInvoiceTypes = DeactivateInvoiceActionType | DeactivateInvoiceRequest;

export type InvoicesActionTypes =
  | CreateInvoiceTypes
  | GetInvoicesTypes
  | GetInvoiceTypes
  | MakePaidInvoiceTypes
  | DeactivateInvoiceTypes
  | SetInvoicesLoading;

export default {
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_FAILURE,
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  GET_INVOICE_REQUEST,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAILURE,
  MAKE_PAID_INVOICE_REQUEST,
  MAKE_PAID_INVOICE_SUCCESS,
  MAKE_PAID_INVOICE_FAILURE,
  DEACTIVATE_INVOICE_REQUEST,
  DEACTIVATE_INVOICE_SUCCESS,
  DEACTIVATE_INVOICE_FAILURE,
  SET_INVOICES_LOADING,
} as const;
