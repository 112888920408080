import React, {useMemo} from 'react';
import { connect } from 'react-redux';

import { Content, MainContainer, SiteWrapper } from './Layout.Styles';
import Sidebar from '../../Sidebar/Sidebar';
import { Modal } from '../../../elements';
import { AppStateType } from '../../../store';
import { setAlert } from '../../../store/loadingsErrors/actions';
import {selectLoadingByKey} from "../../../store/loadingsErrors/selectors";
import types from "../../../store/actionTypes";
import Loading from "../../Loading";

interface Props {
  modal: any;
  children?: any;
  storesLoading: boolean;
  testnetLoading: boolean;
  storeLoading: boolean;
}

const Layout: React.FC<Props> = (props: Props) => {
  const {
    modal,
    children,
    storesLoading, testnetLoading, storeLoading
  } = props;

  const loading = useMemo(() => {
    return storesLoading || testnetLoading || storeLoading
  }, [storesLoading, testnetLoading, storeLoading]);

  return (
    <SiteWrapper>
      <MainContainer className='main-container'>
        <Sidebar />
        <Content className="content">
          {children}
        </Content>
      </MainContainer>

      <Modal
        opened={modal?.opened}
        closeModal={() => modal?.closeModal()}
        className={modal?.className}
        hasCloseBtn={modal?.hasCloseBtn}
        children={modal?.content(modal?.contentParams)}
      />

      {
        loading ? <Loading /> : null
      }
    </SiteWrapper>
  );
};

const mapState = (state: AppStateType) => {
  const { app } = state;
  return {
    modal: app.modal,
    storesLoading: selectLoadingByKey(state, types.GET_STORES_REQUEST),
    storeLoading: selectLoadingByKey(state, types.GET_STORE_REQUEST),
    testnetLoading: selectLoadingByKey(state, types.CHANGE_USING_TESTNET),
  };
};

export default connect(mapState, { setAlert })(Layout);
