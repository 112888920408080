import React from 'react';
import { Icon } from '../Icon/Icon';
import {PageSwitcherBlock} from "./PageSwitcher.Styles";

interface Props {
  activePage: number;
  maxPages: number;
  setPage: (e: any) => void
}

export const PageSwitcher: React.FC<Props> = (props: Props) => {
  const {activePage, maxPages, setPage} = props;

  const applyNumber = () => {
    if (activePage < 4) {
      return [false, 2, 3, 4, true]
    }
    if (activePage >= 4 && activePage + 2 < maxPages) {
      return [true, activePage - 1, activePage, activePage + 1, true]
    }
    if (activePage + 2 >= maxPages){
      return [true, maxPages-3, maxPages-2, maxPages-1, false]
    }
    return []
  }

  return maxPages > 1 ? (
    <PageSwitcherBlock className="pagination">
      <div
        className={`pagination-arrow ${activePage === 1 ? '-disabled' : ''}`}
        onClick={() => setPage(activePage === 1 ? activePage : activePage - 1)}
      >
        <Icon className="pagination-icon" name="left-arrow" size="10" />
      </div>
      <div
        className={`pagination-number ${activePage === 1 ? '-active' : ''}`}
        onClick={() => setPage(1)}
      >1</div>
      <div className="pagination-dots" style={{display: applyNumber()[0] ? '' : 'none'}}>...</div>
      <div
        className={`pagination-number ${activePage === applyNumber()[1] ? '-active' : ''}`}
        onClick={() => setPage(applyNumber()[1])}
      >{applyNumber()[1]}</div>
      {
        maxPages >= 3 ? (
          <div
            className={`pagination-number ${activePage === applyNumber()[2] ? '-active' : ''}`}
            onClick={() => setPage(applyNumber()[2])}
          >{applyNumber()[2]}</div>
        ) : null
      }
      {
        maxPages >= 4 ? (
          <div
            className={`pagination-number ${activePage === applyNumber()[2] ? '-active' : ''}`}
            onClick={() => setPage(applyNumber()[3])}
          >{applyNumber()[3]}</div>
        ) : null
      }
      {
        maxPages >= 5 ? (
          <div
            className={`pagination-number ${applyNumber()[4] ? '' : '-hidden'}`}
            onClick={() => setPage(applyNumber()[3])}
          >...</div>
        ) : null
      }
      {
        maxPages >= 5 ? (
          <div
            className={`pagination-number ${activePage === maxPages ? '-active' : ''}`}
            onClick={() => setPage(maxPages)}
          >{maxPages}</div>
        ) : null
      }
      <div
        className={`pagination-arrow ${activePage === maxPages ? '-disabled' : ''}`}
        onClick={() => setPage(activePage === maxPages ? maxPages : activePage + 1)}
      >
        <Icon className="pagination-icon" name="right-arrow" size="10" />
      </div>
    </PageSwitcherBlock>
  ) : null
};

export default PageSwitcher;
