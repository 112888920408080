import types from '../actionTypes';
import {
  ActivateTFAParams,
  UpdateForgottenParams,
  ConfirmEmailCodeParams, EmailData,
  ResendConfirmationCodeParams,
  UpdatePasswordParams,
  UserData, ConfirmUpdateEmailCodeParams,
  OAuthlogInParams
} from "../../api";

const login = (payload: any) => ({
  type: types.LOGIN_REQUEST,
  payload,
});

const registration = (payload: any) => ({
  type: types.REGISTRATION_REQUEST,
  payload,
});

const forgotPassword = (payload: any) => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  payload,
});

const updateForgottenPassword = (payload: UpdateForgottenParams) => ({
  type: types.UPDATE_FORGOTTEN_PASSWORD_REQUEST,
  payload,
});

const confirmEmail = (payload: ConfirmEmailCodeParams) => ({
  type: types.CONFIRM_EMAIL_REQUEST,
  payload,
});

const resendConfirmation = (payload: ResendConfirmationCodeParams) => ({
  type: types.RESEND_CONFIRMATION_REQUEST,
  payload,
});

const updatePassword = (payload: UpdatePasswordParams) => ({
  type: types.UPDATE_PASSWORD_REQUEST,
  payload,
});

const getTFA = () => ({
  type: types.GET_TFA_REQUEST,
});

const activateTFA = (payload: ActivateTFAParams) => ({
  type: types.ACTIVATE_TFA_REQUEST,
  payload,
});

const turnOffTFA = (payload: ActivateTFAParams) => ({
  type: types.TURN_OFF_TFA_REQUEST,
  payload,
});

const turnOnTFA = (payload: ActivateTFAParams) => ({
  type: types.TURN_ON_TFA_REQUEST,
  payload,
});

const getProfile = () => ({
  type: types.GET_PROFILE_REQUEST,
});

const updateProfile = (payload: UserData) => ({
  type: types.UPDATE_PROFILE_REQUEST,
  payload,
});

const updateEmail = (payload: EmailData) => ({
  type: types.UPDATE_EMAIL_REQUEST,
  payload,
});

const logout = () => ({
  type: types.LOGOUT_REQUEST,
});

const clear = () => ({
  type: types.CLEAR,
});

const changeUsingTestnet = (payload: { useTestnet: boolean }) => ({
  type: types.CHANGE_USING_TESTNET,
  payload,
});

const confirmChangeEmail = (payload: ConfirmUpdateEmailCodeParams) => ({
  type: types.CONFIRM_CHANGE_EMAIL_REQUEST,
  payload,
});

const OAuthLogin = (payload: OAuthlogInParams) => ({
  type: types.OAUTH_LOGIN_REQUEST,
  payload,
});

export {
  login,
  registration,
  forgotPassword,
  updateForgottenPassword,
  confirmEmail,
  resendConfirmation,
  updatePassword,
  getTFA,
  activateTFA,
  turnOffTFA,
  turnOnTFA,
  getProfile,
  updateProfile,
  updateEmail,
  logout,
  clear,
  changeUsingTestnet,
  confirmChangeEmail,
  OAuthLogin,
};
