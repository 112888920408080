import {
  CreateAPIKeyParams,
  CreateStoreParams,
  CreateWithdrawalRequisiteParams,
  DeleteAPIKeyParams,
  DeleteWithdrawalRequisiteParams,
  ShowAPIKeyParams,
  UpdateStoreInfoParams,
  CreateWebhookParams,
  DeleteWebhookParams,
  UpdateIPWLParams,
} from '../../api';

const CREATE_STORE_REQUEST = 'CREATE_STORE_REQUEST';
const CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS';
const CREATE_STORE_FAILURE = 'CREATE_STORE_FAILURE';
const GET_STORES_REQUEST = 'GET_STORES_REQUEST';
const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
const GET_STORES_FAILURE = 'GET_STORES_FAILURE';
const SET_STORE = 'SET_STORE';
const CREATE_API_KEY_REQUEST = 'CREATE_API_KEY_REQUEST';
const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS';
const CREATE_API_KEY_FAILURE = 'CREATE_API_KEY_FAILURE';
const GET_API_KEYS_REQUEST = 'GET_API_KEYS_REQUEST';
const GET_API_KEYS_SUCCESS = 'GET_API_KEYS_SUCCESS';
const GET_API_KEYS_FAILURE = 'GET_API_KEYS_FAILURE';
const DELETE_API_KEY_REQUEST = 'DELETE_API_KEY_REQUEST';
const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
const DELETE_API_KEY_FAILURE = 'DELETE_API_KEY_FAILURE';
const SHOW_API_KEY_REQUEST = 'SHOW_API_KEY_REQUEST';
const SHOW_API_KEY_SUCCESS = 'SHOW_API_KEY_SUCCESS';
const SHOW_API_KEY_FAILURE = 'SHOW_API_KEY_FAILURE';
const CREATE_WEBHOOK_REQUEST = 'CREATE_WEBHOOK_REQUEST';
const CREATE_WEBHOOK_SUCCESS = 'CREATE_WEBHOOK_SUCCESS';
const CREATE_WEBHOOK_FAILURE = 'CREATE_WEBHOOK_FAILURE';
const DELETE_WEBHOOK_REQUEST = 'DELETE_WEBHOOK_REQUEST';
const DELETE_WEBHOOK_SUCCESS = 'DELETE_WEBHOOK_SUCCESS';
const DELETE_WEBHOOK_FAILURE = 'DELETE_WEBHOOK_FAILURE';
const GET_STORE_INFO_REQUEST = 'GET_STORE_INFO_REQUEST';
const GET_STORE_INFO_SUCCESS = 'GET_STORE_INFO_SUCCESS';
const GET_STORE_INFO_FAILURE = 'GET_STORE_INFO_FAILURE';
const UPDATE_STORE_INFO_REQUEST = 'UPDATE_STORE_INFO_REQUEST';
const UPDATE_STORE_INFO_SUCCESS = 'UPDATE_STORE_INFO_SUCCESS';
const UPDATE_STORE_INFO_FAILURE = 'UPDATE_STORE_INFO_FAILURE';
const CREATE_WITHDRAWAL_REQUISITES_REQUEST = 'CREATE_WITHDRAWAL_REQUISITES_REQUEST';
const CREATE_WITHDRAWAL_REQUISITES_SUCCESS = 'CREATE_WITHDRAWAL_REQUISITES_SUCCESS';
const CREATE_WITHDRAWAL_REQUISITES_FAILURE = 'CREATE_WITHDRAWAL_REQUISITES_FAILURE';
const GET_WITHDRAWALS_REQUISITES_REQUEST = 'GET_WITHDRAWALS_REQUISITES_REQUEST';
const GET_WITHDRAWALS_REQUISITES_SUCCESS = 'GET_WITHDRAWALS_REQUISITES_SUCCESS';
const GET_WITHDRAWALS_REQUISITES_FAILURE = 'GET_WITHDRAWALS_REQUISITES_FAILURE';
const DELETE_WITHDRAWAL_REQUISITES_REQUEST = 'DELETE_WITHDRAWAL_REQUISITES_REQUEST';
const DELETE_WITHDRAWAL_REQUISITES_SUCCESS = 'DELETE_WITHDRAWAL_REQUISITES_SUCCESS';
const DELETE_WITHDRAWAL_REQUISITES_FAILURE = 'DELETE_WITHDRAWAL_REQUISITES_FAILURE';
const UPDATE_STORE_REQUEST = 'UPDATE_STORE_REQUEST';
const UPDATE_STORE_SUCCESS = 'UPDATE_STORE_SUCCESS';
const UPDATE_STORE_FAILURE = 'UPDATE_STORE_FAILURE';
const GET_STORE_REQUEST = 'GET_STORE_REQUEST';
const GET_STORE_SUCCESS = 'GET_STORE_SUCCESS';
const GET_STORE_FAILURE = 'GET_STORE_FAILURE';
const GET_IPWL_REQUEST = 'GET_IPWL_REQUEST';
const GET_IPWL_SUCCESS = 'GET_IPWL_SUCCESS';
const GET_IPWL_FAILURE = 'GET_IPWL_FAILURE';
const UPDATE_IPWL_REQUEST = 'UPDATE_IPWL_REQUEST';
const UPDATE_IPWL_SUCCESS = 'UPDATE_IPWL_SUCCESS';
const UPDATE_IPWL_FAILURE = 'UPDATE_IPWL_FAILURE';

type CreateStoreActionType = {
  type: typeof CREATE_STORE_SUCCESS | typeof CREATE_STORE_FAILURE;
  payload?: any;
};

export type CreateStoreRequest = {
  type: typeof CREATE_STORE_REQUEST;
  payload?: CreateStoreParams;
};

type GetStoresActionType = {
  type: typeof GET_STORES_SUCCESS | typeof GET_STORES_FAILURE;
  payload?: any;
};

export type GetStoresRequest = {
  type: typeof GET_STORES_REQUEST;
};

export type SetStores = {
  type: typeof SET_STORE;
  payload: string | null;
};

type CreateAPIKeyActionType = {
  type: typeof CREATE_API_KEY_SUCCESS | typeof CREATE_API_KEY_FAILURE;
  payload?: any;
};

export type CreateAPIKeyRequest = {
  type: typeof CREATE_API_KEY_REQUEST;
  payload?: CreateAPIKeyParams;
};

type GetAPIKeysActionType = {
  type: typeof GET_API_KEYS_SUCCESS | typeof GET_API_KEYS_FAILURE;
  payload?: any;
};

export type GetAPIKeysRequest = {
  type: typeof GET_API_KEYS_REQUEST;
  payload?: any;
};

type DeleteAPIKeyActionType = {
  type: typeof DELETE_API_KEY_SUCCESS | typeof DELETE_API_KEY_FAILURE;
  payload?: any;
};

export type DeleteAPIKeyRequest = {
  type: typeof DELETE_API_KEY_REQUEST;
  payload?: DeleteAPIKeyParams;
};

type ShowAPIKeyActionType = {
  type: typeof SHOW_API_KEY_SUCCESS | typeof SHOW_API_KEY_FAILURE;
  payload?: any;
};

export type ShowAPIKeyRequest = {
  type: typeof SHOW_API_KEY_REQUEST;
  payload?: ShowAPIKeyParams;
};

type CreateWebhookActionType = {
  type: typeof CREATE_WEBHOOK_SUCCESS | typeof CREATE_WEBHOOK_FAILURE;
  payload?: any;
};

export type CreateWebhookRequest = {
  type: typeof CREATE_WEBHOOK_REQUEST;
  payload?: CreateWebhookParams;
};

type DeleteWebhookActionType = {
  type: typeof DELETE_WEBHOOK_SUCCESS | typeof DELETE_WEBHOOK_FAILURE;
  payload?: any;
};

export type DeleteWebhookRequest = {
  type: typeof DELETE_WEBHOOK_REQUEST;
  payload?: DeleteWebhookParams;
};

type UpdateStoreInfoActionType = {
  type: typeof UPDATE_STORE_INFO_SUCCESS | typeof UPDATE_STORE_INFO_FAILURE;
  payload?: any;
};

export type UpdateStoreInfoRequest = {
  type: typeof UPDATE_STORE_INFO_REQUEST;
  payload: UpdateStoreInfoParams;
};

type GetStoreInfoActionType = {
  type: typeof GET_STORE_INFO_SUCCESS | typeof GET_STORE_INFO_FAILURE;
  payload?: any;
};

export type GetStoreInfoRequest = {
  type: typeof GET_STORE_INFO_REQUEST;
  payload?: any;
};

type CreateWithdrawalActionType = {
  type: typeof CREATE_WITHDRAWAL_REQUISITES_SUCCESS | typeof CREATE_WITHDRAWAL_REQUISITES_FAILURE;
  payload?: any;
};

export type CreateWithdrawalRequest = {
  type: typeof CREATE_WITHDRAWAL_REQUISITES_REQUEST;
  payload?: CreateWithdrawalRequisiteParams;
};

type GetWithdrawalsActionType = {
  type: typeof GET_WITHDRAWALS_REQUISITES_SUCCESS | typeof GET_WITHDRAWALS_REQUISITES_FAILURE;
  payload?: any;
};

export type GetWithdrawalsRequest = {
  type: typeof GET_WITHDRAWALS_REQUISITES_REQUEST;
  payload?: any;
};

type DeleteWithdrawalActionType = {
  type: typeof DELETE_WITHDRAWAL_REQUISITES_SUCCESS | typeof DELETE_WITHDRAWAL_REQUISITES_FAILURE;
  payload?: any;
};

export type DeleteWithdrawalRequest = {
  type: typeof DELETE_WITHDRAWAL_REQUISITES_REQUEST;
  payload?: DeleteWithdrawalRequisiteParams;
};

type UpdateStoreActionType = {
  type: typeof UPDATE_STORE_SUCCESS | typeof UPDATE_STORE_FAILURE;
  payload?: any;
};

export type UpdateStoreRequest = {
  type: typeof UPDATE_STORE_REQUEST;
  payload?: {storeId: string; params: CreateStoreParams};
};

type GetStoreActionType = {
  type: typeof GET_STORE_SUCCESS | typeof GET_STORE_FAILURE;
  payload?: any;
};

export type GetStoreRequest = {
  type: typeof GET_STORE_REQUEST;
  payload?: {storeId: string;};
};

type GetIPWLActionType = {
  type: typeof GET_IPWL_SUCCESS | typeof GET_IPWL_FAILURE;
  payload?: any;
};

export type GetIPWLRequest = {
  type: typeof GET_IPWL_REQUEST;
  payload: {storeId: string;};
};

type UpdateIPWLActionType = {
  type: typeof UPDATE_IPWL_SUCCESS | typeof UPDATE_IPWL_FAILURE;
  payload?: any;
};

export type UpdateIPWLRequest = {
  type: typeof UPDATE_IPWL_REQUEST;
  payload?: UpdateIPWLParams;
};

// refactored for shorter version
export type CreateStoreTypes = CreateStoreActionType | CreateStoreRequest;
export type GetStoresTypes = GetStoresActionType | GetStoresRequest;
export type CreateAPIKeyTypes = CreateAPIKeyActionType | CreateAPIKeyRequest;
export type GetAPIKeysTypes = GetAPIKeysActionType | GetAPIKeysRequest;
export type DeleteAPIKeyTypes = DeleteAPIKeyActionType | DeleteAPIKeyRequest;
export type ShowAPIKeyTypes = ShowAPIKeyActionType | ShowAPIKeyRequest;
export type UpdateStoreInfoTypes = UpdateStoreInfoActionType | UpdateStoreInfoRequest;
export type GetStoreInfoTypes = GetStoreInfoActionType | GetStoreInfoRequest;
export type CreateWithdrawalTypes = CreateWithdrawalActionType | CreateWithdrawalRequest;
export type GetWithdrawalsTypes = GetWithdrawalsActionType | GetWithdrawalsRequest;
export type DeleteWithdrawalTypes = DeleteWithdrawalActionType | DeleteWithdrawalRequest;
export type CreateWebhookTypes = CreateWebhookActionType | CreateWebhookRequest;
export type DeleteWebhookTypes = DeleteWebhookActionType | DeleteWebhookRequest;
export type UpdateStoreTypes = UpdateStoreActionType | UpdateStoreRequest;
export type GetStoreTypes = GetStoreActionType | GetStoreRequest;
export type GetIPWLTypes = GetIPWLActionType | GetIPWLRequest;
export type UpdateIPWLTypes = UpdateIPWLActionType | UpdateIPWLRequest;

export type StoresActionTypes =
  | CreateStoreTypes
  | GetStoresTypes
  | SetStores
  | CreateAPIKeyTypes
  | GetAPIKeysTypes
  | DeleteAPIKeyTypes
  | ShowAPIKeyTypes
  | UpdateStoreInfoTypes
  | GetStoreInfoTypes
  | CreateWithdrawalTypes
  | GetWithdrawalsTypes
  | DeleteWithdrawalTypes
  | CreateWebhookTypes
  | DeleteWebhookTypes
  | UpdateStoreTypes
  | GetStoreTypes
  | GetIPWLTypes
  | UpdateIPWLTypes;

export default {
  CREATE_STORE_REQUEST,
  CREATE_STORE_SUCCESS,
  CREATE_STORE_FAILURE,
  GET_STORES_REQUEST,
  GET_STORES_SUCCESS,
  GET_STORES_FAILURE,
  SET_STORE,
  CREATE_API_KEY_REQUEST,
  CREATE_API_KEY_SUCCESS,
  CREATE_API_KEY_FAILURE,
  GET_API_KEYS_REQUEST,
  GET_API_KEYS_SUCCESS,
  GET_API_KEYS_FAILURE,
  DELETE_API_KEY_REQUEST,
  DELETE_API_KEY_SUCCESS,
  DELETE_API_KEY_FAILURE,
  SHOW_API_KEY_REQUEST,
  SHOW_API_KEY_SUCCESS,
  SHOW_API_KEY_FAILURE,
  CREATE_WEBHOOK_REQUEST,
  CREATE_WEBHOOK_SUCCESS,
  CREATE_WEBHOOK_FAILURE,
  DELETE_WEBHOOK_REQUEST,
  DELETE_WEBHOOK_SUCCESS,
  DELETE_WEBHOOK_FAILURE,
  GET_STORE_INFO_REQUEST,
  GET_STORE_INFO_SUCCESS,
  GET_STORE_INFO_FAILURE,
  UPDATE_STORE_INFO_REQUEST,
  UPDATE_STORE_INFO_SUCCESS,
  UPDATE_STORE_INFO_FAILURE,
  CREATE_WITHDRAWAL_REQUISITES_REQUEST,
  CREATE_WITHDRAWAL_REQUISITES_SUCCESS,
  CREATE_WITHDRAWAL_REQUISITES_FAILURE,
  GET_WITHDRAWALS_REQUISITES_REQUEST,
  GET_WITHDRAWALS_REQUISITES_SUCCESS,
  GET_WITHDRAWALS_REQUISITES_FAILURE,
  DELETE_WITHDRAWAL_REQUISITES_REQUEST,
  DELETE_WITHDRAWAL_REQUISITES_SUCCESS,
  DELETE_WITHDRAWAL_REQUISITES_FAILURE,
  UPDATE_STORE_REQUEST,
  UPDATE_STORE_SUCCESS,
  UPDATE_STORE_FAILURE,
  GET_STORE_REQUEST,
  GET_STORE_SUCCESS,
  GET_STORE_FAILURE,
  GET_IPWL_REQUEST,
  GET_IPWL_SUCCESS,
  GET_IPWL_FAILURE,
  UPDATE_IPWL_REQUEST,
  UPDATE_IPWL_SUCCESS,
  UPDATE_IPWL_FAILURE,
} as const;
