import types from '../actionTypes';
import {
  CreateAPIKeyParams,
  CreateStoreParams, CreateWithdrawalRequisiteParams,
  DeleteAPIKeyParams, DeleteWithdrawalRequisiteParams,
  ShowAPIKeyParams,
  UpdateStoreInfoParams,
  CreateWebhookParams,
  DeleteWebhookParams,
  UpdateIPWLParams,
} from "../../api";

const createStore = (payload: CreateStoreParams) => ({
  type: types.CREATE_STORE_REQUEST,
  payload,
});

const getStores = () => ({
  type: types.GET_STORES_REQUEST,
});

const setStore = (payload: string | null) => ({
  type: types.SET_STORE,
  payload,
});

const createAPIKey = (payload: CreateAPIKeyParams) => ({
  type: types.CREATE_API_KEY_REQUEST,
  payload,
});

const getAPIKeys = (payload: { storeId: string }) => ({
  type: types.GET_API_KEYS_REQUEST,
  payload,
});

const deleteAPIKey = (payload: DeleteAPIKeyParams) => ({
  type: types.DELETE_API_KEY_REQUEST,
  payload,
});

const showAPIKey = (payload: ShowAPIKeyParams) => ({
  type: types.SHOW_API_KEY_REQUEST,
  payload,
});

const createWebhook = (payload: CreateWebhookParams) => ({
  type: types.CREATE_WEBHOOK_REQUEST,
  payload,
});

const deleteWebhook = (payload: DeleteWebhookParams) => ({
  type: types.DELETE_WEBHOOK_REQUEST,
  payload,
});

const updateStoreInfo = (payload: UpdateStoreInfoParams) => ({
  type: types.UPDATE_STORE_INFO_REQUEST,
  payload,
});

const getStoreInfo = (payload: { storeId: string }) => ({
  type: types.GET_STORE_INFO_REQUEST,
  payload,
});

const createWithdrawalRequisite = (payload: CreateWithdrawalRequisiteParams) => ({
  type: types.CREATE_WITHDRAWAL_REQUISITES_REQUEST,
  payload,
});

const getWithdrawalRequisites = (payload: { storeId: string }) => ({
  type: types.GET_WITHDRAWALS_REQUISITES_REQUEST,
  payload,
});

const deleteWithdrawalRequisite = (payload: DeleteWithdrawalRequisiteParams) => ({
  type: types.DELETE_WITHDRAWAL_REQUISITES_REQUEST,
  payload,
});

const updateStore = (payload: {storeId: string; params: CreateStoreParams}) => ({
  type: types.UPDATE_STORE_REQUEST,
  payload,
});

const getStore = (payload: {storeId: string;}) => ({
  type: types.GET_STORE_REQUEST,
  payload,
});

const getIPWL = (payload: {storeId: string;}) => ({
  type: types.GET_IPWL_REQUEST,
  payload,
});

const updateIPWL = (payload: UpdateIPWLParams) => ({
  type: types.UPDATE_IPWL_REQUEST,
  payload,
});

export {
  createStore,
  getStores,
  setStore,
  createAPIKey,
  getAPIKeys,
  deleteAPIKey,
  showAPIKey,
  createWebhook,
  deleteWebhook,
  updateStoreInfo,
  getStoreInfo,
  createWithdrawalRequisite,
  getWithdrawalRequisites,
  deleteWithdrawalRequisite,
  updateStore,
  getStore,
  getIPWL,
  updateIPWL,
};
