import styled from 'styled-components';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';

export const CalendarBlock: any = styled.div`
  font-family: var(--inter);
  
  .calendar {
    &-icon {
      
    }
    
    &__btn {
      display: flex;
      background: var(--white);
      border: 1px solid var(--light-gray);
      padding: 9px 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--gray);

      &:before {
        content: '';
        display: inline-block;
        margin-right: 9px;
        width: 18px;
        height: 20px;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjMzMzMgMS42NjY1VjQuOTk5ODRNNS42NjY2NyAxLjY2NjVWNC45OTk4NE0xLjUgOC4zMzMxN0gxNi41TTMuMTY2NjcgMy4zMzMxN0gxNC44MzMzQzE1Ljc1MzggMy4zMzMxNyAxNi41IDQuMDc5MzYgMTYuNSA0Ljk5OTg0VjE2LjY2NjVDMTYuNSAxNy41ODcgMTUuNzUzOCAxOC4zMzMyIDE0LjgzMzMgMTguMzMzMkgzLjE2NjY3QzIuMjQ2MTkgMTguMzMzMiAxLjUgMTcuNTg3IDEuNSAxNi42NjY1VjQuOTk5ODRDMS41IDQuMDc5MzYgMi4yNDYxOSAzLjMzMzE3IDMuMTY2NjcgMy4zMzMxN1oiIHN0cm9rZT0iIzM0NDA1NCIgc3Ryb2tlLXdpZHRoPSIxLjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
        background-repeat: no-repeat;
        background-size: cover;
      }
      
      &.-has-dates {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;
      }
    }
  }
`;

export const CalendarModal: any = styled(StaticDateRangePicker)`
  &.MuiDateRangeCalendar-root {
    & > div:first-child {
      display: none;
    }
  }
`;

export const CalendarPanel: any = styled.div`
  display: flex;

  .calendar {
    &-shortcuts {
      border-right: 1px solid var(--dark-white);
      padding: 12px 16px;
      
      &__item {
        margin-bottom: 4px;
      }
      
      &__btn {
        width: 100%;
        text-align: left;
        padding: 10px 16px;
        border: 0;
        background: transparent;
        outline: none;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        padding-right: 68px;
        color: var(--dark-gray);
        cursor: pointer;
      }
    }
    
    &-block {
      &__calendar {
        border-bottom: 1px solid var(--dark-white);

        .MuiPickersSlideTransition-root {
          min-height: 284px;
        }
        
        .MuiPickersArrowSwitcher-root {
          padding: 0 16px;
          padding-top: 22px;
          padding-bottom: 11px;
          
          .MuiTypography-root {
            font-family: var(--inter);
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--dark-gray);
          }
        }
        
        .MuiDayCalendar-header {
          .MuiTypography-root {
            font-family: var(--inter);
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: var(--dark-gray);
          }
        }
        
        .MuiDayCalendar-weekContainer {
          margin-bottom: 5px;
        }
        
        .MuiDayCalendar-monthContainer {
          .MuiPickersDay-root {
            font-family: var(--inter);
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: var(--dark-gray);
            width: 36px;
            height: 36px;
            
            &.Mui-selected {
              background: var(--purple);
              color: var(--white);

              &:hover {
                background: var(--purple);
                color: var(--white);
              }
            }

            &.MuiPickersDay-today {
              background: #F2F4F7;
              border-color: transparent;

              &.Mui-selected {
                background: var(--purple);
              }
            }

            &:hover {
              background: #EEF4FF;
            }
          }
        }
        
        .MuiDateRangePickerDay-rangeIntervalDayHighlight {
          background: var(--light-blue);

          .MuiPickersDay-root {
            color: var(--dark-purple);
          }
        }
        
        .MuiDateRangePickerDay-hiddenDayFiller {
          background: var(--white);
        }
        
        .MuiDateRangeCalendar-root {
          > div:first-child {
            display: none;
          }
        }

        .MuiDateRangeCalendar-monthContainer {
          padding: 0 8px;
        }

        .MuiDateRangeCalendar-monthContainer:not(:last-of-type) {
          border-right: 1px solid var(--dark-white);
        }
      }
      
      &__actions {
        display: flex;
        justify-content: space-between;
        padding: 16px 24px;
        
        .MuiInputBase-root {
          font-family: var(--inter);
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--black);
          background: var(--white);
          border: 1px solid var(--light-gray);
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: var(--border-radius);
        }

        .MuiFormControl-root {
          &:last-child {
            margin-left: 12px;
          }
        }
        
        .MuiOutlinedInput-notchedOutline {
          display: none;
        }
        
        .MuiFormLabel-root {
          display: none;
        }

        .MuiInputBase-input {
          padding: 8px 14px;
          width: 97px;
          height: auto;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--black);
        }

        .MuiFormControl-root .MuiInputBase-input {
          &::placeholder,
          &::-webkit-input-placeholder {
            opacity: 1!important;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--gray);
            transition: opacity 0.3s ease-in-out;
          }

          &:focus {
            &::placeholder {
              opacity: 0!important;
            }
          }
        }
        
        .MuiTypography-root {
          font-family: var(--inter);
          font-weight: 900;
          font-size: 8px;
          line-height: 24px;
          margin-left: 12px;
          color: var(--gray);
        }
      }
      
      &__btns {
        &_wrap {
          display: flex;
          gap: 12px;
        }
      }
      
      &__btn {
        padding: 10px 35px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        
        &.-white {
          background: var(--white);
          border: 1px solid var(--light-gray);
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
          color: var(--dark-gray);
          padding: 10px 30px;
        }
      }
    }
  }
`;

