import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { PATHS } from "../const/paths.constants";
import { AppStateType } from '../store';
import { getStores } from '../store/stores/actions';
import { StoresReducerState } from "../store/stores/reducers";
import {LOADING_TYPES} from "../const/app.constants";

interface PrivateRoutesProps {
  children: React.ReactElement;
  title?: string;
  loggedIn: boolean;
  stores: StoresReducerState;
  getStores: () => void;
}

const PrivateRoutes: React.FC<PrivateRoutesProps> = (props: PrivateRoutesProps) => {
  const {
    children,
    title,
    loggedIn,
    stores,
    getStores
  } = props;

  const { t } = useTranslation();
  document.title = `${t(title || '')}`;

  useEffect(() => {
    if (loggedIn) {
      if (stores.stores.loaded === LOADING_TYPES.NOT_LOADED) {
        getStores();
      }
    }
  }, [loggedIn, stores, getStores]);

  return loggedIn ? (
    <Layout>
      { children }
    </Layout>
  ) : (
    <Navigate replace to={PATHS.LOGIN} />
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { user, stores } = state;
  return {
    stores,
    loggedIn: user.loggedIn,
  };
};

export default connect(mapStateToProps, { getStores })(PrivateRoutes);
