import React, { useState, useCallback, useEffect } from 'react';

import {SelectElement, SelectListItem, TransactionsWrapper} from './Transactions.styles';
import {ModalCalendar, Select, PageSwitcher, Icon, Button, Skeleton,} from "../../elements";
import {ExpandMore} from "@mui/icons-material";
// import {latinise} from "../../common/utils/latinise";
import cloneDeep from "lodash/cloneDeep";
// import pull from "lodash/pull";
import {DateRange} from "@mui/x-date-pickers-pro";
import dayjs, {Dayjs} from "dayjs";
import {Link} from "react-router-dom";
import {GetInvoicesParams} from "../../api";
import {DEFAULT_PAGE_LIMIT} from "../../const/app.constants";
import getScanLink from "../../common/utils/getScanLink";
import truncateHash from "../../common/utils/truncateHash";

export enum TransactionType {
  NEW = 'New',
  initiated = 'Initiated',
  PAID = 'Paid',
  PENDING = 'Pending',
  CONFIRMED = 'Confirmed',
  OVERPAID = 'Overpaid',
  UNPAID = 'Unpaid',
  COMPLETE = 'Complete',
  REFUNDED = 'Refunded',
  INVALID = 'Invalid',
  CANCELLED = 'Cancelled',
}

export enum TransactionRows {
  WITHDRAWAL_REQUISITES = 'withdrawal_requisites',
  TX = 'tx',
  NUMBER = 'number',
  DATE = 'date',
  CUSTOMER = 'customer',
  STATUS = 'status',
  PAID = 'paid',
  DEPOSITED = 'deposited',
}

export interface Transaction {
  id: string;
  number: string;
  date: number;
  customer: string;
  status: TransactionType;
  paid: string;
  deposited: string;
  payment_currency?: string;
  invoice_id?: string;
}

interface Props {
  items: Transaction[];
  statusesList?: any[];
  download?: string | undefined;
  link?: string;
  loading?: boolean;
  from_day?: string;
  to_day?: string;
  status?: string;
  sort_by?: string;
  direction?: string;
  paginationLimit?: number | string;
  paginationPage?: number | string;
  total_count: number;
  updateFilterParams?: (params: GetInvoicesParams) => void;
  rows: TransactionRows[];
  pdfLoading?: boolean;
  getPdf?: (id: string) => void;
  linkId?: string;
}

const SORT_FIELD = {
  number: 'number',
  created_at: 'created_at',
  customer_email: 'customer_email',
  status: 'status',
  paid: 'paid',
  amount: 'amount',
};

const TableLoader: React.FC = () => {
  const loadingRow = (
    <div className="table-loading">
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  )
  return (
    <>
      {loadingRow}
      {loadingRow}
      {loadingRow}
      {loadingRow}
    </>
  )
}

const Transactions: React.FC<Props> = (props: Props) => {
  const { items, loading, statusesList, download, link, status, sort_by, from_day, to_day, paginationLimit, paginationPage, total_count, updateFilterParams, direction, rows, pdfLoading, getPdf, linkId } = props;
  // const statusesList = [
  //   {
  //     value: "New",
  //     text: "New"
  //   },
  //   {
  //     value: "Pending",
  //     text: "Pending"
  //   },
  //   {
  //     value: "Paid",
  //     text: "Paid"
  //   },
  //   {
  //     value: "Confirmed",
  //     text: "Confirmed"
  //   },
  //   {
  //     value: "Overpaid",
  //     text: "Overpaid"
  //   },
  //   {
  //     value: "Unpaid",
  //     text: "Unpaid"
  //   },
  //   {
  //     value: "Complete",
  //     text: "Complete"
  //   },
  //   {
  //     value: "Refunded",
  //     text: "Refunded"
  //   },
  //   {
  //     value: "Invalid",
  //     text: "Invalid"
  //   }
  // ]

  // const [query, setQuery] = useState('');
  const [statuses, setStatuses] = useState<string[]>(status ? [status] : []);
  const [dataRange, setDataRange] = useState<DateRange<Dayjs>>([from_day ? dayjs(from_day) : null, to_day ? dayjs(to_day) : null]);

  const [page, setPage] = useState<number>(Number(paginationPage) || 1)
  const [maxPage, setMaxPage] = useState<number>(1)
  const [filterParams, setFilterParams] = useState<GetInvoicesParams>({
    status: status || '',
    from_day: from_day || '',
    to_day: to_day || '',
    limit: paginationLimit || DEFAULT_PAGE_LIMIT,
    page: paginationPage || 1,
    sort_by: sort_by ? SORT_FIELD[sort_by] : SORT_FIELD.created_at,
    direction: direction || 'desc'
  })

  const sortedTransactions = items;

  // const sortedTransactions = useMemo(() => {
  //   return items
  //     ? items
  //       .slice()
  //       .sort((a, b) => {
  //         if (a && b) {
  //           return a[filterParams.sort_by as keyof Transaction] > b[filterParams.sort_by as keyof Transaction]
  //             ? (filterParams.direction === 'asc' ? -1 : 1) * 1
  //             : (filterParams.direction === 'asc' ? -1 : 1) * -1
  //         }
  //         return -1
  //       })
  //       // .filter((x) => {
  //       //   return latinise(x.id.toLowerCase()).includes(latinise(query.toLowerCase()))
  //       // })
  //       // .filter((x) => {
  //       //   return statuses.length ? statuses.includes(x.status.toLowerCase()) : true
  //       // })
  //       // .filter((x) => {
  //       //   return txFilter === undefined || x.status === txFilter
  //       // })
  //       .filter((x) => {
  //         const firstDate = !!dataRange[0] ? dataRange[0]?.unix() <= x.date : true;
  //         const lastDate = !!dataRange[1] ? dataRange[1]?.unix() >= x.date : true;
  //
  //         return firstDate && lastDate;
  //       })
  //       .slice(Number(filterParams.limit) * (page - 1), page * Number(filterParams.limit))
  //     : []
  // }, [items, filterParams.limit, filterParams.sort_by, filterParams.direction, page, statuses, dataRange])

  useEffect(() => {
    if (items) {
      if (Number(total_count) % Number(filterParams.limit) === 0) {
        setMaxPage(Math.floor(total_count / Number(filterParams.limit)))
      } else {
        setMaxPage(Math.floor(total_count / Number(filterParams.limit)) + 1)
      }
    }
  }, [items, filterParams.limit, total_count])

  const setDates = (data: DateRange<Dayjs>) => {
    setDataRange(data);
    const newFilterParams = cloneDeep(filterParams);
    newFilterParams.from_day = data[0] ? data[0]?.format('YYYY-MM-DD') : '';
    newFilterParams.to_day = data[1] ? data[1]?.format('YYYY-MM-DD') : '';
    newFilterParams.page = 1;
    setFilterParams(newFilterParams);
    if (updateFilterParams) {
      updateFilterParams(newFilterParams);
    }
  };

  // const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setQuery(event.target.value)
  // }

  const handleSort = useCallback(
    (newField: string) => {
      const newFilterParams = cloneDeep(filterParams);
      newFilterParams.sort_by = newField
      newFilterParams.direction = filterParams.sort_by !== newField ? 'desc' : filterParams.direction === 'asc' ? 'desc' : 'asc'
      setFilterParams(newFilterParams);
      if (updateFilterParams) {
        updateFilterParams(newFilterParams);
      }
    },
    [filterParams, updateFilterParams],
  )

  const setStatus = useCallback(
    (value?: string) => {
      if (!value) {
        return
      }
      let newStatuses = cloneDeep(statuses);
      const newFilterParams = cloneDeep(filterParams);
      newFilterParams.page = 1;
      if (newStatuses.includes(value.toUpperCase())) {
        newStatuses = [];
        setStatuses(newStatuses)
        newFilterParams.status = '';
        setFilterParams(newFilterParams);
      } else {
        newStatuses = [value.toUpperCase()]
        setStatuses(newStatuses)
        newFilterParams.status = value.toUpperCase();
        setFilterParams(newFilterParams);
      }
      if (updateFilterParams) {
        updateFilterParams(newFilterParams);
      }
    },
    [statuses, filterParams, updateFilterParams],
  )

  // const handleFilter = useCallback(
  //   (newFilter: TransactionType | undefined) => {
  //     if (newFilter !== txFilter) {
  //       setTxFilter(newFilter)
  //       setPage(1)
  //     }
  //   },
  //   [txFilter],
  // )

  const arrow = useCallback(
    (field: string) => {
      const directionArrow = filterParams.direction === 'desc' ? '↑' : '↓'
      return filterParams.sort_by === field ? directionArrow : ''
    },
    [filterParams.direction, filterParams.sort_by],
  )

  const selectProps = {
    disablePortal: true,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    }
  };

  const setPageSize = useCallback(
    (newPageSize: number) => {
      const newFilterParams = cloneDeep(filterParams);
      newFilterParams.limit = newPageSize;
      newFilterParams.page = 0;
      setFilterParams(newFilterParams);
      if (updateFilterParams) {
        updateFilterParams(newFilterParams);
      }
    },
    [filterParams, updateFilterParams],
  )

  const setPaginationPage = (page: number) => {
    const newFilterParams = cloneDeep(filterParams);
    setPage(page);
    newFilterParams.page = page;
    setFilterParams(newFilterParams);
    if (updateFilterParams) {
      updateFilterParams(newFilterParams);
    }
  }

  return (
    <TransactionsWrapper className="table">
      <div
        className="table-control"
      >
        <div className="table-control__row">
          {
            statusesList && statusesList.length ? (
              <div className="table-control__statuses">
                <Button
                  onClick={() => setStatus(statuses.length ? statuses[0] : undefined)}
                  className={`table-control__btn ${!statuses.length ? '-active' : ''}`}
                  type="button"
                  tabIndex={-1}
                >
                  All
                </Button>
                {
                  statusesList.map((stat: {value: string, text: string}) => (
                    <Button
                      key={`btn-status-transaction-${stat.value}`}
                      onClick={() => setStatus(stat.value)}
                      className={`table-control__btn ${statuses.includes(stat.value.toUpperCase()) ? '-active' : ''}`}
                      type="button"
                      tabIndex={-1}
                    >
                      {stat.text}
                    </Button>
                  ))
                }
              </div>
            ) : null
          }
        {/*<div className="table-control__row">*/}
          {/*<SearchInput className="table-control__row_search" onChange={handleChangeQuery} placeholder="Search for payments" />*/}
          <ModalCalendar
            dataRange={dataRange}
            setDataRange={setDates}
          />

          {
            statusesList && statusesList.length ? (
              <Select
                className="table-select status"
                name="status"
                values={statuses}
                value=""
                placeholder="Statuses"
                list={statusesList}
                fullWidth
                position="right"
                onSelect={(name: string, value: string) => setStatus(value)}
              />
            ) : null
          }
        </div>
      </div>

      <div className="table-wrap">
        <div className={`table-head ${download ? '-download' : ''} -rows_${rows.length}`}>
          {
            rows.includes(TransactionRows.DATE) ? (
              <span
                className="table-head__item"
                onClick={() => handleSort(SORT_FIELD.created_at)}
              >
                Date <span className="table-head__arrow">{arrow(SORT_FIELD.created_at)}</span>
              </span>
            ) : null
          }
          {
            rows.includes(TransactionRows.DEPOSITED) ? (
              <span
                className="table-head__item"
                onClick={() => handleSort(SORT_FIELD.amount)}
              >
                Amount <span className="table-head__arrow">{arrow(SORT_FIELD.amount)}</span>
              </span>
            ) : null
          }
          {
            rows.includes(TransactionRows.CUSTOMER) ? (
              <span
                className="table-head__item"
                onClick={() => handleSort(SORT_FIELD.customer_email)}
              >
                Customer <span className="table-head__arrow">{arrow(SORT_FIELD.customer_email)}</span>
              </span>
            ) : null
          }
          {
            rows.includes(TransactionRows.STATUS) ? (
              <span
                className="table-head__item"
              >
                Status
              </span>
            ) : null
          }
          {
            rows.includes(TransactionRows.PAID) ? (
            <span
              className="table-head__item"
              onClick={() => handleSort(SORT_FIELD.paid)}
            >
              Amount paid <span className="table-head__arrow">{arrow(SORT_FIELD.paid)}</span>
            </span>
            ) : null
          }
          {
            rows.includes(TransactionRows.NUMBER) ? (
              <span
                className="table-head__item"
                onClick={() => handleSort(SORT_FIELD.number)}
              >
                ID <span className="table-head__arrow">{arrow(SORT_FIELD.number)}</span>
              </span>
            ) : null
          }
          {
            rows.includes(TransactionRows.WITHDRAWAL_REQUISITES) ? (
              <span
                className="table-head__item"
                onClick={() => handleSort(SORT_FIELD.number)}
              >
                Withdrawal requisite <span className="table-head__arrow">{arrow(SORT_FIELD.number)}</span>
              </span>
            ) : null
          }
          {
            rows.includes(TransactionRows.TX) ? (
              <span
                className="table-head__item"
                onClick={() => handleSort(SORT_FIELD.number)}
              >
                TX ID <span className="table-head__arrow">{arrow(SORT_FIELD.number)}</span>
              </span>
            ) : null
          }
          {
            download ? (<span />) : null
          }
        </div>
        {!loading ? (
          <>
            <div className="table-body">
              {sortedTransactions.map((transaction, index) => {
                if (transaction) {
                  return (
                    <DataRow
                      key={`transaction-${index}`}
                      transaction={transaction}
                      link={link}
                      download={download || undefined}
                      rows={rows}
                      pdfLoading={pdfLoading}
                      getPdf={getPdf}
                      linkId={linkId}
                    />
                  )
                }
                return null
              })}

            {sortedTransactions.length === 0 ? (
              <span className="table-body__zero" >No Transactions</span>
            ) : undefined}
            </div>
            <div className="table-pagination">
              {
                maxPage > 1 ? (
                  <PageSwitcher activePage={page} maxPages={maxPage} setPage={(index) => setPaginationPage(index)}/>
                ) : <span className="table-pagination__empty"/>
              }

              <div className="table-size">
                <span className="table-size__text">Show</span>
                <SelectElement
                  MenuProps={selectProps}
                  id="per_page"
                  className="-disable-rotate"
                  name="per_page"
                  value={filterParams.limit}
                  aria-describedby="per_page-text"
                  IconComponent={ExpandMore}
                  tabIndex={-1}
                  onChange={(e: React.ChangeEvent<any>) => setPageSize(e.target.value)}
                >
                  <SelectListItem
                    tabIndex={-1}
                    value={5}
                  >
                    5
                  </SelectListItem>
                  <SelectListItem
                    tabIndex={-1}
                    value={10}
                  >
                    10
                  </SelectListItem>
                </SelectElement>
              </div>

            </div>
          </>
        ) : (
          <>
            <TableLoader />
          </>
        )}
      </div>
    </TransactionsWrapper>
  );
};

const DataRow: React.FC<{
  transaction: Transaction,
  download: string | undefined,
  link: string | undefined,
  rows: TransactionRows[],
  pdfLoading?: boolean,
  getPdf?: (id: string) => void,
  linkId?: string
}> = ({
 transaction,
 download,
 link,
 rows,
 pdfLoading,
 getPdf,
 linkId
}) => {
  const redirectToLink = (e: any, link: string) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const win = window.open(link, '_blank');
    win?.focus();
  }
  return link && linkId && transaction[linkId] ? (
    <Link to={link.replace(':id', transaction[linkId])} className={`table-body__row ${download ? '-download' : ''} -rows_${rows.length}`}>
      {
        rows.includes(TransactionRows.DATE) ? (
          <span className="table-body__item">{dayjs.unix(transaction.date).format('MMM D, h:mm A')}</span>
        ) : null
      }
      {
        rows.includes(TransactionRows.DEPOSITED) ? (
          <span className="table-body__item">{transaction.deposited}</span>
        ) : null
      }
      {
        rows.includes(TransactionRows.CUSTOMER) ? (
          <span className="table-body__item">{transaction.customer}</span>
        ) : null
      }
      {
        rows.includes(TransactionRows.STATUS) ? (
          <span className="table-body__item">
            <span className={`table-body__status -${transaction.status.toLowerCase()}`}>{transaction.status}</span>
          </span>
        ) : null
      }
      {
        rows.includes(TransactionRows.PAID) ? (
          <span className="table-body__item">{transaction.paid}</span>
        ) : null
      }
      {
        rows.includes(TransactionRows.NUMBER) ? (
          <span className="table-body__item -name">{transaction.number}</span>
        ) : null
      }
      {
        rows.includes(TransactionRows.WITHDRAWAL_REQUISITES) ? (
          <>
            {
              transaction.payment_currency ? (
                <Button
                  as={Link}
                  className="table-body__btn -link"
                  tabIndex={-1}
                  to={getScanLink('address', transaction.payment_currency, transaction.number)}
                  target="_blank"
                  type="button"
                >
                  <span className="table-body__item -name">{truncateHash(transaction.number)}</span>
                </Button>
              ) : (
                <span className="table-body__item -name">{transaction.number}</span>
              )
            }
          </>
        ) : null
      }
      {
        rows.includes(TransactionRows.TX) ? (
          <>
            {
              transaction.payment_currency ? (
                <Button
                  className="table-body__btn -link"
                  tabIndex={-1}
                  onClick={(e: any) => redirectToLink(e, getScanLink('transaction', transaction.payment_currency, transaction.number))}
                  type="button"
                >
                  <span className="table-body__item -name">{truncateHash(transaction.number)}</span>
                </Button>
              ) : (
                <span className="table-body__item -name">{transaction.number}</span>
              )
            }
          </>
        ) : null
      }
    </Link>
  ) : (
    <div className={`table-body__row ${download ? '-download' : ''} -rows_${rows.length}`}>
      {
        rows.includes(TransactionRows.DATE) ? (
          <span className="table-body__item">{dayjs.unix(transaction.date).format('MMM D, h:mm A')}</span>
        ) : null
      }
      {
        rows.includes(TransactionRows.DEPOSITED) ? (
          <span className="table-body__item">{transaction.deposited}</span>
        ) : null
      }
      {
        rows.includes(TransactionRows.CUSTOMER) ? (
          <span className="table-body__item">{transaction.customer}</span>
        ) : null
      }
      {
        rows.includes(TransactionRows.STATUS) ? (
          <span className="table-body__item">
            <span className={`table-body__status -${transaction.status.toLowerCase()}`}>{transaction.status}</span>
          </span>
        ) : null
      }
      {
        rows.includes(TransactionRows.PAID) ? (
          <span className="table-body__item">{transaction.paid}</span>
        ) : null
      }
      {
        rows.includes(TransactionRows.NUMBER) ? (
          <span className="table-body__item -name">{transaction.number}</span>
        ) : null
      }
      {
        rows.includes(TransactionRows.WITHDRAWAL_REQUISITES) ? (
          <>
            {
              transaction.payment_currency ? (
                <Button
                  as={Link}
                  className="table-body__btn -link"
                  tabIndex={-1}
                  to={getScanLink('address', transaction.payment_currency, transaction.number)}
                  target="_blank"
                  type="button"
                >
                  <span className="table-body__item -name">{truncateHash(transaction.number)}</span>
                </Button>
              ) : (
                <span className="table-body__item -name">{transaction.number}</span>
              )
            }
          </>
        ) : null
      }
      {
        rows.includes(TransactionRows.TX) ? (
          <>
            {
              transaction.payment_currency ? (
                <Button
                  as={Link}
                  className="table-body__btn -link"
                  tabIndex={-1}
                  to={getScanLink('transaction', transaction.payment_currency, transaction.number)}
                  target="_blank"
                  type="button"
                >
                  <span className="table-body__item -name">{truncateHash(transaction.number)}</span>
                </Button>
              ) : (
                <span className="table-body__item -name">{transaction.number}</span>
              )
            }
          </>
        ) : null
      }
      {
        download && getPdf ? (
          <span>
            {
              transaction.status.toLowerCase() === 'paid' ? (
                <Button
                  className="table-body__btn"
                  onClick={() => getPdf(transaction.id)}
                  tabIndex={-1}
                  disabled={pdfLoading}
                  type="button"
                >
                  <Icon className="table-body__icon" name="cloud" size="20" />
                </Button>
              ) : null
            }
          </span>
        ) : null
      }
    </div>
  )
}

export default Transactions;
