import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import API from '../../api/executor';
import { setLoading } from '../loadingsErrors/actions';
import {
  ExportPaymentsRequest,
  GetPaymentsRequest,
} from './actionTypes';
import types from '../actionTypes';

// calls
const getPaymentsCall = (payload?: any) => API.call('getPayments', payload);
const exportPaymentsCall = (payload?: any) => API.call('exportPayments', payload);

// call types
type GetPayments = SagaReturnType<typeof getPaymentsCall>;

function* getPayments({ payload }: GetPaymentsRequest) {
  yield put(setLoading(types.GET_PAYMENTS_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  try {
    const res: GetPayments = yield call(() => getPaymentsCall(payload));
    yield all([
      put({
        type: types.GET_PAYMENTS_SUCCESS,
        payload: {
          storeId,
          payments: res.data,
          meta: res.meta
        }
      }),
      put(setLoading(types.GET_PAYMENTS_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.GET_PAYMENTS_REQUEST, false)),
      put({
        type: types.GET_PAYMENTS_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* exportPayments({ payload }: ExportPaymentsRequest) {
  yield put(setLoading(types.EXPORT_PAYMENTS_REQUEST, true));
  try {
    yield call(() => exportPaymentsCall(payload));
    yield all([
      put({
        type: types.EXPORT_PAYMENTS_SUCCESS,
        payload: 'Your export request is being processed, as soon as the file is ready we will send it to your email'
      }),
      put(setLoading(types.EXPORT_PAYMENTS_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.EXPORT_PAYMENTS_REQUEST, false)),
      put({
        type: types.EXPORT_PAYMENTS_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

export default function* walletSagas() {
  yield takeLatest(types.GET_PAYMENTS_REQUEST, getPayments);
  yield takeLatest(types.EXPORT_PAYMENTS_REQUEST, exportPayments);
}
