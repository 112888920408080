import styled from 'styled-components';
import {MenuItem, Select} from "@mui/material";

export const TransactionsWrapper: any = styled.div`
  .table {
    
    &-select {
      cursor: pointer;
    }
    
    &-control {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;
      background: var(--cream);
      border: 0.5px solid var(--dark-white);
      border-radius: 12px;
      
      &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        gap: 11px;
        
        &_search {
          margin-right: auto;
        }
      }
      
      &__statuses {
        display: flex;
        flex-direction: row;
        gap: 8px;

        margin-right: auto;
      }
      
      &__btn {
        padding: 10px 14px;
        color: var(--gray);
        background: transparent;
        border: 0;
        box-shadow: none;
        
        &.-active {
          background: var(--white);
          box-shadow: 0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05);
          border-radius: var(--border-radius);
          color: var(--dark-gray);
        }
      }
    }
    
    &-wrap {
      background: var(--white);
      border: 1px solid var(--dark-white);
      box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 4px 20px -2px rgba(50, 50, 71, 0.08);
      border-radius: var(--border-radius);
      margin-top: 22px;
    }
    
    &-head {
      display: grid;
      grid-gap: 1em;
      align-items: center;
      border-bottom: 1px solid var(--dark-white);
      padding: 12px 23px;

      grid-template-columns: 2fr 1.5fr 2.7fr 1.5fr 2fr 2fr ;

      &.-download {
        grid-template-columns: 2fr 1.5fr 2.7fr 1.5fr 2fr 2fr 28px;
      }

      &.-rows {
        &_4 {
          grid-template-columns: 2fr 1.5fr 1.5fr 2fr;
        }
        &_5 {
          grid-template-columns: 2fr 1.5fr 1.5fr 2fr 2fr;
          &.-download {
            grid-template-columns: 2fr 1.5fr 1.5fr 2fr 2fr 28px;
          }
        }
      }
      
      &__item {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--gray);
        cursor: pointer;
      }
      
      &__arrow {
        font-size: 15px;
      }
    }
    
    &-body {
      &__zero {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 23px;
        border-bottom: 1px solid var(--dark-white);
      }
      &__row {
        display: grid;
        grid-gap: 1em;
        align-items: center;
        border-bottom: 1px solid var(--dark-white);
        padding: 15px 23px;
        grid-template-columns: 2fr 1.5fr 2.7fr 1.5fr 2fr 2fr;
        
        &.-download {
          grid-template-columns: 2fr 1.5fr 2.7fr 1.5fr 2fr 2fr 28px;
        }

        &.-rows {
          &_4 {
            grid-template-columns: 2fr 1.5fr 1.5fr 2fr;
          }
          &_5 {
            grid-template-columns: 2fr 1.5fr 1.5fr 2fr 2fr;
            &.-download {
              grid-template-columns: 2fr 1.5fr 1.5fr 2fr 2fr 28px;
            }
          }
        }
      }
      
      &__btn {
        background: none;
        border: 0;
        outline: none;
        cursor: pointer;
        
        &.-make_paid {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background: none;
          border: 0;
          outline: none;
          cursor: pointer;
          padding: 0;
          margin: 0;
          text-align: right;

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: var(--gray);
            margin-left: 8px;
          }

          & svg {
            stroke: var(--gray);
          }
        }
        
        &.-link {
          padding: 0;
          box-shadow: none;
          height: 100%;
          
          > span {
            text-decoration: underline;
          }
        }
      }
      
      &__item {
        display: flex;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray);

        &.-name {
          font-family: var(--cousine);
          font-weight: 400;
        }
      }
        
      &__status {
        &.-new {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #B54708;
          background: #FFFAEB;
          border-radius: 16px;
          padding: 2px 8px 2px 6px;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 4px;
            width: 12px;
            height: 12px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgNFY2TTYgOEg2LjAwNU0xMSA2QzExIDguNzYxNDIgOC43NjE0MiAxMSA2IDExQzMuMjM4NTggMTEgMSA4Ljc2MTQyIDEgNkMxIDMuMjM4NTggMy4yMzg1OCAxIDYgMUM4Ljc2MTQyIDEgMTEgMy4yMzg1OCAxMSA2WiIgc3Ryb2tlPSIjQjU0NzA4IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-initiated {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #B54708;
          background: #FFFAEB;
          border-radius: 16px;
          padding: 2px 8px 2px 6px;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 4px;
            width: 12px;
            height: 12px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgNFY2TTYgOEg2LjAwNU0xMSA2QzExIDguNzYxNDIgOC43NjE0MiAxMSA2IDExQzMuMjM4NTggMTEgMSA4Ljc2MTQyIDEgNkMxIDMuMjM4NTggMy4yMzg1OCAxIDYgMUM4Ljc2MTQyIDEgMTEgMy4yMzg1OCAxMSA2WiIgc3Ryb2tlPSIjQjU0NzA4IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-pending {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #363F72;
          background: #F8F9FC;
          border-radius: 16px;
          padding: 2px 8px 2px 6px;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 5px;
            width: 12px;
            height: 12px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgM1Y2TDggN00xMSA2QzExIDguNzYxNDIgOC43NjE0MiAxMSA2IDExQzMuMjM4NTggMTEgMSA4Ljc2MTQyIDEgNkMxIDMuMjM4NTggMy4yMzg1OCAxIDYgMUM4Ljc2MTQyIDEgMTEgMy4yMzg1OCAxMSA2WiIgc3Ryb2tlPSIjMzYzRjcyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-paid {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #027A48;
          background: #ECFDF3;
          border-radius: 16px;
          padding: 2px 8px 2px 6px;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 4px;
            width: 12px;
            height: 12px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDUuNTM5OTNWNS45OTk5M0MxMC45OTk0IDcuMDc4MTQgMTAuNjUwMyA4LjEyNzI3IDEwLjAwNDcgOC45OTA4NEM5LjM1OTA4IDkuODU0NDIgOC40NTE2NCAxMC40ODYyIDcuNDE3NjggMTAuNzkxOUM2LjM4MzcyIDExLjA5NzYgNS4yNzg2MyAxMS4wNjA5IDQuMjY3MjQgMTAuNjg3MkMzLjI1NTg0IDEwLjMxMzYgMi4zOTIzMyA5LjYyMjk4IDEuODA1NDggOC43MTg0N0MxLjIxODYzIDcuODEzOTUgMC45Mzk4OTYgNi43NDM5NiAxLjAxMDg0IDUuNjY4MDlDMS4wODE3OCA0LjU5MjIxIDEuNDk4NiAzLjU2ODEgMi4xOTkxNCAyLjc0ODQ3QzIuODk5NjggMS45Mjg4NCAzLjg0NjM5IDEuMzU3NjIgNC44OTgwOSAxLjEyMDAxQzUuOTQ5OCAwLjg4MjM4NyA3LjA1MDEzIDAuOTkxMTAxIDguMDM1IDEuNDI5OTNNMTEgMS45OTk5M0w2IDcuMDA0OTNMNC41IDUuNTA0OTMiIHN0cm9rZT0iIzAyN0E0OCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
        
        &.-confirmed {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #363F72;
          background: #F8F9FC;
          border-radius: 16px;
          padding: 2px 8px 2px 6px;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 5px;
            width: 12px;
            height: 12px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgM1Y2TDggN00xMSA2QzExIDguNzYxNDIgOC43NjE0MiAxMSA2IDExQzMuMjM4NTggMTEgMSA4Ljc2MTQyIDEgNkMxIDMuMjM4NTggMy4yMzg1OCAxIDYgMUM4Ljc2MTQyIDEgMTEgMy4yMzg1OCAxMSA2WiIgc3Ryb2tlPSIjMzYzRjcyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-overpaid {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #5925DC;
          background: #F4F3FF;
          border-radius: 16px;
          padding: 2px 8px 2px 6px;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 6px;
            width: 9px;
            height: 9px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41IDMuNUw0IDFMMS41IDMuNU02LjUgN0w0IDQuNUwxLjUgNyIgc3Ryb2tlPSIjNTkyNURDIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-unpaid {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #B54708;
          background: #FFFAEB;
          border-radius: 16px;
          padding: 2px 8px 2px 6px;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 4px;
            width: 12px;
            height: 12px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgNFY2TTYgOEg2LjAwNU0xMSA2QzExIDguNzYxNDIgOC43NjE0MiAxMSA2IDExQzMuMjM4NTggMTEgMSA4Ljc2MTQyIDEgNkMxIDMuMjM4NTggMy4yMzg1OCAxIDYgMUM4Ljc2MTQyIDEgMTEgMy4yMzg1OCAxMSA2WiIgc3Ryb2tlPSIjQjU0NzA4IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-complete {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #027A48;
          background: #ECFDF3;
          border-radius: 16px;
          padding: 2px 8px 2px 6px;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 4px;
            width: 12px;
            height: 12px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDUuNTM5OTNWNS45OTk5M0MxMC45OTk0IDcuMDc4MTQgMTAuNjUwMyA4LjEyNzI3IDEwLjAwNDcgOC45OTA4NEM5LjM1OTA4IDkuODU0NDIgOC40NTE2NCAxMC40ODYyIDcuNDE3NjggMTAuNzkxOUM2LjM4MzcyIDExLjA5NzYgNS4yNzg2MyAxMS4wNjA5IDQuMjY3MjQgMTAuNjg3MkMzLjI1NTg0IDEwLjMxMzYgMi4zOTIzMyA5LjYyMjk4IDEuODA1NDggOC43MTg0N0MxLjIxODYzIDcuODEzOTUgMC45Mzk4OTYgNi43NDM5NiAxLjAxMDg0IDUuNjY4MDlDMS4wODE3OCA0LjU5MjIxIDEuNDk4NiAzLjU2ODEgMi4xOTkxNCAyLjc0ODQ3QzIuODk5NjggMS45Mjg4NCAzLjg0NjM5IDEuMzU3NjIgNC44OTgwOSAxLjEyMDAxQzUuOTQ5OCAwLjg4MjM4NyA3LjA1MDEzIDAuOTkxMTAxIDguMDM1IDEuNDI5OTNNMTEgMS45OTk5M0w2IDcuMDA0OTNMNC41IDUuNTA0OTMiIHN0cm9rZT0iIzAyN0E0OCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-refunded {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #344054;
          background: #F2F4F7;
          border-radius: 16px;
          padding: 2px 8px 2px 6px;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 5px;
            width: 10px;
            height: 10px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuNSA2TDEgMy41TTEgMy41TDMuNSAxTTEgMy41SDdDNy41MzA0MyAzLjUgOC4wMzkxNCAzLjcxMDcxIDguNDE0MjEgNC4wODU3OUM4Ljc4OTI5IDQuNDYwODYgOSA0Ljk2OTU3IDkgNS41VjkiIHN0cm9rZT0iIzM0NDA1NCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-invalid {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #B42318;
          background: #FEF3F2;
          border-radius: 16px;
          padding: 2px 8px 2px 6px;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 6px;
            width: 9px;
            height: 9px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNyAxTDEgN00xIDFMNyA3IiBzdHJva2U9IiNCNDIzMTgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.-cancelled {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #B42318;
          background: #FEF3F2;
          border-radius: 16px;
          padding: 2px 8px 2px 6px;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 6px;
            width: 9px;
            height: 9px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNyAxTDEgN00xIDFMNyA3IiBzdHJva2U9IiNCNDIzMTgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }
    
    &-pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 23px;
      
      &__empty {
        height: 40px;
      }
    }
    
    &-size {
      display: flex;
      align-items: center;
      padding: 7px 0;
      
      &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
        margin-right: 4px;
      }
    }
    
    &-loading {
      display: grid;
      grid-gap: 1em;
      align-items: center;
      padding: 12px 23px;
      border-bottom: 1px solid var(--dark-white);
      grid-template-columns: 2fr 2fr 2.7fr 1.5fr 2fr 1.5fr 28px;
      
      &__item {
        
      }
    }
  }
`;

export const SelectElement: any = styled(Select)`
  &.MuiInputBase-root {
    width: 100%;
    color: var(--black);
    outline: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    transition: box-shadow 0.3s ease-in-out;
    font-family: var(--inter);
    
    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
    
    .MuiSelect-select {
      padding: 0;
      padding-right: 28px!important;
      user-focus: none;
    }
  }

  .MuiSelect-icon {
    right: -2px;
    color: var(--gray);
  }

  .MuiSelect-selectMenu {
    padding: 12px 40px 12px 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #eaeaea;
    background: #eaeaea;
    box-shadow: #eaeaea;
    border-radius: var(--border-radius);
  }

  .MuiMenu-paper {
    border-radius: var(--border-radius);
    box-shadow: none;
    background: #eaeaea;
  }

  .MuiMenu-list {
  }

  .MuiListItem-button {
    border-radius: 8px;
    padding: 10px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.06) 71.01%,
        rgba(255, 255, 255, 0) 99.52%
      );
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &:hover,
    &:focus {
      background-color: transparent;
      background-image: linear-gradient(
        90deg,
        rgba(232, 232, 232, 0.12) 0%,
        rgba(196, 196, 196, 0) 78.12%
      );
    }

    &.Mui-selected {
      background-color: transparent;
    }
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: transparent;
  }

  &.dropdown-menu {
    background-color: transparent;
    border-radius: 5px;
    width: auto;
    margin-bottom: 0;

    .MuiSelect-select {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: var(--white);
      background-color: transparent;
    }

    .MuiSelect-selectMenu {
      height: auto;
      border-radius: 0;
    }

    .MuiMenu-paper {
      border-radius: var(--border-radius);
      box-shadow: 0 0 11px rgba(0, 0, 0, 0.04);
    }

    .MuiList-root {
      background: linear-gradient(
        to top,
        rgba(147, 90, 237, 0.21) 0.14%,
        rgba(41, 47, 57, 0) 99.86%
      );
      border-radius: 13px;
    }

    .MuiList-padding {
      padding: 11px 8px;
    }

    .MuiListItem-button {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: var(--white);
      border-radius: 8px;

      a {
        color: var(--white);
      }

      &::after {
        display: none;
      }

      &:focus,
      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }

      &.Mui-selected {
        background-color: transparent;
        text-decoration: underline;
      }
    }

    .MuiMenuItem-root {
      min-height: auto;
    }

    &.-disable-rotate {
      &.Mui-focused {
        box-shadow: none;
      }
      & .MuiSelect-iconOpen {
        transform: none;
      }
    }
  }

  &.social-dropdown {
    .MuiSelect-selectMenu {
      padding: 0;
    }

    .MuiSelect-icon {
      top: auto;
      right: auto;
    }
  }
`;

export const SelectListItem: any = styled(MenuItem)``;
