import React, {useCallback, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import {Button, Container, CopyInput, Input, Loader, MainContent, Select, Switch,} from '../../elements';
import {HomeContainer} from "./Home.Styles";
import Transactions, {Transaction, TransactionRows, TransactionType} from "../../components/Transactions/Transactions";
import {PATHS} from "../../const/paths.constants";
import {AppStateType} from "../../store";
import {StoresReducerState} from "../../store/stores/reducers";
import {
  ActivateTFAParams,
  CreateStoreParams,
  GetDocumentParams,
  GetWithdrawalsParams,
  Store,
  WithdrawalItem
} from "../../api";
import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";
import {FormControlLabel} from "@mui/material";
import {SettingsSlider} from "../StoreSettings/StoreSettings.Styles";
import {selectLoadingByKey} from "../../store/loadingsErrors/selectors";
import types from "../../store/actionTypes";
import {createStore} from "../../store/stores/actions";
import useToast from "../../hooks/useToast";
import {activateTFA, clear, getTFA, turnOnTFA} from "../../store/user/actions";
import {DEFAULT_PAGE_LIMIT, LOADING_TYPES} from "../../const/app.constants";
import {SETTINGS_TABS} from "../StoreSettings/StoreSettings";
import {formatNumber} from "../../common/utils/formatters";
import {PaginatedWithdrawals, WithdrawalsReducerState} from "../../store/withdrawals/reducers";
import {getWithdrawals, setWithdrawalsLoading} from "../../store/withdrawals/actions";
import API from "../../api/executor";
import {ApiHandlerResponse} from "../../api/calls";
import {UserReducerState} from "../../store/user/reducers";
import AuthCode, {AuthCodeRef} from "react-auth-code-input";
import QRCode from "react-qr-code";

type HomeProps = {
  user: UserReducerState;
  stores: StoresReducerState;
  withdrawals: WithdrawalsReducerState;
  loading: boolean;
  createLoading: boolean;
  loadingTFA: boolean;
  loadingActivateTFA: boolean;
  createStore: (payload: CreateStoreParams) => void;
  getWithdrawals: (payload: GetWithdrawalsParams) => void;
  getTFA: () => void;
  activateTFA: (payload: ActivateTFAParams) => void;
  turnOnTFA: (payload: ActivateTFAParams) => void;
  clear: () => void;
  setWithdrawalsLoading: (payload: GetWithdrawalsParams) => void;
};

const statusesList = [
  {
    value: "Initiated",
    text: "Initiated"
  }
];

const Home: React.FC<HomeProps> = (props: HomeProps) => {
  const { user, stores, withdrawals, loading, loadingTFA, loadingActivateTFA, createLoading, createStore, getWithdrawals, getTFA, activateTFA, clear, setWithdrawalsLoading } = props;
  const { toastError, toastSuccess } = useToast();
  const { store } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryLimit = searchParams.get('limit') || 0;
  const queryPage = searchParams.get('page') || 0;
  const queryStatus = searchParams.get('status') || null;
  const queryFromDay = searchParams.get('from_day') || null;
  const queryToDay = searchParams.get('to_day') || null;
  const querySortBy = searchParams.get('sort_by') || null;
  const queryDirection = searchParams.get('direction') || null;

  const [activeShop, setActiveShop] = useState<Store | undefined>(undefined);
  const [values, setValues] = useState<{ [key: string]: string }>({
    name: '',
    kind: 'convertible',
    min_btc_confirmations: '1',
    min_eth_confirmations: '12',
    allowed_amount_diff_percent: '10',
    min_btc_tx_amount: '0',
    min_usdteth_tx_amount: '0',
    min_usdceth_tx_amount: '0',
    min_daieth_tx_amount: '0',
    min_usdttrx_tx_amount: '0',
    tfa_code: '',
    password: ''
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [additional, setAdditional] = useState<boolean>(false);
  // const kindsList = [
  //   {
  //     value: "convertible",
  //     text: "Convert all"
  //   },
  //   {
  //     value: "btc_only",
  //     text: "Leave volatile assets without conversion"
  //   }
  // ];

  const [paginatedWithdrawals, setPaginatedWithdrawals] = useState<PaginatedWithdrawals | null>( null);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [update, setUpdate] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<GetWithdrawalsParams>({
    status: queryStatus || '',
    from_day: queryFromDay || '',
    to_day: queryToDay || '',
    limit: queryLimit || '',
    page: queryPage || '',
    sort_by: querySortBy || '',
    direction: queryDirection || ''
  });
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const tfaActivateInputRef = useRef<AuthCodeRef>(null);
  const [showInput, setShowInput] = useState<{ [key: string]: boolean }>({
    password: false,
  });
  const [transactionsLoaded, setTransactionsLoaded] = useState<boolean>(!(withdrawals.withdrawals[stores.activeStore || '']?.loaded === LOADING_TYPES.LOADED));

  const getDate = (date: string | undefined): number => {
    const newDate: Date = date ? new Date(date) : new Date()
    return Math.floor(newDate.getTime() / 1000);
  };

  const createTransactions = (withd: WithdrawalItem[]) => {
    const newTransactions: Transaction[] = withd.map((i: WithdrawalItem): Transaction => {
      return {
        id: i.id || '',
        number: i.address ? i.address : '...',
        date: getDate(i.created_at),
        customer: '-',
        status: TransactionType[i.status as keyof typeof TransactionType],
        paid: '0',
        deposited: `${formatNumber(i.amount, 0, 2)} ${i.currency}`,
        payment_currency: i.currency
      }
    });
    setTransactions(newTransactions)
  };

  const getSortBy = (sort: string) => {
    let newSort = sort;
    switch (sort) {
      case 'number':
        newSort = 'address';
        break;
      case 'paid':
        newSort = 'amount_paid';
        break;
      default:
        break;
    }
    return newSort
  }

  const updateSearchParams = (params: GetWithdrawalsParams) => {
    const queryObj: any = {};
    const newFilterParams = cloneDeep(filterParams);

    queryObj.page = `${params.page}`;
    newFilterParams.page = `${params.page}`;
    queryObj.limit = `${params.limit}`;
    newFilterParams.limit = `${params.limit}`;
    queryObj.status = `${params.status}`;
    newFilterParams.status = `${params.status}`;
    queryObj.from_day = `${params.from_day}`;
    newFilterParams.from_day = `${params.from_day}`;
    queryObj.to_day = `${params.to_day}`;
    newFilterParams.to_day = `${params.to_day}`;
    queryObj.sort_by = `${params.sort_by}`;
    newFilterParams.sort_by = `${params.sort_by}`;
    queryObj.direction = `${params.direction}`;
    newFilterParams.direction = `${params.direction}`;

    setFilterParams(newFilterParams);
    setSearchParams(queryObj);
  }

  useEffect(() => {
    if (stores.stores.loadedShop === LOADING_TYPES.LOADED) {
      if (store !== activeShop?.id) {
        const shop: Store | undefined = find(stores.stores.list, { id: store });
        setActiveShop(shop);
        setValues({
          name: '',
          kind: 'convertible',
          min_btc_confirmations: '1',
          min_eth_confirmations: '12',
          allowed_amount_diff_percent: '10',
          min_btc_tx_amount: '0',
          min_usdteth_tx_amount: '0',
          min_usdceth_tx_amount: '0',
          min_daieth_tx_amount: '0',
          min_usdttrx_tx_amount: '0',
          tfa_code: '',
          password: ''
        })
      }
    }

    if (stores.activeStore && stores.stores.loadedShop === LOADING_TYPES.LOADED) {
      if (activeShop?.has_store_info && activeShop?.has_api_key) {
        if (!withdrawals.withdrawals[stores.activeStore] || withdrawals.withdrawals[stores.activeStore]?.loaded === LOADING_TYPES.NOT_LOADED) {
          getWithdrawals({
            storeId: stores.activeStore,
            status: filterParams.status,
            from_day: filterParams.from_day,
            to_day: filterParams.to_day,
            page: filterParams.page || 1,
            limit: filterParams.limit || DEFAULT_PAGE_LIMIT,
            sort_by: getSortBy(filterParams.sort_by || '') || 'created_at',
            direction: filterParams.direction || 'desc'
          })
        }

        if (!transactionsLoaded) {
          setTransactionsLoaded(true);
          // setWithdrawalsLoading({
          //   storeId: stores.activeStore,
          //   status: filterParams.status,
          //   from_day: filterParams.from_day,
          //   to_day: filterParams.to_day,
          //   page: filterParams.page || 1,
          //   limit: filterParams.limit || DEFAULT_PAGE_LIMIT,
          //   sort_by: getSortBy(filterParams.sort_by || '') || 'created_at',
          //   direction: filterParams.direction || 'desc'
          // });
        }

        if (withdrawals.withdrawals[activeShop.id]?.loaded === LOADING_TYPES.LOADED && transactionsLoaded) {
          if (transactions.length === 0 && withdrawals.withdrawals[activeShop.id].list.length > 0) {
            const pagWithdrawals = withdrawals.withdrawals[activeShop.id];
            createTransactions(pagWithdrawals.list)
            setPaginatedWithdrawals(pagWithdrawals)
            updateSearchParams({
              limit: pagWithdrawals.limit,
              page: pagWithdrawals.page,
              status: pagWithdrawals.status,
              from_day: pagWithdrawals.from_day,
              to_day: pagWithdrawals.to_day,
              sort_by: pagWithdrawals.sort_by,
              direction: pagWithdrawals.direction,
            })
          }

          if (
            update &&
            withdrawals.withdrawals[activeShop.id].status === filterParams.status &&
            withdrawals.withdrawals[activeShop.id].from_day === filterParams.from_day &&
            withdrawals.withdrawals[activeShop.id].to_day === filterParams.to_day &&
            withdrawals.withdrawals[activeShop.id].page === Number(filterParams.page) &&
            withdrawals.withdrawals[activeShop.id].limit === Number(filterParams.limit) &&
            withdrawals.withdrawals[activeShop.id].sort_by === filterParams.sort_by &&
            withdrawals.withdrawals[activeShop.id].direction === filterParams.direction
          ) {
            setUpdate(false);
            createTransactions(withdrawals.withdrawals[activeShop.id].list);
            setPaginatedWithdrawals(withdrawals.withdrawals[activeShop.id]);
          }
        }
      }
    }

    if (user.answer?.error?.error) {
      toastError(user.answer?.error?.error);

      if (user.answer?.error?.error === '2fa code is invalid') {
        tfaActivateInputRef.current?.clear();
        tfaActivateInputRef.current?.focus();
      }

      if (user.answer?.error?.error === 'Max number of failed attempts reached. All action with 2FA blocked. Please contact with the administration') {
        tfaActivateInputRef.current?.clear();
        tfaActivateInputRef.current?.focus();
      }

      if (user.answer?.error?.error === 'Invalid password') {
        setErrors({
          password: 'Invalid password'
        });
      }
      clear();
    }

    if (user.answer?.success) {
      toastSuccess(user.answer?.success);
      clear();
    }

    if (stores.answer?.error?.error) {
      toastError(stores.answer?.error?.error);

      clear();
    }

    if (stores.answer?.success) {
      toastSuccess(stores.answer?.success);
      clear();
    }
  }, [stores, activeShop, setActiveShop, toastError, toastSuccess, clear, setValues, store, withdrawals.withdrawals, getWithdrawals, transactions.length, update, filterParams, createTransactions, updateSearchParams, user.tfa.loaded, getTFA]);

  const onUpdateFilterParams = (params: GetWithdrawalsParams) => {
    const newParams = cloneDeep(params);
    updateSearchParams(newParams)
    newParams.storeId = activeShop?.id || '';
    newParams.sort_by = getSortBy(newParams.sort_by);
    setUpdate(true);
    getWithdrawals(newParams)
  }

  let getFormErrors: (data: { [p: string]: string }) => CreateStoreParams;
  getFormErrors = (data: { [key: string]: string }) => {
    const {name, kind, min_btc_confirmations, min_eth_confirmations} = data;
    const newErrors: CreateStoreParams = {
      name: '',
      kind: '',
      min_btc_confirmations: '',
      min_eth_confirmations: '',
    };

    if (!name) newErrors.name = 'Enter name';
    if (!kind) newErrors.kind = 'Select kind';
    if (!Number(min_btc_confirmations)) newErrors.min_btc_confirmations = 'Must be more then 0';
    if (!Number(min_eth_confirmations)) newErrors.min_eth_confirmations = 'Must be more then 0';

    return newErrors;
  };

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: CreateStoreParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: CreateStoreParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onCreateNewShop = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: CreateStoreParams = getFormErrors(values);
      setErrors(newErrors);

      const data: CreateStoreParams = {
        name: values.name,
        kind: values.kind,
        min_btc_confirmations: values.min_btc_confirmations,
        min_eth_confirmations: values.min_eth_confirmations,
        allowed_amount_diff_percent: values.allowed_amount_diff_percent,
        min_btc_tx_amount: values.min_btc_tx_amount,
        min_usdteth_tx_amount: values.min_usdteth_tx_amount,
        min_usdceth_tx_amount: values.min_usdceth_tx_amount,
        min_daieth_tx_amount: values.min_daieth_tx_amount,
        min_usdttrx_tx_amount: values.min_usdttrx_tx_amount,
      };
      if (!checkErrors(newErrors)) {
        createStore(data);
      }
    },
    [values, getFormErrors, createStore]
  );

  const handleGetPdf = async (id?: string) => {
    const documentProps: GetDocumentParams = {
      storeId: stores.activeStore || '',
      type: 'withdrawals',
      id: id || '',
    };
    const getDocumentCall = (payload: GetDocumentParams) => API.call('getDocument', payload);

    setPdfLoading(true);
    await getDocumentCall(documentProps).then((documentBlob: ApiHandlerResponse<'getDocument'>) => {
      const blobUrl = URL.createObjectURL(documentBlob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.target = '_blank';
      setPdfLoading(false);

      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
      document.body.removeChild(link);
    });
  };

  const onEnableTfa = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: any = errors;

      if (!user.tfa.tfa_confirmed) {
        if (!values.tfa_code) newErrors.password = 'Enter password';
      }

      const tfaData: ActivateTFAParams = {
        tfa_code: values.tfa_code
      };

      if (!checkErrors(newErrors)) {
        if (user.tfa.tfa_confirmed) {
          turnOnTFA(tfaData);
        } else {
          tfaData.password = values.password;
          activateTFA(tfaData);
        }
      }
    },
    [errors, user.tfa.tfa_confirmed, values.tfa_code, values.password, activateTFA]
  );

  const handleAuthCode = (res: string) => {
    onChange('tfa_code', res);
  };

  const onToggleShow = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(showInput, field)) {
      setShowInput({
        ...showInput,
        [field]: !showInput[field],
      });
    }
  };

  return (
    <MainContent className="content-main">
      <Container>
        <HomeContainer>
          {
            loading ? null : (
              <>
                {
                  !store ? (
                    <>
                      {
                        !user.useTestnet && !user.tfa.tfa_confirmed ? (
                          <>
                            {
                              loadingTFA ? (
                                <Loader />
                              ) : (
                                <div className="tfa">
                                  <div className="tfa-title__wrap">
                                    <span className="tfa-title">Two-factor authentication</span>
                                  </div>

                                  <form onSubmit={onEnableTfa}>
                                    <div className="grid-x tfa-wrap">
                                      <div className="cell small-4 xlarge-3 tfa-row">
                                        <p className="tfa-item">Scan QR Code</p>
                                      </div>
                                      <div className="cell small-8 xlarge-9 tfa-row">
                                        <div className="tfa-qrcode">
                                          {
                                            user.tfa.provisioning_uri ? (
                                              <QRCode value={user.tfa.provisioning_uri} size={224} />
                                            ) : null
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className="grid-x tfa-wrap">
                                      <div className="cell small-4 xlarge-3 tfa-row">
                                        <p className="tfa-item">Or Copy Code Into Your App</p>
                                      </div>
                                      <div className="cell small-8 xlarge-9 tfa-row">
                                        <div className="tfa-copy">
                                          <CopyInput
                                            name="secretKey"
                                            type="text"
                                            value={user.tfa.secret || ''}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="grid-x tfa-wrap">
                                      <div className="cell small-4 xlarge-3 tfa-row">
                                        <p className="tfa-item">Verify Code</p>
                                      </div>
                                      <div className="cell small-8 xlarge-9 tfa-row">
                                        <div className="tfa-code__wrap">
                                          <AuthCode
                                            ref={tfaActivateInputRef}
                                            containerClassName="tfa-code__container"
                                            inputClassName="tfa-code"
                                            allowedCharacters="numeric"
                                            length={6}
                                            placeholder="0"
                                            autoFocus={false}
                                            onChange={handleAuthCode}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="grid-x tfa-wrap">
                                      <div className="cell small-4 xlarge-3 tfa-row">
                                        <p className="tfa-item">Your Password</p>
                                      </div>
                                      <div className="cell small-8 xlarge-9 tfa-row">
                                        <div className="tfa-code__wrap">
                                          <Input
                                            className='login-input'
                                            type="password"
                                            name="password"
                                            value={values.password}
                                            placeholder="Password"
                                            error={errors.password}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            show={showInput.password}
                                            onShow={onToggleShow}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="tfa-btns">
                                      <Button
                                        className="loading-btn beneficial-btn"
                                        type="submit"
                                        disabled={values.tfa_code.length !== 6 || loadingActivateTFA}
                                      >
                                        Activate
                                        {loadingActivateTFA ? <Loader /> : null}
                                      </Button>
                                    </div>
                                  </form>

                                </div>
                              )
                            }
                          </>
                        ) : (
                          <>
                            <div className="shop">
                              <span className="shop-title">Create new shop</span>
                            </div>
                            <form onSubmit={onCreateNewShop}>
                              <div className="grid-x create">
                                <div className="cell small-12 xlarge-55">
                                  <Input
                                    className='create-input'
                                    type="text"
                                    name="name"
                                    value={values.name}
                                    placeholder="Shop name"
                                    label="Shop name"
                                    error={errors.name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    disabled={createLoading}
                                  />
                                  {/*<Select*/}
                                  {/*  className="create-select"*/}
                                  {/*  name="kind"*/}
                                  {/*  value={values.kind}*/}
                                  {/*  placeholder="Kind of your store"*/}
                                  {/*  error={errors.kind}*/}
                                  {/*  list={kindsList}*/}
                                  {/*  label="Kind of your store"*/}
                                  {/*  fullWidth*/}
                                  {/*  onChange={onChange}*/}
                                  {/*  disabled={createLoading}*/}
                                  {/*/>*/}
                                </div>
                                <div className="cell small-12">
                                  <div className='create-additional'>
                                    <div className="create-additional__title_wrap">
                                      <span className="create-additional__title">Do you want to specify advanced settings?</span>
                                    </div>
                                    <div className="create-additional__row">
                                      <FormControlLabel
                                        className="switch"
                                        control={
                                          <Switch
                                            className="create-additional__switch"
                                            checked={additional}
                                            onChange={() => setAdditional(!additional)}
                                            name="additional"
                                            color="primary"
                                            disabled={createLoading}
                                          />
                                        }
                                        label="Advanced settings"
                                      />

                                      {
                                        additional ? (
                                          <div className='create-additional__wrap'>
                                            <div className="grid-x grid-margin-x">
                                              <div className="cell small-12 xlarge-55">
                                                <div className="create-count__wrap">
                                                  <span className="create-count">Allowed amount diff percent: {values.allowed_amount_diff_percent}%</span>
                                                </div>
                                                <SettingsSlider
                                                  value={Number(values.allowed_amount_diff_percent)}
                                                  onChange={(event: Event, value: number) => onChange('allowed_amount_diff_percent', String(value))}
                                                  disabled={createLoading}
                                                />
                                              </div>
                                              <div className="cell small-12 xlarge-55">
                                                <Select
                                                  className="create-select"
                                                  name="min_btc_confirmations"
                                                  value={values.min_btc_confirmations}
                                                  placeholder="Minimum confirmations for BTC"
                                                  error={errors.min_btc_confirmations}
                                                  list={Array.from(Array(6)).map((num, index) => {
                                                    return {
                                                      value: `${index + 1}`,
                                                      text: `${index + 1}`,
                                                    }
                                                  })}
                                                  label="Minimum confirmations for BTC"
                                                  fullWidth
                                                  onChange={onChange}
                                                  disabled={createLoading}
                                                />
                                              </div>
                                              <div className="cell small-12 xlarge-55">
                                                <Select
                                                  className="create-select"
                                                  name="min_eth_confirmations"
                                                  value={values.min_eth_confirmations}
                                                  placeholder="Minimum confirmations for ETH"
                                                  error={errors.min_eth_confirmations}
                                                  list={Array.from(Array(60)).map((num, index) => {
                                                    return {
                                                      value: `${index + 1}`,
                                                      text: `${index + 1}`,
                                                    }
                                                  })}
                                                  label="Minimum confirmations for ETH"
                                                  fullWidth
                                                  onChange={onChange}
                                                  disabled={createLoading}
                                                />
                                              </div>

                                              <div className="cell small-12 xlarge-55">
                                                <Input
                                                  className='create-input'
                                                  type="number"
                                                  numberType="number"
                                                  name="min_btc_tx_amount"
                                                  value={values.min_btc_tx_amount}
                                                  placeholder="Minimum transaction amount for BTC"
                                                  label="Minimum transaction amount for BTC"
                                                  error={errors.min_btc_tx_amount}
                                                  maxLength={20}
                                                  onChange={onChange}
                                                  onBlur={onBlur}
                                                  disabled={createLoading}
                                                />
                                              </div>

                                              <div className="cell small-12 xlarge-55">
                                                <Input
                                                  className='create-input'
                                                  type="number"
                                                  numberType="number"
                                                  name="min_usdteth_tx_amount"
                                                  value={values.min_usdteth_tx_amount}
                                                  placeholder="Minimum transaction amount for USDT"
                                                  label="Minimum transaction amount for USDT"
                                                  error={errors.min_usdteth_tx_amount}
                                                  maxLength={20}
                                                  onChange={onChange}
                                                  onBlur={onBlur}
                                                  disabled={createLoading}
                                                />

                                                <Input
                                                  className='create-input'
                                                  type="number"
                                                  numberType="number"
                                                  name="min_usdttrx_tx_amount"
                                                  value={values.min_usdttrx_tx_amount}
                                                  placeholder="Minimum transaction amount for USDT in TRX"
                                                  label="Minimum transaction amount for USDT in TRX"
                                                  error={errors.min_usdttrx_tx_amount}
                                                  maxLength={20}
                                                  onChange={onChange}
                                                  onBlur={onBlur}
                                                  disabled={createLoading}
                                                />
                                              </div>
                                              <div className="cell small-12 xlarge-55">
                                                <Input
                                                  className='create-input'
                                                  type="number"
                                                  numberType="number"
                                                  name="min_usdceth_tx_amount"
                                                  value={values.min_usdceth_tx_amount}
                                                  placeholder="Minimum transaction amount for USDC"
                                                  label="Minimum transaction amount for USDC"
                                                  error={errors.min_usdceth_tx_amount}
                                                  maxLength={20}
                                                  onChange={onChange}
                                                  onBlur={onBlur}
                                                  disabled={createLoading}
                                                />

                                                <Input
                                                  className='create-input'
                                                  type="number"
                                                  numberType="number"
                                                  name="min_daieth_tx_amount"
                                                  value={values.min_daieth_tx_amount}
                                                  placeholder="Minimum transaction amount for DAI"
                                                  label="Minimum transaction amount for DAI"
                                                  error={errors.min_daieth_tx_amount}
                                                  maxLength={20}
                                                  onChange={onChange}
                                                  onBlur={onBlur}
                                                  disabled={createLoading}
                                                />

                                              </div>
                                            </div>
                                          </div>
                                        ) : null
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div className="cell small-12 xlarge-55">
                                  <Button
                                    className="create-btn"
                                    type="submit"
                                    disabled={createLoading}
                                  >
                                    Create
                                    {createLoading ? <Loader /> : null}
                                  </Button>
                                </div>
                              </div>
                            </form>
                          </>
                        )
                      }
                    </>
                  ) : (
                    <>
                      <div className="shop">
                        <span className="shop-title">{activeShop?.name}</span>
                        <span className="shop-id">ID {activeShop?.id}</span>
                      </div>

                      {
                        activeShop?.has_store_info && activeShop?.has_api_key && activeShop?.has_invoice ? (
                          <>
                            <div className="balance">
                              <div className="balance-head">
                                <span className="balance-head__title">Balance</span>
                                <div className="balance-head__btns">
                                  <Button
                                    as={Link}
                                    className="balance-head__btn"
                                    to={PATHS.STORE_SETTINGS.replace(':store', `${stores.activeStore}`)}
                                  >
                                    Settings
                                  </Button>
                                  {/*<Button*/}
                                  {/*  as={Link}*/}
                                  {/*  className="balance-head__btn -withdraw"*/}
                                  {/*  to={PATHS.WITHDRAW.replace(':store', `${stores.activeStore}`)}*/}
                                  {/*>*/}
                                  {/*  Withdraw*/}
                                  {/*</Button>*/}
                                </div>
                              </div>
                              <div className="balance-tokens">
                                <div className="balance-token">
                                  <img className="balance-token__img" src="/img/tokens/usdx.svg" alt="Tier" />
                                  <span className="balance-token__title">USDX</span>
                                  <div className="balance-token__amounts">
                                    <span className="balance-token__amount">{formatNumber(activeShop?.balance || '0')} USDX</span>
                                    <span className="balance-token__amount -usd">{formatNumber(activeShop?.balance || '0')} USD</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="settlement">
                              <span className="settlement-title">Settlement</span>
                              <div className="settlement-wrap">
                                <Transactions
                                  items={transactions}
                                  loading={loading}
                                  statusesList={statusesList}
                                  from_day={queryFromDay || paginatedWithdrawals?.from_day}
                                  to_day={queryToDay || paginatedWithdrawals?.to_day}
                                  status={queryStatus || paginatedWithdrawals?.status}
                                  sort_by={querySortBy || paginatedWithdrawals?.sort_by}
                                  direction={queryDirection || paginatedWithdrawals?.direction}
                                  paginationLimit={queryLimit || paginatedWithdrawals?.limit}
                                  paginationPage={queryPage || paginatedWithdrawals?.page}
                                  total_count={paginatedWithdrawals?.total_count || paginatedWithdrawals?.total_count || 0}
                                  updateFilterParams={onUpdateFilterParams}
                                  rows={[TransactionRows.WITHDRAWAL_REQUISITES, TransactionRows.DATE, TransactionRows.PAID, TransactionRows.DEPOSITED, TransactionRows.STATUS]}
                                  pdfLoading={pdfLoading}
                                  getPdf={handleGetPdf}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {
                              activeShop ? (
                                <div className="stages">
                                  <div className={`stages-stage ${store ? '-done' : ''}`}>
                                    <div className="stages-stage__circle" />
                                    <span className="stages-stage__title">Create Store</span>
                                    <Button
                                      as={Link}
                                      className='stages-stage__btn'
                                      to="#"
                                      tabIndex={-1}
                                      disabled
                                    >
                                      Create
                                    </Button>
                                  </div>
                                  <div className={`stages-stage ${activeShop?.has_store_info ? '-done' : '-progress'} -next`}>
                                    <div className="stages-stage__circle" />
                                    <span className="stages-stage__title">Company information</span>
                                    <Button
                                      as={Link}
                                      className='stages-stage__btn'
                                      to={`${PATHS.STORE_SETTINGS.replace(':store', `${stores.activeStore}`)}?tab=${SETTINGS_TABS.STORE_DETAILS}`}
                                      disabled={activeShop?.has_store_info || loading}
                                      tabIndex={activeShop?.has_store_info ? -1 : 0}
                                    >
                                      Settings
                                    </Button>
                                  </div>
                                  <div className={`stages-stage ${activeShop?.has_api_key ? '-done' : '-progress'} ${activeShop?.has_store_info ? '-next' : ''}`}>
                                    <div className="stages-stage__circle" />
                                    <span className="stages-stage__title">Integrate</span>
                                    <Button
                                      as={Link}
                                      className='stages-stage__btn'
                                      to={PATHS.DEVELOPERS.replace(':store', `${stores.activeStore}`)}
                                      disabled={activeShop?.has_api_key || loading}
                                      tabIndex={activeShop?.has_api_key ? -1 : 0}
                                    >
                                      Developers
                                    </Button>
                                  </div>
                                  <div className={`stages-stage ${activeShop?.has_store_info && activeShop?.has_api_key && activeShop?.has_invoice ? '-done' : '-progress'} ${activeShop?.has_api_key ? '-next' : ''}`}>
                                    <div className="stages-stage__circle" />
                                    <span className="stages-stage__title">Start</span>
                                    <Button
                                      as={Link}
                                      className='stages-stage__btn'
                                      to={PATHS.CREATE_INVOICE.replace(':store', `${stores.activeStore}`)}
                                      disabled={activeShop?.has_invoice || loading}
                                    >
                                      Go
                                    </Button>
                                  </div>
                                </div>
                              ) : null
                            }
                          </>
                        )
                      }
                    </>
                  )
                }
              </>
            )
          }
        </HomeContainer>
      </Container>
    </MainContent>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { user, stores, withdrawals } = state;
  return {
    user,
    stores,
    withdrawals,
    loading: selectLoadingByKey(state, types.GET_STORES_REQUEST),
    createLoading: selectLoadingByKey(state, types.CREATE_STORE_REQUEST),
    loadingTFA: selectLoadingByKey(state, types.GET_TFA_REQUEST),
    loadingActivateTFA: selectLoadingByKey(state, types.ACTIVATE_TFA_REQUEST),
  };
};

export default connect(mapStateToProps, { createStore, getWithdrawals, getTFA, turnOnTFA, activateTFA, clear, setWithdrawalsLoading })(Home);
